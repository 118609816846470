import React from 'react';
import { Link } from 'react-router-dom';
import images from '../../../assets/images';

const Access = () => {
  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner ">
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height sell-area-body">
                  <div className="card-body">
                    <section>
                      <div className="center-heading black-text">
                        <h1>Sell direct access to your followers </h1>
                        <p>
                          Pick from our recommended items or{' '}
                          <span className="blue-text">create your own</span>, connect with people on
                          a deeper level and earn money.
                        </p>
                      </div>
                      <div className="sell-area">
                        <div className="row">
                          <div className="col-md-12">
                            <div className=" sell-box">
                              <div className="sell-icon ">
                                <img src={images.accessOne} alt="" />
                              </div>
                              <div className="sell-text ">
                              <h3>I'll send you a video message</h3>
                              <p>
                                Get a short video message from me, perfect for birthdays or just to
                                make someones day.
                              </p>
                              </div>
                              <Link to="/app/ideas" className="next-btn  ">
                                <i className="fa fa-angle-right" aria-hidden="true"/>
                              </Link>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className=" sell-box">
                              <div className="sell-icon ">
                              <img src={images.accessTwo} alt="" />
                              </div>
                              <div className="sell-text ">
                              <h3>I'll Follow you on social media</h3>
                              <p>I'll follow you on the social platform of your chosing.</p>
                              </div>
                              <Link to="/app/ideas" className="next-btn ">
                                <i className="fa fa-angle-right" aria-hidden="true"/>
                              </Link>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className=" sell-box">
                              <div className="sell-icon ">
                              <img src={images.accessTwo} alt="" />
                              </div>
                              <div className="sell-text ">
                              <h3>I'll promote you on social media</h3>
                              <p>
                                I'll promote your business across all of my social media accounts.
                              </p>
                              </div>
                              <Link to="/app/ideas" className="next-btn ">
                                <i className="fa fa-angle-right" aria-hidden="true"/>
                              </Link>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className=" sell-box">
                              <div className="sell-icon ">
                              <img src={images.accessThree} alt="" />
                              </div>
                              <div className="sell-text ">
                              <h3>We'll have a short phone call</h3>
                              <p>
                                I'll call you or whoever you tell me to call for a quick phone
                                conversation.
                              </p>
                                </div>
                              <Link to="/app/ideas" className="next-btn ">
                                <i className="fa fa-angle-right" aria-hidden="true"/>
                              </Link>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className=" sell-box">
                              <div className="sell-icon ">
                              <img src={images.accessFour} alt="" />
                              </div>
                              <div className="sell-text ">
                              <h3>I'll direct message your family or friend</h3>
                              <p>
                                I'll send a direct message to someone you care about saying whatever
                                you chose.
                              </p>
                              </div>
                              <Link to="/app/ideas" className="next-btn ">
                                <i className="fa fa-angle-right" aria-hidden="true"/>
                              </Link>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className=" sell-box">
                              <div className="sell-icon ">
                              <img src={images.accessFive} alt="" />
                              </div>
                              <div className="sell-text ">
                              <h3>I'll send your loved one a card for a special occasion</h3>
                              <p>
                                I'll mail a physical card to whoever you want for a special
                                occasion.
                              </p>
                              </div>
                              <Link to="/app/ideas" className="next-btn ">
                              <i className="fa fa-angle-right" aria-hidden="true"/>
                              </Link>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className=" sell-box">
                              <div className="sell-icon ">
                              <img src={images.accessSix} alt="" />
                              </div>
                              <div className="sell-text ">
                              <h3>Ask me a question with a guaranteed reply</h3>
                              <p>Ask me anything you like and make sure I respond!</p>
                              </div>
                              <Link to="/app/ideas" className="next-btn ">
                                <i className="fa fa-angle-right" aria-hidden="true"/>
                              </Link>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className=" sell-box active-sell">
                              <div className="sell-icon ">
                              <img src={images.accessSeven} alt="" />
                              </div>
                              <div className="sell-text ">
                              <h3>Offer something else</h3>
                              <p>
                                Nobody knows your followers better than you, sell anything and
                                anything.
                              </p>
                              </div>
                              <Link to="/app/ideas" className="next-btn ">
                                <i className="fa fa-angle-right" aria-hidden="true"/>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Access;
