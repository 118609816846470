import React, { useEffect, useRef } from 'react';
import Embedo from 'embedo';

// Initialize embedo instance
const embedo = new Embedo({
  facebook: {
    version: 'v8.0',
    appId: '180015362652616',
    access_token: '180015362652616|fb4b1e9203a607f5e9d9d49c666ee200',
    xfbml: true,
  },
  twitter: true,
  instagram: {
    access_token: '180015362652616|fb4b1e9203a607f5e9d9d49c666ee200',
  },
  pinterest: true,
});

const EmbedoItem = ({ id, name, href, ...props }) => {
  const ref = useRef();

  useEffect(() => {
    const options = props['data-options'] || {};
    const width = props['data-width'];
    const height = props['data-height'];

    if (width) {
      options.width = width;
    }

    if (height) {
      options.height = height;
    }

    embedo
      .load(ref.current, href, options)
      .done((data) => {
        // console.log('done', data);
      })
      .fail((err) => {
        console.error('error', err);
      });
  }, [href]);

  return (
    // <a className="embedo-item iframe-video" ref={ref} key={id} href={href} target="_blank">
    <ul className="preview-videos-list">
      <a href={href} ref={ref} key={id} target="_blank">
        <li className="videos-list">
          <iframe
            width="1019"
            height="573"
            src={href}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </li>
        <h2>{name}</h2>
      </a>
    </ul>

    // </a>
  );
};

export default EmbedoItem;
