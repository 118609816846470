export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const AUTHENTICATE = 'AUTHENTICATE';
export const SET_CURRENT_BIOLINK = 'SET_CURRENT_BIOLINK';
export const DELETE_BIOLINK = 'DELETE_BIOLINK';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_STRIPE_SESSION_ID = 'UPDATE_STRIPE_SESSION_ID';
export const UPDATE_PLANS = 'UPDATE_PLANS';
export const UPDATE_BIOLINK = 'UPDATE_BIOLINK';
export const UPDATE_DARKMODE_SETTING = 'UPDATE_DARKMODE_SETTING';
export const UPLOAD_BIOLINK_COVER_PHOTO = 'UPLOAD_BIOLINK_COVER_PHOTO';
export const UPLOAD_BIOLINK_PROFILE_PHOTO = 'UPLOAD_BIOLINK_PROFILE_PHOTO';
export const IMPORT_BIOLINK_DETAILS_FROM_LINKTREE_PROFILE =
  'IMPORT_BIOLINK_DETAILS_FROM_LINKTREE_PROFILE';
export const UPDATE_CONTACT_BUTTON_SETTINGS = 'UPDATE_CONTACT_BUTTON_SETTINGS';
export const CREATE_NEW_LINK = 'CREATE_NEW_LINK';
export const SORT_BIOLINK_LINKS = 'SORT_BIOLINK_LINKS';
export const REMOVE_LINK = 'REMOVE_LINK';
export const UPDATE_SOCIAL_ACCOUNTS_SETTINGS = 'UPDATE_SOCIAL_ACCOUNTS_SETTINGS';
export const UPDATE_INTEGRATION_SETTINGS = 'UPDATE_INTEGRATION_SETTINGS';
export const UPDATE_DIRECTORY_SETTINGS = 'UPDATE_DIRECTORY_SETTINGS';
export const UPDATE_UTM_PARAMETER_SETTINGS = 'UPDATE_UTM_PARAMETER_SETTINGS';
export const UPDATE_SEO_SETTINGS = 'UPDATE_SEO_SETTINGS';
export const UPDATE_BRANDING_SETTINGS = 'UPDATE_BRANDING_SETTINGS';
export const UPDATE_PRIVACY_SETTINGS = 'UPDATE_PRIVACY_SETTINGS';
export const UPDATE_DONATION_SETTINGS = 'UPDATE_DONATION_SETTINGS';

const getCurrentBiolink = (user) => {
  let currentBiolink = [];

  if (user.currentBiolinkId) {
    currentBiolink = user.biolinks
      ?.slice()
      .filter((biolink) => biolink.id === user.currentBiolinkId);
  } else {
    const biolinks = user.biolinks?.slice().sort((a, b) => {
      if (parseInt(a.createdAt, 10) > parseInt(b.createdAt, 10)) return 1;
      if (parseInt(a.createdAt, 10) < parseInt(b.createdAt, 10)) return -1;
      return 0;
    });
    currentBiolink = biolinks;
  }
  return currentBiolink[0];
};

export const authenticate = (user, isAuthenticated) => {
  let currentBiolink = {};
  if (isAuthenticated) {
    currentBiolink = getCurrentBiolink(user);
  }
  console.log(`currentBiolink`, currentBiolink);
  return {
    type: AUTHENTICATE,
    user,
    currentBiolink,
    isAuthenticated,
  };
};

export const setCurrentBiolink = (item) => {
  return {
    type: SET_CURRENT_BIOLINK,
    currentBiolink: item,
  };
};

export const updateUserData = (user) => {
  return {
    type: UPDATE_USER_DATA,
    user,
  };
};

export const deleteBiolink = (biolinkId) => {
  return {
    type: DELETE_BIOLINK,
    biolinkId,
  };
};

export const login = (user) => {
  const currentBiolink = getCurrentBiolink(user);

  return {
    type: LOGIN,
    user,
    currentBiolink,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const updateStripeSessionId = (id) => {
  return {
    type: UPDATE_STRIPE_SESSION_ID,
    id,
  };
};

export const updatePlans = (plans) => {
  return {
    type: UPDATE_PLANS,
    plans,
  };
};

export const updateBiolink = (biolink) => {
  return {
    type: UPDATE_BIOLINK,
    biolink,
  };
};

export const updateDarkmodeSetting = (biolink) => {
  return {
    type: UPDATE_DARKMODE_SETTING,
    biolink,
  };
};

export const uploadBiolinkCoverPhoto = (biolink) => {
  return {
    type: UPLOAD_BIOLINK_COVER_PHOTO,
    biolink,
  };
};

export const uploadBiolinkProfilePhoto = (biolink) => {
  return {
    type: UPLOAD_BIOLINK_PROFILE_PHOTO,
    biolink,
  };
};

export const importBiolinkDetailsFromLinktreeProfile = (biolink) => {
  return {
    type: IMPORT_BIOLINK_DETAILS_FROM_LINKTREE_PROFILE,
    biolink,
  };
};

export const updateContactButtonSettings = (biolink) => {
  return {
    type: UPDATE_CONTACT_BUTTON_SETTINGS,
    biolink,
  };
};

export const createNewLink = (link) => {
  return {
    type: CREATE_NEW_LINK,
    link,
  };
};

export const sortBiolinkLinks = (biolink) => {
  return {
    type: SORT_BIOLINK_LINKS,
    biolink,
  };
};

export const removeLink = (link) => {
  return {
    type: REMOVE_LINK,
    link,
  };
};

export const updateSocialAccountsSettings = (biolink) => {
  return {
    type: UPDATE_SOCIAL_ACCOUNTS_SETTINGS,
    biolink,
  };
};

export const updateIntegrationSettings = (biolink) => {
  return {
    type: UPDATE_INTEGRATION_SETTINGS,
    biolink,
  };
};

export const updateDirectorySettings = (biolink) => {
  return {
    type: UPDATE_DIRECTORY_SETTINGS,
    biolink,
  };
};

export const updateUTMParameterSettings = (biolink) => {
  return {
    type: UPDATE_UTM_PARAMETER_SETTINGS,
    biolink,
  };
};

export const updateSEOSettings = (biolink) => {
  return {
    type: UPDATE_SEO_SETTINGS,
    biolink,
  };
};

export const updateBrandingSettings = (biolink) => {
  return {
    type: UPDATE_BRANDING_SETTINGS,
    biolink,
  };
};

export const updatePrivacySettings = (biolink) => {
  return {
    type: UPDATE_PRIVACY_SETTINGS,
    biolink,
  };
};

export const updateDonationSettings = (biolink) => {
  return {
    type: UPDATE_DONATION_SETTINGS,
    biolink,
  };
};
