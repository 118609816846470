import React, { useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import images from '../../assets/images';
import Claim from './Claim';

const Faq = (props) => {
  const [show, setShow] = useState(false);

  const [sections, setSections] = useState([
    {
      title: 'How simple is it to edit my Stash.ee profile?',
      content:
        'Our tool is simple and effective. We give you exactly what you need to showcase your links, stack your content, and update your bio as you grow.',
      isOpen: false,
    },
    {
      title: 'What do I get if I purchase Professional Plan for Stash.ee?',
      content:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      isOpen: false,
    },
    {
      title: 'How can i login to my Stash.ee account?',
      content:
        ' Lorem Ipsum is simply dummy text of the printing and typesetting industryLorem Ipsum is simply dummy text of the printing and typesetting industry',
      isOpen: false,
    },
    {
      title: 'Can I integrate my all social media pages?',
      content:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      isOpen: false,
    },
    {
      title: 'How simple is it to edit my Stash.ee profile?',
      content:
        'Our tool is simple and effective. We give you exactly what you need to showcase your links, stack your content, and update your bio as you grow.',
      isOpen: false,
    },
    {
      title: 'Is it possible to embed Youtube, Vimeo, or other Videos?',
      content:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      isOpen: false,
    },
    {
      title: 'What is included with the Founder Lifetime Plan?',
      content:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industryLorem Ipsum is simply dummy text of the printing and typesetting industry',
      isOpen: false,
    },
    {
      title: 'Can I upload my own images for backgrounds, thumbnails, and bio?',
      content:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      isOpen: false,
    },
    {
      title: ' What payment methods do you accept?',
      content:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industryLorem Ipsum is simply dummy text of the printing and typesetting industry',
      isOpen: false,
    },
    {
      title: 'Can I view analytics on all of my clicks?',
      content:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      isOpen: false,
    },
    {
      title: 'Do I get a dedicated email with Stash.ee?',
      content:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industryLorem Ipsum is simply dummy text of the printing and typesetting industry',
      isOpen: false,
    },
    {
      title: 'Can I view analytics on all of my clicks?',
      content:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      isOpen: false,
    },
    {
      title: 'How can I get a verified checkmark on my page?',
      content:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      isOpen: false,
    },
  ]);

  const updateFieldChanged = (index) => (e) => {
    const newArr = [...sections];
    // copying the old datas array
    newArr.forEach((item, i) => {
      if (index === i) {
        newArr[index].isOpen = !newArr[index].isOpen;
      } else {
        newArr[i].isOpen = false;
      }
    });
    setSections(newArr); // ??
  };
  return (
    <>
      <div className="banner-box">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>
                The Most Frequently
                <br />
                Asked Question
              </h2>
            </div>
            <div className="col-md-6 text-right">
              <img src={images.faqimage} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-2">
        <div className="row mt--2">
          <div className="col-md-12">
            <div className="card full-height">
              <div className="center-heading black-text heading-area">
                <div className="sub-heading">Have Any Qustion?</div>
                <h1> Frequently Asked Question</h1>
              </div>
              <div className="card-body">
                <section
                  className="accordion-section clearfix pt-1"
                  aria-label="Question Accordions"
                >
                  <ul className="faq-listing">
                    <Accordion>
                      {sections &&
                        sections.map((value, index) => (
                          <li key={index} className="panel panel-default">
                            <div className={value.isOpen ? 'active faq-row' : 'faq-row'}>
                              <div className="question-box">
                                <Accordion.Toggle
                                  variant="button"
                                  className="btnaccordion"
                                  eventKey={`${index}`}
                                  onClick={updateFieldChanged(index)}
                                >
                                  <span id={`${index}`}>
                                    {value.isOpen ? (
                                      <i className="fa fa-minus" aria-hidden="true" />
                                    ) : (
                                      <i className="fa fa-plus" aria-hidden="true" />
                                    )}
                                  </span>

                                  <a
                                    className="que-btn"
                                    data-bs-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                  >
                                    {value.title}
                                  </a>
                                </Accordion.Toggle>
                              </div>
                              <Accordion.Collapse eventKey={`${index}`}>
                                <div className="faq-content" id="collapseExample">
                                  <div className=" answer-box">{value.content}</div>
                                </div>
                              </Accordion.Collapse>
                            </div>
                          </li>
                        ))}
                    </Accordion>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Claim />
      </div>
    </>
  );
};

export default Faq;
