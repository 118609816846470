import amazon from './social_Icons/amazon.png';
import amazonPrimeVideo from './social_Icons/amazon-video.png';
import apple_music from './social_Icons/apple-music.png';
import blogger from './social_Icons/blogger.png';
// import comments from './social_Icons/comments-48.png';
import delicious from './social_Icons/delicious.png';
import deviantart from './social_Icons/deviantart.png';
// import a from './social_Icons/deviantart-48.png';
import emailOpen from './social_Icons/email.png';
import facebook from './social_Icons/facebook.png';
import github from './social_Icons/github.png';
import gmail from './social_Icons/gmail.png';
import googleMeet from './social_Icons/google-meet.png';
import googlePlus from './social_Icons/google-plus.png';
// import html5 from './social_Icons/html-5.png';
import instagram from './social_Icons/insta.png';
import linkedin from './social_Icons/linkedin.png';
// import microsoftTeams from './social_Icons/microsoft-teams-2019-48.png';
import myspace from './social_Icons/my-space.png';
import netflix from './social_Icons/netflix.png';
import outlook from './social_Icons/outlook.png';
// import a from './social_Icons/paypal-48.png';
import paypal from './social_Icons/paypal.png';
import pinterest from './social_Icons/pinterest.png';
import imqq from './social_Icons/imqq.png';
import quora from './social_Icons/quora.png';
import reddit from './social_Icons/reddit.png';
// import remove from './social_Icons/remove.png';
// import share from './social_Icons/share-chat.png';
import sharechat from './social_Icons/share-chat.png';
// import a from './social_Icons/skype-48.png';
import skype from './social_Icons/skype.png';
import slack from './social_Icons/slack.png';
import soundcloud from './social_Icons/soundcloud.png';
import spotify from './social_Icons/spotify.png';
import telegram from './social_Icons/telegram.png';
import tiktok from './social_Icons/tiktok.png';
import tinder from './social_Icons/tinder.png';
import tumblr from './social_Icons/tumblr.png';
import twitch from './social_Icons/twitch.png';
import twitter from './social_Icons/twitter.png';
import venmo from './social_Icons/venmo.png';
import vine from './social_Icons/vine.png';
import vk from './social_Icons/vk.png';
import wechat from './social_Icons/wechat.png';
import weibo from './social_Icons/weibo.png';
import wikipedia from './social_Icons/wikipedia.png';
import xing from './social_Icons/xing.png';
import yelp from './social_Icons/yelp.png';
import youtube from './social_Icons/youtube.png';
import zoom from './social_Icons/zoom.png';
import payoneer from './social_Icons/payoneer.png';

const socialIcons = {
  amazon,
  amazonPrimeVideo,
  apple_music,
  blogger,
  //   comments,
  delicious,
  deviantart,
  emailOpen,
  facebook,
  github,
  gmail,
  googleMeet,
  googlePlus,
  //   html5,
  instagram,
  linkedin,
  //   microsoftTeams,
  myspace,
  netflix,
  outlook,
  paypal,
  pinterest,
  imqq,
  quora,
  reddit,
  //   remove,
  //   share,
  sharechat,
  skype,
  slack,
  soundcloud,
  spotify,
  telegram,
  tiktok,
  tinder,
  tumblr,
  twitch,
  twitter,
  venmo,
  vine,
  vk,
  wechat,
  weibo,
  wikipedia,
  xing,
  yelp,
  youtube,
  zoom,
  payoneer,
};

export default socialIcons;
