import React, { useState, useEffect } from 'react';
// import { useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'clipboard-copy';
import validate from '../../../utils/validations';
import { CREATE_REFERRAL_MUTATION } from '../../../graphql/Mutations';
import generateRandomId from '../../../utils/genrateRandomId';
import * as alertActions from '../../../store/actions/alert';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import ReferralsList from '../../../components/app/ReferralsList';
import useNetwork from '../../../hooks/useNetwork';
import socialMediaIcons from '../../../assets/socialMediaIcons';

const Referrals = (props) => {
  const dispatch = useDispatch();
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const userDetails = useSelector((state) => {
    return {
      name: state.auth.currentBiolink.username.username,
      email: state.auth.user.email,
    };
  });
  const referrals = useSelector((state) => state.auth.user.referrals);
  // console.log('testing data====', referrals);
  const referralCode = useSelector((state) => state.auth.user?.codes[0]?.code);
  // console.log('user vall==>', state.auth.user);
  const [referralCodeLink, setReferralCodeLink] = useState('');
  // const referralLink = `www.stash.ee/refer/`;
  const [referralLink, setReferralLink] = useState('www.stash.ee/refer/');
  useEffect(() => {
    if (referralCode != 'undefined' && referralCode != null && referralCode != '') {
      // console.log('ReferralCode vall==>', referralCode);
      // setReferralCodeLink(referralLink`${referralCode}`);
      setReferralCodeLink(`${referralLink}${referralCode}`);
    } else {
      setReferralCodeLink(referralLink);
    }
  }, [referralLink]);
  const [enableColoredSocialMediaIcons, setEnableColoredSocialMediaIcons] = useState('');
  const [socialAccountStyleType, setSocialAccountStyleType] = useState('');

  useEffect(() => {
    setEnableColoredSocialMediaIcons(currentBiolink?.settings?.enableColoredSocialMediaIcons);
    setSocialAccountStyleType(currentBiolink?.settings?.socialAccountStyleType);
  }, [currentBiolink]);
  // console.log('referralCodeLink===>', referralCodeLink);
  const [createReferrals, { data }] = useNetwork(CREATE_REFERRAL_MUTATION);
  const initialFormState = {
    referralToEmail: '',
    referralToEmailError: [],
    referralToName: '',
    referralToNameError: [],
  };
  const [form, setForm] = useState([initialFormState]);

  const inputChangeHandler = (name, value, index) => {
    const newForm = [...form];
    newForm[index] = {
      ...newForm[index],
      [name]: value,
    };
    setForm(newForm);
  };

  const onCopyHandler = (url) => {
    copy(url);

    dispatch(
      alertActions.addAlert({
        id: generateRandomId(),
        message: 'Link copied.',
        type: 'success',
      }),
    );
  };

  const addReferralForm = () => {
    const newForm = [...form];
    if (newForm.length >= 5) return;
    newForm.push({
      referralToEmail: '',
      referralToEmailError: [],
      referralToName: '',
      referralToNameError: [],
    });
    setForm(newForm);
    // setForm({
    //   ...form,
    //   referralToName: '',
    //   referredToEmail: '',
    // });
  };
  useEffect(() => {
    // console.log('testt------->', form);
  }, [form]);
  const removeReferralForm = (index) => {
    const newForm = [...form];
    newForm.splice(index, 1);
    setForm(newForm);
    // setForm({
    //   ...form,
    //   referredToName: '',
    //   referredToEmail: '',
    // });
  };

  const renderReferralForm = () => {
    return form.map((item, index) => {
      return (
        <>
          <div className="form-group form-floating-label inline-label inline-small">
            <input
              type="text"
              id="inputFloatingLabel83"
              placeholder="Name of friend"
              name="referralToName"
              className="form-control input-solid"
              value={form.referralToName}
              onChange={(e) => inputChangeHandler(e.target.name, e.target.value, index)}
            />
            {/* <label htmlor="inputFloatingLabel83" className="placeholder">
              Name of friend
            </label> */}
            {item.referralToNameError?.length
              ? item.referralToNameError?.map((err, i) => <ErrorSpan key={i} error={err} />)
              : null}
          </div>

          <div className="form-group form-floating-label inline-label inline-small">
            <input
              type="text"
              id="inputFloatingLabel81"
              name="referralToEmail"
              placeholder="Enter his email address"
              className="form-control input-solid"
              value={form.referralToEmail}
              onChange={(e) => inputChangeHandler(e.target.name, e.target.value, index)}
            />
            {/* <label htmlFor="inputFloatingLabel81" className="placeholder">
              Enter his email address
            </label> */}
            {item.referralToEmailError?.length
              ? item.referralToEmailError?.map((err, i) => <ErrorSpan key={i} error={err} />)
              : null}
          </div>

          {index ? (
            <i
              role="button"
              className="fa fa-close remove-referral"
              onClick={() => removeReferralForm(index)}
              onKeyUp={() => removeReferralForm(index)}
              tabIndex="-9"
            />
          ) : (
            <i
              role="button"
              className="fa fa-close remove-referral hide-referral"
              onClick={() => removeReferralForm(index)}
              onKeyUp={() => removeReferralForm(index)}
              tabIndex="-9"
            />
          )}

          <div className="form-group inline-box">
            {form.length < 5 ? (
              <div className=" leftbtn">
                <a
                  role="button"
                  className="btn btn-primary"
                  onClick={addReferralForm}
                  onKeyUp={addReferralForm}
                  tabIndex="-10"
                >
                  Add
                </a>
              </div>
            ) : null}
          </div>
        </>
      );
    });
  };

  const createReferralsHandler = () => {
    let isValid = true;
    const currentForm = [...form];

    currentForm.forEach((item, index) => {
      currentForm[index].referralToEmailError = [];
      currentForm[index].referralToNameError = [];
      if (!validate.emailFormat(item.referralToEmail)) {
        isValid = false;
        currentForm[index].referralToEmailError.push('Enter valid email.');
      }
      if (validate.minLength(item.referralToName, 1)) {
        isValid = false;
        currentForm[index].referralToNameError.push('Name cannot be empty.');
      }
    });

    setForm(currentForm);

    if (!isValid) return;
    const newForm = [];
    currentForm.forEach((item) => {
      newForm.push({
        referredToEmail: item.referralToEmail,
        referredToName: item.referralToName,
      });
    });
    createReferrals({
      variables: {
        userInfo: newForm,
        referredByEmail: userDetails.email,
        referredByName: userDetails.name,
      },
    });
    setForm([initialFormState]);
    // setForm({
    //   ...form,
    //   referredToName: '',
    //   referredToEmail: '',
    // });
  };

  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner refer-section ">
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="center-heading">
                    <h1>Refer a Friend</h1>
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      <p>Share Invite Link with your friend</p>
                      {/* <div className="socialicon">
                        <span>
                          <i className="fa fa-facebook-square" /> Facebook
                        </span>
                        <span>
                          <i className="fa fa-twitter" /> Twitter
                        </span>
                        <span>
                          <i className="fa fa-instagram" /> Instagram
                        </span>
                        <span>
                          <i className="fa fa-linkedin" /> Linkdin
                        </span>
                      </div> */}
                      <div className="footer-social-links author-social-admin social-media-container">
                        <div
                          className={`social-links ${
                            enableColoredSocialMediaIcons == true ? 'color' : 'minimal'
                          }
                  ${
                    socialAccountStyleType == 'Round'
                      ? 'Round'
                      : socialAccountStyleType == 'Square'
                      ? 'Square'
                      : 'OneClick'
                  }
                  `}
                        >
                          {currentBiolink?.links
                            ? currentBiolink?.links.map((sdata, index) =>
                                enableColoredSocialMediaIcons == true ? (
                                  <>
                                    <a
                                      key={index}
                                      href={`${sdata.url}`}
                                      className="btn-admin social-media-link"
                                    >
                                      {' '}
                                      <img src={sdata.iconColorful} alt="" />{' '}
                                    </a>
                                  </>
                                ) : (
                                  <a
                                    key={index}
                                    href={`${sdata.url}`}
                                    className="btn-admin social-media-link"
                                  >
                                    {' '}
                                    <img src={sdata.iconMinimal} alt="" />{' '}
                                  </a>
                                ),
                              )
                            : null}
                        </div>
                      </div>

                      <div className="form-group w-75 mx-auto">
                        <div className="input-group mb-3 referal-input">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Recipient's username"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            value={referralCodeLink}
                            readOnly
                          />
                          <div className="input-group-append">
                            <button
                              id="basic-addon2"
                              type="submit"
                              className="btn bg-primary"
                              onClick={() => onCopyHandler(referralCodeLink)}
                              onKeyPress={() => onCopyHandler(referralCodeLink)}
                              target="_blank"
                            >
                              Copy URL
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="card-header refr-sect">
                    <div className="card-title blue-text">Referrals</div>
                  </div>
                  <div className="card-body">
                    <div className="form-list w-100">
                      {renderReferralForm()}
                      <div className="form-group form-floating-label inline-label inline-small">
                        <input
                          id="inputFloatingLabel82"
                          type="text"
                          className="form-control input-solid"
                          value={userDetails.name}
                          readOnly
                          required
                        />
                      </div>
                      <div className="form-group form-floating-label inline-label inline-small">
                        <input
                          id="inputFloatingLabel82"
                          type="text"
                          className="form-control input-solid"
                          value={userDetails.email}
                          readOnly
                          required
                        />
                      </div>
                      <div className="form-group refr-sect">
                        <a
                          type="button"
                          className="btn btn-primary long-btn"
                          onClick={createReferralsHandler}
                          onKeyUp={createReferralsHandler}
                        >
                          Send
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt--2 ">
              <div className="clearfix card-header text-center w-100  mb-4 mt-4 heading-section">
                <h1>Referrals List</h1>
                <span className="note">(Invite friends upto 5)</span>
              </div>

              <ReferralsList referrals={referrals} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referrals;
