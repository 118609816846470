import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { useDispatch } from 'react-redux';
import { GET_ALL_MESSAGES, GET_LAST_MESSAGES } from '../../../graphql/Queries';
import { SEND_MESSAGE_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';
import * as appActions from '../../../store/actions/app';
import * as authActions from '../../../store/actions/auth';
import images from '../../../assets/images';

const Inbox = (props) => {
 // const otherUserId = props.location.params?.otherUserId || null;
    const otherUserId ="1";
  const [user, setUser] = useState([]);
  const receiverUsername = props.match.params.username;
  // console.log('Show user Name ==', receiverUsername);
  // const [lastUser, setLastUser] = useState({});
  const receiverId = props.location.params?.receiverId;
  // console.log('Show user Id ==', receiverId);
  // const receiverUsername = props.receiverUsername;
  // console.log('Show UserName Here', receiverUsername);
  const [allMessages, setAllMessages] = useState([]);

  const [sendMessage, { data: sendMessageData }] = useNetwork(SEND_MESSAGE_MUTATION);
  const [items, setItems] = useState('');

  const dispatch = useDispatch();

  const [getAllMessages, { data, loading }] = useLazyQuery(GET_ALL_MESSAGES);
  const [getLastMessages, { data: lastMessages, loading: loadLastMessages }] =
    useLazyQuery(GET_LAST_MESSAGES);

  useEffect(() => {
    dispatch(appActions.isLoading(loading));
    console.log("---------------getAllMessages------------->", data);
    // console.log("---------------lastMessages------------->", lastMessages);
  }, [loading]);

  useEffect(() => {
    setItems();
  }, []);

  useEffect(() => {
    if (otherUserId) {
      getAllMessages({
        variables: {
          otherUserId,
          // before: '',
          // after: '',
          // query: '',
          // first: 10,
          // last: 10,
        },
      });
    }

    getLastMessages({
      variables: {
        before: 'MjAyMS0wOS0yOCAwNToyNDoxOQ==',
        after: '',
        query: '',
        first: 10,
        last: 10,
      },
    });
  }, []);

  useEffect(() => {
    if (data?.getAllMessages?.errors === null) {
      // console.log(`fetched messages: `, data);
      setAllMessages(data?.getAllMessages?.edges);
    }
    // if (data?.getAllMessages?.edges) {
    //   setAllMessages(data?.getAllMessages?.edges);
    // }
  }, [data]);

  // console.log('Show All Mesages from Users', JSON.stringify(allMessages));
  // console.log('Show All Mesages from Users----', JSON.stringify(allMessages[0]?.node?.message));

  useEffect(() => {
    if (lastMessages && lastMessages?.getLastMessages?.edges) {
      console.log(`fetched last messages: `, lastMessages);
      setUser(lastMessages?.getLastMessages?.edges);
    }
    if (
      lastMessages?.getLastMessages.errors &&
      lastMessages?.getLastMessages.errors[0].errorCode === 2
    ) {
     // dispatch(authActions.authenticate({}, false));
    }
    // setUser(lastMessages?.getLastMessages?.edges);
    // setItems(user?.node?.message);
  }, [lastMessages]);

  // useEffect(() => {
  //   setItems(user?.node?.message);
  // }, []);
  // console.log('Geting Data from last Items =======>>> ', items);

  // console.log('Geting Data from last User ', JSON.stringify(user));
  // // console.log('Geting Data from last User----->> ', user?.node?.receiver?.usernames.username);
  // console.log('Geting Data from last User======= ', JSON.stringify(lastMessages));
  // console.log('Geting Data from last User======= ', lastMessages);
  // console.log('Geting Data from last User=======>>> ', user);
  // console.log('Geting Data from last User=======>>>--- ', user[0]?.node?.message);

  // send message start

  const [form, setForm] = useState({
    message: '',
    attachment: null,
  });

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  // console.log('show name here', name);

  const hiddenFileInput = useRef(null);

  const uploadDocumentHandler = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    e.persist();
    const [file] = e.target.files;
    if (file) {
      setForm({
        ...form,
        attachment: file,
      });
      //   uploadBiolinkCoverPhoto({
      //     variables: {
      //       coverPhoto: file,
      //       id: userData.id,
      //     },
      //   });
    }
  };

  const sendMessageHandler = () => {
    if (form.attachment) {
      sendMessage({
        variables: {
          receiverId: otherUserId,
          message: form.message,
          attachment: form.attachment,
        },
      });
    } else {
      sendMessage({
        variables: {
          receiverId: otherUserId,
          message: form.message,
        },
      });
      setForm({
        message: '',
      });
    }
  };

  // const sendMessageHandler = () => {
  //   if (form.attachment) {
  //     sendMessage({
  //       variables: {
  //         receiverId,
  //         message: form.message,
  //         attachment: form.attachment,
  //       },
  //     });
  //   } else {
  //     sendMessage({
  //       variables: {
  //         receiverId,
  //         message: form.message,
  //       },
  //     });
  //   }
  // };
  // send message end
  console.log('SHow Username Here ', user);

  const dataHandler = () => {
    console.log('dataHandler dataHandler= ');
  };
  // console.log('show Message HERE===', message);
  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner ">
            <div className="row mt--2">
              <div className="col-md-12">
                <div className=" chat-app  full-height">
                  <div id="plist" className="people-list">
                    <div className="input-group">
                      {/* <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-search" />
                        </span>
                      </div>
                      <input type="text" className="form-control" placeholder="Search..." /> */}
                    </div>
                    {/* <ul className="list-unstyled chat-list mt-2 mb-0">
                      <li className="clearfix">
                        <img src="../assets/img/avatar1.png" alt="avatar" />
                        <div className="about">
                          <div className="name">Vincent Porter</div>
                          <div className="status">
                            <i className="fa fa-circle offline" /> left 7 mins ago
                          </div>
                        </div>
                      </li>
                      <li className="clearfix active">
                        <img src="../assets/img/avatar2.png" alt="avatar" />
                        <div className="about">
                          <div className="name">Aiden Chavez</div>
                          <div className="status">
                            <i className="fa fa-circle online" /> online
                          </div>
                        </div>
                      </li>
                      <li className="clearfix">
                        <img src="../assets/img/avatar3.png" alt="avatar" />
                        <div className="about">
                          <div className="name">Mike Thomas</div>
                          <div className="status">
                            <i className="fa fa-circle online" /> online
                          </div>
                        </div>
                      </li>
                      <li className="clearfix">
                        <img src="../assets/img/avatar7.png" alt="avatar" />
                        <div className="about">
                          <div className="name">Christian Kelly</div>
                          <div className="status">
                            <i className="fa fa-circle offline" /> left 10 hours ago
                          </div>
                        </div>
                      </li>
                      <li className="clearfix">
                        <img src="../assets/img/avatar8.png" alt="avatar" />
                        <div className="about">
                          <div className="name">Monica Ward</div>
                          <div className="status">
                            <i className="fa fa-circle online" /> online
                          </div>
                        </div>
                      </li>
                      <li className="clearfix">
                        <img src="../assets/img/avatar3.png" alt="avatar" />
                        <div className="about">
                          <div className="name">Dean Henry</div>
                          <div className="status">
                            <i className="fa fa-circle offline" /> offline since Oct 28
                          </div>
                        </div>
                      </li>
                    </ul> */}

                    <div id="plist" className="people-sec-list">
                      <div className="input-group">
                      <h3>Chat</h3>
                        {/* <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="fa fa-search" />
          </span>
        </div>
        <input type="text" className="form-control" placeholder="Search..." /> */}
                      </div>
                      <ul className="list-unstyled chat-list mt-2 mb-0" >
                      {user.map((fetchUser, index) => (
                        <>
                          {fetchUser.node.receiver.usernames.map((value, index1) => (
                            <li className="clearfix" key={index1} onClick={() => dataHandler()}>
                              <img src="../assets/img/avatar1.png" alt="avatar" />
                              <div className="about">
                                <div className="name">{value.username}</div>

                                <div className="status">
                                  <i className="fa fa-circle offline" /> left 7 mins ago
                                </div>
                              </div>
                            </li>
                          ))}
                       </>
                      ))}
                       </ul>
                    </div>
                  </div>
                  <div className="chat">
                    {otherUserId ? (
                      <>
                        <div className="chat-header clearfix">
                          <div className="row">
                            <>
                              <div className="col-lg-6">
                                <a href="#asdf" data-toggle="modal" data-target="#view_info">
                                  <img src="../assets/img/avatar2.png" alt="avatar" />
                                </a>
                                <div className="chat-about">
                                  <h6 className="m-b-0">Aiden Chavez</h6>
                                  {/* <h6 className="m-b-0">{receiverUsername}</h6> */}
                                  <small>Last seen: 2 hours ago</small>
                                </div>
                              </div>
                              <div className="col-lg-6 hidden-sm text-right chat-media-icon-body">
                                <a
                                  className="btn btn-outline-secondary chat-media-icon"
                                  // onClick={() => uploadDocumentHandler()}
                                  // onChange={handleChange}
                                >
                                  <i className="fa fa-camera" />
                                </a>
                                <a
                                  className="btn btn-outline-primary chat-media-icon"
                                  // onClick={() => uploadDocumentHandler()}
                                  // onChange={handleChange}
                                >
                                  <i className="fa fa-image" />
                                </a>
                                <a href="#asdf" className="btn btn-outline-info chat-media-icon">
                                  <i className="fa fa-link" />
                                </a>
                              </div>
                            </>
                          </div>
                        </div>
                        <div className="chat-history">
                       
                          {/* {allMessages?.map((value, index) => ( */}
                            <ul className="m-b-0" >
                              <li
                                className="clearfix"
                                onClick={(evt) => dataHandler(evt)}
                                id="chat-history"
                              >
                                <div className="message-data text-right">
                                  {/* <span className="message-data-time">10:10 AM, Today</span> */}
                                  <span className="message-data-time">
                                    {"10:15 AM, Today"}
                                    {/* {moment(new Date(+value.node.createdAt)).format('MMM-DD-YYYY')} */}
                                  </span>

                                  {/* <img src="../assets/img/avatar7.png" alt="avatar" /> */}
                                </div>
                                <div className="message other-message float-right">
                                  {/* {allMessages?.node?.message} */}
                                  {/* {value.node.message || value.node.attachmentUrl} */}
                                  {"test"}
                                </div>
                                {/* <div className="message other-message float-right">
                                  {value.node.attachmentUrl}
                                </div> */}
                              </li>

                              <li className="clearfix">
                              <div className="message-data">
                                <span className="message-data-time">10:12 AM, Today</span>
                              </div>
                              <div className="message my-message">Are we meeting today?</div>
                            </li>
                            <li className="clearfix">
                              <div className="message-data">
                                <span className="message-data-time">10:15 AM, Today</span>
                              </div>
                              <div className="message my-message">
                                Project has been already finished and I have results to show you.
                              </div>
                            </li>
                            <li className="clearfix">
                              <div className="message-data">
                                <span className="message-data-time">10:12 AM, Today</span>
                              </div>
                              <div className="message my-message">Are we meeting today?</div>
                            </li>
                            <li className="clearfix">
                              <div className="message-data">
                                <span className="message-data-time">10:15 AM, Today</span>
                              </div>
                              <div className="message my-message">
                                Project has been already finished and I have results to show you.
                              </div>
                            </li>
                            </ul>
                          {/* ))} */}
                        </div>

                        <div className="chat-message clearfix">
                          <div className="input-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter text here..."
                              value={form.message || ''}
                              name="message"
                              onChange={(evt) =>
                                inputChangeHandler(evt.target.name, evt.target.value)
                              }
                            />
                             <div className="input-group-prepend">
                              <span className="input-group-text chat-social-icon">
                              <img src={images.chatSend} alt="" onClick={() => sendMessageHandler()} />
                              </span>
                              <span className="input-group-text chat-social-icon">
                              <img src={images.chatSmiley} alt="" onClick={() => sendMessageHandler()} />
                              </span>
                              <span className="input-group-text chat-social-icon">
                              <img src={images.chatAttach} alt="" onClick={() => sendMessageHandler()} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        className="chat-message clearfix"
                        style={{
                          height: '525px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <p>No message selected</p>
                        <p>Explore people to connect with</p>
                        <Link to="/directory" className="btn btn-primary">
                          Explore Stash.ee
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inbox;
