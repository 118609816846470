import React from 'react';
import images from '../../assets/images';
// import images from '../../assets/images';

const ToggleSwitch = ({ currentState, isActive, onToggle }) => {
  return (
    <div className="swap-box">
      <div className="clearfix">
        <div className="swap-btn-bar-admin">
          <a
            className={currentState ? 'pull-left' : 'pull-left active'}
            onClick={() => onToggle()}
          />

          <a
            className={currentState ? 'pull-right active' : 'pull-right'}
            onClick={() => onToggle()}
          />
        </div>
        <div className="lockimages">
          {isActive !== 'na' ? (
            isActive ? (
              <img src={images.unlock} alt="" />
            ) : (
              <img src={images.lock} alt="" />
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
