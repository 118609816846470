import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ScrollToTop from '../components/ui/ScrollToTop';
import routes from './routes';
import RouteWithSubRoutes from './RouteWithSubRoutes';

const RootNavigator = () => {
  // console.log('ALL State Auth=>>', state.auth ? state.auth : '');
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Router>
      {/* <ScriptInjector /> */}
      <ScrollToTop />
      <Switch>
        {routes.map((route, index) => (
          <RouteWithSubRoutes key={index} {...route} isAuthenticated={isAuthenticated} />
        ))}
      </Switch>
    </Router>
  );
};

export default RootNavigator;
