import React, { useState } from 'react';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import { CREATE_NEW_BIOLINK_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';

const CreateBiolinkForm = () => {
  const initialState = {
    username: '',
    usernameError: [],
  };
  const [form, setForm] = useState(initialState);

  const [createNewBiolink, { data }] = useNetwork(CREATE_NEW_BIOLINK_MUTATION);

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    const usernameError = [];
    // add validations here
    if (!form.username) usernameError.push('username cannot be empty.');

    setForm({
      ...form,
      usernameError,
    });

    if (usernameError.length) return;
    // submit form
    createNewBiolink({
      variables: {
        username: form.username,
      },
    });
  };

  return (
    <>
      <div className="bio-section pb-0">
        <div className="center-heading black-text ">
          <h1 className="mt-0 pb-0">
            Create your <span className="blue-text">USERNAME</span>
          </h1>
        </div>
      </div>
      <div className="form-list w-50 mobile-full-width mx-auto">
        <div className="form-group form-floating-label create-user-box">
          <span>stash.ee/</span>

          <input
            type="text"
            className="form-control input-solid"
            id="inputFloatingLabel3"
            placeholder="Enter your biolink username here"
            name="username"
            value={form.username || ''}
            onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
          />
        </div>

        {form.usernameError.length
          ? form.usernameError.map((err, i) => <ErrorSpan key={i} error={err} />)
          : null}

        <div className="form-group text-center">
          <button type="button" className="btn btn-primary" onClick={onSubmitHandler}>
            Create biolink
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateBiolinkForm;
