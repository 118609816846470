import React from 'react';
import { Link } from 'react-router-dom';
import images from '../../../assets/images';

const HowItWorks = (props) => {
  return (
    <>
      <div className="testimonial-wrapper home-banner mb-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className=" howitwork">
                <div className="black-text heading-area ">
                  <img src={images.howitwork} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12  home-right-col testimonial-heading-sec">
              <div className="col-md-6 ">
                <div className="black-text heading-area">
                  <h1>
                    <b>How It works ?</b>
                  </h1>
                </div>
                <p>
                  Make it easy for your followers to find your important links, social media, and
                  latest content by having it all on one page.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial-wrapper mb-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6 v-middle heading-class how-title-sec">
              <h1>
                <b>
                  Sign <span>Up </span>
                </b>
              </h1>

              <p>
                Create an account to receive your personal STASH.EE link, then customize the page to
                match your style.
              </p>
            </div>
            <div className="col-md-6 testimonial-sec-img v-middle">
              <img src={images.signuphowitwork} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 testimonial-sec-img v-middle howwork-border-sec">
            <img src={images.howit} alt="" />
          </div>
          <div className="col-md-6 testimonial-sec-img v-middle">
            <div className="heading-class how-title-sec">
              <h1>
                {' '}
                Add <span>Content </span>
              </h1>
              <p>
                Add links to websites and social media, embed music and videos from your favorite
                platforms, or include email and phone contact buttons. .
              </p>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-6 v-middle last-work">
            <div className="heading-class how-title-sec">
              <h1>
                {' '}
                Share In <span>Bio </span>
              </h1>
              <p>
                Use your custom STASH.EE link as the bio link for your social media accounts to
                connect your audience across all the platforms you use.
              </p>
            </div>
          </div>
          <div className="col-md-6 testimonial-sec-img v-middle">
            <img src={images.sharebio} alt="" />
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="bio-section">
          <h1>The perfect bio link for...</h1>
          <div className="row">
            <div className="col-md-3">
              <div className="bio-column">
                <img src={images.bottom_image} alt="" />
                <div className="bio-detail">
                  <h2>Musicians</h2>
                  <h3>Learn Why</h3>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="bio-column">
                <img src={images.bottom_image1} alt="" />
                <div className="bio-detail">
                  <h2>Youtuber</h2>
                  <h3>Learn Why</h3>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="bio-column">
                <img src={images.bottom_image2} alt="" />
                <div className="bio-detail">
                  <h2>Insta influencer</h2>
                  <h3>Learn Why</h3>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="bio-column blank-bio">
                <img src={images.bottom_image3} alt="" />
                <div className="bio-detail">
                  <h1>Artists, Creators, Businesses, & Many</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="howwork-bottom">
            <div className="inlineview">
              <img src={images.getlink} alt="" />
            </div>
            <div className="howwork-link-btn">
              <div className="inlineview righview howwork-get-link">
                <span> Get your link </span>
              </div>
              <a
                className="workbtn"
                href="/auth/register"
                // onClick={() => props.history.push('/auth/register')}
              >
                Create Account{' '}
              </a>
              </div>
          </div>
        </div>

        {/* Claim div here */}
      </div>
    </>
  );
};

export default HowItWorks;
