import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const RouteWithSubRoutes = (route) => {
  // console.log('Route=======>', route);
  return (
    <Route
      path={route.path}
      render={(props) =>
        route.isPrivate ? (
          route.isAuthenticated ? (
            route.component && (
              <route.component
                {...props}
                routes={route.routes}
                isAuthenticated={route.isAuthenticated}
              />
            )
          ) : (
            <Redirect to="/auth/login" />
          )
        ) : route.checkLogin ? (
          !route.isAuthenticated ? (
            route.component && (
              <route.component
                {...props}
                routes={route.routes}
                isAuthenticated={route.isAuthenticated}
              />
            )
          ) : (
            <Redirect to="/app" />
          )
        ) : (
          route.component && (
            <route.component
              {...props}
              routes={route.routes}
              isAuthenticated={route.isAuthenticated}
            />
          )
        )
      }
    />
  );
};

export default RouteWithSubRoutes;
