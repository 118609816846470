import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import { CREATE_NEW_LINK } from '../../../graphql/Mutations';
import { LOAD_USER_DETAILS } from '../../../graphql/Queries';
import useNetwork from '../../../hooks/useNetwork';
import * as authActions from '../../../store/actions/auth';
import LinkShortnerList from './LinkShortnerList';
import images from '../../../assets/images';

const LinkShortner = (props) => {
  const dispatch = useDispatch();
  const [me, { data: userData }] = useLazyQuery(LOAD_USER_DETAILS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (userData?.me) {
      dispatch(authActions.authenticate(userData.me, true));
    }
  }, [userData]);

  const currentBiolinkId = useSelector((state) => state.auth.currentBiolink.id);

  const initialForm = {
    url: '',
    urlError: [],
    note: '',
    title: '',
    titleError: [],
    shortenedUrl: '',
    shortenedUrlError: [],
  };

  const [form, setForm] = useState(initialForm);

  const [addedUrl, setAddedUrl] = useState({
    longUrl: '',
    shortUrl: '',
  });

  const [createNewLinkMutation, { data }] = useNetwork(CREATE_NEW_LINK);

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();

    const urlError = [];

    if (!form.url) urlError.push('url cannot be empty');

    setForm({
      ...form,
      urlError,
    });

    if (urlError.length) return;

    createNewLinkMutation({
      variables: {
        url: form.url,
        title: form.title,
        note: form.note,
        biolinkId: currentBiolinkId,
      },
    });
    setForm(initialForm);
    // const [form, setForm] = useState(initialForm);
  };

  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner link-shortner">
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="center-heading">
                    <h1>Short Your URL</h1>
                    <h4>Paste the URL to be shortened</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-list w-50 mobile-full-width mx-auto">
                      <div className="form-group form-floating-label">
                        <input
                          id="selectFloatingLabells2"
                          type="text"
                          placeholder="Enter the link here"
                          className="form-control input-solid"
                          required
                          name="url"
                          value={form.url || ''}
                          onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                        />
                        {/* <label htmlFor="selectFloatingLabells2" className="placeholder">
                          Enter the link here
                        </label> */}
                        {form.urlError.length
                          ? form.urlError.map((err, i) => <ErrorSpan key={i} error={err} />)
                          : null}
                      </div>
                      <div className="form-group form-floating-label">
                        <input
                          id="inputFloatingLabel2"
                          type="text"
                          placeholder="Enter title (optional)"
                          className="form-control input-solid"
                          name="title"
                          value={form.title}
                          onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                        />
                        {/* <label htmlFor="inputFloatingLabel2" className="placeholder">
                          Enter title (optional)
                        </label> */}
                        {form.titleError.length
                          ? form.titleError.map((err, i) => <ErrorSpan key={i} error={err} />)
                          : null}
                      </div>
                      <div className="form-group form-floating-label">
                        <input
                          id="inputFloatingLabel3"
                          type="text"
                          placeholder="Enter a note (optional)"
                          className="form-control input-solid"
                          name="note"
                          value={form.note || ''}
                          onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                        />
                        {/* <label htmlFor="inputFloatingLabel3" className="placeholder">
                          Enter a note (optional)
                        </label> */}
                      </div>
                      <div className="form-group text-center">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={onSubmitHandler}
                          onKeyUp={onSubmitHandler}
                        >
                          Shorten URL
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="center-heading">
                    <div className=" w-50 mobile-full-width mx-auto mt-5">
                      <div className="short-link-description">
                        <img src="img/quet.png" alt="" style={{ marginTop: '-10px' }} />
                        Stash.ee is a free tool to shorten a URL or reduce a link
                        <span>
                          Use our URL Shortener to create a shortened link making it easy to
                          <img src="img/quet-botto.png" alt="" style={{ marginTop: '10px' }} />
                        </span>
                      </div>
                      <h1>Your Shortened URL</h1>
                      <h4>
                        Copy the shortened link and share it in messages, texts, posts, websites and
                        other locations.
                      </h4>
                    </div>
                  </div>
                  <LinkShortnerList
                    longUrl={addedUrl.longUrl}
                    shortUrl={addedUrl.longUrl}
                    currentBiolinkId={currentBiolinkId}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="card full-height card-box">
                  <div className="card-body">
                    {' '}
                    <div className="shorter-wrapper new-shorter-design">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="main-directory-box light-bg">
                            <div className="directory-profile shorter-profile">
                              <img src={images.easyIcon} alt="" />
                            </div>
                            <h3>Easy</h3>
                            <p>
                              This Tool is easy and fast, enter the long link to get your shortened
                              link
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="main-directory-box light-bg">
                            <div className="directory-profile shorter-profile">
                              <img src={images.shortenedIcon} alt="" />
                            </div>
                            <h3>Shortened</h3>
                            <p>Use any link, no matter what size, Stash.ee always shortens</p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="main-directory-box light-bg">
                            <div className="directory-profile shorter-profile">
                              <img src={images.secureIcon} alt="" />
                            </div>
                            <h3>Secure</h3>
                            <p>
                              It is fast and secure, our service have HTTPS protocol and data
                              encryption
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="main-directory-box light-bg">
                            <div className="directory-profile shorter-profile">
                              <img src={images.devicesIcon} alt="" />
                            </div>
                            <h3>Devices</h3>
                            <p>Compatible with smartphones, tablets and desktop</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkShortner;
