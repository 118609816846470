import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REMOVE_BIOLINK_MUTATION } from '../../graphql/Mutations';
import useNetwork from '../../hooks/useNetwork';
import * as alertActions from '../../store/actions/alert';
import generateRandomId from '../../utils/genrateRandomId';

const DeleteBiolink = ({ biolinkId }) => {
  const dispatch = useDispatch();
  const totalBiolinks = useSelector((state) => state.auth.user.biolinks.length);

  const [removeBiolink, { data }] = useNetwork(REMOVE_BIOLINK_MUTATION);

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    if (totalBiolinks < 2) {
      dispatch(
        alertActions.addAlert({
          id: generateRandomId(),
          message: 'There must be alleast one biolink!!!',
          type: 'warning',
        }),
      );
      return;
    }
    if (confirm('Do you really want to delete the biolink')) {
      removeBiolink({
        variables: {
          id: biolinkId,
        },
      });
    }
  };

  if (totalBiolinks < 2) {
    return null;
  }

  return (
    <a className="delete" onClick={(evt) => onSubmitHandler(evt)}>
      <i className="fa fa-trash" aria-hidden="true" />
    </a>
  );
};

export default DeleteBiolink;
