import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import images from '../../../assets/images';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import { CREATE_NEW_LINK } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';

const LinksForm = ({ showUpgrade }) => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const [newLinkColor, setNewLinkColor] = useState();
  const LinksLength = currentBiolink.links.length;
  const [colorCodes, setColorCodes] = useState([
    {
      colorName: 'Blue',
      colorCode: '#70CFE1',
      isColorSet: false,
    },
    {
      colorName: 'Green',
      colorCode: '#85C85D',
      isColorSet: false,
    },
    {
      colorName: 'Red',
      colorCode: '#F4614C',
      isColorSet: false,
    },
    {
      colorName: 'Orange',
      colorCode: '#F79D2A',
      isColorSet: false,
    },
  ]);
  const [showAdd, setShowAdd] = useState(true);
  const [showLinkTypes, setShowLinkTypes] = useState(false);
  const [isActive, setIsActive] = useState({
    Link: false,
    Line: false,
    Embed: false,
  });
  const [showForm, setShowForm] = useState(false);
  const [showColorBox, setShowColorBox] = useState(false);

  const initialState = {
    linkType: '',
    linkTitle: '',
    linkTitleError: [],
    linkColor: '',
    linkColorError: [],
    url: '',
    urlError: [],
  };

  const [newLink, setNewLink] = useState(initialState);

  const resetComponent = () => {
    setNewLink(initialState);
    setShowAdd(true);
    setShowLinkTypes(false);
    setShowForm(false);
    setShowColorBox(false);
    setIsActive({
      Link: false,
      Line: false,
      Embed: false,
    });
  };

  const [createNewLinkMutation, { data }] = useNetwork(CREATE_NEW_LINK);
  useEffect(() => {
    if (data?.createNewLink.errors === null) {
      resetComponent();
    }
  }, [data]);

  const onAddLinkHandler = () => {
    setShowLinkTypes(true);
    setShowAdd(false);
  };

  const onSelectLinkType = (title) => {
    setIsActive({
      Link: title === 'Link' ? true : false,
      Line: title === 'Line' ? true : false,
      Embed: title === 'Embed' ? true : false,
    });
    setNewLink({
      ...initialState,
      linkType: title,
    });

    if (title == 'Line') {
      setShowForm(false);
      setShowColorBox(true);
    } else {
      setShowColorBox(false);
      setShowForm(true);
    }
  };

  const onDoneHandler = (evt, type = null) => {
    evt.preventDefault();

    if (newLink.linkType == 'Line') {
      const linkColorError = [];

      if (!newLink.linkColor) {
        linkColorError.push('Please select color');
        setNewLink({
          ...newLinkColor,
          linkColorError,
        });
        return;
      }

      createNewLinkMutation({
        variables: {
          biolinkId: currentBiolink.id,
          url: '',
          linkType: 'Line',
          linkColor: newLink.linkColor,
          order: LinksLength + 1,
        },
      });
    } else {
      const urlError = [];
      const linkTitleError = [];

      if (!newLink.url) urlError.push('Url cannot be empty');
      if (!newLink.linkTitle) linkTitleError.push('Title cannot be empty');

      if (!newLink.url || !newLink.linkTitle) {
        setNewLink({
          ...newLink,
          urlError,
          linkTitleError,
        });
        return;
      }

      createNewLinkMutation({
        variables: {
          biolinkId: currentBiolink.id,
          url: newLink.url,
          linkTitle: newLink.linkTitle,
          linkType: newLink.linkType,
          order: LinksLength + 1,
        },
      });
    }
    // setForm({
    //   ...form,
    //   url: '',
    //   linkTitle: '',
    //   linkType: '',
    //   linkColor: '',
    // });
  };

  const onColorSelect = (index, code) => (e) => {
    const newArr = [...colorCodes];
    // copying the old datas array
    newArr.forEach((item, i) => {
      if (index === i) {
        newArr[index].isColorSet = !newArr[index].isColorSet;
      } else {
        newArr[i].isColorSet = false;
      }
    });

    setColorCodes(newArr);

    setNewLink({
      ...newLink,
      linkColor: code,
    });
  };

  return (
    <div className="modal fade" id="modal-11">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Links & Embeds</h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-list">
              {showAdd && (
                <div className="drop-box ">
                  <a
                    className="btn-admin icon-design-admin addlink"
                    onClick={() => onAddLinkHandler()}
                  >
                    <span className="addnew"> Add New</span>
                    <i className="fa fa-plus-circle ml-2"> </i>
                  </a>
                </div>
              )}
              {showLinkTypes && (
                <ul className="linksection" id="linkSections">
                  <a
                    onClick={() => onSelectLinkType('Link')}
                    className={isActive.Link ? 'active' : ''}
                  >
                    <li>
                      <img src={images.linkicon} alt="" /> Link
                    </li>
                  </a>
                  <a
                    onClick={() => onSelectLinkType('Line')}
                    className={isActive.Line ? 'active' : ''}
                  >
                    <li>
                      <img src={images.sectionicon} alt="" />
                      Section
                    </li>
                  </a>
                  <a
                    onClick={() => onSelectLinkType('Embed')}
                    className={isActive.Embed ? 'active' : ''}
                  >
                    <li>
                      <img src={images.embed} alt="" /> Embed
                    </li>
                  </a>
                </ul>
              )}

              {showForm && (
                <div className="linkbar">
                  <div className="form-group form-floating-label">
                    <input
                      id="inputFloatingLabel142"
                      type="text"
                      className="form-control input-solid"
                      required
                      name="linkTitle"
                      value={newLink.linkTitle}
                      onChange={(evt) =>
                        setNewLink({
                          ...newLink,
                          linkTitle: evt.target.value,
                        })
                      }
                      placeholder="Enter the title here"
                    />
                    {/* <label htmlFor="inputFloatingLabel142" className="placeholder">
                      Enter the title here
                    </label> */}
                    {newLink.linkTitleError.length
                      ? newLink.linkTitleError.map((err, i) => <ErrorSpan key={i} error={err} />)
                      : null}
                  </div>

                  <div className="form-group form-floating-label">
                    <input
                      id="inputFloatingLabel143"
                      type="text"
                      className="form-control input-solid"
                      required
                      name="url"
                      value={newLink.url}
                      onChange={(evt) =>
                        setNewLink({
                          ...newLink,
                          url: evt.target.value,
                        })
                      }
                      placeholder="Enter the link here"
                    />
                    {/* <label htmlFor="inputFloatingLabel143" className="placeholder">
                      Enter the link here
                    </label> */}
                    {newLink.urlError.length
                      ? newLink.urlError.map((err, i) => <ErrorSpan key={i} error={err} />)
                      : null}
                  </div>
                </div>
              )}

              {showColorBox && (
                <div className="colorBox-linkbar">
                  <div className="colorBox-area" style={{ display: 'inline-block' }}>
                    {colorCodes &&
                      colorCodes.map((value, index) => (
                        <div
                          key={index}
                          className="colorBox"
                          style={{
                            background: value.colorCode,
                            border: value.isColorSet ? '2px solid #0a5b8b' : '',
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            display: 'inline-block',
                            margin: 10,
                          }}
                          onClick={onColorSelect(index, value.colorCode)}
                          onChange={(evt) =>
                            setNewLinkColor({
                              ...newLinkColor,
                              linkColor: value.colorCode,
                            })
                          }
                        />
                      ))}
                  </div>

                  {newLink.linkColorError.length
                    ? newLink.linkColorError.map((err, i) => <ErrorSpan key={i} error={err} />)
                    : null}
                </div>
              )}
            </div>
          </div>

          <div className="modal-footer">
            <a
              href="#modal-1"
              data-toggle="modal"
              data-dismiss="modal"
              className="btn btn-default"
              onClick={(evt) => resetComponent(evt)}
            >
              Previous
            </a>
            <button
              type="button"
              className="btn btn-danger"
              onClick={(evt) => onDoneHandler(evt)}
              disabled={showForm || showColorBox ? false : true}
            >
              Save
            </button>
          </div>
        </div>
        {/* <!-- /.modal-content --> */}
      </div>
      {/* <!-- /.modal-dialog --> */}
    </div>
  );
};

export default LinksForm;
