import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import { CHANGE_USER_PASSWORD_MUTATION } from '../../../graphql/Mutations';
import validate from '../../../utils/validations';
import useNetwork from '../../../hooks/useNetwork';

const ChangePasswordForm = ({ userEmail }) => {
  const initialState = {
    oldPassword: '',
    oldPasswordError: [],
    newPassword: '',
    newPasswordError: '',
    confirmPassword: [],
    confirmPasswordError: [],
  };
  const [form, setForm] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [changeUserPassword, { data }] = useNetwork(CHANGE_USER_PASSWORD_MUTATION);

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    const oldPasswordError = [];
    const newPasswordError = [];
    const confirmPasswordError = [];
    // add validations here
    if (validate.minLength(form.oldPassword, 8) || form.oldPassword.trim() == '') {
      if (form.oldPassword.trim() == '') {
        oldPasswordError.push('Old password should not be empty');
      } else {
        oldPasswordError.push('Old password must be atleast 8 characters long');
      }
    }
    if (validate.minLength(form.newPassword, 8))
      newPasswordError.push('Password must be atleast 8 characters long');
    if (form.newPassword !== form.confirmPassword)
      confirmPasswordError.push('Password did not matched.');

    setForm({
      ...form,
      oldPasswordError,
      newPasswordError,
      confirmPasswordError,
    });

    if (oldPasswordError.length || newPasswordError.length || confirmPasswordError.length) return;
    // submit form
    changeUserPassword({
      variables: {
        oldPassword: form.oldPassword,
        newPassword: form.newPassword,
      },
    });
    setForm({
      ...form,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  };

  return (
    <div className="card full-height">
      <div className="card-header text-center">
        <div className="card-title blue-text big-title">Password</div>
      </div>
      <div className="card-body">
        <div className="form-list ">
          <div className="form-group form-floating-label inline-label">
            <input type="text" className="form-control input-solid" value={userEmail} readOnly />
          </div>
          <div className="form-group form-floating-label inline-label">
            <input
              type={showPassword ? 'text' : 'password'}
              id="inputFloatingLabel22"
              className="form-control input-solid"
              name="oldPassword"
              placeholder="Type your old password"
              value={form.oldPassword}
              onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
            />
            {/* {form.oldPassword.length == 0 ? (
              <label htmlFor="inputFloatingLabel22" className="placeholder">
                Type your old password
              </label>
            ) : null} */}

            <span
              className="input-icon sizespace"
              role="button"
              onClick={() => setShowPassword(!showPassword)}
              onKeyDown={() => setShowPassword(!showPassword)}
              tabIndex={-8}
            >
              <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true" />
            </span>
            {form.oldPasswordError.length
              ? form.oldPasswordError.map((err, i) => <ErrorSpan key={i} error={err} />)
              : null}
          </div>

          <div className="form-group form-floating-label inline-label">
            <input
              type={showNewPassword ? 'text' : 'password'}
              id="inputFloatingLabel32"
              className="form-control input-solid"
              name="newPassword"
              value={form.newPassword}
              placeholder="Type your new password"
              onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
            />
            {/* {form.newPassword.length == 0 ? (
              <label htmlFor="inputFloatingLabel32" className="placeholder">
                Type your new password
              </label>
            ) : null} */}
            <span
              className="input-icon sizespace"
              role="button"
              onClick={() => setShowNewPassword(!showNewPassword)}
              onKeyDown={() => setShowNewPassword(!showNewPassword)}
              tabIndex={-8}
            >
              <i className={showNewPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true" />
            </span>
            {form.newPasswordError.length
              ? form.newPasswordError.map((err, i) => <ErrorSpan key={i} error={err} />)
              : null}
          </div>

          <div className="form-group form-floating-label inline-label">
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              id="inputFloatingLabel42"
              className="form-control input-solid"
              name="confirmPassword"
              placeholder="Confirm your new password"
              value={form.confirmPassword}
              onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
            />
            {/* {form.confirmPassword.length == 0 ? (
              <label htmlFor="inputFloatingLabel42" className="placeholder">
                Confirm your new password
              </label>
            ) : null} */}
            <span
              className="input-icon sizespace"
              role="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              onKeyDown={() => setShowConfirmPassword(!showConfirmPassword)}
              tabIndex={-8}
            >
              <i
                className={showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}
                aria-hidden="true"
              />
            </span>
            {form.confirmPasswordError.length
              ? form.confirmPasswordError.map((err, i) => <ErrorSpan key={i} error={err} />)
              : null}
          </div>

          <div className="form-group text-center">
            <button
              type="submit"
              name="pass_click"
              className="btn btn-primary"
              onClick={(evt) => onSubmitHandler(evt)}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
