import './style.css';
import React from 'react';

const ErrorSpan = ({ error }) => (
  <span className="error" style={{ display: 'block' }}>
    * {error}
  </span>
);

export default ErrorSpan;
