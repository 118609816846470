import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useHandleResponse from '../useHandleResponse';
import { IMPORT_BIOLINK_DETAILS_FROM_LINKTREE_PROFILE_MUTATION } from '../../graphql/Mutations';
import * as appActions from '../../store/actions/app';
import * as authActions from '../../store/actions/auth';
import * as alertActions from '../../store/actions/alert';
import generateRandomId from '../../utils/genrateRandomId';

const importBiolinkDetailsFromLinktreeProfile = () => {
  const dispatch = useDispatch();
  const [importBiolinkDetailsFromLinktreeProfileMutation, { loading, data }] = useMutation(
    IMPORT_BIOLINK_DETAILS_FROM_LINKTREE_PROFILE_MUTATION,
  );
  const { successMessage, errorMessage } = useHandleResponse();

  useEffect(() => {
    dispatch(appActions.isLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (data?.importBiolinkDetailsFromLinktreeProfile?.errors === null) {
      // successMessage();
      dispatch(
        authActions.importBiolinkDetailsFromLinktreeProfile(
          data?.importBiolinkDetailsFromLinktreeProfile.biolink,
        ),
      );
      dispatch(
        alertActions.addAlert({
          id: generateRandomId(),
          message: 'Updated Successfully.',
          type: 'success',
        }),
      );
    }
    if (data?.importBiolinkDetailsFromLinktreeProfile?.errors?.length) {
      errorMessage(data?.importBiolinkDetailsFromLinktreeProfile?.errors);
    }
  }, [data]);

  return [
    importBiolinkDetailsFromLinktreeProfileMutation,
    {
      data,
    },
  ];
};

export default importBiolinkDetailsFromLinktreeProfile;
