import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
// import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';

import './index.css';
import App from './App';

ReactDOM.render(
  <React.Fragment key="key">
    {/* <ClearBrowserCacheBoundary auto fallback="Loading" duration={60000}> */}
    <App />
    {/* </ClearBrowserCacheBoundary> */}
  </React.Fragment>,

  document.getElementById('root'),
);
