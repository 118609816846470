import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import images from '../../../../assets/images';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './style.css';
import validate from '../../../../utils/validations';

const DirectoryCarousel = ({ directoryData }) => {
  const [list, setList] = useState([]);
  const [updateList, setUpdateList] = useState([]);
  const [itemNo, setItemNo] = useState(4);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (directoryData?.edges?.length) {
      const tempList = [];
      directoryData?.edges?.forEach((value) => {
        tempList.push({
          image: value.node.profilePhotoUrl ? value.node.profilePhotoUrl : images.dummy_img,
          name: value.node.username?.username,
          bio: value.node?.category?.categoryName,
          desc: value.node?.bio ? `${value.node?.bio?.substring(0, 40)}...` : '',
        });
      });

      // console.log('tempList=====>', tempList);
      setList(tempList);
      setTimeout(() => {
        setRefresh(!refresh);
      }, 50);
    } else {
      const tempList = [];
      setList(tempList);
      setTimeout(() => {
        setRefresh(!refresh);
      }, 50);
    }
  }, [directoryData]);

  useEffect(() => {
    if (list.length <= 3 && list.length) {
      setItemNo(list.length);
    }
    // console.log('listlistlist===>', list);
  }, [list]);

  const options = {
    loop: true,
    // center: true,
    items: 4,
    margin: 30,
    dots: false,
    nav: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 300,
    // navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    navClass: ['owl-prev owl-prev-directory', 'owl-next owl-next-directory'],
    stageClass: 'owl-stage directory-stage',
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1100: {
        items: 3,
      },
      1170: {
        items: 4,
      },
    },
  };

  // options.owlCarousel();
  return (
    <div className="directory-people">
      <OwlCarousel
        id="directory-testimonials"
        className="owl-carousel directory-people-slider"
        {...options}
      >
        {list?.map((item, index) => (
          <div className="main-directory-box  ">
            <Link
              to={{
                pathname: '/person_directory_detail',
                state: {
                  profileDatas: directoryData,
                  nodeId: index,
                },
              }}
              id={item.id}
            >
              <div className="directory-profile">
              <div className="directory-img">
                <img
                  src={validate.imageValidate(item.image) ? item.image : images.dummy_img}
                  alt=""
                />
              </div>
              <div className="directory-author">
              <h3>{item?.name}</h3>
              <h5>{item?.bio}</h5>
              </div>
              </div>
              <p>{item?.desc?.substring(0, 40)}...</p>
            </Link>
            <span>
              <Link
                to={{
                  pathname: `${item.name}`,
                }}
                className="go_to_biolink btn btn-primary"
              >
                Go to Biolink
              </Link>
            </span>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default DirectoryCarousel;
