import logo from './images/logo.svg';
import logoIcon from './images/logo-1-icon.svg';

import defaultProfilePic from './images/default_profile_pic.png';
import logo2 from './images/logo-2.png';
import logo3 from './images/logo-2_bkkk.png';
import signUpBg from './images/sign-up-bg.png';
import signInBg from './images/sign-in-bg.png';
import signUp from './images/sign-up.png';
// import toggle from './images/toggle.png';
import iconLink from './images/icn-link.png';
import watsapp from './images/watsapp.png';
import bannerImg from './images/banner-img.svg';
import img1 from './images/landing page/Group 48.svg';
import img2 from './images/landing page/Group 43.svg';
import img3 from './images/landing page/Group 47.svg';
import product1 from './images/prduct-1.png';
import product2 from './images/prduct-2.png';
import product3 from './images/prduct-3.png';
import ellipse13 from './images/Ellipse 13.png';
import ellipse14 from './images/Ellipse 14.png';
import ellipse15 from './images/Ellipse 15.png';
import ellipse16 from './images/Ellipse 16.png';
import group535 from './images/Group 535.png';
import group537 from './images/Group 537.png';
import group538 from './images/Group 538.png';
import mapBanner from './images/map-banner.png';

import personLinked from './images/linkedin 2.png';
import personRectangle from './images/Rectangle.png';
import personArrowIcon from './images/arrow-icon.png';
import personFacebook from './images/facebook.png';
import personBracIcon from './images/brac-icon.png';
import personInsta from './images/insta.png';
import termsTeam1 from './images/team-1.jpg';
import termsTeam2 from './images/team-2.jpg';
import termsTeam3 from './images/team-3.jpg';
import termsTeam4 from './images/team-4.jpg';
import termsTeam5 from './images/team-5.jpg';
import aboutTeam1 from './images/about-1.png';
import aboutTeam2 from './images/about-2.png';

import banner_top from './images/banner_top.svg';

// import blogPlaceholder from './images/Image Placeholder.png';
// import blogPlaceholder2 from './images/Image Placeholder2.png';
// // import blogPlaceholder3 from './images/Image Placeholder 3.png';
// import blogPlaceholder6 from './images/Image-placeholder-6.png';
// import blogPlaceholder4 from './images/Image Placeholder 4.png';
// import blogPlaceholder5 from './images/Image Placeholder 5.png';
// import blogVideoPlaceholder from './images/Video Placeholder.png';
import blogButton from './images/Button.png';
//App Images
import dashboardProfile from './images/profile-img.png';
import dashboardDashboard from './images/Dashboard Icon.png';
import dashboardAnalytics from './images/Analytics Icon.png';
import dashboardMessages from './images/Messages Icon.png';
import dashboardSettings from './images/Settings Icon.png';
import dashboardVerification from './images/verification.png';
import dashboardDirectory from './images/directory-icon.png';
import dashboardLinkShortner from './images/link 1.png';
import dashboardLogOut from './images/log-out 1.png';
import bannerImage from './images/banner.png';
import profileNewImage from './images/profile-banner.png';
import billingBg from './images/billing-bg.png';
import billingRedBg from './images/premium-plan.png';
import billingBlueBg from './images/Savings-Plan.png';
import pendingVerif from './images/pending-verif.png';
import footerReport from './images/report1.png';
import verifiedVerif from './images/verified-verif.png';
import rejectedVerif from './images/rejected-verif.png';
// Home Page
import homeBanner from './images/home_slider.png';
import claimUsername from './images/Claim-Username.png';
import landingPage from './images/Landing-Page.png';
import yourBio from './images/Your-Bio.png';
import homeDesigner from './images/Designer.png';
import homeArtist from './images/Artist.png';
// About us Page
import aboutUs1 from './images/About-us1.png';
import ourMission1 from './images/Our-Mission1.png';
import ourVision1 from './images/Our-Vision1.png';
// Dashboard Page
import dashboardBannerImgAdmin from './images/banner_top.svg';
import plusprofile from './images/plusprofile.svg';
import dashboard_profilepic from './images/dashboard_profilepic.svg';
import dashboardTweeter from './images/tweeter.png';
import dashboardYoutube from './images/YOUTUBE-LOGO 3.png';
import dashboardIcon from './images/icon.png';
import dashboardLockIcon from './images/lock.png';
import dashboardReferalBanner from './images/referal-banner.png';
import dashboardRefral0img from './images/refral0img.png';
import linklogo from './images/linklogo.svg';
import mypage from './images/mypage.svg';
import account from './images/account.svg';
import analytics from './images/analytics.svg';
import billing from './images/billing.svg';
import linksort from './images/linksort.svg';
import logout from './images/logout.svg';
import maindirectory from './images/maindirectory.svg';
import mydirectory from './images/mydirectory.svg';
import referrals from './images/referrals.svg';
import verification from './images/verification.svg';
import rounddashboard from './images/rounddashboard.svg';
import notidashboard from './images/notidashboard.svg';
import chatdash from './images/chatdash.svg';
import youtube_icon from './images/youtube_icon.svg';
import urlicon from './images/urlicon.svg';

import dashboardGroup3 from './images/Group 3.png';
import dashboardGroup31 from './images/Group 3 (1).png';
import dashboardGroup2 from './images/Group 2.png';
import dashboardGroup21 from './images/Group 2 (1).png';
import dashboardkpi1 from './images/kpi1.png';
import dashboardkpi2 from './images/kpi2.png';
import stasheeLogo from './images/lgo-07.png';
import featureOne from './images/feature1.png';
import featureTwo from './images/feature2.png';
import featureThree from './images/feature3.png';
import threeDots from './images/menu-threedots.png';
// access page
import accessOne from './images/video-message.png';
import accessTwo from './images/social-media.png';
import accessThree from './images/phone-call.png';
import accessFour from './images/message-family-friend.png';
import accessFive from './images/loved-card.png';
import accessSix from './images/question-reply.png';
import accessSeven from './images/Offer-something.png';
import chatSmiley from './images/smiley-face.png';
import chatAttach from './images/attach-file.png';
import chatSend from './images/send.png';
// link shortner page
import easy from './images/easy.png';
import shortened from './images/shortened.png';
import secure from './images/secure.png';
import devices from './images/devices.png';
import switch_off from './images/switch_off.png';
import mid from './images/mid.png';
import content_bg from './images/content_bg.png';
import designer from './images/designer.svg';
import artist from './images/artist.svg';
import testiimage from './images/testiimage.svg';
import easyIcon from './images/easyone.png';
import shortenedIcon from './images/shortenedsec.png';
import secureIcon from './images/securethird.png';
import devicesIcon from './images/devicesimg.png';

//Referals
import Referral from './images/refer-bg.svg';

import dummy_img from './images/user-dummy-pic.png';
import verified_badge from './images/verified_badge.png';
import pending_badge from './images/pending_badge.jpg';
import rejected_badge from './images/rejected_badge.jpg';
import report_top from './images/report_top.svg';
import login_sidebar from './images/login_sidebar.svg';
import footer_logo from './images/footer_logo.svg';
import contact from './images/contact.svg';
// team
import VahidChitsaz from './images/VahidChitsaz.svg';
import profilepic from './images/profilepic.svg';
import r1 from './images/r1.svg';
import r2 from './images/r2.svg';
import WilliamJ from './images/WilliamJ.svg';
import AmirAlkabir from './images/AmirAlkabir.svg';
import about1 from './images/about1.svg';
import about2 from './images/about2.svg';
import about3 from './images/about3.svg';
import teamm from './images/teamm.svg';
import team1 from './images/team1.svg';
import teamm2 from './images/teamm2.svg';
import teamm3 from './images/teamm3.svg';
import teamm4 from './images/teamm4.svg';
import maskGroupsvg4 from './images/team/Mask Groupsvg (1).svg';
import maskGroupsvg3 from './images/team/Mask Groupsvg-3.svg';
import maskGroupsvg2 from './images/team/Mask Groupsvg-2.svg';
import maskGroupsvg1 from './images/team/Mask Groupsvg-1.svg';
import maskGroupsvg from './images/team/Mask Groupsvg.svg';
import group227 from './images/team/Group 227.svg';
import amirMask from './images/team/amir mask.svg';
import maskGroup from './images/team/Mask Group.svg';
import nSvg from './images/team/n.svg';
import group228 from './images/team/Group 228.svg';
import group221 from './images/team/Group 221.svg';
import group223 from './images/team/Group 223.svg';
import group226 from './images/team/Group 226.svg';
import group220 from './images/team/Group 220svg.svg';
import faqimage from './images/faqimage.svg';
import blog1 from './images/blog01.png';
import blog2 from './images/blog02.png';
import blog3 from './images/blog03.png';
import blog4 from './images/blog04.png';
import blog5 from './images/blog05.png';
import blog6 from './images/blog06.png';
import blog7 from './images/blog07.png';
import blog8 from './images/blog08.png';
import blog9 from './images/blog09.png';
import blog10 from './images/blog010.png';
import blog11 from './images/blog011.png';

import content_work from './images/content_work.svg';
import work from './images/work.svg';
import signwork from './images/signwork.svg';
import socialtree from './images/socialtree.svg';
import bottom_work from './images/bottom_work.svg';
import bottom_work1 from './images/bottom_work1.svg';
import bottom_work2 from './images/bottom_work2.svg';
import bottomwork from './images/bottomwork.svg';
import btmlogo1 from './images/btmlogo1.svg';
import border_bg from './images/border_bg.svg';
import dot from './images/dot.svg';
import linkicon from './images/linkicon.svg';
import banner_dashboard from './images/banner_dashboard.svg';
import sectionicon from './images/sectionicon.svg';
import embed from './images/embed.svg';
import plusadd from './images/plusadd.svg';
import round from './images/round.svg';
import squar from './images/squar.svg';
import oneclick from './images/oneclick.svg';
import lock from './images/lock.svg';
import unlock from './images/unlock.svg';
import profilepic1 from './images/profilepic1.svg';
import profilepic2 from './images/profilepic1.svg';
import checked_icon from './images/checked_icon.svg';
import countarrow from './images/countarrow.svg';
import darrow from './images/darrow.svg';
import dieractoryprofile from './images/dieractoryprofile.svg';

import device1 from './images/device1.svg';
import secure1 from './images/secure1.svg';
import sorthand1 from './images/sorthand1.svg';
import easy1 from './images/easy1.svg';
import upgradeicon from './images/upgradeicon.svg';
import btn_upgrade from './images/btn_upgrade.svg';
import comma1 from './images/comma1.svg';
import comma2 from './images/comma2.svg';
import paymentimg from './images/paymentimg.svg';
import profileicon from './icons/icon/profile.png';
import accounticon from './icons/icon/account.png';
import brandingicon from './icons/icon/branding.png';
import directoryicon from './icons/icon/directory.png';
import linkicon1 from './icons/icon/link.png';
import privacysettingicon from './icons/icon/privacy-setting.png';
import seoicon from './icons/icon/seo.png';
import socialicon from './icons/icon/social.png';
import utmicon from './icons/icon/utm.png';
import paymenticon from './icons/icon/payment.png';
import contacticon from './icons/icon/contact-icon.png';
import integration from './icons/icon/integration.png';

import menu from './images/menu.png';
import msg from './images/msg.png';
import notification from './images/notification.png';
import search from './images/search.png';

import Account from './images/Account.png';
import Account_blue from './images/Account_blue.png';
import Analytics from './images/Analytics.png';
import Analytics_blue from './images/Analytics_blue.png';
import Billing from './images/Billing.png';
import Billing_blue from './images/Billing_blue.png';
import Dashboard from './images/Dashboard.png';
import Dashboard_blue from './images/Dashboard_blue.png';
import Link_Shortner from './images/Link_Shortner.png';
import Link_Shortner_blue from './images/Link_Shortner_blue.png';
import Log_Out from './images/Log_Out.png';
import Main_Directory from './images/Main_Directory.png';
import Main_Directory_blue from './images/Main_Directory_blue.png';
import My_Directory from './images/My_Directory.png';
import My_Directory_blue from './images/My_Directory_blue.png';
import Referrals from './images/Referrals.png';
import Referrals_blue from './images/Referrals_blue.png';
import Verification_Badge from './images/Verification_Badge.png';
import Verification_Badge_blue from './images/Verification_Badge_blue.png';
import logout_white from './images/logout_white.png';
import howitwork from './images/howitwork.png';
import signuphowitwork from './images/signuphowitwork.png';
import howit from './images/howit.png';
import sharebio from './images/sharebio.png';
import bottom_image from './images/bottom_image.png';
import bottom_image1 from './images/bottom_image1.png';
import bottom_image2 from './images/bottom_image2.png';
import bottom_image3 from './images/bottom_image3.png';
import getlink from './images/getlink.png';
import register from './images/register.png';
import register_bg from './images/register_bg.png';
import contactus from './images/contactus.png';

const images = {
  logoIcon,
  logo,
  logo2,
  logo3,
  signUpBg,
  signUp,
  signInBg,
  // toggle,
  profileicon,
  accounticon,
  brandingicon,
  directoryicon,
  linkicon1,
  privacysettingicon,
  seoicon,
  socialicon,
  utmicon,
  paymenticon,
  contacticon,
  integration,

  iconLink,
  watsapp,
  bannerImg,
  img1,
  img2,
  img3,
  product1,
  product2,
  product3,
  ellipse13,
  ellipse14,
  ellipse15,
  ellipse16,
  group535,
  group537,
  group538,
  mapBanner,
  personLinked,
  personRectangle,
  personArrowIcon,
  personFacebook,
  personBracIcon,
  personInsta,
  termsTeam1,
  termsTeam2,
  termsTeam3,
  termsTeam4,
  termsTeam5,
  aboutTeam1,
  aboutTeam2,
  // blogPlaceholder,
  // blogPlaceholder2,
  // blogPlaceholder3,
  // blogPlaceholder4,
  // blogPlaceholder6,
  // blogPlaceholder5,
  // blogVideoPlaceholder,
  // blogButton,
  dashboardProfile,
  dashboardDashboard,
  dashboardAnalytics,
  dashboardMessages,
  dashboardSettings,
  dashboardVerification,
  dashboardDirectory,
  dashboardLinkShortner,
  dashboardLogOut,
  // Dashboard Page
  dashboardBannerImgAdmin,

  // dashboardProfile2,
  dashboardTweeter,
  dashboardYoutube,
  dashboardIcon,
  dashboardLockIcon,
  dashboardReferalBanner,
  dashboardRefral0img,
  dashboardGroup3,
  dashboardGroup31,
  dashboardGroup2,
  dashboardGroup21,
  dashboardkpi1,
  dashboardkpi2,
  Referral,
  easy,
  shortened,
  secure,
  devices,
  switch_off,
  mid,
  content_bg,
  designer,
  artist,
  testiimage,
  stasheeLogo,
  dummy_img,
  verified_badge,
  pending_badge,
  rejected_badge,
  login_sidebar,
  footer_logo,
  report_top,
  contact,
  VahidChitsaz,
  profilepic,
  r1,
  r2,
  WilliamJ,
  AmirAlkabir,
  about1,
  about2,
  about3,
  teamm,
  team1,
  teamm2,
  teamm3,
  teamm4,
  maskGroupsvg4,
  maskGroupsvg3,
  maskGroupsvg2,
  maskGroupsvg1,
  maskGroupsvg,
  group227,
  amirMask,
  maskGroup,
  nSvg,
  group228,
  group221,
  group223,
  group226,
  group220,
  faqimage,
  blog1,
  blog2,
  blog3,
  blog4,
  blog5,
  blog6,
  blog7,
  blog8,
  blog9,
  blog10,
  content_work,
  work,
  signwork,
  socialtree,
  bottom_work,
  bottom_work1,
  bottom_work2,
  bottomwork,
  btmlogo1,
  border_bg,
  dot,
  banner_dashboard,
  banner_top,
  dashboard_profilepic,
  plusprofile,
  linklogo,
  embed,
  sectionicon,
  linkicon,
  plusadd,
  round,
  squar,
  oneclick,
  unlock,
  lock,
  mypage,
  account,
  analytics,
  billing,
  linksort,
  logout,
  maindirectory,
  mydirectory,
  referrals,
  verification,
  rounddashboard,
  notidashboard,
  chatdash,
  youtube_icon,
  urlicon,
  profilepic1,
  profilepic2,
  checked_icon,
  countarrow,
  darrow,
  dieractoryprofile,
  device1,
  secure1,
  sorthand1,
  easy1,
  upgradeicon,
  btn_upgrade,
  comma1,
  comma2,
  paymentimg,
  bannerImage,
  profileNewImage,
  menu,
  msg,
  notification,

  Account,
  Account_blue,
  Analytics,
  Analytics_blue,
  Billing,
  Dashboard,
  Dashboard_blue,
  Link_Shortner,
  Link_Shortner_blue,
  Log_Out,
  Main_Directory,
  Main_Directory_blue,
  My_Directory,
  My_Directory_blue,
  Referrals,
  Referrals_blue,
  Verification_Badge,
  Verification_Badge_blue,
  Billing_blue,
  logout_white,
  billingBg,
  billingRedBg,
  billingBlueBg,
  pendingVerif,
  easyIcon,
  shortenedIcon,
  secureIcon,
  devicesIcon,
  homeBanner,
  claimUsername,
  landingPage,
  yourBio,
  homeDesigner,
  homeArtist,
  aboutUs1,
  ourMission1,
  ourVision1,
  howitwork,
  signuphowitwork,
  howit,
  sharebio,
  bottom_image,
  bottom_image1,
  bottom_image2,
  bottom_image3,
  getlink,
  register,
  register_bg,
  footerReport,
  blog11,
  contactus,
  featureOne,
  featureTwo,
  featureThree,
  threeDots,
  accessOne,
  accessTwo,
  accessThree,
  accessFour,
  accessFive,
  accessSix,
  accessSeven,
  chatSmiley,
  chatSend,
  chatAttach,
  verifiedVerif,
  rejectedVerif,
};

export default images;
