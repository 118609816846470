import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { UPLOAD_BIOLINK_PROFILE_PHOTO_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';

const ProfileImage = () => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const [uploadBiolinkProfilePhoto, { data }] = useNetwork(UPLOAD_BIOLINK_PROFILE_PHOTO_MUTATION);

  const hiddenFileInput = useRef(null);

  const uploadDocumentHandler = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    e.persist();
    const [file] = e.target.files;
    if (file) {
      uploadBiolinkProfilePhoto({
        variables: {
          profilePhoto: file,
          id: currentBiolink.id,
        },
      });
    }
  };
  return (
    <div className="file-btn" onClick={() => uploadDocumentHandler()}>
      <img src="../assets/img/pluse.svg" alt="" className="img-responsive-pro" />
      <input
        type="file"
        name="profileImage"
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleChange}
      />
    </div>
  );
};

export default ProfileImage;
