import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useNetwork from '../../../hooks/useNetwork';
import { IMPORT_BIOLINK_DETAILS_FROM_LINKTREE_PROFILE_MUTATION } from '../../../graphql/Mutations';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import images from '../../../assets/images';

const ImportForm = () => {
  const [importBiolinkDetailsFromLinktreeProfile, { data }] = useNetwork(
    IMPORT_BIOLINK_DETAILS_FROM_LINKTREE_PROFILE_MUTATION,
  );

  const currentBiolink = useSelector((state) => state.auth.currentBiolink);

  const [form, setForm] = useState({
    selectedOption: '',
    selectedOptionError: [],
    url: '',
    urlError: [],
  });

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    let isValidated = true;
    const selectedOptionError = [];
    const urlError = [];

    // add validations here
    if (!form.selectedOption) {
      isValidated = false;
      selectedOptionError.push('Select an option.');
    }
    if (!form.url) {
      isValidated = false;
      urlError.push('URL cannot be empty.');
    }

    setForm({
      ...form,
      selectedOptionError,
      urlError,
    });
    if (!isValidated) return;

    // submit form
    importBiolinkDetailsFromLinktreeProfile({
      variables: {
        id: currentBiolink.id,
        linktreeUsername: form.url,
      },
    });
    setForm({
      ...form,
      url: '',
    });
  };

  return (
    <div className="modal fade" id="modal-1">
      <div className="modal-dialog">
        <div className="modal-content">
          {/* <div className="modal-header">
            <h4 className="modal-title">Import Details</h4>

            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          {/* <div className="modal-body">
            <div className="form-list"> */}
          {/* <div className="form-group form-floating-label">
                <select
                  className="form-control input-solid"
                  id="selectFloatingLabel2"
                  required=""
                  name="selectedOption"
                  value={form.selectedOption}
                  onChange={(evt) => {
                    inputChangeHandler(evt.target.name, evt.target.value);
                  }}
                  placeholder="Select Option"
                >
                  <option value="">&nbsp;</option>
                  <option value="linktree">LINK TREE</option>
                </select>
                <label htmlFor="selectFloatingLabel2" className="placeholder">
                  Select Option
                </label>
                {form.selectedOptionError.length
                  ? form.selectedOptionError.map((err, i) => <ErrorSpan key={i} error={err} />)
                  : null}
              </div> */}
          {/* <div className="form-group form-floating-label">
                <input
                  id="inputFloatingLabel2"
                  type="text"
                  className="form-control input-solid"
                  required
                  name="url"
                  value={form.url}
                  onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                  placeholder="Enter Username"
                />
                <label htmlFor="inputFloatingLabel2" className="placeholder">
                  Enter Username
                </label>
                {form.urlError.length
                  ? form.urlError.map((err, i) => <ErrorSpan key={i} error={err} />)
                  : null}
              </div> */}

          {/* <button type="button" className="btn btn-primary" onClick={onSubmitHandler}>
                Import Details
              </button> */}
          {/* </div>
          </div> */}

          <div className="modal-footer">
            <ul className="other-detail-list">
              <li>
                <h3 className="addDestails">Add other Detail</h3>
                <button type="button" className="close" data-dismiss="modal">
                  <span aria-hidden="true">&times;</span>
                  <span className="sr-only">Close</span>
                </button>
              </li>
              <li>
                <div className="inline-icons">
                  <a href="#modal-2" data-toggle="modal" data-dismiss="modal">
                    <span className="icon-span">
                      <img alt="" src={images.profileicon} />
                    </span>
                    <span> Profile</span>
                  </a>
                  {/* <a href="#modal-2" data-toggle="modal" data-dismiss="modal">
                    Profile
                  </a> */}
                </div>
              </li>
              <li>
                <div className="inline-icons">
                  <a href="#modal-3" data-toggle="modal" data-dismiss="modal">
                    <span className="icon-span">
                      {' '}
                      <img alt="" src={images.contacticon} />
                    </span>
                    <span> Contact Buttons</span>
                  </a>
                </div>
                {/* <div className="inline-icons">
                  <a href="#modal-3" data-toggle="modal" data-dismiss="modal">
                    Contact Buttons
                  </a>
                </div> */}
              </li>
              <li>
                <div className="inline-icons">
                  <a href="#modal-11" data-toggle="modal" data-dismiss="modal">
                    <span className="icon-span">
                      {' '}
                      <img alt="" src={images.linkicon1} />
                    </span>
                    <span> Links & Embeds</span>
                  </a>
                </div>
                {/* <div className="inline-icons">
                  <a href="#modal-11" data-toggle="modal" data-dismiss="modal">
                    Links & Embeds
                  </a>
                </div> */}
              </li>
              <li>
                <div className="inline-icons">
                  <a href="#modal-12" data-toggle="modal" data-dismiss="modal">
                    <span className="icon-span">
                      {' '}
                      <img alt="" src={images.socialicon} />
                    </span>
                    <span> Social Media</span>
                  </a>
                </div>
                {/* <div className="inline-icons">
                  <a href="#modal-12" data-toggle="modal" data-dismiss="modal">
                    Social Media
                  </a>
                </div> */}
              </li>
              <li>
                <div className="inline-icons">
                  <a href="#modal-4" data-toggle="modal" data-dismiss="modal">
                    <span className="icon-span">
                      {' '}
                      <img alt="" src={images.integration} />
                    </span>
                    <span> Integrations</span>
                  </a>
                </div>
                {/* <div className="inline-icons">
                  <a href="#modal-4" data-toggle="modal" data-dismiss="modal">
                    Integrations
                  </a>
                </div> */}
              </li>
              <li>
                <div className="inline-icons">
                  <a href="#modal-5" data-toggle="modal" data-dismiss="modal">
                    <span className="icon-span">
                      {' '}
                      <img alt="" src={images.directoryicon} />
                    </span>
                    <span>Directory</span>
                  </a>
                </div>
                {/* <div className="inline-icons">
                  <a href="#modal-5" data-toggle="modal" data-dismiss="modal">
                    Directory
                  </a>
                </div> */}
              </li>
              <li>
                <div className="inline-icons">
                  <a href="#modal-6" data-toggle="modal" data-dismiss="modal">
                    <span className="icon-span">
                      {' '}
                      <img alt="" src={images.utmicon} />
                    </span>
                    <span>UTM Parameters</span>
                  </a>
                </div>
                {/* <div className="inline-icons">
                  <a href="#modal-6" data-toggle="modal" data-dismiss="modal">
                    UTM Parameters
                  </a>
                </div> */}
              </li>
              <li>
                <div className="inline-icons">
                  <a href="#modal-7" data-toggle="modal" data-dismiss="modal">
                    <span className="icon-span">
                      {' '}
                      <img alt="" src={images.seoicon} />
                    </span>
                    <span> SEO Settings</span>
                  </a>
                </div>
                {/* <div className="inline-icons">
                  <a href="#modal-7" data-toggle="modal" data-dismiss="modal">
                    SEO Settings
                  </a>
                </div> */}
              </li>
              <li>
                <div className="inline-icons">
                  <a href="#modal-8" data-toggle="modal" data-dismiss="modal">
                    <span className="icon-span">
                      {' '}
                      <img alt="" src={images.brandingicon} />
                    </span>
                    <span> Branding Settings</span>
                  </a>
                </div>
                {/* <div className="inline-icons">
                  <a href="#modal-8" data-toggle="modal" data-dismiss="modal">
                    Branding Settings
                  </a>
                </div> */}
              </li>
              <li>
                <div className="inline-icons">
                  <a href="#modal-9" data-toggle="modal" data-dismiss="modal">
                    <span className="icon-span">
                      <img alt="" src={images.privacysettingicon} />
                    </span>
                    <span> Privacy Settings</span>
                  </a>
                </div>
                {/* <div className="inline-icons">
                  <a href="#modal-9" data-toggle="modal" data-dismiss="modal">
                    Privacy Settings
                  </a>
                </div> */}
              </li>
              <li>
                <div className="inline-icons">
                  <a href="#modal-10" data-toggle="modal" data-dismiss="modal">
                    <span className="icon-span">
                      {' '}
                      <img alt="" src={images.paymenticon} />
                    </span>
                    <span> Payments</span>
                  </a>
                </div>
                {/* <div className="inline-icons">
                  <a href="#modal-10" data-toggle="modal" data-dismiss="modal">
                    Payments
                  </a>
                </div> */}
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- /.modal-content --> */}
      </div>
      {/* <!-- /.modal-dialog --> */}
    </div>
  );
};

export default ImportForm;
