import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import { CHANGE_USER_ACCOUNT_INFO_MUTATION } from '../../../graphql/Mutations';
import validate from '../../../utils/validations';
import useNetwork from '../../../hooks/useNetwork';

const ChangeEmailForm = ({ userEmail, userName }) => {
  const [changeUserAccountInfo, { data }] = useNetwork(CHANGE_USER_ACCOUNT_INFO_MUTATION);
  const biolinkId = useSelector((state) => state?.auth?.currentBiolink?.id);

  const [form, setForm] = useState({
    currentUsername: userName,
    email: userEmail,
    username: userName,
    emailError: [],
    usernameError: [],
  });

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    const emailError = [];
    const usernameError = [];
    // add validations here
    if (!validate.emailFormat(form.email) && !validate.emailFormat(form.email))
      emailError.push('Enter valid email.');
    if (!form.username) usernameError.push('Name cannot be empty');
    setForm({
      ...form,
      emailError,
      usernameError,
    });
    if (emailError.length) return;
    if (usernameError.length) return;
    // submit form

    changeUserAccountInfo({
      variables: {
        biolinkId,
        email: form.email,
        username: form.username,
      },
    });
  };

  return (
    <div className="card full-height">
      <div className="card-header text-center">
        <div className="card-title blue-text big-title">User Info</div>
      </div>
      <div className="card-body">
        <div className="form-list ">
          <div className="form-group form-floating-label">
            <input
              id="inputFloatingLabel2"
              type="text"
              className="form-control input-solid"
              placeholder="Write your email"
              name="email"
              value={form.email}
              onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
            />
            {/* <label htmlFor="inputFloatingLabel2" className="placeholder">
              Write your email
            </label> */}
          </div>

          {form.emailError.length
            ? form.emailError.map((err, i) => <ErrorSpan key={i} error={err} />)
            : null}
          <div className="form-group form-floating-label">
            <input
              type="text"
              id="inputFloatingLabel3"
              className="form-control input-solid"
              name="username"
              placeholder="Write your Username"
              value={form.username}
              onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
            />
            {/* <label htmlFor="inputFloatingLabel3" className="placeholder">
              Write your Username{' '}
            </label> */}
          </div>
        </div>
        {form.usernameError.length
          ? form.usernameError.map((err, i) => <ErrorSpan key={i} error={err} />)
          : null}

        <div className="form-group text-center">
          <button
            type="submit"
            name="acc_click"
            className="btn btn-primary"
            onClick={(evt) => onSubmitHandler(evt)}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeEmailForm;
