import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useHandleResponse from '../useHandleResponse';
import { UPLOAD_BIOLINK_PROFILE_PHOTO_MUTATION } from '../../graphql/Mutations';
import * as appActions from '../../store/actions/app';
import * as authActions from '../../store/actions/auth';
import * as alertActions from '../../store/actions/alert';
import generateRandomId from '../../utils/genrateRandomId';

const uploadBiolinkProfilePhoto = () => {
  const dispatch = useDispatch();
  const [uploadBiolinkProfilePhotoMutation, { loading, data }] = useMutation(
    UPLOAD_BIOLINK_PROFILE_PHOTO_MUTATION,
  );
  const { successMessage, errorMessage } = useHandleResponse();

  useEffect(() => {
    dispatch(appActions.isLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (data?.uploadBiolinkProfilePhoto?.errors === null) {
      dispatch(authActions.uploadBiolinkProfilePhoto(data?.uploadBiolinkProfilePhoto.biolink));
      // successMessage();
    }
    if (data?.uploadBiolinkProfilePhoto?.errors?.length) {
      errorMessage(data?.uploadBiolinkProfilePhoto?.errors);
    }
  }, [data]);

  return [
    uploadBiolinkProfilePhotoMutation,
    {
      data,
    },
  ];
};

export default uploadBiolinkProfilePhoto;
