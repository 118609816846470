import React from 'react';
import { Link } from 'react-router-dom';

const Claim = (props) => {
  return (
    <>
      <div className="row bg-claim">
        <div className="col-md-6 ">
          <div className="claim-detail">
            <h2>Claim The Username Now</h2>
            <p>
              Claim the stash.ee username for you or your brand and for business instantly and
              securely.
            </p>
          </div>
        </div>
        <div className="col-md-6 claim-inputs ">
          <div className="claim-now-area float-right">
            <span className="text-input float-left">
              You will get the confirmation on your Email
            </span>
            <Link to="/auth/register" className="btn btn-primary float-right">
              Claim Now
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Claim;
