import React from 'react';
import { Link } from 'react-router-dom';
import images from '../../../assets/images';

const Ideas = () => {
  return (
    <>
      <div className="main-panel">
        <div className="content ideas-page">
          <div className="page-inner ">
            <div className="row mt--2">
              <div className="col-md-12">
                <div className=" full-height">
                  <div className="card-body">
                    <section>
                      <div className="sell-area sell-ideas">
                      <div className="sell-icon back-section">
                                <Link to="/app/access" className="next-btn">
                                  <i className="fa fa-angle-left"/>
                                </Link>
                                <div className="sell-back"> <img src={images.accessOne} alt="" />
                                  <h3>I'll send you a video message</h3></div>
                              </div>
                        <div className="row">
                          <div className="col-md-8 m-auto">
                            <div className=" sell-box text-left sell-box-detail">
                              <p>
                                Get a short video message from me, perfect for birthdays or just to
                                make someones day.
                              </p>
                              <div className="form-group form-floating-label px-0">
                                <input
                                  id="inputFloatingLabel2"
                                  type="text"
                                  placeholder="$ Enter price"
                                  className="form-control input-solid sell-input"
                                  required
                                />
                                {/* <label htmlFor="inputFloatingLabel2" className="placeholder">
                                  Set your price
                                </label> */}
                              </div>
                              <button type="button" className="btn btn-primary btn-lg mt-2">
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ideas;
