import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import images from '../../../assets/images';

const Navbar = () => {
  const isLogin = useSelector((state) => state.auth.isAuthenticated);
  const email = useSelector((state) => state.auth.user.email);

  const onNavBarLink = () => {
    // console.log('Hide data==============');
    const mediaMatch = window.matchMedia('(min-width: 991)');
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
    #collapsenav {
      display:none;
    }
  `;
    // $('#collapsenav').css('display:none');
  };
  return (
    <>
      <div className="frontend-navigation clearfix">
        <div className="logo-box">
          <Link to="/">
            <img src="../assets/img/logo@2x.svg" alt="navbar brand" className="navbar-brand" />
          </Link>
        </div>
        <div className="btn-bar float-right">
          {!isLogin ? (
            <>
              <Link to="/auth/login" className="btn btn-blue btn-border">
                Sign In
              </Link>
              <Link to="/auth/register" className="btn btn-primary">
                Sign Up
              </Link>
            </>
          ) : (
            <Link to="/app" className="btn btn-primary">
              {email}
            </Link>
          )}
        </div>
        <a className="nav-icon" data-toggle="collapse" href="#collapsenav" id="nav-icon-id">
          <img className="icon-head-nav" src={images.menu} alt="" />
        </a>
        <ul className="nav-list" id="collapsenav">
          <li>
            <Link className="nav-clk-cls" onClick={() => onNavBarLink()} to="/feature">
              Features
            </Link>
          </li>
          <li>
            <Link className="nav-clk-cls" onClick={() => onNavBarLink()} to="/pricing">
              
              Pricing
            </Link>
          </li>
          <li>
            <Link className="nav-clk-cls" onClick={() => onNavBarLink()} to="/directory">
              Directory
            </Link>
          </li>
          <li>
            <Link className="nav-clk-cls" onClick={() => onNavBarLink()} to="/faq">
              FAQ
            </Link>
          </li>
          <li>
            <Link className="nav-clk-cls" onClick={() => onNavBarLink()} to="/how_it_works">
              How It works
            </Link>
          </li>
          {/* <li>
            <Link
              className="nav-clk-cls"
              onClick={() => onNavBarLink()}
              to="/auth/email_verification"
            >
              email
            </Link>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default Navbar;
