import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useHandleResponse from '../useHandleResponse';
import { CHANGE_CURRENT_BIOLINKID_MUTATION } from '../../graphql/Mutations';
import * as appActions from '../../store/actions/app';

const changeCurrentBiolinkId = () => {
  const dispatch = useDispatch();
  const [changeCurrentBiolinkIdMutation, { loading, data }] = useMutation(
    CHANGE_CURRENT_BIOLINKID_MUTATION,
  );
  const { successMessage, errorMessage } = useHandleResponse();

  useEffect(() => {
    dispatch(appActions.isLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (data?.changeCurrentBiolinkId?.errors === null) {
      successMessage();
    }
    if (data?.changeCurrentBiolinkId?.errors?.length) {
      errorMessage(data?.changeCurrentBiolinkId?.errors);
    }
  }, [data]);

  return [
    changeCurrentBiolinkIdMutation,
    {
      data,
    },
  ];
};

export default changeCurrentBiolinkId;
