import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import ToggleSwitch from '../../../components/ui/ToggleSwitch';
import { UPDATE_PRIVACY_SETTINGS_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';

const PrivacyForm = ({ showUpgrade }) => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const currentPlan = useSelector((state) => state.auth.user.plan);
  const initialFormState = {
    password: '',
    enablePasswordProtection: currentBiolink.settings?.enablePasswordProtection,
    enableSensitiveContentWarning: currentBiolink.settings?.enableSensitiveContentWarning,
    passwordError: [],
  };
  const [form, setForm] = useState(initialFormState);

  const [updatePrivacySettings, { data }] = useNetwork(UPDATE_PRIVACY_SETTINGS_MUTATION);

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  
  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    if (
      !currentPlan?.settings.passwordProtectionEnabled ||
      !currentPlan?.settings.sensitiveContentWarningEnabled
    ) {
      showUpgrade();
      return;
    }
    // let isValidated = true;
    const passwordError = [];

    // add validations here
    // if (form.enablePasswordProtection && form.password.length < 8) {
    //   isValidated = false;
    //   passwordError.push('Password cannot be less than 8 characters.');
    // }

    setForm({
      ...form,
      passwordError,
    });
    // if (!isValidated) return;

    // submit form
    updatePrivacySettings({
      variables: {
        enablePasswordProtection: form.enablePasswordProtection,
        password: form.password,
        enableSensitiveContentWarning: form.enableSensitiveContentWarning,
        id: currentBiolink.id,
      },
    });
    setForm({
      ...form,
      password: '',
    });
  };

  const removeHandler = () => {
    // history.push('/app');
    //  <Link>
    //  </Link>
    // props.history.push('/app');
  };

  return (
    <div className="modal fade" id="modal-9">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Privacy Settings</h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body branding-model">
            <div className="form-list">
              <div className="form-group form-floating-label clearfix">
                {/* <input
                  id="inputFloatingLabel22" 
                  type="text"
                  className="form-control input-solid"
                  required
                /> */}
                <ToggleSwitch
                  id="inputFloatingLabel222"
                  currentState={form.enablePasswordProtection}
                  onToggle={() => {
                    if (!currentPlan?.settings.passwordProtectionEnabled) {
                      showUpgrade();
                      return;
                    }
                    setForm({
                      ...form,
                      enablePasswordProtection: !form.enablePasswordProtection,
                    });
                  }}
                  isActive={currentPlan?.settings.passwordProtectionEnabled}
                />
                <label htmlFor="inputFloatingLabel222" className="placeholder">
                  Enable Password Protection
                </label>
              </div>

              <div className="form-group form-floating-label clearfix">
                {/* <input
                  id="inputFloatingLabel23"
                  type="text"
                  className="form-control input-solid"
                  required
                /> */}
                <ToggleSwitch
                  id="inputFloatingLabel223"
                  currentState={form.enableSensitiveContentWarning}
                  onToggle={() => {
                    if (!currentPlan?.settings.sensitiveContentWarningEnabled) {
                      showUpgrade();
                      return;
                    }
                    setForm({
                      ...form,
                      enableSensitiveContentWarning: !form.enableSensitiveContentWarning,
                      // customBrandingName: '',
                      // customBrandingUrl: '',
                    });
                  }}
                  isActive={currentPlan?.settings.sensitiveContentWarningEnabled}
                  // isActive="na"
                />
                <label htmlFor="inputFloatingLabel223" className="placeholder">
                  Enable Sensitive Content Warning{' '}
                </label>
              </div>
              {form.enablePasswordProtection && (
                <>
                  <div className="form-group form-floating-label">
                    {/* <input
                      id="inputFloatingLabel24"
                      type="text"
                      className="form-control input-solid"
                      required
                    /> */}
                    <input
                      type="text"
                      id="inputFloatingLabel224"
                      className="form-control input-solid"
                      name="password"
                      value={form.password || ''}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      placeholder="Password"
                    />
                    {/* <label htmlFor="inputFloatingLabel224" className="placeholder">
                      Password
                    </label> */}
                    {form.passwordError.length
                      ? form.passwordError.map((err, i) => <ErrorSpan key={i} error={err} />)
                      : null}
                  </div>
                </>
              )}
              <button
                type="button"
                className="btn btn-primary"
                onClick={(evt) => onSubmitHandler(evt)}
              >
                Save changes
              </button>
            </div>
          </div>

          <div className="modal-footer">
            <a href="#modal-1" data-toggle="modal" data-dismiss="modal" className="btn btn-default">
              Previous
            </a>
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-danger"
              onClick={() => removeHandler()}
            >
              Close
            </button>
            {/* <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true" className="btn btn-danger">
                {' '}
                Cancel
              </span>
              {/* <span className="sr-only">Cancel</span> */}
            {/*  </button> */}
          </div>
        </div>
        {/* <!-- /.modal-content --> */}
      </div>
      {/* <!-- /.modal-dialog --> */}
    </div>
  );
};

export default PrivacyForm;
