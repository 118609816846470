import React from 'react';
import images from '../../../assets/images';

const Blog = (props) => {
  return (
    <>
      <div className=" blog-page">
        <div className="container pt-5">
          <div className="row mt--2">
            <div className="col-md-12">
              <div className=" full-height">
                <div className="center-heading black-text heading-area">
                  <div className="sub-heading mt-0">STASH.EE</div>
                  <h1>Blogs</h1>
                </div>
                <div className="card-body ">
                  <div className="row ">
                    <div className="col-md-12">
                      <div className="heading-blog">Latest one</div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="blog-box ">
                        <img src={images.blog1} alt="" />
                        <div className="blog-detail">
                          .
                        </div>
                        <div className="blog-text">
                          <a href="#" className="btn btn-primary blog-btn">
                            Category
                          </a>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <div className="inline-section">
                            <span>by Floyd Miles</span>
                            <span>June 13th 2021</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 blog-sec-col">
                    <div className="blog-box ">
                        <img src={images.blog2} alt="" />
                        <div className="blog-gradient">.</div>
                        <div className="blog-text">
                          <a href="#" className="btn btn-primary blog-btn">
                            Category
                          </a>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <div className="inline-section">
                            <span>by Floyd Miles</span>
                            <span>June 13th 2021</span>
                          </div>
                        </div>
                      </div>
                      <div className="blog-box ">
                        <img src={images.blog3} alt="" />
                        <div className="blog-gradient">.</div>
                        <div className="blog-text">
                          <a href="#" className="btn btn-primary blog-btn">
                            Category
                          </a>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <div className="inline-section">
                            <span>by Floyd Miles</span>
                            <span>June 13th 2021</span>
                          </div>
                        </div>
                      </div>
                      <div className="blog-box ">
                        <img src={images.blog4} alt="" />
                        <div className="blog-gradient">.</div>
                        <div className="blog-text">
                          <a href="#" className="btn btn-primary blog-btn">
                            Category
                          </a>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <div className="inline-section">
                            <span>by Floyd Miles</span>
                            <span>June 13th 2021</span>
                          </div>
                        </div>
                      </div>
                      <div className="blog-box ">
                        <img src={images.blog5} alt="" />
                        <div className="blog-gradient">.</div>
                        <div className="blog-text">
                          <a href="#" className="btn btn-primary blog-btn">
                            Category
                          </a>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <div className="inline-section">
                            <span>by Floyd Miles</span>
                            <span>June 13th 2021</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card full-height">
                <div className="card-body px-5 recommend-section">
                  <div className="row ">
                    <div className="col-md-8">
                      <div className="heading-blog">Recommended For You</div>
                    </div>
                    <div className="col-md-4 text-right mt-4">
                      <a href="#" className="btn btn-primary blog-btn">
                        View All
                      </a>
                    </div>
                    <div className="col-md-4 left-blog-text">
                      <div className="blog-box mb-3">
                        <img src={images.blog5} alt="" />
                        <div className="blog-text">
                          <a href="#" className="btn btn-primary blog-btn">
                            Category
                          </a>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <div className="inline-section">
                            <span>by Floyd Miles</span>
                            <span>June 13th 2021</span>
                          </div>
                        </div>
                      </div>
                      <div className="blog-box ">
                        <img src={images.blog8} alt="" />
                        <div className="blog-text">
                          <a href="#" className="btn btn-primary blog-btn">
                            Category
                          </a>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <div className="inline-section">
                            <span>by Floyd Miles</span>
                            <span>June 13th 2021</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 right-blog-text order-md-2">
                      <div className="blog-box width-full">
                        <img src={images.blog6} alt="" />
                        <div className="blog-text ">
                          <div className="blog-top-text">
                          <a href="#" className="btn btn-primary blog-btn">
                            Category
                          </a>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <div className="inline-section">
                            <span>by Floyd Miles</span>
                            <span>June 13th 2021</span>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
            </div>
            <div className="col-md-12">
              <div className=" full-height">
                <div className="card-body ">
                  <div className="row ">
                    <div className="col-md-12">
                      <div className="heading-blog">Recent Articale</div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="blog-box ">
                        <img src={images.blog8} alt="" />
                        <div className="blog-detail">
                          .
                        </div>
                        <div className="blog-text">
                          <a href="#" className="btn btn-primary blog-btn">
                            Category
                          </a>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <div className="inline-section">
                            <span>by Floyd Miles</span>
                            <span>June 13th 2021</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 blog-sec-col">
                    <div className="blog-box ">
                        <img src={images.blog9} alt="" />
                        <div className="blog-gradient">.</div>
                        <div className="blog-text">
                          <a href="#" className="btn btn-primary blog-btn">
                            Category
                          </a>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <div className="inline-section">
                            <span>by Floyd Miles</span>
                            <span>June 13th 2021</span>
                          </div>
                        </div>
                      </div>
                      <div className="blog-box ">
                        <img src={images.blog6} alt="" />
                        <div className="blog-gradient">.</div>
                        <div className="blog-text">
                          <a href="#" className="btn btn-primary blog-btn">
                            Category
                          </a>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <div className="inline-section">
                            <span>by Floyd Miles</span>
                            <span>June 13th 2021</span>
                          </div>
                        </div>
                      </div>
                      <div className="blog-box ">
                        <img src={images.blog10} alt="" />
                        <div className="blog-gradient">.</div>
                        <div className="blog-text">
                          <a href="#" className="btn btn-primary blog-btn">
                            Category
                          </a>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <div className="inline-section">
                            <span>by Floyd Miles</span>
                            <span>June 13th 2021</span>
                          </div>
                        </div>
                      </div>
                      <div className="blog-box ">
                        <img src={images.blog11} alt="" />
                        <div className="blog-gradient">.</div>
                        <div className="blog-text">
                          <a href="#" className="btn btn-primary blog-btn">
                            Category
                          </a>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          <div className="inline-section">
                            <span>by Floyd Miles</span>
                            <span>June 13th 2021</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                
              </div>
              </div>
          </div>
        </div>
        {/* <div className="container ">
          <div className="row recent-area-box">
            <div className="col-md-8">
              <div className="heading-blog">Recent Articale</div>
            </div>
            <div className="col-md-4 text-right mt-4">
              <a href="#" className="btn btn-primary blog-btn">
                View All
              </a>
            </div>
            <div className="col-md-4 order-md-1">
              <div className="blog-box mb-3">
                <img src={images.blog8} alt="" />
                <div className="blog-text">
                  <a href="#" className="btn btn-primary blog-btn">
                    Category
                  </a>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  <div className="inline-section">
                    <span>by Floyd Miles</span>
                    <span>June 13th 2021</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 order-md-2">
              <div className="blog-box width-full">
                <img src={images.blog9} alt="" />
                <div className="blog-text">
                  <a href="#" className="btn btn-primary blog-btn">
                    Category
                  </a>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  <div className="inline-section">
                    <span>by Floyd Miles</span>
                    <span>June 13th 2021</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 order-md-1">
              <div className="blog-box mb-3">
                <img src={images.blog10} alt="" />
                <div className="blog-text">
                  <a href="#" className="btn btn-primary blog-btn">
                    Category
                  </a>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  <div className="inline-section">
                    <span>by Floyd Miles</span>
                    <span>June 13th 2021</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-8">
              <div className="heading-blog">Recommended For You</div>
            </div>
            <div className="col-md-4 text-right mt-4">
              <a href="#" className="btn btn-primary blog-btn">
                View All
              </a>
            </div>
            <div className="col-md-8 order-md-2">
              <div className="blog-box width-full">
                <img src={images.blog3} alt="" />
                <div className="blog-text">
                  <a href="#" className="btn btn-primary blog-btn">
                    Category
                  </a>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  <div className="inline-section">
                    <span>by Floyd Miles</span>
                    <span>June 13th 2021</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 order-md-1">
              <div className="blog-box mb-3">
                <img src={images.blog1} alt="" />
                <div className="blog-text">
                  <a href="#" className="btn btn-primary blog-btn">
                    Category
                  </a>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  <div className="inline-section">
                    <span>by Floyd Miles</span>
                    <span>June 13th 2021</span>
                  </div>
                </div>
              </div>
              <div className="blog-box">
                <img src={images.blog2} alt="" />
                <div className="blog-text">
                  <a href="#" className="btn btn-primary blog-btn">
                    Category
                  </a>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  <div className="inline-section">
                    <span>by Floyd Miles</span>
                    <span>June 13th 2021</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container">{/* Claim div here */}</div>
      </div>
    </>
  );
};

export default Blog;
