import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../loader/Loader';
import Alert from './Alert';

const Provider = ({ children }) => {
  const alerts = useSelector((state) => state.alerts);
  const isLoading = useSelector((state) => state.app.isLoading);

  return (
    <>
      <div className="alert-area">

        {alerts.map((alert) => (
          <Alert key={alert.id} alertId={alert.id} type={alert.type} message={alert.message} />
        ))}
      </div>

      {children}

      {isLoading ? <Loader /> : null}
    </>
  );
};

export default Provider;
