import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import ToggleSwitch from '../../../components/ui/ToggleSwitch';
import { UPDATE_BRANDING_SETTINGS_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';

const BrandingSettings = ({ showUpgrade }) => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const currentPlan = useSelector((state) => state.auth.user.plan);
  const dispatch = useDispatch();
  const initialFormState = {
    removeDefaultBranding: currentBiolink.settings?.removeDefaultBranding,
    enableCustomBranding: currentBiolink.settings?.enableCustomBranding,
    customBrandingName: currentBiolink.settings?.customBrandingName,
    customBrandingUrl: currentBiolink.settings?.customBrandingUrl,
    customBrandingNameError: [],
    customBrandingUrlError: [],
  };

  const [form, setForm] = useState(initialFormState);

  const [updateBrandingSettings, { data }] = useNetwork(UPDATE_BRANDING_SETTINGS_MUTATION);

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    if (!currentPlan?.settings.removableBrandingEnabled) {
      showUpgrade();
      return;
    }

    if (!currentPlan?.settings.customFooterBrandingEnabled) {
      showUpgrade();
      return;
    }
    let isValidated = true;
    const customBrandingNameError = [];
    const customBrandingUrlError = [];

    // add validations here
    if (form.enableCustomBranding && !form.customBrandingName) {
      isValidated = false;
      customBrandingNameError.push('Custom Branding Name cannot be empty.');
    }
    if (form.enableCustomBranding && !form.customBrandingUrl) {
      isValidated = false;
      customBrandingUrlError.push('Custom Branding Url cannot be empty.');
    }

    setForm({
      ...form,
      customBrandingNameError,
      customBrandingUrlError,
    });
    if (!isValidated) return;

    // submit form
    if (form.enableCustomBranding) {
      updateBrandingSettings({
        variables: {
          removeDefaultBranding: form.removeDefaultBranding,
          enableCustomBranding: form.enableCustomBranding,
          customBrandingName: form.customBrandingName,
          customBrandingUrl: form.customBrandingUrl,
          id: currentBiolink.id,
        },
      });
    } else {
      updateBrandingSettings({
        variables: {
          removeDefaultBranding: form.removeDefaultBranding,
          enableCustomBranding: form.enableCustomBranding,
          customBrandingName: null,
          customBrandingUrl: null,
          id: currentBiolink.id,
        },
      });
    }
  };
  return (
    <div className="modal fade" id="modal-8">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Branding Settings</h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body branding-model">
            <div className="form-list">
              <div className="form-group form-floating-label clearfix">
                {/* <input
                  id="inputFloatingLabel22"
                  type="text"
                  className="form-control input-solid"
                  required
                /> */}
                <ToggleSwitch
                  id="inputFloatingLabel22"
                  currentState={form.removeDefaultBranding}
                  onToggle={() => {
                    if (!currentPlan?.settings.removableBrandingEnabled) {
                      showUpgrade();
                      return;
                    }
                    setForm({
                      ...form,
                      removeDefaultBranding: !form.removeDefaultBranding,
                    });
                  }}
                  isActive={currentPlan?.settings.removableBrandingEnabled}
                />
                <label htmlFor="inputFloatingLabel22" className="placeholder">
                  Remove Branding
                </label>
              </div>

              <div className="form-group form-floating-label clearfix">
                {/* <input
                  id="inputFloatingLabel23"
                  type="text"
                  className="form-control input-solid"
                  required
                /> */}
                <ToggleSwitch
                  currentState={form.enableCustomBranding}
                  onToggle={() => {
                    if (!currentPlan?.settings.customFooterBrandingEnabled) {
                      showUpgrade();
                      return;
                    }
                    setForm({
                      ...form,
                      enableCustomBranding: !form.enableCustomBranding,
                      customBrandingName: '',
                      customBrandingUrl: '',
                    });
                  }}
                  isActive={currentPlan?.settings.customFooterBrandingEnabled}
                />
                <label htmlFor="inputFloatingLabel23" className="placeholder">
                  Enable Custom Branding
                </label>
              </div>
              {form.enableCustomBranding && (
                <>
                  <div className="form-group form-floating-label">
                    {/* <input
                      id="inputFloatingLabel24"
                      type="text"
                      className="form-control input-solid"
                      required
                    /> */}
                    <input
                      type="text"
                      id="inputFloatingLabel24"
                      className="form-control input-solid"
                      name="customBrandingName"
                      placeholder="Open Graph Image Url"
                      value={form.customBrandingName || ''}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {/* <label htmlFor="inputFloatingLabel24" className="placeholder">
                      Open Graph Image Url
                    </label> */}
                    {form.customBrandingNameError.length
                      ? form.customBrandingNameError.map((err, i) => (
                          <ErrorSpan key={i} error={err} />
                        ))
                      : null}
                  </div>
                  <div className="form-group form-floating-label">
                    <input
                      type="text"
                      id="inputFloatingLabel224"
                      className="form-control input-solid"
                      name="customBrandingUrl"
                      placeholder="Custom Branding URL"
                      value={form.customBrandingUrl || ''}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {/* <label htmlFor="inputFloatingLabel224" className="placeholder">
                      Custom Branding URL
                    </label> */}
                    {form.customBrandingUrlError.length
                      ? form.customBrandingUrlError.map((err, i) => (
                          <ErrorSpan key={i} error={err} />
                        ))
                      : null}
                  </div>
                </>
              )}
              <button
                type="button"
                className="btn btn-primary"
                onClick={(evt) => onSubmitHandler(evt)}
              >
                Save changes
              </button>
            </div>
          </div>

          <div className="modal-footer">
            <a href="#modal-1" data-toggle="modal" data-dismiss="modal" className="btn btn-default">
              Previous
            </a>
            <button type="button" data-dismiss="modal" className="btn btn-danger">
              Close
            </button>
          </div>
        </div>
        {/* <!-- /.modal-content --> */}
      </div>
      {/* <!-- /.modal-dialog --> */}
    </div>
  );
};

export default BrandingSettings;
