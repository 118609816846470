import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import images from '../../assets/images';

const UpgradeModal = ({ onUpgrade, currentState, onClose }) => {
  const [showUpgrade, setShowUpgrade] = useState(false);

  return (
    <Modal show={currentState} onHide={onClose}>
      <Modal.Header className="upgrade-modal-header" closeButton>
        <Modal.Title className="upgrade-modal-title">
          <div className="upgrade-title">
            <img src={images.stasheeLogo} height="40px" alt="" />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="person-plan-box">
          <h4>To use this feature and more, upgrade to the Personal Plan</h4>
          <div className="upgrade-modal-body-content">
            <ul>
              <li>
                <i className="fa fa-check" aria-hidden="true" />
                Social buttons
              </li>
              <li>
                <i className="fa fa-check" aria-hidden="true" />
                Section titles
              </li>
              <li>
                <i className="fa fa-check" aria-hidden="true" />
                Highlighted links
              </li>
              <li>
                <i className="fa fa-check" aria-hidden="true" />
                Dark mode theme
              </li>
              <li>
                <i className="fa fa-check" aria-hidden="true" />
                Customize link subtitles
              </li>
              <li>
                <i className="fa fa-check" aria-hidden="true" />
                Customize background image
              </li>
            </ul>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="upgrade-modal-footer">
        <Button className="upgrade-btn-cls" variant="primary" onClick={onUpgrade}>
          Upgrade
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpgradeModal;
