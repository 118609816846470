import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';

import { DELETE_USER_ACCOUNT, LOGOUT_MUTATION } from '../../../graphql/Mutations';
import validate from '../../../utils/validations';
import * as authActions from '../../../store/actions/auth';
import * as alertActions from '../../../store/actions/alert';
import * as appActions from '../../../store/actions/app';
import generateRandomId from '../../../utils/genrateRandomId';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';

const DeleteAccount = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const dispatch = useDispatch();

  const [form, setForm] = useState({
    password: '',
    passwordError: [],
  });

  const [deleteUserAccount, { error, loading, data }] = useMutation(DELETE_USER_ACCOUNT);
  const [response, setResponse] = useState(null);
  const [logout] = useMutation(LOGOUT_MUTATION);

  useEffect(() => {
    dispatch(appActions.isLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (data?.deleteUserAccount) {
      if (!data?.deleteUserAccount.errors) {
        setResponse({
          error,
          data,
        });

        logout();
        dispatch(authActions.authenticate({}, false));
      }
      // if (data?.deleteUserAccount.errors) {
      //   dispatch(
      //     alertActions.addAlert({
      //       id: generateRandomId(),
      //       message: 'Error occured.',
      //       type: 'error',
      //     }),
      //   );
      // }
    }
  }, [data]);

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleDelete = (evt) => {
    evt.preventDefault();
    const passwordError = [];

    // add validations here
    if (validate.minLength(form.password, 8))
      passwordError.push('Password must be atleast 8 characters long');

    setForm({
      ...form,
      passwordError,
    });

    if (passwordError.length) return;
    // submit form
    deleteUserAccount({
      variables: {
        password: form.password,
      },
    });
  };

  return (
    <>
      <div className="row mt--2 form-w">
        <div className="col-md-12">
          <div className="delete-box">
            <a
              className="btn btn-danger btn-round btn-lg mb-5"
              onClick={setModalIsOpenToTrue}
              style={{ cursor: 'pointer' }}
            >
              DELETE ACCOUNT
            </a>
          </div>
        </div>
        <Modal isOpen={modalIsOpen}>
          <div className="model3 account-model">
            <div className="modal-heading-wrapper text-center">
              <div className="text-block-7">Delete Account (Forever)</div>
            </div>
            {/* <ModelNew onCloseModal={setModalIsOpenToFalse} /> */}
            <div className="form-group inner-popup inpt-rel">
              <label>Enter Password:</label>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                className="form-control input-design"
                value={form.password}
                onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
              />
              <span
                className="input-icon sizespace"
                role="button"
                onClick={() => setShowPassword(!showPassword)}
                onKeyDown={() => setShowPassword(!showPassword)}
                tabIndex={-8}
              >
                <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true" />
              </span>
              {form.passwordError?.length > 0
                ? form.passwordError?.map((err, i) => <ErrorSpan key={i} error={err} />)
                : null}
              {data?.deleteUserAccount?.errors
                ? data.deleteUserAccount.errors.map((err, i) => (
                    <ErrorSpan key={i} error={err.message} />
                  ))
                : null}
            </div>
            <div className="form-group inner-popup1 text-center">
              <button
                type="button"
                className="btn-admin icon-design-admin inner-popup-delete"
                // onClick={(evt) => {
                //   const confirmBox = window.confirm('Do you really want to delete this account');
                //   if (confirmBox === true) {
                //     handleDelete(evt);
                //   }
                // }}
                onClick={(evt) => handleDelete(evt)}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn-admin icon-design-admin inner-popup-cancel"
                onClick={setModalIsOpenToFalse}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default DeleteAccount;
