import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import { GET_LINK_BY_SHORTENED_URL } from '../../../graphql/Queries';
import validate from '../../../utils/validations';
import useNetwork from '../../../hooks/useNetwork';

const Links = (props) => {
  const [showModel, setShowModel] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showMessage, setShowMessage] = useState('');
  const [form, setForm] = useState({
    password: '',
    passwordError: [],
  });
  const url = window.location.href;
  // console.log('thissssssssss========>', url);
  const lastSegment = url.substring(url.lastIndexOf('/') + 1);

  // console.log('lastSegment========>', lastSegment);

  const { refetch: directoryRefetch, data } = useQuery(GET_LINK_BY_SHORTENED_URL, {
    variables: {
      password: form.password,
      shortenedUrl: lastSegment,
    },
  });

  // const { data, refetch: directoryRefetch } = useQuery(GET_LINK_BY_SHORTENED_URL, {
  //   variables: {
  //     password: form.password,
  //     shortenedUrl: lastSegment,
  //   },
  // });

  useEffect(() => {
    // const idddd = useParams();

    if (data) {
      if (data?.getLinkByShortenedUrl?.errors === null) {
        // console.log('data 5===>', data?.getLinkByShortenedUrl);
        const urll = data?.getLinkByShortenedUrl?.link?.url;
        window.location.href = urll; // working code
      } else if (data?.getLinkByShortenedUrl?.errors[0].errorCode === 12) {
        // Need to show password
        // console.log('Need to show password model');
        if (
          data?.getLinkByShortenedUrl?.errors[0].message === 'Password did not match' &&
          form.password != ''
        ) {
          setShowMessage(data?.getLinkByShortenedUrl?.errors[0].message);
        } else {
          setShowMessage('');
        }
        setShowModel(true);
      } else if (data?.getLinkByShortenedUrl?.errors[0].errorCode === 4) {
        // No link found
        setShowMessage(data?.getLinkByShortenedUrl?.errors[0].message);
      } else {
        // Password didn't Match
        console.log('else===========>');
      }
    }
  }, [data]);

  useEffect(() => {
    console.log('showModel--', showModel);
  }, [showModel]);
  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  const handleModalClose = (isUpdated) => {
    setShowModel(false);
    if (isUpdated) {
      setForm({
        ...form,
      });
    }
  };
  const onTogglePasswordProtection = () => {
    const passwordError = [];

    if (validate.minLength(form.password, 8)) {
      passwordError.push('Password must be atleaset 8 characters long.');
    }

    setForm({
      ...form,
      passwordError,
    });

    if (passwordError.length === 0) {
      // directoryRefetch();

      directoryRefetch({
        variables: {
          password: form.password,
          shortenedUrl: lastSegment,
        },
      });
    }

    // directoryRefetch({
    //   variables: {
    //     password: form.password,
    //     shortenedUrl: '04VGRQjP5',
    //   },
    // });
  };
  useEffect(() => {
    console.log('showMessage-------->', showMessage);
  }, [showMessage]);
  return (
    <>
      <div>
        {showModel === true ? (
          <Modal show={showModel}>
            {/* <Modal.Header closeButton> */}
            <Modal.Header>
              <Modal.Title>Enter password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                className="form-control"
                value={form.password}
                onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
              />
              <span
                className="input-icon sizespace"
                role="button"
                onClick={() => setShowPassword(!showPassword)}
                onKeyDown={() => setShowPassword(!showPassword)}
                tabIndex={-8}
              >
                <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true" />
              </span>
              {form.passwordError.length
                ? form.passwordError.map((err, i) => <ErrorSpan key={i} error={err} />)
                : null}
              {showMessage != '' ? <ErrorSpan error={showMessage} /> : null}
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="secondary" onClick={() => handleModalClose(false)}>
                Cancel
              </Button> */}
              {/* <Button variant="primary" onClick={() => onTogglePasswordProtection()}>
                Done
              </Button> */}
            </Modal.Footer>
          </Modal>
        ) : null}
      </div>
    </>
  );
};

export default Links;
