import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row footer-sec">
            <div className="col-md-4">
              <div className="footer-logo">
                <Link to="/">
                  <img src="/static/media/footer_logo.127e9e1b.svg" alt="" />
                </Link>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et
                dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                lacus vel facilisis.
              </p>
            </div>
            <div className="col-md-8 right-margn">
              <ul className="inline-sec">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about_us">About Us</Link>
                </li>
                <li>
                  <Link to="/how_it_works">How It Works</Link>
                </li>
                <li>
                  <Link to="/team">Our Team</Link>
                </li>
                <li>
                  <Link to="/app/referrals">Referrals</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
              </ul>
              <ul className="inline-sec">
                <li>
                  <Link to="/terms_condition">Terms &amp; Conditions</Link>
                </li>
                <li>
                  <Link to="/auth/register">Sign Up</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/pricing">Our pricing</Link>
                </li>
                <li>
                  <Link to="/privacy_policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/report">Report</Link>
                </li>
              </ul>
              <ul className="inline-sec social">
                <li>
                  <h6>Get Connected</h6>
                </li>
                <li>
                  <a href="#asdf" target="_blank">
                    <i className="fa fa-facebook" aria-hidden="true" />
                  </a>
                  <a href="#asdf" target="_blank">
                    <i className="fa fa-twitter" aria-hidden="true" />
                  </a>
                  <a href="#asdf" target="_blank">
                    <i className="fa fa-pinterest-p" aria-hidden="true" />
                  </a>
                  <a href="#asdf" target="_blank">
                    <i className="fa fa-google-plus" aria-hidden="true" />
                  </a>
                  <a href="#asdf" target="_blank">
                    <i className="fa fa-linkedin" aria-hidden="true" />
                  </a>
                  <a href="#asdf" target="_blank">
                    <i className="fa fa-instagram" aria-hidden="true" />
                  </a>
                  <a href="#asdf" target="_blank">
                    <i className="fa fa-youtube-play" aria-hidden="true" />
                  </a>
                  <a href="#asdf" target="_blank">
                    <i className="fa fa-tumblr" aria-hidden="true" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
