import React from 'react';
import PricingBody from './PricingBody';

const Pricing = () => {
  return (
    <>
      <div className="center-heading black-text ">
        <h1>
          Choose Your <span className="blue-text">Flexible Plan</span>
        </h1>
        <h4>No contracts, no surprise fees.</h4>
      </div>
      <PricingBody />
    </>
  );
};

export default Pricing;
