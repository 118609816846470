import React from 'react';

const Team = () => {
  return (
    <>
      <div className="  fron-end-wrapper ">
        <div className="container-fluid pt-5 team-us-section">
          <div className="row mt--2">
            <div className="col-md-12">
              <div className="card full-height">
                <div className="card-body">
                  <section className="section-space">
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          id="custCarousel"
                          className="carousel slide"
                          data-ride="carousel"
                          align="center"
                        >
                          {/* slides */}
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                src="https://avyatated.com/static/media/Group%20220svg.f6154f0b.svg"
                                alt=""
                              />
                              <div className="team-detail">
                                <div className=" light-bg about-bottom">
                                <div className="about-body">
                                  <div className="shorter-profile">
                                    <img
                                      src="https://avyatated.com/static/media/profilepic.ad5fefb7.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="about-detail">
                                    <h3>AMIR ALKABIR</h3>
                                    <h5>VICE PRESIDENT AT NRUS CO</h5>
                                    <ul className="contact-list">
                                      <li>
                                        {' '}
                                        <i className="fa fa-map-marker inline-icon" />
                                        <p className="inline-detail">United States</p>
                                      </li>
                                      <li>
                                        {' '}
                                        <i className="fa fa-envelope-o inline-icon" />
                                        <p className="inline-detail">Email </p>
                                      </li>
                                      <li>
                                        {' '}
                                        <i className="fa fa-phone inline-icon" />
                                        <p className="inline-detail">Call </p>
                                      </li>
                                      
                                    </ul>
                                  </div>
                                  </div>
                                  <div className="inline-sec social">
                                        <a href="#asdf">
                                          <i className="fa fa-facebook" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-twitter" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-pinterest-p" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-google-plus" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-linkedin" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-instagram" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-youtube-play" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-tumblr" aria-hidden="true" />
                                        </a>
                                      </div>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                                    posuere morbi morbi at donec pretium, ligula lectus viverra.
                                    Tristique enim velit vitae aliquam, etiam pharetra. Sit quisque
                                    tristique eget tincidunt morbi vitae lacus. Accumsan risus ipsum
                                    id in nunc. Augue elit, eget ullamcorper enim eget. Lectus
                                    blandit etiam maecenas dolor tristique magna.Lorem ipsum dolor
                                    sit amet, consectetur adipiscing elit.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="https://avyatated.com/static/media/Group%20223.38108e70.svg"
                                alt=""
                              />
                              <div className="team-detail">
                                <div className=" light-bg about-bottom">
                                <div className="about-body">
                                  <div className="shorter-profile">
                                    <img
                                      src="https://avyatated.com/static/media/Mask%20Group.392f1b72.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="about-detail">
                                    <h3>AMIR ALKABIR</h3>
                                    <h5>VICE PRESIDENT AT NRUS CO</h5>
                                    <ul className="contact-list">
                                      <li>
                                        {' '}
                                        <i className="fa fa-map-marker inline-icon" />
                                        <p className="inline-detail">United States</p>
                                      </li>
                                      <li>
                                        {' '}
                                        <i className="fa fa-envelope-o inline-icon" />
                                        <p className="inline-detail">Email </p>
                                      </li>
                                      <li>
                                        {' '}
                                        <i className="fa fa-phone inline-icon" />
                                        <p className="inline-detail">Call </p>
                                      </li>
                                      
                                    </ul>
                                  </div>
                                  </div>
                                  <div className="inline-sec social">
                                        <a href="#asdf">
                                          <i className="fa fa-facebook" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-twitter" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-pinterest-p" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-google-plus" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-linkedin" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-instagram" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-youtube-play" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-tumblr" aria-hidden="true" />
                                        </a>
                                      </div>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                                    posuere morbi morbi at donec pretium, ligula lectus viverra.
                                    Tristique enim velit vitae aliquam, etiam pharetra. Sit quisque
                                    tristique eget tincidunt morbi vitae lacus. Accumsan risus ipsum
                                    id in nunc. Augue elit, eget ullamcorper enim eget. Lectus
                                    blandit etiam maecenas dolor tristique magna.Lorem ipsum dolor
                                    sit amet, consectetur adipiscing elit.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="https://avyatated.com/static/media/Group%20226.0088af23.svg"
                                alt=""
                              />
                              <div className="team-detail">
                                <div className=" light-bg about-bottom">
                                <div className="about-body">
                                  <div className="shorter-profile">
                                    <img
                                      src="https://avyatated.com/static/media/n.e2472147.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="about-detail">
                                    <h3>AMIR ALKABIR</h3>
                                    <h5>VICE PRESIDENT AT NRUS CO</h5>
                                    <ul className="contact-list">
                                      <li>
                                        {' '}
                                        <i className="fa fa-map-marker inline-icon" />
                                        <p className="inline-detail">United States</p>
                                      </li>
                                      <li>
                                        {' '}
                                        <i className="fa fa-envelope-o inline-icon" />
                                        <p className="inline-detail">Email </p>
                                      </li>
                                      <li>
                                        {' '}
                                        <i className="fa fa-phone inline-icon" />
                                        <p className="inline-detail">Call </p>
                                      </li>
                                      
                                    </ul>
                                  </div>
                                  </div>
                                  <div className="inline-sec social">
                                        <a href="#asdf">
                                          <i className="fa fa-facebook" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-twitter" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-pinterest-p" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-google-plus" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-linkedin" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-instagram" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-youtube-play" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-tumblr" aria-hidden="true" />
                                        </a>
                                      </div>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                                    posuere morbi morbi at donec pretium, ligula lectus viverra.
                                    Tristique enim velit vitae aliquam, etiam pharetra. Sit quisque
                                    tristique eget tincidunt morbi vitae lacus. Accumsan risus ipsum
                                    id in nunc. Augue elit, eget ullamcorper enim eget. Lectus
                                    blandit etiam maecenas dolor tristique magna.Lorem ipsum dolor
                                    sit amet, consectetur adipiscing elit.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="https://avyatated.com/static/media/Group%20228.7f87ab9c.svg"
                                alt=""
                              />
                              <div className="team-detail">
                                <div className=" light-bg about-bottom">
                                <div className="about-body">
                                  <div className="shorter-profile">
                                    <img
                                      src="https://avyatated.com/static/media/Group%20227.59a5f868.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="about-detail">
                                    <h3>AMIR ALKABIR</h3>
                                    <h5>VICE PRESIDENT AT NRUS CO</h5>
                                    <ul className="contact-list">
                                      <li>
                                        {' '}
                                        <i className="fa fa-map-marker inline-icon" />
                                        <p className="inline-detail">United States</p>
                                      </li>
                                      <li>
                                        {' '}
                                        <i className="fa fa-envelope-o inline-icon" />
                                        <p className="inline-detail">Email </p>
                                      </li>
                                      <li>
                                        {' '}
                                        <i className="fa fa-phone inline-icon" />
                                        <p className="inline-detail">Call </p>
                                      </li>
                                    </ul>
                                  </div>
                                  </div>
                                  <div className="inline-sec social">
                                        <a href="#asdf">
                                          <i className="fa fa-facebook" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-twitter" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-pinterest-p" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-google-plus" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-linkedin" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-instagram" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-youtube-play" aria-hidden="true" />
                                        </a>
                                        <a href="#asdf">
                                          <i className="fa fa-tumblr" aria-hidden="true" />
                                        </a>
                                      </div>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                                    posuere morbi morbi at donec pretium, ligula lectus viverra.
                                    Tristique enim velit vitae aliquam, etiam pharetra. Sit quisque
                                    tristique eget tincidunt morbi vitae lacus. Accumsan risus ipsum
                                    id in nunc. Augue elit, eget ullamcorper enim eget. Lectus
                                    blandit etiam maecenas dolor tristique magna.Lorem ipsum dolor
                                    sit amet, consectetur adipiscing elit.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Left right */}
                          <div className="carosal-nav">
                            <a
                              className="carousel-control-prev"
                              href="#custCarousel"
                              data-slide="prev"
                            >
                              {' '}
                              <span className="carousel-control-prev-icon" />{' '}
                            </a>{' '}
                            <a
                              className="carousel-control-next"
                              href="#custCarousel"
                              data-slide="next"
                            >
                              {' '}
                              <span className="carousel-control-next-icon" />{' '}
                            </a>
                          </div>
                          {/* Thumbnails */}
                          <ol className="carousel-indicators list-inline">
                            <li className="list-inline-item active thumb-one">
                              {' '}
                              <a
                                id="carousel-selector-0"
                                className="selected"
                                data-slide-to={0}
                                data-target="#custCarousel"
                              >
                                {' '}
                                <img
                                  src="https://avyatated.com/static/media/Mask%20Groupsvg%20(1).a49d850a.svg"
                                  alt=""
                                  className="img-fluid"
                                />{' '}
                              </a>{' '}
                            </li>
                            <li className="list-inline-item thumb-two">
                              {' '}
                              <a
                                id="carousel-selector-1"
                                data-slide-to={1}
                                data-target="#custCarousel"
                              >
                                {' '}
                                <img
                                  src="https://avyatated.com/static/media/Mask%20Groupsvg-2.b1dc683d.svg"
                                  alt=""
                                  className="img-fluid"
                                />{' '}
                              </a>{' '}
                            </li>
                            <li className="list-inline-item thumb-three">
                              {' '}
                              <a
                                id="carousel-selector-2"
                                data-slide-to={2}
                                data-target="#custCarousel"
                              >
                                {' '}
                                <img
                                  src="https://avyatated.com/static/media/Mask%20Groupsvg-3.82c4424b.svg"
                                  alt=""
                                  className="img-fluid"
                                />{' '}
                              </a>{' '}
                            </li>
                            <li className="list-inline-item thumb-four">
                              {' '}
                              <a
                                id="carousel-selector-2"
                                data-slide-to={3}
                                data-target="#custCarousel"
                              >
                                {' '}
                                <img
                                  src="https://avyatated.com/static/media/Mask%20Groupsvg.813503ea.svg"
                                  alt=""
                                  className="img-fluid"
                                />{' '}
                              </a>{' '}
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
