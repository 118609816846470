import socialIcons from '../assets/socialIcons';
// import social_Icons from '../assets/socialIcons';

const socialIconList = {
  facebook: {
    title: 'Facebook',
    link: '',
    status: '',
    icon: socialIcons.facebook,
  },
  youtube: {
    title: 'Youtube',
    link: '',
    status: '',
    icon: socialIcons.youtube,
  },
  twitter: {
    title: 'Twitter',
    link: '',
    status: '',
    icon: socialIcons.twitter,
  },
  instagram: {
    title: 'Instagram',
    link: '',
    status: '',
    icon: socialIcons.instagram,
  },
  linkedin: {
    title: 'LinkedIn',
    link: '',
    status: '',
    icon: socialIcons.linkedin,
  },
  wechat: {
    title: 'We Chat',
    link: '',
    status: '',
    icon: socialIcons.wechat,
  },
  tumblr: {
    title: 'Tumblr',
    link: '',
    status: '',
    icon: socialIcons.tumblr,
  },
  gmail: {
    title: 'Gmail',
    link: '',
    status: '',
    icon: socialIcons.gmail,
  },
  skype: {
    title: 'Skype',
    link: '',
    status: '',
    icon: socialIcons.skype,
  },
  tiktok: {
    title: 'Tiktok',
    link: '',
    status: '',
    icon: socialIcons.tiktok,
  },
  zoom: {
    title: 'Zoom',
    link: '',
    status: '',
    icon: socialIcons.zoom,
  },
  reddit: {
    title: 'Reddit',
    link: '',
    status: '',
    icon: socialIcons.reddit,
  },
  amazon: {
    title: 'Amazon',
    link: '',
    status: '',
    icon: socialIcons.amazon,
  },
  amazonPrimeVideo: {
    title: 'Amazon Video',
    link: '',
    status: '',
    icon: socialIcons.amazonPrimeVideo,
  },
  apple_music: {
    title: 'Apple Music',
    link: '',
    status: '',
    icon: socialIcons.apple_music,
  },
  pinterest: {
    title: 'Pinterest',
    link: '',
    status: '',
    icon: socialIcons.pinterest,
  },
  blogger: {
    title: 'Blogger',
    link: '',
    status: '',
    icon: socialIcons.blogger,
  },
  delicious: {
    title: 'Delicious',
    link: '',
    status: '',
    icon: socialIcons.delicious,
  },
  deviantart: {
    title: 'Deviant Art',
    link: '',
    status: '',
    icon: socialIcons.deviantart,
  },
  emailOpen: {
    title: 'Email',
    link: '',
    status: '',
    icon: socialIcons.emailOpen,
  },
  github: {
    title: 'Github',
    link: '',
    status: '',
    icon: socialIcons.github,
  },
  googleMeet: {
    title: 'Google Meet',
    link: '',
    status: '',
    icon: socialIcons.googleMeet,
  },
  googlePlus: {
    title: 'Google Plus',
    link: '',
    status: '',
    icon: socialIcons.googlePlus,
  },
  microsoftTeams: {
    title: 'Microsoft Teams',
    link: '',
    status: '',
    icon: socialIcons.microsoftTeams,
  },
  myspace: {
    title: 'My Space',
    link: '',
    status: '',
    icon: socialIcons.myspace,
  },
  netflix: {
    title: 'Netflix',
    link: '',
    status: '',
    icon: socialIcons.netflix,
  },
  outlook: {
    title: 'Outlook',
    link: '',
    status: '',
    icon: socialIcons.outlook,
  },
  paypal: {
    title: 'Paypal',
    link: '',
    status: '',
    icon: socialIcons.paypal,
  },
  telegram: {
    title: 'Telegram',
    link: '',
    status: '',
    icon: socialIcons.telegram,
  },
  imqq: {
    title: 'IMQQ',
    link: '',
    status: '',
    icon: socialIcons.imqq,
  },
  quora: {
    title: 'Quora',
    link: '',
    status: '',
    icon: socialIcons.quora,
  },
  sharechat: {
    title: 'Share Chat',
    link: '',
    status: '',
    icon: socialIcons.sharechat,
  },
  slack: {
    title: 'Slack',
    link: '',
    status: '',
    icon: socialIcons.slack,
  },
  soundcloud: {
    title: 'Sound Cloud',
    link: '',
    status: '',
    icon: socialIcons.soundcloud,
  },
  spotify: {
    title: 'Spotify',
    link: '',
    status: '',
    icon: socialIcons.spotify,
  },
  tinder: {
    title: 'Tinder',
    link: '',
    status: '',
    icon: socialIcons.tinder,
  },
  twitch: {
    title: 'Twitch',
    link: '',
    status: '',
    icon: socialIcons.twitch,
  },
  venmo: {
    title: 'Venmo',
    link: '',
    status: '',
    icon: socialIcons.venmo,
  },
  vine: {
    title: 'Vine',
    link: '',
    status: '',
    icon: socialIcons.vine,
  },
  vk: {
    title: 'vk.com',
    link: '',
    status: '',
    icon: socialIcons.vk,
  },
  weibo: {
    title: 'Weibo',
    link: '',
    status: '',
    icon: socialIcons.weibo,
  },
  wikipedia: {
    title: 'Wikipedia',
    link: '',
    status: '',
    icon: socialIcons.wikipedia,
  },
  xing: {
    title: 'Xing',
    link: '',
    status: '',
    icon: socialIcons.xing,
  },
  yelp: {
    title: 'Yelp',
    link: '',
    status: '',
    icon: socialIcons.yelp,
  },
  pyoneer: {
    title: 'Pyoneer',
    link: '',
    status: '',
    icon: socialIcons.pyoneer,
  },
};

export default socialIconList;
