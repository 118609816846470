import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import copy from 'clipboard-copy';
import EmbedoItem from '../../../components/ui/embedo/EmbedoItem';
import { GET_BIOLINK_FROM_USERNAME } from '../../../graphql/Queries';
import * as alertActions from '../../../store/actions/alert';
import generateRandomId from '../../../utils/genrateRandomId';
import Loader from '../../../components/ui/loader/Loader';
import socialMediaIcons from '../../../assets/socialMediaIcons';
import socialMediaList from '../../../constants/socialMediaList';

const Preview = (props) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [checkUsername, setCheckUsername] = useState(false);
  const [currentBiolink, setCurrentBiolink] = useState(
    isAuthenticated ? useSelector((state) => state.auth.currentBiolink) : {},
  );

  const [
    getBiolinkFromUsername,
    { data: biolinkData, loading: biolinkLoading, error: biolinkError, refetch: biolinkRefetch },
  ] = useLazyQuery(GET_BIOLINK_FROM_USERNAME);

  useEffect(() => {
    if (biolinkData && biolinkData.getBiolinkFromUsername?.errors === null) {
      setCurrentBiolink(biolinkData.getBiolinkFromUsername?.biolink);
      setCheckUsername(true);
    }
  }, [biolinkData]);

  const [form, setForm] = useState({
    email: '',
  });
  
  const [enableColoredSocialMediaIcons,setEnableColoredSocialMediaIcons] = useState('');
  const [socialAccountStyleType,setSocialAccountStyleType] = useState('');

  useEffect(()=>{
    // console.log('icons====>',currentBiolink?.settings?.enableColoredSocialMediaIcons);
    // console.log('enableColoredSocialMediaIcons====>', currentBiolink?.settings?.enableColoredSocialMediaIcons);
    setEnableColoredSocialMediaIcons(currentBiolink?.settings?.enableColoredSocialMediaIcons)  ;
    setSocialAccountStyleType(currentBiolink?.settings?.socialAccountStyleType);

  },[currentBiolink]
     
  );
  

  useEffect(() => {
    if (
      props.location?.pathname === '/preview' ||
      props.userName === currentBiolink?.username?.username
    ) {
      setCheckUsername(true);
    } else {
      getBiolinkFromUsername({ variables: { username: props.userName } });
    }

    const link = document.createElement('link');
    link.href = '//cdn-images.mailchimp.com/embedcode/classic-10_7.css';
    link.rel = 'stylesheet';
    link.type = 'text/css';

    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      #mc_embed_signup {
        background: #fff;
        clear: left;
        font: 14px Helvetica, Arial, sans-serif;
      }
    `;

    const scriptLink = document.createElement('script');
    scriptLink.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
    scriptLink.type = 'text/javascript';

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function ($) {
        window.fnames = new Array();
        window.ftypes = new Array();
        fnames[0] = "EMAIL";
        ftypes[0] = "email";
        fnames[1] = "FNAME";
        ftypes[1] = "text";
        fnames[2] = "LNAME";
        ftypes[2] = "text";
        fnames[3] = "ADDRESS";
        ftypes[3] = "address";
        fnames[4] = "PHONE";
        ftypes[4] = "phone";
        fnames[5] = "BIRTHDAY";
        ftypes[5] = "birthday";
      })(jQuery);
      var $mcj = jQuery.noConflict(true);
    `;

    const facebookScriptTag = document.createElement('script');

    // scriptTag.src = "https://use.typekit.net/foobar.js";
    facebookScriptTag.innerHTML = `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '{your-pixel-id-goes-here}');
    fbq('track', 'PageView');`;
    facebookScriptTag.async = true;

    const iframelyScriptTag = document.createElement('script');
    iframelyScriptTag.async = true;
    iframelyScriptTag.charSet = 'utf-8';
    iframelyScriptTag.src = '//cdn.iframe.ly/embed.js?api_key=9cc616b877b40e12c514a2';

    document.head.appendChild(link);
    document.head.appendChild(style);
    document.body.appendChild(scriptLink);
    // document.body.appendChild(script);
    document.body.appendChild(facebookScriptTag);
    document.body.appendChild(iframelyScriptTag);
    if (window.iframely) iframely.load();

    return () => {
      document.head.removeChild(link);
      document.head.removeChild(style);
      document.body.removeChild(scriptLink);
      // document.body.removeChild(script);
      document.body.removeChild(facebookScriptTag);
      document.body.removeChild(iframelyScriptTag);
    };
  }, []);

  if (!checkUsername) {
    return <Loader />;
  }

  const renderLinks = () => {
    return currentBiolink?.links?.map((link, index) => {
      if (link.linkType === 'Line') {
        return (
          <li key={index}>
            <hr
              style={{
                height: '10px',
                borderRadius: '5px',
                background: link.linkColor,
              }}
            />
          </li>
        );
      }
      if (link.linkType === 'Embed') {
        return (
          //
          // <div className="links-and-video-tab">
          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            key={index}
          >
            <div className="preview-video-section">
              <div className="preview-videos-list">
                {/* <li key={index}> */}
                <EmbedoItem
                  key={index}
                  id={link.id}
                  href={link.url || ''}
                  // href="https://www.npmjs.com/package/embedo"
                  // name={link.linkTitle || ''}
                  data-width="100%"
                  data-height="30vw"
                  // data-options={}
                  className="iframe-video"
                />
              </div>
            </div>
            {/* </div> */}
            {/* </li> */}
          </div>
          // {/* </li> */}
        );
      }

      return (
        // <li key={index}>
        //   <a href={link.url} className="biolink-links" target="_blank">
        //     <span className="bio-icon">
        //       <img src="img/you-tube.png" alt="" />
        //     </span>
        //     <span className="bio-text">{link.linkTitle}</span>
        //   </a>
        // </li>
        // <div className="links-and-video-tab">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="preview-links-section">
              <div className="preview-links-list">
                <a href={link.url} target="_blank">
                  <img src="" alt="" />
                  <li className="links-list">{link.linkTitle}</li>
                </a>
              </div>
            </div>
          </div>
        </div>
        // </div>
        // <div
        //   className="tab-pane fade show active"
        //   id="pills-home"
        //   role="tabpanel"
        //   aria-labelledby="pills-home-tab"
        // >
        //   <div className="preview-links-section">
        //     <div className="preview-links-list">
        //       <a href={link.url} target="_blank">
        //         <img src="" alt="" />
        //         <li className="links-list">{link.linkTitle}</li>
        //       </a>
        //     </div>
        //   </div>
        // </div>
        // <div
        //   className="tab-pane fade show active"
        //   id="pills-home"
        //   role="tabpanel"
        //   aria-labelledby="pills-home-tab"
        // >
        //   <div className="preview-links-section">
        //     <div className="preview-links-list">
        //       <a href="#">
        //         <img src="" alt="" />
        //         <li className="links-list">link</li>
        //       </a>
        //     </div>
        //   </div>
        // </div>
      );
    });
  };

  const onCopyHandler = (url) => {
    copy(url);

    dispatch(
      alertActions.addAlert({
        id: generateRandomId(),
        message: 'Link copied.',
        type: 'success',
      }),
    );
  };

  return (
    <div className="content bg-img-section preview-page">
      <div className="page-inner">
        <div className="row mt--2">
          <div className="col-md-10 offset-md-1 text-center">
            <div className="card full-height">
              <div className="card-body">
                <div className="panel-header profile-header pd1-080">
                  <div className="page-inner py-5">
                    <div
                      className="
                  d-flex
                  align-items-left align-items-md-center
                  flex-column flex-md-row
                "
                    >
                      {/* <div className="profile-edit-wrapper">
                        <BannerImage />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="swap-btn-bar-admin rightdv topmargin">
                  <Link to="/app" className={location.pathname !== '/preview' ? 'active' : ''}>
                    Editor
                  </Link>
                  <Link to="/preview" className={location.pathname === '/preview' ? 'active' : ''}>
                    Preview
                  </Link>
                </div>
                <div className="white-box mb-5">
                  <div className="row mt--2">
                    <div className="col-md-12">
                      <div className=" userProfile">
                        <div className="profile-left">
                          <div className="profile-box">
                            <div className="user-profile">
                              <img src="../assets/img/user.jpg" alt="" />
                              {/* <ProfileImage /> */}
                            </div>
                          </div>
                        </div>

                        {/* ******** Calling the new Previw page********     */}
                        <div className="text-left text-heading">
                          <h3 className="mb-1">{currentBiolink?.displayName}</h3>
                          <p className="mb-1 hed-title">
                            <span className="label-box">
                              <i className="fa fa-link" />
                            </span>
                            <span className="label-detail ">
                              stash.ee/{currentBiolink?.username.username}
                              <span
                                className="copy-button "
                                onClick={() =>
                                  onCopyHandler(`stash.ee/${currentBiolink?.username?.username}`)
                                }
                              >
                                <i className="fa fa-clone" aria-hidden="true" />
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>

                      <div
                        className="user-detail row user-dt-head
 align-items-center"
                      >
                        <ul className="preview-list mt-2 mb-2 col-lg-7">
                          <li>
                            <span className="label-detail">
                              <h4>{currentBiolink?.bio}</h4>
                            </span>
                          </li>
                          <li>
                            <span className="label-box">
                              <i className="fa fa-map-marker" />
                              Address:
                            </span>
                            <span className="label-detail">
                              {currentBiolink?.city}
                              {currentBiolink?.city && currentBiolink?.state && <span>, </span>}
                              {currentBiolink?.state}
                              {currentBiolink?.state && currentBiolink?.country && <span>, </span>}
                              {currentBiolink?.country}
                            </span>
                          </li>
                          <li>
                            <span className="label-box">
                              <i className="fa fa-envelope" />
                              Email:
                            </span>
                            <span className="label-detail">
                              {currentBiolink?.settings?.email || ''}
                            </span>
                          </li>

                          <li>
                            <span className="label-box">
                              <i className="fa fa-phone" />
                              Call:
                            </span>
                            <span className="label-detail">{currentBiolink?.settings?.phone}</span>
                          </li>
                        </ul>

                        {/* ******** Verfication Status*****************  */}
                        <div className="author-footer">
                          {currentBiolink.settings?.verificationStatus == 'Verified' ? (
                            <i className="fa fa-check-circle" aria-hidden="true">
                              Verified Author
                            </i>
                          ) : null}
                          {/* {currentBiolink.settings?.verifiedGovernmentId === true ? (
                            <a className="verified">
                              Government ID <i className="fa fa-check-circle" aria-hidden="true" />
                            </a>
                          ) : null}

                          {currentBiolink.settings?.verifiedEmail === true ? (
                            <a className="verified">
                              Email <i className="fa fa-check-circle" aria-hidden="true" />
                            </a>
                          ) : null}

                          {currentBiolink.settings?.verifiedPhoneNumber === true ? (
                            <a className="verified">
                              Phone Number
                              <i className="fa fa-check-circle" aria-hidden="true" />
                            </a>
                          ) : null}
                          {currentBiolink.settings?.verifiedWorkEmail === true ? (
                            <a>
                              Work Email <i className="fa fa-check-circle" aria-hidden="true" />
                            </a>
                          ) : null} */}
                        </div>

                        {/* **** Donation Method Start here**** */}
                        <div className="col-lg-5">
                          <div className="footer-social-links author-social-admin social-media-container">
                          <div
                  className={`social-links ${
                    enableColoredSocialMediaIcons == true ? 'color' : 'minimal'
                  }
                  ${
                    socialAccountStyleType == 'Round'
                      ? 'Round'
                      : socialAccountStyleType == 'Square'
                      ? 'Square'
                      : 'OneClick'
                  }
                  `}
                >
                          {currentBiolink?.links
                            ? currentBiolink?.links.map((sdata, index) => (
                           
                            enableColoredSocialMediaIcons == true ? (<>
                            <a key={index} href={`${sdata.url}`} className="btn-admin social-media-link"
                            > <img src={sdata.iconColorful} alt="" /> </a></>
                            ) : <a key={index} href={`${sdata.url}`} className="btn-admin social-media-link"
                            > <img src={sdata.iconMinimal} alt="" /> </a>

                          
                            )
                            
                            )
                            : null}
                          </div>
                          </div>
                          <div className="donation-wrapper">
                            <div className="donation-box">
                              {/* <a href="#">
                                <img src="../assets/img/paypal.png" alt="" />
                              </a> */}
                              <a href={currentBiolink?.settings?.paypalLink} target="_blank">
                                <img src={socialMediaList.paypal.icon} alt="" />
                              </a>
                              {/* <a href="#">
                                <img src="../assets/img/vi.png" alt="" />
                              </a> */}
                              <a href={currentBiolink?.settings?.venmoLink} target="_blank">
                                <img src={socialMediaList.venmo.icon} alt="" />
                              </a>
                              {/* <a href="#">
                                <img src="../assets/img/pay.png" alt="" />
                              </a> */}
                              <a href={currentBiolink?.settings?.payoneerLink} target="_blank">
                                <img src={socialMediaList.pyoneer.icon} alt="" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div
                            className="payments-section user-review-section"
                            style={{
                              marginBottom: 40,
                              marginTop: 0,
                              background: '#fff',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {currentBiolink?.settings?.paypalLink && (
                                <a href={currentBiolink?.settings?.paypalLink} target="_blank">
                                  <img
                                    src={socialMediaList.paypal.icon}
                                    alt=""
                                    height="25"
                                    style={{ display: 'inline-block', margin: '0 20px' }}
                                  />
                                </a>
                              )}

                              {currentBiolink?.settings?.venmoLink && (
                                <a href={currentBiolink?.settings?.venmoLink} target="_blank">
                                  <img
                                    src={socialMediaList.venmo.icon}
                                    alt=""
                                    height="25"
                                    style={{ display: 'inline-block', margin: '0 20px' }}
                                  />
                                </a>
                              )}
                              {currentBiolink?.settings?.payoneerLink && (
                                <a href={currentBiolink?.settings?.payoneerLink} target="_blank">
                                  <img
                                    src={socialMediaList.pyoneer.icon}
                                    alt=""
                                    height="35"
                                    style={{ display: 'inline-block', margin: '0 20px' }}
                                  />
                                </a>
                              )}
                            </div>
                          </div>
 */}
                      {/* **** Donation Method End here**** */}

                      {/* <div className="socialicon">
                            <span>
                              <i className="fa fa-facebook-square" /> Facebook
                            </span>
                            <span>
                              <i className="fa fa-twitter" /> Twitter
                            </span>
                            <span>
                              <i className="fa fa-instagram" /> Instagram
                            </span>
                            <span>
                              <i className="fa fa-linkedin" /> Linkdin
                            </span>
                          </div> */}

                      {/* <div className="footer-social-links author-social-admin social-media-container">
                            {currentBiolink?.settings?.socialAccounts
                              ? currentBiolink?.settings?.socialAccounts.map((sdata, index) =>
                                  socialMediaIcons[sdata.platform] ? (
                                    <a
                                      key={index}
                                      href={`${sdata.link}`}
                                      className="social-media-link"
                                    >
                                      <img src={socialMediaIcons[sdata.platform]} alt="" />
                                    </a>
                                  ) : null,
                                )
                              : null}
                          </div> */}

                      {/* *** Social Media  Icons End Here***  */}

                      {/* <div className="donation-wrapper">
                            <div className="donation-box">
                              <a href="#">
                                <img src="../assets/img/paypal.png" alt="" />
                              </a>
                              <a href="#">
                                <img src="../assets/img/vi.png" alt="" />
                              </a>
                              <a href="#">
                                <img src="../assets/img/pay.png" alt="" />
                              </a>
                            </div>
                          </div> */}
                    </div>
                    {/* <ul className="listing-bottom biolink-list minus-margin">{renderLinks()}</ul> */}
                  </div>
                </div>
                {/* <div className="preview-videos-section">
                  <div className="links-and-videos">
                    <a href="#" className="preview-links">
                      <div>Links</div>
                    </a>
                    <a href="#" className="preview-videos">
                      <div>Videos</div>
                    </a>
                  </div>
                  <div className="preview-links-section">
                    <ul className="preview-links-list">
                      <a href="#">
                        <img src="" alt="" />
                        <li className="links-list">link</li>
                      </a>
                      <a href="#">
                        <img src="" alt="" />
                        <li className="links-list">link</li>
                      </a>
                    </ul>
                    <ul className="preview-videos-list">
                      <a href="#">
                        <li className="videos-list">
                          <iframe
                            width="1019"
                            height="573"
                            src="https://www.youtube.com/embed/J59lSgUl1vY"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        </li>
                      </a>
                      <a href="#">
                        <li className="videos-list">
                          <iframe
                            width="1019"
                            height="573"
                            src="https://www.youtube.com/embed/J59lSgUl1vY"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        </li>
                      </a>
                    </ul>
                  </div>
                </div> */}
                {/* <ul className="listing-bottom biolink-list minus-margin mt-3">{renderLinks()}</ul> */}
                <div className="links-and-video-tab">
                  <ul className="nav nav-pills mb-3 links-and-videos" id="pills-tab" role="tablist">
                    <li className="nav-item preview-links">
                      <a
                        className="nav-link active "
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Links
                      </a>
                    </li>
                    <li className="nav-item preview-videos">
                      <a
                        className="nav-link "
                        id="pills-profile-tab"
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Videos
                      </a>
                    </li>
                  </ul>
                  <div>
                    {/* {renderLinks()} */}

                    {/* <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="preview-links-section">
                        <div className="preview-links-list">
                          <a href="#">
                            <img src="" alt="" />
                            <li className="links-list">link</li>
                          </a>
                        </div>
                      </div>
                    </div> */}

                    {/* <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="preview-video-section">
                        <div className="preview-videos-list">
                          <a href="#">
                            <div className="videos-list">
                              <iframe
                                width="1019"
                                height="573"
                                src="https://www.youtube.com/embed/J59lSgUl1vY"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
