import React, { useEffect, useState } from 'react';
import { ADD_SUPPORT } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import images from '../../../assets/images';

const ContactUs = () => {
  const [addSuportMutation, { data }] = useNetwork(ADD_SUPPORT);
  const initialFormState = {
    fullName: '',
    fullNameError: [],
    email: '',
    emailError: [],
    phoneNumber: '',
    phoneNumberError: [],
    company: '',
    companyError: [],
    subject: '',
    subjectError: [],
    message: '',
    messageError: [],
  };
  const [form, setForm] = useState(initialFormState);
  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    const fullNameError = [];
    const emailError = [];
    const phoneNumberError = [];
    const companyError = [];
    const subjectError = [];
    const messageError = [];

    if (!form.fullName) fullNameError.push('Full name cannot be empty');
    if (!form.emailError) emailError.push('Email cannot be empty');
    if (!form.phoneNumber) phoneNumberError.push('Phone number cannot be empty');
    if (!form.company) companyError.push('Company cannot be empty');
    if (!form.subject) subjectError.push('Subject cannot be empty');
    if (!form.message) messageError.push('Message cannot be empty');

    setForm({
      ...form,
      fullNameError,
      emailError,
      phoneNumberError,
      companyError,
      subjectError,
      messageError,
    });

    if (
      fullNameError.length ||
      emailError.length ||
      phoneNumberError.length ||
      subjectError.length ||
      companyError.length ||
      messageError.length
    )
      return;
    addSuportMutation({
      variables: {
        fullName: form.fullName,
        email: form.email,
        phoneNumber: form.phoneNumber,
        company: form.company,
        subject: form.subject,
        message: form.message,
      },
    });
  };

  useEffect(() => {
    if (data?.addSupport?.errors === null) {
      setForm({
        ...form,
        fullName: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        company: '',
        message: '',
        subject: '',
      });
    }
  }, [data]);
  return (
    <>
      <div className="container-fluid signup-section">
        <div className="row">
          <div className="col-md-5 col-sm-12">
            <div className="box-wrapper contact-sec">
              <h1> CONTACT US FOR QUOTE, HELP TO JOIN THE TEAM</h1>
              <div className="img-section center-img-section">
                <img alt="" src={images.contactus} />
              </div>
              <div className="signup-contact-details">
                <div className="contact-detail">
                  <h4>North America</h4>
                  <h5>New York, NY</h5>
                  <ul className="contact-list">
                    <li>
                      <i className="fa fa-map-marker inline-icon" />
                      <p className="inline-detail">
                        396 Lillian Blvd, Holbrook NY 11741,USA See On The Map
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-envelope-o inline-icon" />
                      <p className="inline-detail">New.York@Example.Com</p>
                    </li>
                    <li>
                      <i className="fa fa-phone inline-icon" />
                      <p className="inline-detail">+ 1 526 220 0459</p>
                    </li>
                  </ul>
                </div>
                <div className="contact-detail">
                  <h4>Europe</h4>
                  <h5>Berlin, Germany</h5>
                  <ul className="contact-list">
                    <li>
                      <i className="fa fa-map-marker inline-icon" />
                      <p className="inline-detail">
                        Mohrenstrasse 37 10117Berlin, GermanySee On The Map
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-envelope-o inline-icon" />
                      <p className="inline-detail">Brlin@Example.Com</p>
                    </li>
                    <li>
                      <i className="fa fa-phone inline-icon" />
                      <p className="inline-detail">+ 030 778 345 26</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-sm-12">
            <div className="">
              <div className="cform">
                <div className="form-list">
                  <div className="form-group form-floating-label">
                    <input
                      id="inputFloatingLabel2"
                      type="text"
                      name="fullName"
                      className="form-control input-solid"
                      placeholder="Full Name"
                      required
                      value={form.fullName}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {/* <label htmlFor="inputFloatingLabel2" className="placeholder">
                                Full Name
                              </label> */}
                    {form.fullNameError.length
                      ? form.fullNameError.map((error, i) => <ErrorSpan key={i} error={error} />)
                      : null}
                  </div>
                  <div className="form-group form-floating-label">
                    <input
                      id="inputFloatingLabel3"
                      type="text"
                      name="email"
                      className="form-control input-solid"
                      required
                      placeholder="Email Address"
                      value={form.email}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {/* <label htmlFor="inputFloatingLabel3" className="placeholder">
                                Email Address
                              </label> */}
                    {form.emailError.length
                      ? form.emailError.map((error, i) => <ErrorSpan key={i} error={error} />)
                      : null}
                  </div>
                  <div className="form-group form-floating-label">
                    <input
                      id="inputFloatingLabel4"
                      type="text"
                      className="form-control input-solid"
                      required
                      name="phoneNumber"
                      placeholder="Phone Number"
                      value={form.phoneNumber}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {/* <label htmlFor="inputFloatingLabel4" className="placeholder">
                                Phone Number
                              </label> */}
                    {form.phoneNumberError.length
                      ? form.phoneNumberError.map((error, i) => <ErrorSpan key={i} error={error} />)
                      : null}
                  </div>
                  <div className="form-group form-floating-label">
                    <input
                      id="inputFloatingLabel5"
                      type="text"
                      className="form-control input-solid"
                      required
                      name="company"
                      placeholder="Company"
                      value={form.company}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {/* <label htmlFor="inputFloatingLabel5" className="placeholder">
                                Company
                              </label> */}
                    {form.companyError.length
                      ? form.companyError.map((error, i) => <ErrorSpan key={i} error={error} />)
                      : null}
                  </div>
                  <div className="form-group form-floating-label">
                    <input
                      id="inputFloatingLabel6"
                      type="text"
                      className="form-control input-solid"
                      required
                      placeholder="Subject"
                      name="subject"
                      value={form.subject}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {/* <label htmlFor="inputFloatingLabel6" className="placeholder">
                                Subject
                              </label> */}
                    {form.subjectError.length
                      ? form.subjectError.map((error, i) => <ErrorSpan key={i} error={error} />)
                      : null}
                  </div>
                  <div className="form-group form-floating-label">
                    <textarea
                      id="inputFloatingLabel7"
                      className="form-control input-solid"
                      required
                      rows={4}
                      placeholder="Write a message"
                      name="message"
                      value={form.message}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {/* <label htmlFor="inputFloatingLabel7" className="placeholder">
                                Write a message
                              </label> */}
                    {form.messageError.length
                      ? form.messageError.map((error, i) => <ErrorSpan key={i} error={error} />)
                      : null}
                  </div>
                  <div className="form-group contact-us-last-btn">
                    <button
                      onClick={(evt) => onSubmitHandler(evt)}
                      type="button"
                      className="btn btn-primary"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
