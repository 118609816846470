import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Link, withRouter, useParams } from 'react-router-dom';

import { VERIFY_USER_EMAIL_BY_ACTIVATION_CODE } from '../../graphql/Mutations';

const EmailVerification = (props) => {
  const query = new URLSearchParams(props.location.search);

  const token = query.get('token');
  const [isQuery, setIsQuery] = useState(true);

  const [addReportQuery, { error, loading, data }] = useMutation(
    VERIFY_USER_EMAIL_BY_ACTIVATION_CODE,
  );
  useEffect(() => {
    if (isQuery === true) {
      addReportQuery({
        variables: {
          emailActivationCode: token,
        },
      });

      setIsQuery(false);
    }
    // console.log('data-------', data);
  }, []);
  useEffect(() => {
    console.log('data-------', data?.verifyUserEmailByActivationCode?.errors);
    // const isDone = data && data?.errors === null;
  }, [data]);
  return (
    <>
      <div>
        <div className="container">
          <div className="page-inner ">
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="payment-box1">
                    {data && data?.verifyUserEmailByActivationCode?.errors === null ? (
                      <>
                        <div className="sucess-bar">
                          <i className="fa  fa-check-circle" />
                          <h1>Email Verified Successfully </h1>
                          <Link to="/app">
                            <p>Click here to login</p>
                          </Link>
                        </div>
                      </>
                    ) : data?.verifyUserEmailByActivationCode?.errors != null ? (
                      <div className="sucess-bar">
                        <h1>Your accout has been verified</h1>
                        <Link to="/app">
                          <p>Click here to login</p>
                        </Link>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerification;
