import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import { UPDATE_LINK_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';

const EditLinksForm = (props) => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const [form, setForm] = useState({});
  const [updateLink, { data: updateData }] = useNetwork(UPDATE_LINK_MUTATION);

  useEffect(() => {
    if (props) {
      setForm({
        ...form,
        urlError: [],
        id: props?.item?.id,
        url: props?.item?.url,
        note: props?.item?.note,
      });
    }
  }, [props]);

  const inputChangeHandler = (name, value) => {
    const urlError = [];

    if (!form.url) urlError.push('URL cannot be empty');
    setForm({
      ...form,
      urlError,
    });

    if (urlError?.length) return;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSaveHandlers = () => {
    updateLink({
      variables: {
        biolinkId: currentBiolink.id,
        id: form.id,
        url: form.url,
        note: form.note,
      },
    });
  };

  return (
    <>
      <div className="modal fade" id="modal-133">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Links & Embeds</h4>
              <button type="button" className="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
                <span className="sr-only">Close</span>
              </button>
            </div>
            <div className="modal-body branding-model">
              <div className="form-list mt-5">
                <div className="form-group inte-form clearfix">
                  <label htmlFor="inputFloatingLabel10" className="label-design">
                    Long URL
                  </label>

                  <input
                    type="text"
                    id="inputFloatingLabel10"
                    className="form-control input-solid"
                    // placeholder="Long URL"
                    name="url"
                    value={form.url}
                    onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                  />
                  {form?.urlError?.length
                    ? form?.urlError.map((err, i) => <ErrorSpan key={i} error={err} />)
                    : null}
                </div>

                <div className="form-group inte-form clearfix">
                  <label htmlFor="inputFloatingLabel11" className="label-design">
                    Note
                  </label>
                  <div className="theam-toggle">
                    <input
                      type="text"
                      id="inputFloatingLabel11"
                      className="form-control input-solid"
                      // placeholder="Phone"
                      name="note"
                      value={form.note}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onSaveHandlers()}
                  data-dismiss="modal"
                >
                  Save changes
                </button>
              </div>
            </div>

            <div className="modal-footer">
              <a
                href="#modal-1"
                data-toggle="modal"
                data-dismiss="modal"
                className="btn btn-default"
              >
                Previous
              </a>
              <button type="button" data-dismiss="modal" className="btn btn-danger">
                Close
              </button>
            </div>
          </div>
          {/* <!-- /.modal-content --> */}
        </div>
        {/* <!-- /.modal-dialog --> */}
      </div>
    </>
  );
};

export default EditLinksForm;
