import React, { useState, useEffect } from 'react';
import copy from 'clipboard-copy';

import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Table from 'react-bootstrap/Table';
import { UPDATE_LINK_MUTATION, REMOVE_LINK_MUTATION } from '../../../graphql/Mutations';
import generateRandomId from '../../../utils/genrateRandomId';
import * as alertActions from '../../../store/actions/alert';
import ToggleSwitch from '../../../components/ui/ToggleSwitch';
import validate from '../../../utils/validations';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import useNetwork from '../../../hooks/useNetwork';
import EditLinksForm from '../dashboard/EditLinksForm';

const LinkShortnerList = ({ longUrl, shortUrl, currentBiolinkId }) => {
  const dispatch = useDispatch();
  const links = useSelector((state) => state.auth.currentBiolink.links);
  let serialNumber = 0;
  const [form, setForm] = useState({
    id: '',
    url: '',
    urlError: [],
    shortenedUrl: '',
    note: '',
    enablePasswordProtection: false,
    password: '',
    passwordError: [],
  });
  const[test,setTest]=useState(false);
  const [updateLink, { data: updateData }] = useNetwork(UPDATE_LINK_MUTATION);
  const [removeLink, { data: deleteData }] = useNetwork(REMOVE_LINK_MUTATION);

  const [isEditing, setisEditing] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleModalClose = (isUpdated) => {
    setModalShow(false);
    if (isUpdated) {
      setForm({
        ...form,
        enablePasswordProtection: !form.enablePasswordProtection,
      });
    }
  };

  const handleModalShow = (item) => {
    if (item.enablePasswordProtection) {
      updateLink({
        variables: {
          biolinkId: currentBiolinkId,
          id: item.id,
          url: item.url,
          enablePasswordProtection: false,
          password: '',
        },
      });
      return;
    }
    if (isEditing) {
      setForm({
        ...form,
        enablePasswordProtection: true,
      });
      setModalShow(true);
      return;
    }
    setModalShow(true);
    setForm({
      ...form,
      biolinkId: currentBiolinkId,
      id: item.id,
      url: item.url,
      note: item.note,
      enablePasswordProtection: true,
    });
  };

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onCopyHandler = (url) => {
    copy(url);

    dispatch(
      alertActions.addAlert({
        id: generateRandomId(),
        message: 'Link copied.',
        type: 'success',
      }),
    );
  };

  const onTogglePasswordProtection = () => {
    const passwordError = [];

    if (validate.minLength(form.password, 8)) {
      passwordError.push('Password must be atleaset 8 characters long.');
    }

    setForm({
      ...form,
      passwordError,
    });

    if (passwordError.length) {
      return;
    }

    if (isEditing) {
      return;
    }

    updateLink({
      variables: {
        biolinkId: currentBiolinkId,
        id: form.id,
        url: form.url,
        note: form.note,
        enablePasswordProtection: form.enablePasswordProtection,
        password: form.password,
      },
    });
  };

  const onEditHandler = (item) => {
    setisEditing(true);
    setForm({
      ...form,
      id: item.id,
      url: item.url,
      shortenedUrl: item.shortenedUrl,
      note: item.note,
      enablePasswordProtection: item.enablePasswordProtection,
    });
  };

  const onSaveHandler = () => {
    const urlError = [];

    if (!form.url) urlError.push('URL cannot be empty');
    setForm({
      ...form,
      urlError,
    });

    if (urlError.length) return;

    updateLink({
      variables: {
        biolinkId: currentBiolinkId,
        id: form.id,
        url: form.url,
        note: form.note,
      },
    });
  };

  const onCancelHandler = () => {
    setisEditing(false);
    setForm({
      ...form,
      id: '',
      url: '',
      shortenedUrl: '',
      note: '',
      enablePasswordProtection: false,
      password: '',
    });
  };

  const onDeleteHandler = (id) => {
    removeLink({
      variables: {
        id,
      },
    });
  };

  const getSerialNumber = () => {
    serialNumber += 1;
    return serialNumber;
  };

  useEffect(() => {
    if (updateData?.updateLink?.errors === null) {
      setisEditing(false);
      handleModalClose();
    }
  }, [updateData]);

  return (
    <>
      <div className="card-body">
        <h1>Share URL </h1>
    <div>

      <Table responsive>
  <thead>
    <tr>
          <th>#</th>
          <th>Long URL</th>
          <th>Short URL</th>
          <th colSpan="1">Action</th>
          <th>Password Protection</th>
    
    </tr>
  </thead>
  <tbody>
              {links.length ? (
                links?.map((item, index) => {
                  if (item.linkType === 'Line') return null;
                  return (
                    <tr key={index} >
                      <td>{getSerialNumber()}</td>
                      <td>
                        {isEditing && form.shortenedUrl === item.shortenedUrl ? (
                          <div className="shorturl-edit-form">
                            <div className="shorturl-edit-form-input-group">
                              <label>Long URL:</label>
                              <input
                                type="text"
                                placeholder="Enter the link here"
                                className="input-design short-link-input"
                                name="url"
                                value={form.url}
                                onChange={(evt) =>
                                  inputChangeHandler(evt.target.name, evt.target.value)
                                }
                              />
                            </div>
                            <div className="shorturl-edit-form-input-group">
                              <label>Note:</label>
                              <input
                                type="text"
                                placeholder="Enter a note for this link(optional)"
                                className="input-design short-link-input"
                                name="note"
                                value={form.note}
                                onChange={(evt) =>
                                  inputChangeHandler(evt.target.name, evt.target.value)
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="urllist">
                              <a href={`${item.url}`} target="blank_">
                                {item.url}
                              </a>
                            </div>
                            {item.note ? (
                              <div style={{ marginTop: '5px' }}>
                                <span>Note: {item.note}</span>
                              </div>
                            ) : null}
                          </>
                        )}
                      </td>
                      <td>
                        <a href={`https://avyatated.com/${item.shortenedUrl}`} target="blank_">
                          https://avyatated.com/{item.shortenedUrl}
                        </a>
                      </td>
                      <td className="links-action-col link_with">
                        <button
                          className="copy links-action-btn"
                          type="button"
                          onClick={() =>
                            onCopyHandler(`https://avyatated.com/${item.shortenedUrl}`)
                          }
                        >
                          <i className="fa fa-copy" aria-hidden="true" />
                        </button>
                        {isEditing && form.shortenedUrl === item.shortenedUrl ? (
                          <button
                            className="none links-action-btn"
                            type="button"
                            onClick={() => onSaveHandler()}
                          >
                            <i className="fa fa-floppy-o" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                          className="none links-action-btn"
                          type="button"
                          onClick={() => setTest(item)}
                           href="#modal-133"
                          data-toggle="modal"
                          data-dismiss="modal"
                          //  className="btn-sm"
                        >
                          <i className="fa fa-pencil-square-o" aria-hidden="true" />
                              <EditLinksForm item={test} />
                        </button>
                        )}

                        {isEditing && form.shortenedUrl === item.shortenedUrl ? (
                          <button
                            className="none links-action-btn"
                            type="button"
                            onClick={() => onCancelHandler(item.shortenedUrl)}
                          >
                            <i className="fa fa-times" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                            className="none links-action-btn"
                            type="button"
                            onClick={() => onDeleteHandler(item.id)}
                          >
                            <i className="fa fa-trash" aria-hidden="true" />
                          </button>
                        )}
                      </td>
                      <td>
                        {/* <div className="theam-toggle"> */}
                        <ToggleSwitch
                          currentState={
                            isEditing && form.shortenedUrl === item.shortenedUrl
                              ? form.enablePasswordProtection
                              : item.enablePasswordProtection
                          }
                          onToggle={() => handleModalShow(item)}
                        />
                        {/* </div> */}
                        <Modal show={modalShow} onHide={() => handleModalClose(false)}>
                          <Modal.Header closeButton>
                            <Modal.Title>Enter password</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <input
                              type={showPassword ? 'text' : 'password'}
                              name="password"
                              className="form-control"
                              value={form.password}
                              onChange={(evt) =>
                                inputChangeHandler(evt.target.name, evt.target.value)
                              }
                            />
                            <span
                              className="input-icon sizespace"
                              role="button"
                              onClick={() => setShowPassword(!showPassword)}
                              onKeyDown={() => setShowPassword(!showPassword)}
                              tabIndex={-8}
                            >
                              <i
                                className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}
                                aria-hidden="true"
                              />
                            </span>
                            {form.passwordError.length
                              ? form.passwordError.map((err, i) => (
                                  <ErrorSpan key={i} error={err} />
                                ))
                              : null}
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleModalClose(false)}>
                              Cancel
                            </Button>
                            <Button variant="primary" onClick={() => onTogglePasswordProtection()}>
                              Enable
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>No record found</td>
                </tr>
              )}
            </tbody>
</Table>
      </div>
      </div>
    </>
  );
};

export default LinkShortnerList;
