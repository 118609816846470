import React from 'react';
import { useDispatch } from 'react-redux';
import images from '../../../assets/images';
import useNetwork from '../../../hooks/useNetwork';
import * as authActions from '../../../store/actions/auth';
import DeleteBiolink from '../../../components/app/DeleteBiolink';
import { CHANGE_CURRENT_BIOLINKID_MUTATION } from '../../../graphql/Mutations';

const CurrentBioLinks = ({ bioLinks, currentBiolink }) => {
  const dispatch = useDispatch();
  const [changeCurrentBiolinkId, { data }] = useNetwork(CHANGE_CURRENT_BIOLINKID_MUTATION);
  // const setCurrentHandler = (item) => {
  //   dispatch(authActions.setCurrentBiolink(item));
  // };

  return (
    <>
      <ul className="listing-bottom setting-listing usr-accont">
        {bioLinks?.length &&
          bioLinks.map((item, index) => (
            <li key={index}>
              <div className="listurl clearfix">
                <div className="profile-img-box">
                  <img src={images.profilepic1} alt="" />
                </div>
                <div className="personal-social-detail">
                  <h3>{item.username?.username}</h3>
                  {/* <p>https://www.youtube.com/watch?v=W6NZfCO5SIk</p> */}
                </div>
                <div className="prsonal-list-icon blue-text">
                  {currentBiolink?.id === item.id ? (
                    <span className="biolink-status">
                      CURRENT <i className="fa fa-check-circle-o" />
                    </span>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn-admin icon-design-admin "
                        onClick={() =>
                          changeCurrentBiolinkId({ variables: { biolinkId: item.id } })
                        }
                      >
                        Set Current
                      </button>
                      <DeleteBiolink biolinkId={item?.id} />
                    </>
                  )}
                </div>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};

export default CurrentBioLinks;
