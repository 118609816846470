import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UpgradeModal from '../../../components/app/UpgradeModal';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import ToggleSwitch from '../../../components/ui/ToggleSwitch';
import { UPDATE_UTM_PARAMETER_SETTINGS_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';

const UTMParameters = ({ showUpgrade }) => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const currentPlan = useSelector((state) => state.auth.user.plan);
  const initialFormState = {
    enableUtmParameters: currentBiolink.settings?.enableUtmParameters,
    utmSource: currentBiolink.settings?.utmSource,
    utmMedium: currentBiolink.settings?.utmMedium,
    utmCampaign: currentBiolink.settings?.utmCampaign,
    utmSourceError: [],
    utmMediumError: [],
    utmCampaignError: [],
  };

  const [form, setForm] = useState(initialFormState);

  const [updateUTMParameterSettings, { data }] = useNetwork(UPDATE_UTM_PARAMETER_SETTINGS_MUTATION);

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onToggle = (button) => {
    if (!currentPlan?.settings.addedToDirectoryEnabled) {
      showUpgrade();
      return;
    }
    if (button === 'enableUtmParameters' && form[button] === true) {
      setForm({
        ...form,
        enableUtmParameters: false,
        utmSource: '',
        utmMedium: '',
        utmCampaign: '',
      });
    } else {
      setForm({
        ...form,
        [button]: !form[button],
      });
    }
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    if (!currentPlan?.settings.utmParametersEnabled) {
      showUpgrade();
      return;
    }
    let isValidated = true;
    const utmSourceError = [];
    const utmMediumError = [];
    const utmCampaignError = [];

    // add validations here
    if (form.enableUtmParameters && !form.utmSource) {
      isValidated = false;
      utmSourceError.push('UTM Source cannot be empty.');
    }
    if (form.enableUtmParameters && !form.utmMedium) {
      isValidated = false;
      utmMediumError.push('UTM Medium cannot be empty.');
    }

    if (form.enableUtmParameters && !form.utmCampaign) {
      isValidated = false;
      utmCampaignError.push('UTM Campaign cannot be empty.');
    }

    setForm({
      ...form,
      utmSourceError,
      utmMediumError,
      utmCampaignError,
    });
    if (!isValidated) return;

    // submit form
    updateUTMParameterSettings({
      variables: {
        enableUtmParameters: form.enableUtmParameters,
        utmSource: form.utmSource,
        utmMedium: form.utmMedium,
        utmCampaign: form.utmCampaign,
        id: currentBiolink.id,
      },
    });
    setForm({
      ...form,
      utmSource: '',
      utmMedium: '',
      utmCampaign: '',
    });
  };

  return (
    <div className="modal fade" id="modal-6">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">UTM Parameters </h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body branding-model">
            <div className="form-list">
              <div className="form-group form-floating-label clearfix">
                <ToggleSwitch
                  currentState={form.enableUtmParameters}
                  onToggle={() => {
                    onToggle('enableUtmParameters');
                  }}
                  id="inputFloatingLabel116"
                  isActive="na"
                />
                <label htmlFor="inputFloatingLabel116" className="placeholder">
                  Enable UTM
                </label>
              </div>
              {form.enableUtmParameters && (
                <>
                  <div className="form-group form-floating-label clearfix">
                    {/* <input
                  id="inputFloatingLabel16"
                  type="text"
                  className="form-control input-solid"
                  required
                /> */}
                    <input
                      type="text"
                      id="inputFloatingLabel16"
                      className="form-control input-solid"
                      name="utmSource"
                      value={form.utmSource || ''}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      placeholder="UTM Source"
                    />
                    {/* <label htmlFor="inputFloatingLabel16" className="placeholder">
                      UTM Source
                    </label> */}
                    {form.utmSourceError.length
                      ? form.utmSourceError.map((err, i) => <ErrorSpan key={i} error={err} />)
                      : null}
                  </div>

                  <div className="form-group form-floating-label clearfix">
                    {/* <input
                  id="inputFloatingLabel17"
                  type="text"
                  className="form-control input-solid"
                  required
                /> */}
                    <input
                      type="text"
                      id="inputFloatingLabel17"
                      className="form-control input-solid"
                      name="utmMedium"
                      value={form.utmMedium || ''}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      placeholder="UTM Medium"
                    />
                    {/* <label htmlFor="inputFloatingLabel17" className="placeholder">
                      UTM Medium
                    </label> */}
                    {form.utmMediumError.length
                      ? form.utmMediumError.map((err, i) => <ErrorSpan key={i} error={err} />)
                      : null}
                  </div>

                  <div className="form-group form-floating-label clearfix">
                    {/* <input
                  id="inputFloatingLabel18"
                  type="text"
                  className="form-control input-solid"
                  required
                /> */}
                    <input
                      type="text"
                      id="inputFloatingLabel18"
                      className="form-control input-solid"
                      name="utmCampaign"
                      value={form.utmCampaign || ''}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      placeholder="UTM Campaign"
                    />
                    {/* <label htmlFor="inputFloatingLabel18" className="placeholder">
                      UTM Campaign
                    </label> */}
                    {form.utmCampaignError.length
                      ? form.utmCampaignError.map((err, i) => <ErrorSpan key={i} error={err} />)
                      : null}
                  </div>
                </>
              )}

              <button
                type="button"
                className="btn btn-primary"
                onClick={(evt) => onSubmitHandler(evt)}
              >
                Save changes
              </button>
            </div>
          </div>

          <div className="modal-footer">
            <a href="#modal-1" data-toggle="modal" data-dismiss="modal" className="btn btn-default">
              Previous
            </a>
            <button data-dismiss="modal" type="button" className="btn btn-danger">
              Close
            </button>
          </div>
        </div>
        {/* <!-- /.modal-content --> */}
      </div>
      {/* <!-- /.modal-dialog --> */}
    </div>
  );
};

export default UTMParameters;
