import React from 'react';

const TermsAndConditions = (props) => {
  return (
    <>
      <div className="container pt-5">
        <div className="row mt--2">
          <div className="col-md-12">
            <div className="card full-height term-and-condition-section">
              <div className="center-heading black-text heading-area">
                <div className="sub-heading mt-0">THIS IS STASH.EE</div>
                <h1 className="terms-main-heading">Terms and Conditions</h1>
              </div>
              <div className="card-body">
                <section className=" section-space pt-3 pb-0">
                  <div className="policy-area terms-area">
                    <h4>Intellectual Propertly</h4>
                    <ul>
                      <li>
                        1-Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam quis diam
                        erat. Duis velit lectus, posuere a blandit sit amet, tempor at lorem. Donec
                        ultricies, lorem sed ultrices interdum.
                      </li>
                      <li>
                        2-Leo metus luctus sem, vel vulputate diam ipsum sed lorem. Donec tempor
                        arcu nisl, et molestie massa scelerisque ut. Nunc at rutrum leo. Mauris
                        metus mauris, tristique quis sapien eu, rutrum vulputate enim.
                      </li>
                      <li>
                        3-Mauris tempus erat laoreet turpis lobortis, eu tincidunt erat fermentum.
                      </li>
                      <li>
                        4- Aliquam non tincidunt urna. Integer tincidunt nec nisl vitae ullamcorper.
                        Proin sed ultrices erat. Praesent varius ultrices massa at faucibus.
                      </li>
                      <li>
                        5- Aenean dignissim, orci sed faucibus pharetra, dui mi dignissim tortor,
                        sit amet condimentum mi ligula sit amet augue.
                      </li>
                      <li>6- Pellentesque vitae eros eget enim mollis placerat.</li>
                    </ul>
                  </div>
                  <div className="policy-area terms-area">
                    <h4>Termination</h4>
                    <ul>
                      <li>
                        1-Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam quis diam
                        erat. Duis velit lectus, posuere a blandit sit amet, tempor at lorem. Donec
                        ultricies, lorem sed ultrices interdum.
                      </li>
                      <li>
                        2-Leo metus luctus sem, vel vulputate diam ipsum sed lorem. Donec tempor
                        arcu nisl, et molestie massa scelerisque ut. Nunc at rutrum leo. Mauris
                        metus mauris, tristique quis sapien eu, rutrum vulputate enim.
                      </li>
                      <li>
                        3-Mauris tempus erat laoreet turpis lobortis, eu tincidunt erat fermentum.
                      </li>
                      <li>
                        4- Aliquam non tincidunt urna. Integer tincidunt nec nisl vitae ullamcorper.
                        Proin sed ultrices erat. Praesent varius ultrices massa at faucibus.
                      </li>
                      <li>
                        5- Aenean dignissim, orci sed faucibus pharetra, dui mi dignissim tortor,
                        sit amet condimentum mi ligula sit amet augue.
                      </li>
                      <li>6- Pellentesque vitae eros eget enim mollis placerat.</li>
                    </ul>
                  </div>
                  <div className="policy-area terms-area">
                    <h4>Changes To This Agreement</h4>
                    <p>
                      We reserve the right, at our sole discretion, to modify or replace these Terms
                      and Conditions by posting the updated terms on the Site. Your continued use of
                      the Site after any such changes constitutes your acceptance of the new Terms
                      and Conditions.
                    </p>
                  </div>
                  <div className="policy-area terms-area">
                    <h4>Hyperlinks</h4>
                    <p>
                      Our website may contain hyperlinks. These hyperlinks connect you to sites of
                      other organisations which are not our responsibility. We have used our
                      reasonable endeavours in preparing our own website and the information
                      included in it is done so in good faith. However, we have no control over any
                      of the information you can access via other websites. Therefore, no mention of
                      any organisation, company or individual to which our website is linked shall
                      imply any approval or warranty as to the standing and capability of any such
                      organisations, company or individual on the part of The Book Depository.
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        {/* Claim div here */}
      </div>
    </>
  );
};

export default TermsAndConditions;
