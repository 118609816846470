import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/client';
import Links from '../links/Links';
import EmbedoItem from '../../../components/ui/embedo/EmbedoItem';

import socialMediaIcons from '../../../assets/socialMediaIcons';

import { GET_BIOLINK_FROM_USERNAME, GET_IF_FOLLOWING } from '../../../graphql/Queries';
import Loader from '../../../components/ui/loader/Loader';
import ProfileImage from '../../app/dashboard/ProfileImage';
import BannerImage from '../../app/dashboard/BannerImage';
import useNetwork from '../../../hooks/useNetwork';
import { FOLLOW_USER_MUTATION, UNFOLLOW_USER_MUTATION } from '../../../graphql/Mutations';

const BioLink = (props) => {
  const [FollowStatus, setFollowStatus] = useState(false);
  const username = props.location.pathname?.replace('/', '');
  if (username.startsWith('0')) {
    return <Links />;
  }

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [checkUsername, setCheckUsername] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [currentBiolink, setCurrentBiolink] = useState(
    isAuthenticated ? useSelector((state) => state.auth.currentBiolink) : {},
  );
  const [follow, setFollow] = useState(false);
  const [
    getBiolinkFromUsername,
    { data: biolinkData, loading: biolinkLoading, error: biolinkError, refetch: biolinkRefetch },
  ] = useLazyQuery(GET_BIOLINK_FROM_USERNAME);
  // console.log('Biolink Data==========////////////', biolinkData);
  useEffect(() => {
    if (biolinkData && biolinkData.getBiolinkFromUsername?.errors === null) {
      setCurrentBiolink(biolinkData.getBiolinkFromUsername?.biolink);
      setCheckUsername(true);
      setLoggedInUser(false);
    } else {
      setLoggedInUser(true);
    }
  }, [biolinkData]);
  // console.log('Biolink Data==========////////////', biolinkData);


  const [form, setForm] = useState({
    email: '',
  });
   const[enableColoredSocialMediaIcons, setEnableColoredSocialMediaIcons]=useState('');
   const[socialAccountStyleType,setSocialAccountStyleType]=useState('');

  useEffect(()=>{  setEnableColoredSocialMediaIcons(currentBiolink?.settings?.enableColoredSocialMediaIcons);
    setSocialAccountStyleType(currentBiolink?.settings?.socialAccountStyleType);},[currentBiolink]);

  useEffect(() => {
    if (loggedInUser) {
      // loggedInUser;
    }
  }, [loggedInUser]);
  useEffect(() => {
    if (isAuthenticated && username !== currentBiolink.username.username) {
      getBiolinkFromUsername({ variables: { username } });
    } else {
      setCheckUsername(true);
    }

    const link = document.createElement('link');
    link.href = '//cdn-images.mailchimp.com/embedcode/classic-10_7.css';
    link.rel = 'stylesheet';
    link.type = 'text/css';

    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      #mc_embed_signup {
        background: #fff;
        clear: left;
        font: 14px Helvetica, Arial, sans-serif;
      }
    `;

    const scriptLink = document.createElement('script');
    scriptLink.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
    scriptLink.type = 'text/javascript';

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function ($) {
        window.fnames = new Array();
        window.ftypes = new Array();
        fnames[0] = "EMAIL";
        ftypes[0] = "email";
        fnames[1] = "FNAME";
        ftypes[1] = "text";
        fnames[2] = "LNAME";
        ftypes[2] = "text";
        fnames[3] = "ADDRESS";
        ftypes[3] = "address";
        fnames[4] = "PHONE";
        ftypes[4] = "phone";
        fnames[5] = "BIRTHDAY";
        ftypes[5] = "birthday";
      })(jQuery);
      var $mcj = jQuery.noConflict(true);
    `;

    const facebookScriptTag = document.createElement('script');

    // scriptTag.src = "https://use.typekit.net/foobar.js";
    facebookScriptTag.innerHTML = `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '{your-pixel-id-goes-here}');
    fbq('track', 'PageView');`;
    facebookScriptTag.async = true;

    const iframelyScriptTag = document.createElement('script');
    iframelyScriptTag.async = true;
    iframelyScriptTag.charSet = 'utf-8';
    iframelyScriptTag.src = '//cdn.iframe.ly/embed.js?api_key=9cc616b877b40e12c514a2';

    document.head.appendChild(link);
    document.head.appendChild(style);
    document.body.appendChild(scriptLink);
    // document.body.appendChild(script);
    document.body.appendChild(facebookScriptTag);
    document.body.appendChild(iframelyScriptTag);
    if (window.iframely) iframely.load();

    return () => {
      document.head.removeChild(link);
      document.head.removeChild(style);
      document.body.removeChild(scriptLink);
      // document.body.removeChild(script);
      document.body.removeChild(facebookScriptTag);
      document.body.removeChild(iframelyScriptTag);
    };
  }, []);

  const { data: getfollowUser, refetch: refetchgetfollowUser } = useQuery(GET_IF_FOLLOWING, {
    variables: {
      biolinkId: currentBiolink?.id,
    },
  });
  useEffect(() => {
    if (currentBiolink?.id) {
      refetchgetfollowUser();
    }
  }, [currentBiolink]);

  useEffect(() => {
    //  if (followUser && followUser?.getItFollowing?.biolinkId === biolinkData?.biolink?.id) {

    // }

    // console.log('getfollowUser=====================>', getfollowUser);
    if (
      getfollowUser?.getIfFollowing?.following == true ||
      getfollowUser?.getIfFollowing?.following == false
    ) {
      // console.log('setFollowStatus=====================', getfollowUser?.getIfFollowing?.following);
      setFollowStatus(getfollowUser?.getIfFollowing?.following);
    }
  }, [getfollowUser]);

  useEffect(() => {
    // console.log('FollowStatus=====================', FollowStatus);
  }, [FollowStatus]);

  const [addFollowUser, { data: FollowData }] = useNetwork(FOLLOW_USER_MUTATION);

  useEffect(() => {
    // if (isAuthenticated && FollowData?.followBiolink?.followingBiolinkId) {
    //   //  console.log('You are following me------- ', FollowData);
    // }
    refetchgetfollowUser();
  }, [FollowData]);

  const [unfollowUser, { data: unfollowData }] = useNetwork(UNFOLLOW_USER_MUTATION);
  useEffect(() => {
    // if (
    //   isAuthenticated &&
    //   unfollowData?.unfollowBliolink?.followingBiolinkId !== biolinkData?.biolink?.id
    // ) {
    //   // setFollow(true);
    // }
    refetchgetfollowUser();
  }, [unfollowData]);

  const followHandler = () => {
    addFollowUser({
      variables: {
        followingBiolinkId: currentBiolink?.id,
      },
    });
    // refetchgetfollowUser();
  };

  const unfollowHandler = () => {
    unfollowUser({
      variables: {
        followingBiolinkId: currentBiolink?.id,
      },
    });
    // refetchgetfollowUser();
  };

  if (!checkUsername) {
    return <Loader />;
  }

  const renderLinks = () => {
    return currentBiolink?.links?.map((link, index) => {
      if (link.linkType === 'Line') {
        return (
          <li key={index}>
            <hr
              style={{
                height: '10px',
                borderRadius: '5px',
                background: link.linkColor,
              }}
            />
          </li>
        );
      }
      if (link.linkType === 'Embed') {
        return (
          <li key={index}>
            <EmbedoItem
              key={index}
              id={link.id}
              href={link.url || ''}
              // href="https://www.npmjs.com/package/embedo"
              // name={link.linkTitle || ''}
              data-width="100%"
              data-height="30vw"
              // data-options={}
            />
          </li>
        );
      }

      return (
        <li key={index}>
          <a href={link.url} className="biolink-links" target="_blank">
            <span className="bio-icon">
              <img src="img/you-tube.png" alt="" />
            </span>
            <span className="bio-text">{link.linkTitle}</span>
          </a>
        </li>
      );
    });
  };

  return (
    <div className="content bg-img-section">
      <div className="page-inner">
        <div className="row mt--2">
          <div className="col-md-10 offset-md-1 text-center">
            <div className="card full-height">
              <div className="card-body">
                <div className="panel-header profile-header pd1-090">
                  <div className="page-inner py-5">
                    <div
                      className="
                  d-flex
                  align-items-left align-items-md-center
                  flex-column flex-md-row
                "
                    >
                      {/* <div className="profile-edit-wrapper">
                        <BannerImage />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="white-box mb-5">
                  <div className="row mt--2">
                    <div className="col-md-12 biolink-area">
                      <div className="profile-left userProfile">
                        <div className="d-flex">
                          <div className="profile-box">
                            <div className="user-profile">
                              <img src="../assets/img/user.jpg" alt="" />
                              {/* <ProfileImage /> */}
                            </div>
                          </div>
                        </div>
                        <div className="text-left text-heading">
                          <h3 className="mb-1">{currentBiolink?.displayName}</h3>
                          <p className="mb-1 hed-title">
                            <span className="label-box">
                              <i className="fa fa-link" />
                              My Link:
                            </span>
                            <span className="label-detail">
                              stash.ee/{currentBiolink?.username?.username}
                            </span>
                          </p>
                          <div className="profile-social mt-0 tst-profileHead">
                            {/* <a
                              //  href={FollowData && FollowData?.followUser.followingId}
                              className="btn btn-border btn-round btn-lg btn-info mr-2"
                              onClick={() => followHandler()}
                            > */}
                            {/* follow */}
                            {/* {!unfollowData == true
                                ? ' unfollow'
                                : 'follow' && !checkUsername
                                ? 'unfollow'
                                : 'follow'} */}
                            {loggedInUser == true ? (
                              <a
                                //  href={FollowData && FollowData?.followUser.followingId}
                                className="btn btn-border btn-round  btn-info mr-2"
                              >
                                This is you
                              </a>
                            ) : FollowStatus == true ? (
                              <a
                                //  href={FollowData && FollowData?.followUser.followingId}
                                className="btn btn-border btn-round  btn-info mr-2"
                                onClick={() => unfollowHandler()}
                              >
                                Unfollow
                              </a>
                            ) : (
                              <a
                                //  href={FollowData && FollowData?.followUser.followingId}
                                className="btn btn-border btn-round  btn-info mr-2"
                                onClick={() => followHandler()}
                              >
                                Follow
                              </a>
                            )}

                            {/* </a> */}
                            {/* <button
                              type="button"
                              className="btn btn-border btn-round btn-lg btn-info mr-2"
                              onClick={() => followHandler()}
                            >
                              {!checkUsername ? ' follow' : 'unfollow'}
                            </button> */}

                            {/* Open Chat code Open in 2nd phase  */}
                            <Link
                              to={{
                                pathname: `/${username}/message`,
                                params: {
                                  receiverId: currentBiolink?.user?.id,
                                },
                              }}
                              className="btn btn-border btn-round  btn-info"
                            >
                              <i className="fa fa-comments" />
                            </Link>
                            {/* Close Chat code Open in 2nd phase  */}
                          </div>
                        </div>
                      </div>
                      <div className="user-detail user-dt-head row align-items-center">
                        <ul className="preview-list mt-2 mb-2 col-md-7">
                          <li>
                            <span className="label-detail">
                              <h4>{currentBiolink?.bio}</h4>
                            </span>
                          </li>
                          <li>
                            <span className="label-box">
                              <i className="fa fa-map-marker" />
                              Address:
                            </span>
                            <span className="label-detail">
                              {currentBiolink?.city}
                              {currentBiolink?.city && currentBiolink?.state && <span>, </span>}
                              {currentBiolink?.state}
                              {currentBiolink?.state && currentBiolink?.country && <span>, </span>}
                              {currentBiolink?.country}
                            </span>
                          </li>

                          <li>
                            <span className="label-box">
                              <i className="fa fa-envelope" />
                              Email:
                            </span>
                            <span className="label-detail">
                              {currentBiolink?.settings?.email || ''}
                            </span>
                          </li>

                          <li>
                            <span className="label-box">
                              <i className="fa fa-phone" />
                              Call:
                            </span>
                            <span className="label-detail">{currentBiolink?.settings?.phone}</span>
                          </li>

                          {/* <!--<li><span className="label-box">Donations:</span> <span className="label-detail">  <div className="donation-box"><a href="#"><img src="./assets/img/paypal.png" alt=""/></a>
                      <a href="#"><img src="./assets/img/vi.png" alt=""/></a>
                      <a href="#"><img src="./assets/img/pay.png" alt=""/></a></div>
                      </span>
                      </li>--> */}
                        </ul>

                        {/* <div className="socialicon">
                            <span>
                              <i className="fa fa-facebook-square" /> Facebook
                            </span>
                            <span>
                              <i className="fa fa-twitter" /> Twitter
                            </span>
                            <span>
                              <i className="fa fa-instagram" /> Instagram
                            </span>
                            <span>
                              <i className="fa fa-linkedin" /> Linkdin
                            </span>
                          </div> */}

                        {/* ***** Social Media Icons Start Here******* */}
                        <div className="col-md-5 biolink-social">
                          <div className="footer-social-links author-social-admin social-media-container">
                          <div
                  className={`social-links ${
                    enableColoredSocialMediaIcons == true ? 'color' : 'minimal'
                  }
                  ${
                    socialAccountStyleType == 'Round'
                      ? 'Round'
                      : socialAccountStyleType == 'Square'
                      ? 'Square'
                      : 'OneClick'
                  }
                  `}
                >
                          {currentBiolink?.links
                            ? currentBiolink?.links.map((sdata, index) => (
                           
                            enableColoredSocialMediaIcons == true ? (<>
                            <a key={index} href={`${sdata.url}`} className="btn-admin social-media-link"
                            > <img src={sdata.iconColorful} alt="" /> </a></>
                            ) : <a key={index} href={`${sdata.url}`} className="btn-admin social-media-link"
                            > <img src={sdata.iconMinimal} alt="" /> </a>

                          
                            )
                            
                            )
                            : null}

</div>
                          </div>
                          {/* ***** Social Media Icons Start Here******* */}
                        </div>
                        
                        <div className="donation-wrapper">
                          <div className="donation-box">
                            <a href="#">
                              <img src="../assets/img/paypal.png" alt="" />
                            </a>
                            <a href="#">
                              <img src="../assets/img/vi.png" alt="" />
                            </a>
                            <a href="#">
                              <img src="../assets/img/pay.png" alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <ul className="listing-bottom biolink-list minus-margin">{renderLinks()}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BioLink;
