import { useMutation } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';

import { SEND_MESSAGE_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';

const Message = (props) => {
  const receiverUsername = props.match.params.username;
  // const receiverEmail = props.match.params?.email;
  console.log('Show userName Here ===>>>', receiverUsername);
  const receiverId = props.location.params?.receiverId;

  if (!receiverId) {
    props.history.push(`/${receiverUsername}`);
  }

  const [sendMessage, { data }] = useNetwork(SEND_MESSAGE_MUTATION);

  const [form, setForm] = useState({
    message: '',
    attachment: null,
  });

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const hiddenFileInput = useRef(null);

  const uploadDocumentHandler = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    e.persist();
    const [file] = e.target.files;
    if (file) {
      setForm({
        ...form,
        attachment: file,
      });
      //   uploadBiolinkCoverPhoto({
      //     variables: {
      //       coverPhoto: file,
      //       id: userData.id,
      //     },
      //   });
    }
  };

  const sendMessageHandler = () => {
    if (form.attachment) {
      sendMessage({
        variables: {
          receiverId,
          message: form.message,
          attachment: form.attachment,
        },
      });
    } else {
      sendMessage({
        variables: {
          receiverId,
          message: form.message,
        },
      });
    }
  };

  useEffect(() => {
    if (data?.sendMessage.errors === null) {
      props.history.push({
        pathname: `/app/inbox`,
        params: { otherUserId: receiverId },
      });
    }
  }, [data]);

  return (
    // <div className="main-panel">
    <div className="content">
      <div className="page-inner">
        <div className="row mt--2">
          <div className="col-md-12">
            <div className="card full-height">
              <div className="singal-chat-box chat">
                <div className="chat-header clearfix">
                  <a
                    // href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#view_info"
                    className="chat-user-img"
                  >
                    <img src="../assets/img/avatar2.png" alt="avatar" />
                  </a>
                  <div className="chat-about">
                    {/* <h6 className="m-b-0">Aiden Chavez</h6> */}
                    <h6 className="m-b-0">{receiverUsername}</h6>

                    {/* <small>@demoqqqq</small> */}
                    {/* <small>{receiverEmail}</small> */}
                  </div>
                </div>
                <div className="chat-detail-box clearfix">
                  <h2>Start a conversation</h2>
                  <textarea
                    className="form-control"
                    id="comment"
                    rows={5}
                    placeholder="Your Message"
                    type="text"
                    value={form.message || ''}
                    name="message"
                    onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                  />

                  {/* <span onClick={() => uploadDocumentHandler()}>
                    <i className="fa fa-paperclip" />
                  </span> */}

                  {/* <span onClick={() => sendMessageHandler()}>
                    <i className="fa fa-paper-plane" />
                  </span> */}

                  <input
                    type="file"
                    name="attachment"
                    ref={hiddenFileInput}
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="btn btn-primary mx-auto mt-4 float-right"
                    onClick={() => sendMessageHandler()}
                  >
                    Continue
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary mx-auto mt-4 float-right"
                    style={{
                      marginRight: '10px !important',
                    }}
                    onClick={() => uploadDocumentHandler()}
                  >
                    <i className="fa fa-paperclip" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Message;
