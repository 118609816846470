import {useState} from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from '../../navigation/RouteWithSubRoutes';
import Dashboard from '../app/dashboard/Dashboard';
import NavBar from '../app/navbar/NavBar';
import SideBar from '../app/sidebar/SideBar';

// import Footer from '../main/footer/Footer';

const AppLayout = ({ history, location, routes, match }) => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  if (!currentBiolink && location.pathname !== '/app/account') {
    history.push('/app/account');
    return null;
  }
  return (
    <>
      <div className="wrapper">
        <NavBar />
        <SideBar />
        {match.path === '/app' && match.isExact && <Dashboard />}
        <Switch>
          {routes.map((route, index) => (
            <RouteWithSubRoutes key={index} {...route} />
          ))}
        </Switch>
      </div>
    </>
  );
};

export default AppLayout;
