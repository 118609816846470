import MainLayout from '../pages/layouts/MainLayout';
import AppLayout from '../pages/layouts/AppLayout';
import Analytics from '../pages/app/analytics/Analytics';
import PageNotFound from '../pages/main/PageNotFound';
import Login from '../pages/auth/Login';
import Referrals from '../pages/app/referrals/Referrals';
import Account from '../pages/app/account/Account';
import LinkShortner from '../pages/app/linkShortner/LinkShortner';
import Billing from '../pages/app/billing/Billing';
import Inbox from '../pages/app/inbox/Inbox';
import Directory from '../pages/main/directory/Directory';
import Feature from '../pages/main/Feature';
import Register from '../pages/auth/Register';
import Faq from '../pages/main/Faq';
import BioLink from '../pages/main/biolink/BioLink';
import Message from '../pages/main/message/Message';
import VerificationBadge from '../pages/app/verificationbadge/VerificationBadge';
import Upgrade from '../pages/app/upgrade/Upgrade';
import HowItWorks from '../pages/main/how_it_works/HowItWorks';
import TermsAndConditions from '../pages/main/terms_and_conditions/TermsAndConditions';
import ContactUs from '../pages/main/contact_us/ContactUs';
import PrivacyPolicy from '../pages/main/privacy_policy/PrivacyPolicy';
import Team from '../pages/main/team/Team';
import AboutUs from '../pages/main/about_us/AboutUs';
import Blog from '../pages/main/blog/Blog';
import Report from '../pages/main/report/Report';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';
import MyDirectory from '../pages/app/mydirectory/MyDirectory';
import PersonDirectoryDetail from '../pages/main/persondirectorydetail/PersonDirectoryDetail';
import Checkout from '../pages/app/payment/Checkout';
import Access from '../pages/app/access/Access';
import Ideas from '../pages/app/access/Ideas';
import Learnmore from '../pages/main/learnmore/Learnmore';

import Preview from '../pages/main/preview/Preview';
import Success from '../pages/main/pricing/Success';
import Cancel from '../pages/main/pricing/Cancel';
import Pricing from '../pages/main/pricing/Pricing';
import EmailVerify from '../pages/auth/EmailVerify';
import EmailVerification from '../pages/auth/EmailVerification';

const routes = [
  //   // admin panel
  {
    path: '/app',
    component: AppLayout,
    isPrivate: true,
    checkLogin: false,
    title: 'Dashboard',
    routes: [
      {
        path: '/app/analytics',
        component: Analytics,
        title: 'Analytics',
      },
      {
        path: '/app/referrals',
        component: Referrals,
        title: 'Referrals',
      },
      {
        path: '/app/account',
        component: Account,
        title: 'Account',
      },
      {
        path: '/app/billing',
        component: Billing,
        title: 'Billing',
      },
      {
        path: '/app/upgrade',
        component: Upgrade,
        title: 'Upgrade',
      },
      {
        path: '/app/payment/checkout',
        component: Checkout,
        title: 'Payment',
      },
      {
        path: '/app/Verification_badge',
        component: VerificationBadge,
        title: 'Verification Badge',
      },
      //       {
      //         path: '/app/directory',
      //         component: DirectoryApp,
      //         title: 'Directory',
      //       },

      {
        path: '/app/link_shortner',
        component: LinkShortner,
        title: 'Link Shortner',
      },
      //       {
      //         path: '/app/short_links',
      //         component: LinkShortnerList,
      //         title: 'Short Link List',
      //       },
      //       {
      //         path: '/app/person_directory_detail',
      //         component: PersonDirectoryDetail,
      //         title: 'PersonDirectory',
      //       },

      {
        path: '/app/my_directory',
        component: MyDirectory,
        title: 'My Directory',
      },
      {
        path: '/app/inbox',
        component: Inbox,
        title: 'PersonDirectory',
      },
      {
        path: '/app/access',
        component: Access,
        title: 'Access',
      },
      {
        path: '/app/ideas',
        component: Ideas,
        title: 'ideas',
      },
    ],
  },

  {
    path: '/',
    component: MainLayout,
    exact: false,
    isPrivate: false,
    isAuthenticated: true,
    title: 'Home',
    routes: [
      {
        path: '/feature',
        component: Feature,
        exact: false,
        isPrivate: false,
        title: 'Feature',
        routes: [],
      },
      {
        path: '/pricing',
        component: Pricing,
        exact: false,
        isPrivate: false,
        title: 'Pricing',
        routes: [],
      },
      {
        path: '/directory',
        component: Directory,
        exact: false,
        isPrivate: false,
        title: 'Feature',
        routes: [],
      },
      {
        path: '/contact',
        component: ContactUs,
        exact: false,
        isPrivate: false,
        title: 'Feature',
        routes: [],
      },
      {
        path: '/faq',
        component: Faq,
        exact: false,
        isPrivate: false,
        title: 'Feature',
        routes: [],
      },
      {
        path: '/how_it_works',
        component: HowItWorks,
        exact: false,
        isPrivate: false,
        title: 'Feature',
        routes: [],
      },
      {
        path: '/terms_condition',
        component: TermsAndConditions,
        exact: false,
        isPrivate: false,
        title: 'Terms & Conditions',
        routes: [],
      },
      {
        path: '/privacy_policy',
        component: PrivacyPolicy,
        exact: false,
        isPrivate: false,
        title: 'Feature',
        routes: [],
      },
      {
        path: '/person_directory_detail',
        component: PersonDirectoryDetail,
        exact: false,
        isPrivate: false,
        title: 'PersonDirectory',
        routes: [],
      },
      {
        path: '/team',
        component: Team,
        exact: false,
        isPrivate: false,
        title: 'Feature',
        routes: [],
      },
      {
        path: '/about_us',
        component: AboutUs,
        exact: false,
        isPrivate: false,
        title: 'Feature',
        routes: [],
      },
      {
        path: '/blog',
        component: Blog,
        exact: false,
        isPrivate: false,
        title: 'Feature',
        routes: [],
      },
      {
        path: '/learnmore',
        component: Learnmore,
        exact: false,
        isPrivate: false,
        title: 'learnmore',
        routes: [],
      },
      {
        path: '/report',
        component: Report,
        exact: false,
        isPrivate: false,
        title: 'Feature',
        routes: [],
      },
      {
        path: '/preview',
        component: Preview,
        exact: false,
        isPrivate: false,
        title: 'Preview',
        routes: [],
      },
      {
        path: '/auth/login',
        component: Login,
        title: 'Login',
      },
      {
        path: '/auth/register',
        component: Register,
        title: 'Register',
      },
      {
        path: '/auth/forgot_password',
        component: ForgotPassword,
        title: 'Forgot',
      },
      {
        path: '/auth/reset_password',
        component: ResetPassword,
        title: 'Reset Password',
      },
      {
        path: '/auth/email_verify',
        component: EmailVerify,
        title: 'Email Verify',
      },
      {
        path: '/auth/email_verification',
        component: EmailVerification,
        title: 'Email Verification',
      },
      {
        path: '/:username/message',
        component: Message,
        exact: false,
        isPrivate: false,
        title: 'Message',
        routes: [],
      },
      {
        path: '/:username',
        component: BioLink,
        exact: true,
        isPrivate: false,
        title: 'Feature',
        routes: [],
      },
      {
        path: '/payment/success',
        component: Success,
        exact: false,
        isPrivate: false,
        title: 'Stripe Success',
        routes: [],
      },
      {
        path: '/payment/cancel',
        component: Cancel,
        exact: false,
        isPrivate: false,
        title: 'Stripe Success',
        routes: [],
      },
    ],
  },
  //   // auth starts
  // {
  //   path: '/auth',
  //   component: AuthLayout,
  //   isPrivate: false,
  //   checkLogin: true,
  //   routes: [
  //     {
  //       path: '/auth/login',
  //       component: Login,
  //       title: 'Login',
  //     },
  //       {
  //         path: '/auth/register',
  //         component: Register,
  //         title: 'Register',
  //       },
  //       {
  //         path: '/auth/forgot_password',
  //         component: ForgotPassword,
  //         title: 'Forgot',
  //       },
  //       {
  //         path: '/auth/reset_password',
  //         component: ResetPassword,
  //         title: 'Reset Password',
  //       },
  //   ],
  // },
  //   //

  {
    path: '*',
    component: PageNotFound,
    title: 'Page Not Found',
  },
];
export default routes;
