import { useMutation } from '@apollo/client';
import React, { useEffect, useState,useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import images from '../../assets/images';
import ErrorSpan from '../../components/ui/errorSpan/ErrorSpan';
import { LOGIN_MUTATION } from '../../graphql/Mutations';
import * as appActions from '../../store/actions/app';
import * as authActions from '../../store/actions/auth';
import validate from '../../utils/validations';
import Dashboard from '../app/dashboard/Dashboard';


const Login = ({ history }) => {
  // const isLogin = useSelector((state) => state.auth.isAuthenticated);
  // if (isLogin) history.push('/app');
  const isLogin = useSelector((state) => state.auth.isAuthenticated);

  // console.log('issssss loginnn===>', isLogin);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: '',
    emailError: [],
    password: '',
    passwordError: [],
  });
  function refresh() {    
    setTimeout( () => {
        location.reload()
    }, 1000);
}
  

  const [showPassword, setShowPassword] = useState(false);


  const [login, { error, loading, data }] = useMutation(LOGIN_MUTATION);


  useEffect(() => {
    dispatch(appActions.isLoading(loading));
    // window.location.reload();
  }, [loading]);

  useEffect(() => 
  {
    // console.log('DATA_ON_CHANGES - ', JSON.stringify(data));
    // return false;
    if (data == undefined) {
      return;
    }
    const isLoginSuccess = data && data?.login?.errors === null;
    if (!isLoginSuccess) {
      const responseErrors = [];
      data.login.errors.forEach((err) => (responseErrors[err.field] = err.message));

      setForm({
        ...form,
        emailError: responseErrors.email ? [responseErrors.email] : [],
        passwordError: responseErrors.password ? [responseErrors.password] : [],
      });
    } else if (data?.login?.user?.emailVerifiedAt === null) {
      history.push('/auth/email_verify');

    } else {
      dispatch(authActions.login(data.login.user));

     refresh( history.push('/app'))

     
      
      
     
      

    }
   
      
  }, [error, data, history]);

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    const emailError = [];
    const passwordError = [];
    // add validations here
    if (!validate.emailFormat(form.email)) emailError.push('Enter valid email.');

    if (validate.minLength(form.password, 8))
      passwordError.push('Password must be atleast 8 characters long.');

    setForm({
      ...form,
      emailError,
      passwordError,
    });
    if (emailError.length || passwordError.length) return;
    // submit form
    login({
      variables: {
        email: form.email,
        password: form.password,
      },
    });
  };
  return (
    <>
      <div className="container pt-5">
        <div className="row mt--2">
          <div className="col-md-6 order2">
            <div className="signup-text signup-left-box">
              <h1>
                Single bio link for <span>everything</span>
              </h1>
              <p>
                Share and cross promote your important links with your followers and our directory
                members.
              </p>
              <div className="login-img">
                <img src={images.login_sidebar} alt="" className="sign-img " />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card full-height ">
              <div className="card-body">
                <div className="signup-text text-center mb-5">
                  <h1>
                    Sign in to <span>Stash.ee</span>
                  </h1>
                </div>
                <div className="form-signup">
                  <div className="form-group form-floating-label">
                    <input
                      id="inputFloatingLabel1"
                      type="text"
                      className="form-control input-solid inpt-sld"
                      placeholder="Enter your email in here"
                      name="email"
                      autoComplete="off"
                      value={form.email || ''}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      required
                    />
                    {/* <label htmlFor="inputFloatingLabel1" className="placeholder">
                      Your Email or Username
                    </label> */}

                    {form.emailError.length
                      ? form.emailError.map((err, i) => <ErrorSpan key={i} error={err} />)
                      : null}
                  </div>
                  <div className="form-group form-floating-label position-relative">
                    <div className="input-group-prepend position-absolute">
                      <span
                        className="input-group-text"
                        id="basic-addon1"
                        onClick={() => setShowPassword(!showPassword)}
                        onKeyDown={() => setShowPassword(!showPassword)}
                      >
                        <i
                          className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <input
                      id="inputFloatingLabel2"
                      className="form-control input-solid inpt-sld"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password in here"
                      name="password"
                      autoComplete="off"
                      value={form.password || ''}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      required
                    />
                    {/* <label htmlFor="inputFloatingLabel2" className="placeholder">
                      Enter your password in here
                    </label> */}

                    {form.passwordError.length
                      ? form.passwordError.map((err, i) => <ErrorSpan key={i} error={err} />)
                      : null}
                  </div>
                  <p className="text-right">
                    <Link to="/auth/forgot_password">Recovery passwod?</Link>
                  </p>
                  <div className="card-action text-center">
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      onClick={(evt) => onSubmitHandler(evt)}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="change-login text-center">
                    <span className="block">
                      <b>Or</b>
                    </span>
                    <p>
                      If Your Don't Have An Account?
                      <span className="text-sign">
                        You Can
                        <Link className="signup" to="/auth/register">
                          Sign Up Here!
                        </Link>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
