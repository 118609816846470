import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import makeAnimated from 'react-select/animated';

import { Editor } from '@tinymce/tinymce-react';
import 'emoji-mart/css/emoji-mart.css';
import Select from 'react-select';
import $ from 'jquery';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import { UPDATE_BIOLINK_MUTATION } from '../../../graphql/Mutations';
import { FETCH_ALL_CATEGORIES } from '../../../graphql/Queries';
import useNetwork from '../../../hooks/useNetwork';
import validate from '../../../utils/validations';

const ProfileForm = () => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [formBio, setFormBio] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const animatedComponents = makeAnimated();

  const [refresh, setRefresh] = useState(false);

  const [items, setItems] = useState([]);
  const [formTest, setFormTest] = useState({});

  const [form, setForm] = useState({
    displayName: currentBiolink.displayName,
    city: currentBiolink.city,
    state: currentBiolink.state,
    country: currentBiolink.country,
    categoryId: currentBiolink.category?.id || null,
    bio: currentBiolink.bio,
    displayNameError: [],
    cityError: [],
    stateError: [],
    countryError: [],
    categoryIdError: [],
    bioError: [],
  });

  const [updateBiolink, { data }] = useNetwork(UPDATE_BIOLINK_MUTATION);
  // const [item, setItem] = useState('');

  const { data: fetchcategoryData } = useQuery(FETCH_ALL_CATEGORIES, {
    variables: {
      first: 0,
    },
  });
  const [fetch, setFetch] = useState();

  useEffect(() => {
    if (fetchcategoryData && fetchcategoryData?.fetchAllCategories?.edges) {
      // console.log('Data Status Checking Here=========>', fetchcategoryData);
    }
    setItems(fetchcategoryData?.fetchAllCategories?.edges);
  }, [fetchcategoryData]);

  useEffect(() => {
    setFetch(items?.node);
  }, [fetch]);
  // console.log('Final TEsting Here', fetch);

  useEffect(() => {
    setFormBio(currentBiolink.bio);
  }, [currentBiolink]);

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  // componentWillUnmount() {
  //   biodetailss = $('#tinymce').text();
  //   console.log('biodetailss======>', biodetailss);
  // }

  let stripedHtml = '';
  const handleChange = (content) => {
    // console.log('content======>', content);

    $(document).ready(function () {
      stripedHtml = content.replace(/<[^>]+>/g, '');
      setFormBio(stripedHtml);
      // setForm({
      //   ...form,
      //   bio: stripedHtml,
      // });
    });
  };
  useEffect(() => {
    // console.log('formBio==========>', formBio);
  }, [formBio]);

  const handlerChange = (value2, callback) => {
    // console.log('onCHANGE INPUT CHECK', value2);
    setFormTest(value2);
  };
  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    let isValidated = true;
    const bioError = [];
    // biodetailss = $('#tinymce p').text();

    // bio_detail = $("#tinymce").text();
    // console.log('FormBio====t==>', formBio);
    if (validate.maxLength(form.bio, 200)) {
      isValidated = false;
      bioError.push('Bio cannot be more than 200 characters.');
    }

    setForm({
      ...form,
      bioError,
    });
    if (!isValidated) return;

    // submit form
    updateBiolink({
      variables: {
        displayName: form.displayName,
        city: form.city,
        state: form.state,
        country: form.country,
        // categoryId: parseFloat(form.categoryId),
        categoryId: formTest.value,
        bio: formBio,
        id: currentBiolink.id,
      },
    });
  };

  console.log('Showing the Data Here', form.displayName);
  const [chosenEmoji, setChosenEmoji] = useState(null);

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(
      showEmojiPicker,
      //   {
      //   showEmojiPicker: !showEmojiPicker,
      // }
    );
  };

  const addEmoji = (emoji) => {
    const { newMessage, setNewMessage } = useState();
    const text = `${newMessage}${emoji.native}`;

    setNewMessage({
      newMessage: form.bio,
      showEmojiPicker: true,
    });
  };

  const emojiHandler = () => {
    <Picker onEmojiClick={onEmojiClick} />;
  };

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const temp = [];
    fetchcategoryData?.fetchAllCategories?.edges?.forEach((value) => {
      if (currentBiolink?.category?.id == value.node.id) {
        setFormTest({
          label: value.node.categoryName,
          value: value.node.id,
        });
      }
      temp.push({
        label: value.node.categoryName,
        value: value.node.id,
      });
    });
    setOptions(temp);
  }, [fetchcategoryData]);

  // console.log('Testing Options Value', options);
  // console.log('check some data-----', JSON.stringify(fetchcategoryData));
  // console.log('Testinggg', value1);
  useEffect(() => {
    setTimeout(() => {
      setRefresh(!refresh);
    }, 3000);
  }, [items]);
  // console.log('CATEGORY_CHECK1', JSON.stringify(currentBiolink));

  // console.log('testttttttttttttttttttttt----', );

  // console.log('Testing The Data===', options);
  return (
    <div className="modal fade" id="modal-2">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Profile Details</h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-list">
              <div className="form-group form-floating-label">
                <input
                  type="text"
                  id="inputFloatingLabel4"
                  className="form-control input-solid"
                  name="displayName"
                  required
                  value={form.displayName || ''}
                  onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                  placeholder="Display Name"
                />
                {/* <label htmlFor="inputFloatingLabel4" className="placeholder">
                  Display Name
                </label> */}
                {form.displayNameError.length
                  ? form.displayNameError.map((err, i) => <ErrorSpan key={i} error={err} />)
                  : null}
              </div>

              <div className="form-group form-floating-label">
                {/* <textarea id="inputFloatingLabel9" className="form-control input-solid" required /> */}

                {/* ********* Final Emoji Solution in Profile Data Start Here********* */}
                
                {/* qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc */}
                <Editor
                  apiKey="wx1snplv5l77nlt0jxbaoboorl8vi807lsonbcphtdjxu70u"
                  init={{
                    plugins: 'emoticons',
                    toolbar: 'emoticons',
                    toolbar_location: 'bottom',
                    menubar: false,
                    statusbar: false,
                    height: 120,
                    selector: 'textarea', // change this value according to your HTML
                    tabfocus_elements: ':prev,:next',
                  }}
                  name="bio"
                  onEditorChange={handleChange}
                  value={formBio || ''}
                  id="newEditor"
                />

                {form.bioError.length
                  ? form.bioError.map((err, i) => <ErrorSpan key={i} error={err} />)
                  : null}
                <span className="note">upto 200 characters</span>
                {/* <label htmlFor="inputFloatingLabel9" className="placeholder">
                  Lorem Ipsum is simply dummy
                </label> */}
              </div>

              <div className="form-group form-floating-label">
                {/* <select
                  className="form-control input-solid"
                  id="selectFloatingLabel8"
                  name="categoryId"
                  onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                  value={form.categoryId || ''}
                  placeholder="Select Category"
                >
                  <option value="">&nbsp;</option>
                  {fetchcategoryData?.fetchAllCategories?.edges?.map((value, index) => {
                    return (
                      <option key={index} value={value.node.id}>
                        {value.node.categoryName}
                      </option>
                    );
                  })}
                </select> */}

                <Select
                  id="selectFloatingLabel8"
                  name="value2"
                  placeholder="Select Category"
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  inputValue={inputValue?.node}
                  onChange={handlerChange}
                  options={options}
                  value={formTest}
                  // getOptionLabel={(option) => option}
                  getOptionValue={(option) => option}
                />
                {/* {options.map((value) => {
                    return <options>{value.label}</options>;
                  })}
                </Select> */}

                {/* {options.node}
                </Select> */}
                {/* <label htmlFor="selectFloatingLabel8" className="placeholder">
                  Select Category
                </label> */}
                {form.categoryIdError.length
                  ? form.categoryIdError.map((err, i) => <ErrorSpan key={i} error={err} />)
                  : null}
              </div>

              <div className="form-group form-floating-label">
                <input
                  type="text"
                  id="inputFloatingLabel5"
                  className="form-control input-solid"
                  required
                  name="city"
                  value={form.city || ''}
                  onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                  placeholder="City"
                />
                {/* <label htmlFor="inputFloatingLabel5" className="placeholder">
                  City
                </label> */}
                {form.cityError.length
                  ? form.cityError.map((err, i) => <ErrorSpan key={i} error={err} />)
                  : null}
              </div>

              <div className="form-group form-floating-label">
                <input
                  type="text"
                  id="inputFloatingLabel6"
                  className="form-control input-solid"
                  required
                  name="state"
                  value={form.state || ''}
                  onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                  placeholder="State"
                />
                {/* <label htmlFor="inputFloatingLabel6" className="placeholder">
                  State
                </label> */}
                {form.stateError.length
                  ? form.stateError.map((err, i) => <ErrorSpan key={i} error={err} />)
                  : null}
              </div>

              <div className="form-group form-floating-label">
                <input
                  type="text"
                  id="inputFloatingLabel7"
                  className="form-control input-solid"
                  required
                  name="country"
                  value={form.country || ''}
                  onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                  placeholder="Country"
                />
                {/* <label htmlFor="inputFloatingLabel7" className="placeholder">
                  Country
                </label> */}
                {form.countryError.length
                  ? form.countryError.map((err, i) => <ErrorSpan key={i} error={err} />)
                  : null}
              </div>

              <button
                type="button"
                className="btn btn-primary"
                onClick={onSubmitHandler}
                onKeyUp={onSubmitHandler}
                data-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>

          <div className="modal-footer">
            <a href="#modal-1" data-toggle="modal" data-dismiss="modal" className="btn btn-default">
              Previous
            </a>
            <button type="button" data-dismiss="modal" className="btn btn-danger">
              Close
            </button>
          </div>
        </div>
        {/* <!-- /.modal-content --> */}
      </div>
      {/* <!-- /.modal-dialog --> */}
    </div>
  );
};

export default ProfileForm;
