import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useHandleResponse from '../useHandleResponse';
import { CHANGE_USER_ACCOUNT_INFO_MUTATION } from '../../graphql/Mutations';
import * as appActions from '../../store/actions/app';

const changeUserAccountInfo = () => {
  const dispatch = useDispatch();
  const [changeUserAccountInfoMutation, { loading, data }] = useMutation(
    CHANGE_USER_ACCOUNT_INFO_MUTATION,
  );
  const { successMessage, errorMessage } = useHandleResponse();

  useEffect(() => {
    dispatch(appActions.isLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (data?.changeUserAccountInfo?.errors === null) {
      successMessage();
    }
    if (data?.changeUserAccountInfo?.errors?.length) {
      errorMessage(data?.changeUserAccountInfo?.errors);
    }
  }, [data]);

  return [
    changeUserAccountInfoMutation,
    {
      data,
    },
  ];
};

export default changeUserAccountInfo;
