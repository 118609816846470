import { IS_LOADING } from '../actions/app';

const initialState = {
  isLoading: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return {
        isLoading: action.changeTo,
      };
    default:
      return state;
  }
};

export default appReducer;
