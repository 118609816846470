import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import generateRandomId from '../../utils/genrateRandomId';
import * as alertActions from '../../store/actions/alert';

import { REGISTER_MUTATION } from '../../graphql/Mutations';
import validate from '../../utils/validations';
import ErrorSpan from '../../components/ui/errorSpan/ErrorSpan';
import * as authActions from '../../store/actions/auth';
import images from '../../assets/images';

const Register = ({ history, location }) => {
  const isLogin = useSelector((state) => state.auth.isAuthenticated);
  if (isLogin) history.push('/app');
  const dispatch = useDispatch();

  const search = location.search.replace('?', '').split('&');

  const codeParameter = [];

  search.forEach((value) => {
    const parameter = value.split('=');
    codeParameter[parameter[0]] = parameter[1];
  });

  const [form, setForm] = useState({
    referralToken: codeParameter.code ? codeParameter.code : '',
    referralTokenError: [],
    name: '',
    nameError: [],
    email: '',
    emailError: [],
    password: '',
    passwordError: [],
    confirmPassword: '',
    confirmPasswordError: [],
  });

  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [registerUser, { loading, data }] = useMutation(REGISTER_MUTATION);

  useEffect(() => {
    if (data) {
      if (data.registerUser.user) {
        // dispatch(authActions.authenticate(data.registerUser.user, true));
        // history.push('/app');
        // history.push('/auth/email_verify');

        setForm({
          referralToken: '',
          referralTokenError: [],
          name: '',
          nameError: [],
          email: '',
          emailError: [],
          password: '',
          passwordError: [],
          confirmPassword: '',
          confirmPasswordError: [],
        });

        dispatch(
          alertActions.addAlert({
            id: generateRandomId(),
            message: 'Register Successfully.',
            type: 'success',
          }),
        );
      }

      if (data.registerUser.errors) {
        const responseErrors = [];
        data.registerUser.errors.forEach((err) => {
          // console.log('err.field=====>', err.field);
          if (err.field) {
            responseErrors[err.field] = err.message;
          }
          if (!err.field) {
            responseErrors.referralToken = err.message;
          }
        });

        setForm({
          ...form,
          nameError: responseErrors.username ? [responseErrors.username] : [],
          emailError: responseErrors.email ? [responseErrors.email] : [],
          passwordError: responseErrors.password ? [responseErrors.password] : [],
          referralTokenError: responseErrors.referralToken ? [responseErrors.referralToken] : [],
        });
      }
    }
  }, [loading, data, history]);

  const inputChangeHandler = (name, value) => {
    // add validations here
    // update values
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    const nameError = [];
    const emailError = [];
    const passwordError = [];
    const confirmPasswordError = [];
    // add validations here
    if (!consent) {
      setConsentError(true);
    }
    if (!form.name) nameError.push('Name cannot be empty');
    if (!validate.emailFormat(form.email)) emailError.push('Enter valid email.');
    if (validate.minLength(form.password, 8) || form.password == '') {
      passwordError.push('Password must be atleast 8 characters long');
    }
    if (form.confirmPassword == '') {
      confirmPasswordError.push('Confirm Password must be atleast 8 characters long');
    }

    if (form.password !== form.confirmPassword) {
      confirmPasswordError.push('Password did not matched.');
    }

    setForm({
      ...form,
      nameError,
      emailError,
      passwordError,
      confirmPasswordError,
    });

    if (
      nameError.length ||
      emailError.length ||
      passwordError.length ||
      confirmPasswordError.length ||
      consentError
    )
      return;
    // submit form
    registerUser({
      variables: {
        referralToken: form.referralToken,
        name: form.name,
        email: form.email,
        password: form.password,
      },
    });
  };

  return (
    <div className="">
      <div className="container-fluid signup-section">
        <div className="row">
          <div className="col-md-6 order2">
            <div className="new-sec">
              <h1>A Single Landing Page Customized By You</h1>
              <p className>
                Stash.ee puts you in control of how your profile looks so you can be on brand and on
                purpose.
              </p>
              <div className="login-img">
                <img src={images.register_bg} alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="full-height ">
              <div className="card-body">
                <div className="signup-text" />
                <div className="form-signup form-sec1">
                  <h1>Sign in to Stash.ee</h1>
                  <div className="form-group form-floating-label">
                    <input
                      id="inputFloatingLabel1"
                      type="text"
                      className="form-control input-solid inpt-sld"
                      required
                      placeholder="Enter your username here"
                      name="name"
                      autoComplete="off"
                      value={form.name}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {/* <label htmlFor="inputFloatingLabel1" className="placeholder">
                      Enter your username here
                    </label> */}

                    {form.nameError.length
                      ? form.nameError.map((error, i) => <ErrorSpan key={i} error={error} />)
                      : null}
                  </div>
                  <div className="form-group form-floating-label">
                    <input
                      id="inputFloatingLabel2"
                      type="text"
                      className="form-control input-solid inpt-sld"
                      name="email"
                      placeholder="Your Email"
                      autoComplete="off"
                      value={form.email}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      required
                    />
                    {/* <label htmlFor="inputFloatingLabel2" className="placeholder">
                      Your Email
                    </label> */}
                    {form.emailError.length
                      ? form.emailError.map((error, i) => <ErrorSpan key={i} error={error} />)
                      : null}
                  </div>
                  <div className="form-group form-floating-label position-relative">
                    <div className="input-group-prepend position-absolute">
                      <span
                        className="input-group-text"
                        id="basic-addon1"
                        onClick={() => setShowPassword(!showPassword)}
                        onKeyDown={() => setShowPassword(!showPassword)}
                      >
                        <i
                          className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <input
                      id="inputFloatingLabel3"
                      className="form-control input-solid inpt-sld"
                      required
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password here"
                      name="password"
                      autoComplete="new-password"
                      value={form.password}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {/* <label htmlFor="inputFloatingLabel3" className="placeholder">
                      Enter your password here
                    </label> */}

                    {form.passwordError.length
                      ? form.passwordError.map((error, i) => <ErrorSpan key={i} error={error} />)
                      : null}
                  </div>
                  <div className="form-group form-floating-label position-relative">
                    <div className="input-group-prepend position-absolute">
                      <span
                        className="input-group-text"
                        id="basic-addon1"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        onKeyDown={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        <i
                          className={showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <input
                      id="inputFloatingLabel4"
                      className="form-control input-solid inpt-sld"
                      required
                      type={showConfirmPassword ? 'text' : 'password'}
                      placeholder="Re-enter password here"
                      name="confirmPassword"
                      // autoComplete="new-password"
                      autoComplete="off"
                      value={form.confirmPassword}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {/* <label htmlFor="inputFloatingLabel4" className="placeholder">
                      Re-enter password here
                    </label> */}
                    {form.confirmPasswordError.length
                      ? form.confirmPasswordError.map((error, i) => (
                          <ErrorSpan key={i} error={error} />
                        ))
                      : null}
                  </div>
                  <div className="form-group form-floating-label">
                    <input
                      id="inputFloatingLabel5"
                      type="text"
                      className="form-control input-solid inpt-sld"
                      required
                      placeholder="Enter the referral code"
                      name="referralToken"
                      autoComplete="off"
                      value={form.referralToken}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {/* <label htmlFor="inputFloatingLabel5" className="placeholder">
                      Enter the referral code
                    </label> */}
                    {form.referralTokenError.length
                      ? form.referralTokenError.map((error, i) => (
                          <ErrorSpan key={i} error={error} />
                        ))
                      : null}
                  </div>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue
                        id="exampleCheck6"
                        defaultChecked={consent}
                        onChange={() => {
                          setConsentError(consent);
                          setConsent(!consent);
                        }}
                      />
                      <span className="form-check-sign">
                        I have read and agree to the Terms of Service
                      </span>
                    </label>
                    {consentError ? <ErrorSpan error="Adhere the consent." /> : null}
                  </div>
                  <div className="card-action text-center">
                    <button
                      className="btn btn-primary btn-block"
                      type="button"
                      onClick={(evt) => onSubmitHandler(evt)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
