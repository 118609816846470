import { useLazyQuery, useQuery } from '@apollo/client';
import { TextField } from '@material-ui/core';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Autocomplete } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DirectoryCarousel from '../../components/ui/carousel/directory/DirectoryCarousel';
import {
  FETCH_ALL_CATEGORIES,
  GET_ALL_DIRECTORIES,
  GET_ALL_PLANS,
  GET_SEARCH_QUERIES,
} from '../../graphql/Queries';
import * as appActions from '../../store/actions/app';
import Claim from './Claim';
import * as authActions from '../../store/actions/auth';
import images from '../../assets/images';

const Home = () => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.auth.isAuthenticated);
  const limit = isLogin ? 12 : 30;
  const [categoryArray, setCategoryArray] = useState([]);
  const [categoryUpdated, setCategoryUpdated] = useState(false);
  const [searchUpdated, setSearchUpdated] = useState(false);
  const [isSectionName, setIsSectionName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [validationCate, setValidationCate] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [items, setItems] = useState([]);
  const [value1, setValue1] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [autoSearchValue, setAutoSearchValue] = useState('');

  const { data: plansData } = useQuery(GET_ALL_PLANS);

  useEffect(() => {
    if (plansData?.getAllPlans?.errors === null) {
      dispatch(authActions.updatePlans(plansData.getAllPlans.plans));
    }
  }, [plansData]);

  let dynamic = '';
  let queryData = '';
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
    endCursor: '',
  });

  const { data, fetchMore } = useQuery(FETCH_ALL_CATEGORIES, {
    variables: {
      first: 0,
    },
  });

  const {
    data: directoryData,
    loading: directoryLoading,
    refetch: directoryRefetch,
    fetchMore: directoryFetchMore,
  } = useQuery(GET_ALL_DIRECTORIES, {
    variables: {
      first: limit,
    },
  });

  const [
    getSearchQueries,
    {
      data: directorySeachData,
      // loading: directorySeachLoading,
      // refetch: directorySeachRefetch,
      // fetchMore: directorySeachFetchMore,
    },
  ] = useLazyQuery(
    GET_SEARCH_QUERIES,
    {
      variables: {
        query: autoSearchValue,
      },
    },
    // {
    //   fetchPolicy: 'cache-and-network',
    // },
  );

  useEffect(() => {
    if (directorySeachData?.getSearchQueries?.results.length) {
      const tempitem = [];
      directorySeachData?.getSearchQueries?.results?.forEach((value, index) => {
        tempitem.push(value);
      });
      setItems(tempitem);
    }
  }, [directorySeachData]);

  useEffect(() => {
    setTimeout(() => {
      setRefresh(!refresh);
    }, 3000);
  }, [items]);

  useEffect(() => {
    directoryRefetch();
  }, []);

  useEffect(() => {
    if (!directoryData?.errors) {
      if (directoryData?.getAllDirectories?.pageInfo) {
        setPagination({
          hasNextPage: directoryData.getAllDirectories.pageInfo.hasNextPage,
          hasPreviousPage: directoryData.getAllDirectories.pageInfo.hasPreviousPage,
          startCursor: directoryData.getAllDirectories.pageInfo.startCursor,
          endCursor: directoryData.getAllDirectories.pageInfo.endCursor,
        });
      }
    }
    if (isLoadMore) dispatch(appActions.isLoading(directoryLoading));
  }, [directoryData, directoryLoading]);

  const loadMoreSearch = () => {
    // if (autoSearchValue) {
    getSearchQueries({
      variables: {
        query: autoSearchValue,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult;
      },
    });
    // }
  };

  const loadMore = () => {
    if (isSectionName == '') {
      // In case empty category & empty seach
      dynamic = pagination.endCursor;
      queryData = '';
    }
    if (isSectionName == 'Search') {
      dynamic = pagination.endCursor;
      queryData = searchQuery;
    }
    if (isSectionName == 'Category') {
      dynamic = pagination.endCursor;
      queryData = searchQuery;
    }

    directoryFetchMore({
      variables: {
        categoryId: categoryArray.length ? categoryArray : null,
        query: queryData, // Directory name
        after: isLoadMore == true ? dynamic : '',
        first: limit,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if ((isSectionName == 'Search' || isSectionName == 'Category') && isLoadMore == false) {
          fetchMoreResult.getAllDirectories.edges = [...fetchMoreResult.getAllDirectories.edges];
        } else {
          fetchMoreResult.getAllDirectories.edges = [
            ...previousResult.getAllDirectories.edges,
            ...fetchMoreResult.getAllDirectories.edges,
          ];
        }
        return fetchMoreResult;
      },
    });
  };

  useEffect(() => {
    // if (autoSearchValue) {
    const SearchData = loadMoreSearch();
    // SearchData?.data.getSearchQueries.directories;
    // }
  }, [autoSearchValue]);

  useEffect(() => {
    if (isLoadMore == true) {
      loadMore();
    }
  }, [isLoadMore]);

  const onClickCategory = (id, catetype = null) => {
    // check if alredy exist
    const updatedCategoryArray = [...categoryArray];
    const index = updatedCategoryArray.indexOf(id);
    if (index !== -1) {
      // remove
      updatedCategoryArray.splice(index, 1);
    } else {
      // add
      if (updatedCategoryArray.length === 3) {
        setValidationCate('You can not selete more than 3 category');
        return;
      }
      updatedCategoryArray.push(id);
    }
    setCategoryUpdated(true);
    setCategoryArray(updatedCategoryArray);
    setValidationCate('');
    setIsLoadMore(false);
  };

  useEffect(() => {}, [validationCate]);

  useEffect(() => {
    setCategoryUpdated(false);
    setIsSectionName('Category');
    if (categoryUpdated) {
      loadMore();
    }
  }, [categoryArray]);

  const onSearchDirectory = (e) => {
    setIsLoadMore(false);
    setIsSectionName('Search');
    if (e.target.value) {
      setSearchQuery(e.target.value);
    } else {
      setIsSectionName('');
      setSearchQuery('');
    }
    setSearchUpdated(true);
  };

  useEffect(() => {
    setSearchUpdated(false);
    if (searchUpdated) {
      loadMore();
    }
  }, [searchQuery]);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const temp = [];
    data?.fetchAllCategories?.edges?.forEach((value) => {
      temp.push({
        label: value.node.categoryName,
        value: value.node.id,
      });
    });
    setOptions(temp);
  }, [data]);

  const handleOnSelect = (item) => {
    // the item selected

    if (item == null) {
      item = '';
    }
    setValue1(item);
    setIsLoadMore(false);
    setIsSectionName('Search');
    setSearchUpdated(true);
    setSearchQuery(item);
    // setSearchUpdated(false);
    // if (item.name) {
    //   loadMore();
    // }
  };

  const handleOnSearch = (string) => {
    if (string == null) {
      string = '';
    }
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setInputValue(string);
    setSearchQuery(string);
    setAutoSearchValue(string);
  };

  return (
    <>
      <div className="testimonial-wrapper home-banner mb-2">
        <div className="container-fluid">
          <div className="row home-banner-row">
            <div className="col-md-6 col-sm-12 how-work-left pt-5">
              <div className="how-work-left-body">
              <div className="black-text heading-area ">
                <h1 className="home-left-title">
                  <b>The <span className="color-bio"> Bio Link </span> That Connects All of Them to All of You</b>
                </h1>
              </div>
              <p className="lead mb-0 mt-4">
                <Link className="btn btn-primary mr-3" to="/auth/register">
                  <span>CLAIM YOUR USERNAME</span>
                </Link>

                <Link className="btn btn-blue btn-border blue" to="/auth/register">
                  Try Now For Free
                </Link>
              </p>

              <div className="home-page-social mt-5 ">
                <p className="mb-1">Or Share this:</p>
                <div className="home-banner-link">
                <a href="#">
                  <i className="fa fa-facebook" aria-hidden="true" />
                </a>
                <a href="#">
                  <i className="fa fa-twitter" aria-hidden="true" />
                </a>
                <a href="#">
                  <i className="fa fa-pinterest" aria-hidden="true" />
                </a>

                <a href="#">
                  <i className="fa fa-whatsapp" aria-hidden="true" />
                </a>
                <a href="#">
                  <i className="fa fa-link" aria-hidden="true" />
                </a>
                </div>
              </div>
            </div>
            </div>
            <div className="col-md-6 col-sm-12 text-right home-right-col">
            <img src={images.homeBanner} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="home-bg-color">
      <div className="container ">
        <div className="row mt--2  home-how-it-work">
          <div className="card full-height mb-0 home-how-it-work-body">
            <div className="center-heading black-text heading-area">
              <div className="sub-heading">Working Process</div>
              <h1>How It Works ?</h1>
            </div>
            <div className="card-body">
              <section className="feature-wrapper section-space pt-0">
                <div className="row feature-row">
                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="home-img">
                      <img src={images.claimUsername} alt="" />
                      </div>
                      <div className="detail-section">
                        <h4>Easy to Claim Username</h4>
                        <p>Secure your username and receive your personal linby.io landing page.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="home-img">
                      <img src={images.landingPage} alt="" />
                      </div>
                      <div className="detail-section">
                        <h4>Customize Your Landing Page</h4>
                        <p>
                          Add links to your websites, integrate your social media pages, and embed
                          headlines, videos, and contact buttons.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="box-wrapper">
                      <div className="home-img">
                      <img src={images.yourBio} alt="" />
                      </div>
                      <div className="detail-section">
                        <h4>Build Your Bio</h4>
                        <p>Tell the world who you are, what you do, and what you offer. </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="home-bg-color">
      <div className="testimonial-wrapper ">
        <div className="container home-user-review">
          <div className="center-heading black-text heading-area ">
            <h1>User Review</h1>
          </div>
          <div className="carousel-item active">
            <div className="row">
            <div className="col-md-4">
                      <div className="user-review-img">
                      <img src="https://i.imgur.com/lE89Aey.jpg" alt="" />
                      </div>
                      <div className="user-review-sec-img">
                     .
                      </div>
                      </div>
                    <div className="col-md-8">
                      <div className="carousel-caption">
                        <p>
                          If Shai Reznik's TDD videos don't convince you to add automated testing your code, I
                          don't know what will.This was the very best explanation of frameworks for brginners
                          that I've ever seen.
                        </p>
                        <div id="image-caption">Nick Doe</div>
                      </div>
                      </div>
            </div>
          </div>
        </div>
       
      </div>
      </div>
      <div className="home-bg-color">
      <div className="container design-artist-section">
        <div className="card-body">
          <div className="image-view">
          <div className="design-artist-row">
            <div className="row">
               <div className="col-md-6 ">
               <div className="designer-text">
               <h2>Designer</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam,
                   nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </p>
                </div>
               </div>
               <div className="col-md-6">
                <div className="designer-img">
                <img src={images.homeDesigner} alt="" />
                </div>
              </div>
            </div>
            </div>
            <div className="design-artist-row">
            <div className="row">
               <div className="col-md-6 home-order">
               <div className="artist-img">
                <img src={images.homeArtist} alt="" />
                </div>
               </div>
               <div className="col-md-6">
               <div className="designer-text">
                 <h2>Artist</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam,
                   nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </p>
                </div>
              </div>
            </div>
            </div>
            {/* <img
              src="https://avyatated.com/static/media/designer.b510e746.svg"
              alt=""
              className="designer-img"
            />
            <img
              src="https://avyatated.com/static/media/artist.8470f5ab.svg"
              alt=""
              className="artist-img"
            /> */}
          </div>
        </div>
        <div className="col-md-12 bg-main-directory">
          <div className="card-body">
            <div className="home-main-directory">
          <div className="center-heading black-text">
            <h1>Search People By Directory</h1>
          </div>
            <div className="form-list w-50 mobile-full-width mx-auto">
            <i className="fa fa-search search-icon" />
              {/* <form className="navbar-left navbar-form nav-search mr-md-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <button type="submit" className="btn btn-search pr-1">
                      <i className="fa fa-search search-icon" />
                    </button>
                  </div>
                  <input
                    type="text"
                    placeholder="Write your favarioute tag"
                    className="form-control"
                  />

                  
                </div>
              </form> */}
              <Autocomplete
                placeholder="Search Here"
                value={value1}
                onChange={(event, newValue) => handleOnSelect(newValue)}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => handleOnSearch(newInputValue)}
                id="controllable-states-demo"
                options={items}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
            </div>

            {validationCate ? <div className="categories_class">{validationCate}</div> : null}
            <div className="all-tab-area">
              {data?.fetchAllCategories?.edges?.map((value, index) => {
                return (
                  <a
                    key={index}
                    className={categoryArray.includes(value.node.id) ? 'selected' : ''}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onClickCategory(value.node.id)}
                  >
                    {value.node.categoryName}
                  </a>
                );
              })}
            </div>
            </div>
            {directoryData?.getAllDirectories?.edges?.length ? (
              <DirectoryCarousel directoryData={directoryData?.getAllDirectories} />
            ) : (
              <h2 className="no-record">No record found</h2>
            )}
            {/* <span className="directory-learn-btn">
              <Link to="/learnmore" className="go_to_biolink btn btn-primary">
                Learnmore
              </Link>
            </span> */}
          
          </div>
        </div>
        <Claim />
      </div>
      </div>
    </>
  );
};

export default Home;
