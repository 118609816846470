import React from 'react';

const PrivacyPolicy = (props) => {
  return (
    <>
      {' '}
      <div className="container pt-5">
        <div className="row mt--2">
          <div className="col-md-12">
            <div className="card full-height">
              <div className="center-heading black-text heading-area">
                <div className="sub-heading mt-0">THIS IS STASH.EE</div>
                <h1>Privacy Policy</h1>
              </div>
              <div className="card-body">
                <section className=" section-space pt-3 pb-0">
                  <div className="policy-area">
                    <h4>Overview</h4>
                    <p>
                      Our Linkby web platform, herein referred to as the “Platform”, provides a
                      digital web service allowing users to share curated links and content on their
                      own personalized pages. Our Privacy Policy informs both users and visitors of
                      our policies regarding the collection, use, and disclosure of personal
                      information for anyone accessing the Platform.
                    </p>
                    <p>
                      If you choose to use the Platform, then you agree to the collection and use of
                      information in relation to this policy. The information we collect is used for
                      providing and enhancing the Platform. We will not use or share your
                      information with anyone except as described in this Privacy Policy. We do not
                      sell your personal information under any circumstances.
                    </p>
                  </div>
                  <div className="policy-area">
                    <h4>Definitions</h4>
                    <ul>
                      <li>
                        Personal Data:
                        <p>
                          information that identifies, relates to, describes, is capable of being
                          associated with, or could reasonably be linked, directly or indirectly,
                          with a particular user
                        </p>
                        <p>
                          any and all activities relating to the use of your personal data by the
                          Platform such as collection, storage, and deletion
                        </p>
                      </li>
                      <li>
                        Cookies:
                        <p>
                          files containing a small amount of data that are commonly used as
                          anonymous unique identifiers
                        </p>
                      </li>
                    </ul>
                    <p>
                      Cookies are sent to your browser from the websites you visit and are stored on
                      your device's internal memory. You can instruct your browser to deny cookies
                      or to prompt when a cookie is being sent. However, without accepting cookies
                      you might not be able to use some features of the Platform. Examples of
                      cookies the Platform may utilize include session cookies that remembers a
                      user's logged-in status, or from third-party service providers as outlined
                      below.
                    </p>
                  </div>
                  <div className="policy-area">
                    <h4>Information Processing</h4>
                    <p>We process data using the following principles:</p>
                    <ul>
                      <li>
                        <p>
                          obey the law; only process personal data in a way that people would
                          reasonably expect; always be open about our data protection practices
                        </p>
                      </li>
                      <li>
                        <p>
                          Purpose limitation: process personal data for the specific reason it was
                          collected for
                        </p>
                      </li>
                      <li>
                        <p>Data minimization: don't process any more data than needed</p>
                      </li>
                      <li>
                        <p>
                          Accuracy: ensure personal data is as adequate and accurate as possible
                        </p>
                      </li>
                      <li>
                        <p>Storage limitation: don't store personal data longer than necessary</p>
                      </li>
                      <li>
                        <p>
                          Integrity and confidentiality: always process personal data securely to
                          the best of our ability
                        </p>
                      </li>
                    </ul>
                    <p>
                      We store personal data that is provided voluntarily to us which may include
                      email addresses, hashed passwords, feedback, page content, and subscription
                      details for users of the Platform. General log data may also be generated in
                      certain cases for site maintenance purposes, which could include but is not
                      limited to browser details, timestamps, and referring page information. This
                      data is stored in addition to non-personally identifying data on our servers
                      which include aggregated daily page views and link click counts for user
                      generated pages existing on the Platform.
                    </p>
                    <p>
                      We collect data through the general use of the Platform, and through
                      third-party services such as Help Scout. The data we collect is to provide and
                      maintain the services of the Platform. We utilize trusted third-party service
                      providers as outlined below that process information for payments, support,
                      and security as long as those parties agree to keep such information
                      confidential. Additionally, we may use information from service providers for
                      purposes such as retargeted marketing. We do not sell or trade personally
                      identifiable information to third-parties.
                    </p>
                    <p>
                      In specific cases we may need to disclose personal data when we believe it is
                      necessary to comply with the law, enforce the Terms of Service for our site,
                      or protect the legal rights of ourselves or others.
                    </p>
                    <p>We only process data for the following reasons:</p>
                    <ul>
                      <li>
                        <p>Consent: earned permission in a GDPR-compliant way when applicable</p>
                      </li>
                      <li>
                        <p>Contract: needed to fulfill a contract such as our terms of service</p>
                      </li>
                      <li>
                        <p>Legal obligation:would be breaking the law if not processed</p>
                      </li>
                      <li>
                        <p>Vital interests: a person's life depends on it</p>
                      </li>
                      <li>
                        <p>Public task:needed to carry out a task in the public interest</p>
                      </li>
                      <li>
                        <p>
                          Legitimate interests:determined to be in our interests following a
                          Legitimate Interests Assessment
                        </p>
                      </li>
                    </ul>
                    <p>
                      Data that hasn't been explicitly removed or replaced by the user is typically
                      stored for the duration of the account or page. Full pages and accounts that
                      are deleted by the user remain stored in an inactive state for a brief period
                      of time until being permanently removed from our system. This policy is to
                      help safeguard against cases of accidental or malicious deletion. You can
                      request to have your data erased immediately by contacting us at
                    </p>
                  </div>
                  <div className="policy-area">
                    <h4>Service Providers</h4>
                    <p>
                      This payment processor follows the standards set by PCI-DSS as managed by the
                      PCI Security Standards Council, which helps to ensure the secure handling of
                      payment information. Our integration also meets the European standards for
                      PSD2 and SCA compliance.
                    </p>
                    <p>
                      wherein your role is referred to as the end user. Help Scout maintains ongoing
                      Level 1 PCI and HIPPA compliance to ensure the security of data they process.
                    </p>
                    <p>
                      Our on-page Help features, Help Docs, and support inboxes integrate the
                      services of Help Scout in order to better serve the needs of users looking for
                      assistance with the Platform. You can review their Privacy Policy
                    </p>
                    <p>
                      The Platform and some of the user generated pages use retargeting services
                      provided by Facebook Pixel to advertise to you on third-party platforms after
                      visiting or interacting with specific pages on the site. You can learn more
                      about this type of interest-based advertising
                    </p>
                    <p>
                      The Platform uses the reverse proxy and web application firewall services
                      provided by Cloudflare to protect against Distributed Denial of Service (DDoS)
                      and other attacks on our infrastructure. In order to provide these services
                      Cloudflare processes log data from end users in accordance with their Privacy
                      Policy found
                    </p>
                  </div>
                  <div className="policy-area">
                    <h4>Rights to Access</h4>
                    <p>
                      As the consumer, and in accordance with the CCPA, you have the right to
                      request personal data stored on the Platform in an easily accessible format
                      regarding your account. This includes the information we collect, what we use
                      the information for, and which third-party service providers we utilize in
                      order to process data and maintain the Platform's services.
                    </p>
                    <p>
                      You have the right to request deletion of personal data that we hold with the
                      following exceptions: to provide the Platform's services to the consumer,
                      detect or resolve security or functionality-related issues, comply with the
                      law, conduct research in the public interest, safeguard the right to free
                      speech, or carry out any actions for internal purposes that the consumer might
                      reasonably expect.
                    </p>
                    <p>
                      Specifically for having exercised the rights above, you have the right to not
                      be discriminated against including: denied services to the Platform, charged
                      different prices for services, provided with a different quality of service,
                      or threatened with any of the prior.
                    </p>
                    <p>
                      Under the jurisdiction of the GDPR, you have the following rights over your
                      data: to be informed, access, rectification, erasure (known as 'the right to
                      be forgotten'), restrict processing, data portability, objection, and rights
                      in relation to automated decision-making.
                    </p>
                    <p>
                      To exercise any of the rights above you can contact us at support@stash.ee
                    </p>
                  </div>
                  <div className="policy-area">
                    <h4>Security</h4>
                    <p>
                      We have taken numerous measures to ensure the security of the Platform by
                      minimizing the amount of data we collect and following applicable security
                      standards including utilizing the services of Cloudflare as outlined above
                      under Service Providers. Subscription payment methods are processed securely
                      by Stripe, and not stored on our servers. Our servers are regularly kept up to
                      date with security and bug fixing patches. Although no method of transmission
                      over the Internet can be guaranteed to have absolute security due to its
                      inherent nature, we take the security of the Platform and users very seriously
                      and strive to continuously improve it any way we can.
                    </p>
                  </div>
                  <div className="policy-area">
                    <h4>Links to Other Sites</h4>
                    <p>
                      Due to the link sharing nature of the Platform, pages hosted the Platform will
                      frequently link to third-party websites. If you click on a third-party link,
                      you will be directed to that site. Note that these external sites are not
                      operated by us. Therefore, we strongly advise you to review the Privacy Policy
                      of these websites.
                    </p>
                    <p>
                      We have no control over and assume no responsibility or liability for the
                      content, privacy policies, or practices of any third-party sites or services.
                    </p>
                  </div>
                  <div className="policy-area">
                    <h4>Children's Privacy</h4>
                    <p>
                      The Platform does not address anyone under the age of 13. We do not knowingly
                      collect personally identifiable information from children under 13 in order
                      for compliance with the requirements of the Children's Online Privacy
                      Protection Act (COPPA).
                    </p>
                    <p>
                      In the case of discovering a child under 13 has provided us with personal
                      data, we will immediately remove it from our servers. If you are a parent or
                      guardian and are aware that your child has provided us with personal data,
                      please contact us so that we will be able to take necessary action.
                    </p>
                  </div>
                  <div className="policy-area">
                    <h4>Privacy Policy Changes</h4>
                    <p>
                      We may update our Privacy Policy from time to time, at a minimum of at least
                      once per year. Therefore, you are advised to review this page periodically for
                      any changes. These changes will be effective immediately after they are
                      posted, as noted by the updated date on this page. We will not make
                      significant changes to how we process your personal data in our Privacy Policy
                      without notifying you via email.
                    </p>
                  </div>
                  <div className="policy-area">
                    <h4>Contact Us</h4>
                    <p>
                      If you have any questions or suggestions regarding our Privacy Policy, please
                      contact us at support@stash.ee
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        {/* Claim div here */}
      </div>
    </>
  );
};

export default PrivacyPolicy;
