import React from 'react';

const Cancel = () => {
  return (
    <>
      <div className=" white-box fron-end-wrapper pt-5">
        <div className="container">
          <div className="page-inner ">
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="payment-box">
                    <div className=" cancel-bar pt-3">
                      <h1> Oops! </h1>
                      <h2> Payment Cancel </h2>
                    </div>
                    <p className="payment-detail">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      Lorem Ipsum has been the industry's standard dummy text ever since the
                    </p>
                    <div className="btn-bar-bottom text-center">
                      <a href="#" className="btn btn-primary pmt-cls">
                        Try Again
                      </a>
                      <a href="#" className="btn btn-primary pmt-cls" data-dismiss="modal">
                        Close
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cancel;
