import React from 'react';
import images from '../../../assets/images';

const AboutUs = (props) => {
  return (
    <>
      <div className="container about-us-section">
        <div className="row mt--2">
          <div className="col-md-12">
            <div className="card full-height">
              {/* <div className="center-heading black-text heading-area">
                <div className="sub-heading">Who we are ?</div>
                <h1>About US</h1>
              </div> */}
              <div className="card-body">
                <section className="feature-wrapper section-space">
                  <div className="row feature-row">
                    <div className="col-md-6 order-md-2">
                      <div className="feature-image">
                        <img src={images.aboutUs1} alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 order-md-1">
                      <div className="feature-detail">
                        <h1>
                        <span>About</span> US 
                        </h1>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet posuere
                          morbi morbi at donec pretium, ligula lectus viverra. Tristique enim velit
                          vitae
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row feature-row">
                    <div className="col-md-6 order-md-1">
                      <div className="feature-image">
                        <img alt="" src={images.ourVision1} />
                      </div>
                    </div>
                    <div className="col-md-6 order-md-2">
                      <div className="feature-detail">
                        <h1>
                        <span>Our </span>Vision 
                        </h1>
                        <p>
                          Quisque quis faucibus tellus, non tincidunt urna. Pellentesque faucibus
                          aliquet mi, id efficitur nisl aliquam at. Phasellus volutpat lobortis
                          consectetur. Duis efficitur iaculis ipsum ut sodales. Phasellus suscipit
                          lectus eget sapien die lut accumsan, vitae auctor magna suscipit. Ut
                          sagittis mauris auctor dictum semifeugiat. Duis lectus neque, aliquet quis
                          justo euismod, vehicula felis. In luctus dui nec pretium.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row feature-row">
                    <div className="col-md-6 order-md-2">
                      <div className="feature-image">
                        <img src={images.ourMission1} alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 order-md-1">
                      <div className="feature-detail ">
                        <h1>
                        <span>Our </span>Mission 
                        </h1>
                        <p>
                          Quisque quis faucibus tellus, non tincidunt urna. Pellentesque faucibus
                          aliquet mi, id efficitur nisl aliquam at. Phasellus volutpat lobortis
                          consectetur. Duis efficitur iaculis ipsum ut sodales. Phasellus suscipit
                          lectus eget sapien die lut accumsan, vitae auctor magna suscipit. Ut
                          sagittis mauris auctor dictum semifeugiat. Duis lectus neque, aliquet quis
                          justo euismod, vehicula felis. In luctus dui nec pretium.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card full-height">
              {/* <div className="center-heading black-text heading-area">
                <h1>Our Team</h1>
                <div className="sub-heading mt-0 mb-4">
                  <small>
                    Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit
                    amet nisi erat.
                  </small>
                </div>
              </div> */}
               <div className="feature-detail">
                        <h1>
                        <span>Our</span> Team
                        </h1>
                        <p> Fusce placerat pretium mauris, vel sollicitudin elit lacinia vitae. Quisque sit
                    amet nisi erat.</p>
                        </div>
              <div className="card-body">
                <div className="shorter-wrapper">
                  <div className="row">
                    <div className="col-md-6">
                      <div className=" light-bg about-bottom">
                        <div className="about-body">
                        <div className="shorter-profile">
                          <img src={images.teamm} alt="" />
                        </div>
                        <div className="about-detail">
                          <h3>AMIR ALKABIR</h3>
                          <h5>VICE PRESIDENT AT NRUS CO</h5>
                          <ul className="contact-list">
                            <li>
                              <i className="fa fa-map-marker inline-icon" />
                              <p className="inline-detail">United States</p>
                            </li>
                            <li>
                              <i className="fa fa-envelope-o inline-icon" />
                              <p className="inline-detail">Email </p>
                            </li>
                            <li>
                              <i className="fa fa-phone inline-icon" />
                              <p className="inline-detail">Call </p>
                            </li>
                          </ul>
                        </div>
                        </div>
                        <div className="inline-sec social">
                              <a href="#asdf">
                                <i className="fa fa-facebook" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-twitter" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-pinterest-p" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-google-plus" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-linkedin" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-instagram" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-youtube-play" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-tumblr" aria-hidden="true" />
                              </a>
                            </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet posuere
                          morbi morbi at donec pretium, ligula lectus viverra. Tristique enim velit
                          vitae aliquam, etiam pharetra. Sit quisque tristique eget tincidunt morbi
                          vitae lacus. Accumsan risus ipsum id in nunc. Augue elit, eget ullamcorper
                          enim eget. Lectus blandit etiam maecenas dolor tristique magna.Lorem ipsum
                          dolor sit amet, consectetur adipiscing elit. Amet posuere morbi morbi at
                          donec pretium, ligula lectus viverra. Tristique enim velit vitae aliquam,
                          etiam pharetra. Sit quisque tristique eget tincidunt morbi vitae lacus.
                          Accumsan risus ipsum id in nunc. Augue elit, eget ullamcorper enim eget.
                          Lectus blandit etiam maecenas dolor tristique magna.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="light-bg about-bottom">
                      <div className="about-body">
                        <div className="shorter-profile">
                          <img src={images.team1} alt="" />
                        </div>
                        <div className="about-detail">
                          <h3>VAHID CHITSAZ</h3>
                          <h5>EXECUTIVE FIELD DIRECTOR</h5>
                          <ul className="contact-list">
                            <li>
                              <i className="fa fa-map-marker inline-icon" />
                              <p className="inline-detail">United States</p>
                            </li>
                            <li>
                              <i className="fa fa-envelope-o inline-icon" />
                              <p className="inline-detail">Email </p>
                            </li>
                            <li>
                              <i className="fa fa-phone inline-icon" />
                              <p className="inline-detail">Call </p>
                            </li>
                          </ul>
                        </div>
                        </div>
                        <div className="inline-sec social">
                              <a href="#asdf">
                                <i className="fa fa-facebook" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-twitter" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-pinterest-p" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-google-plus" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-linkedin" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-instagram" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-youtube-play" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-tumblr" aria-hidden="true" />
                              </a>
                            </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet posuere
                          morbi morbi at donec pretium, ligula lectus viverra. Tristique enim velit
                          vitae aliquam, etiam pharetra. Sit quisque tristique eget tincidunt morbi
                          vitae lacus. Accumsan risus ipsum id in nunc. Augue elit, eget ullamcorper
                          enim eget. Lectus blandit etiam maecenas dolor tristique magna.Lorem ipsum
                          dolor sit amet, consectetur adipiscing elit. Amet posuere morbi morbi at
                          donec pretium, ligula lectus viverra. Tristique enim velit vitae aliquam,
                          etiam pharetra. Sit quisque tristique eget tincidunt morbi vitae lacus.
                          Accumsan risus ipsum id in nunc. Augue elit, eget ullamcorper enim eget.
                          Lectus blandit etiam maecenas dolor tristique magna.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className=" light-bg about-bottom">
                      <div className="about-body">
                        <div className="shorter-profile">
                          <img alt="" src={images.teamm4} />
                        </div>
                        <div className="about-detail">
                          <h3>NARGUESS NOOHI</h3>
                          <h5>SERVICES PROFESSIONAL</h5>
                          <ul className="contact-list">
                            <li>
                              <i className="fa fa-map-marker inline-icon" />
                              <p className="inline-detail">United States</p>
                            </li>
                            <li>
                              <i className="fa fa-envelope-o inline-icon" />
                              <p className="inline-detail">Email </p>
                            </li>
                            <li>
                              <i className="fa fa-phone inline-icon" />
                              <p className="inline-detail">Call </p>
                            </li>
                          </ul>
                        </div>
                        </div>
                        <div className="inline-sec social">
                              <a href="#asdf">
                                <i className="fa fa-facebook" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-twitter" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-pinterest-p" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-google-plus" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-linkedin" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-instagram" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-youtube-play" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-tumblr" aria-hidden="true" />
                              </a>
                            </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet posuere
                          morbi morbi at donec pretium, ligula lectus viverra. Tristique enim velit
                          vitae aliquam, etiam pharetra. Sit quisque tristique eget tincidunt morbi
                          vitae lacus. Accumsan risus ipsum id in nunc. Augue elit, eget ullamcorper
                          enim eget. Lectus blandit etiam maecenas dolor tristique magna.Lorem ipsum
                          dolor sit amet, consectetur adipiscing elit. Amet posuere morbi morbi at
                          donec pretium, ligula lectus viverra. Tristique enim velit vitae aliquam,
                          etiam pharetra. Sit quisque tristique eget tincidunt morbi vitae lacus.
                          Accumsan risus ipsum id in nunc. Augue elit, eget ullamcorper enim eget.
                          Lectus blandit etiam maecenas dolor tristique magna.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className=" light-bg about-bottom">
                      <div className="about-body">
                        <div className="shorter-profile">
                          <img alt="" src={images.teamm2} />
                        </div>
                        <div className="about-detail">
                          <h3>WILLIAM J.KAY</h3>
                          <h5>ENTERTAINMENT LAWYER</h5>
                          <ul className="contact-list">
                            <li>
                              <i className="fa fa-map-marker inline-icon" />
                              <p className="inline-detail">United States</p>
                            </li>
                            <li>
                              <i className="fa fa-envelope-o inline-icon" />
                              <p className="inline-detail">Email </p>
                            </li>
                            <li>
                              <i className="fa fa-phone inline-icon" />
                              <p className="inline-detail">Call </p>
                            </li>
                          </ul>
                        </div>
                        </div>
                        <div className="inline-sec social">
                              <a href="#asdf">
                                <i className="fa fa-facebook" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-twitter" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-pinterest-p" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-google-plus" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-linkedin" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-instagram" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-youtube-play" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-tumblr" aria-hidden="true" />
                              </a>
                            </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet posuere
                          morbi morbi at donec pretium, ligula lectus viverra. Tristique enim velit
                          vitae aliquam, etiam pharetra. Sit quisque tristique eget tincidunt morbi
                          vitae lacus. Accumsan risus ipsum id in nunc. Augue elit, eget ullamcorper
                          enim eget. Lectus blandit etiam maecenas dolor tristique magna.Lorem ipsum
                          dolor sit amet, consectetur adipiscing elit. Amet posuere morbi morbi at
                          donec pretium, ligula lectus viverra. Tristique enim velit vitae aliquam,
                          etiam pharetra. Sit quisque tristique eget tincidunt morbi vitae lacus.
                          Accumsan risus ipsum id in nunc. Augue elit, eget ullamcorper enim eget.
                          Lectus blandit etiam maecenas dolor tristique magna.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className=" light-bg about-bottom">
                      <div className="about-body">
                        <div className="shorter-profile">
                          <img alt="" src={images.teamm3} />
                        </div>
                        <div className="about-detail">
                          <h3>JOSMAR TEJEDA</h3>
                          <h5>FAMOUS COACH</h5>
                          <ul className="contact-list">
                            <li>
                              <i className="fa fa-map-marker inline-icon" />
                              <p className="inline-detail">United States</p>
                            </li>
                            <li>
                              <i className="fa fa-envelope-o inline-icon" />
                              <p className="inline-detail">Email </p>
                            </li>
                            <li>
                              <i className="fa fa-phone inline-icon" />
                              <p className="inline-detail">Call </p>
                            </li>
                          </ul>
                        </div>
                        </div>
                        <div className="inline-sec social">
                              <a href="#asdf">
                                <i className="fa fa-facebook" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-twitter" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-pinterest-p" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-google-plus" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-linkedin" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-instagram" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-youtube-play" aria-hidden="true" />
                              </a>
                              <a href="#asdf">
                                <i className="fa fa-tumblr" aria-hidden="true" />
                              </a>
                            </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet posuere
                          morbi morbi at donec pretium, ligula lectus viverra. Tristique enim velit
                          vitae aliquam, etiam pharetra. Sit quisque tristique eget tincidunt morbi
                          vitae lacus. Accumsan risus ipsum id in nunc. Augue elit, eget ullamcorper
                          enim eget. Lectus blandit etiam maecenas dolor tristique magna.Lorem ipsum
                          dolor sit amet, consectetur adipiscing elit. Amet posuere morbi morbi at
                          donec pretium, ligula lectus viverra. Tristique enim velit vitae aliquam,
                          etiam pharetra. Sit quisque tristique eget tincidunt morbi vitae lacus.
                          Accumsan risus ipsum id in nunc. Augue elit, eget ullamcorper enim eget.
                          Lectus blandit etiam maecenas dolor tristique magna.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* claim div here */}
      </div>
    </>
  );
};

export default AboutUs;
