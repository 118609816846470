import amazon from './social-media-icons/amazon-48.png';
import amazonPrimeVideo from './social-media-icons/amazon-prime-video-64.png';
import apple_music from './social-media-icons/music-48.png';
import blogger from './social-media-icons/blogger-48 (1).png';
import comments from './social-media-icons/comments-48.png';
import delicious from './social-media-icons/delicious-48 (2).png';
import deviantart from './social-media-icons/deviantart-48 (3).png';
// import a from './social-media-icons/deviantart-48.png';
import emailOpen from './social-media-icons/email-open-48.png';
import facebook from './social-media-icons/facebook-48.png';
import github from './social-media-icons/github-48.png';
import gmail from './social-media-icons/gmail-48.png';
import googleMeet from './social-media-icons/google-meet-48.png';
import googlePlus from './social-media-icons/google-plus.png';
import html5 from './social-media-icons/html-5.png';
import instagram from './social-media-icons/instagram.png';
import linkedin from './social-media-icons/linkedin.png';
import microsoftTeams from './social-media-icons/microsoft-teams-2019-48.png';
import myspace from './social-media-icons/myspace-48.png';
import netflix from './social-media-icons/netflix-48 (5).png';
import outlook from './social-media-icons/outlook-48 (4).png';
// import a from './social-media-icons/paypal-48.png';
import paypal from './social-media-icons/paypal.png';
import pinterest from './social-media-icons/pinterest.png';
import imqq from './social-media-icons/qq-48.png';
import quora from './social-media-icons/quora-32.png';
import reddit from './social-media-icons/reddit-48.png';
import remove from './social-media-icons/remove.png';
import share from './social-media-icons/share-64.png';
import sharechat from './social-media-icons/sharechat-48 (6).png';
// import a from './social-media-icons/skype-48.png';
import skype from './social-media-icons/skype.png';
import slack from './social-media-icons/slack-48 (7).png';
import soundcloud from './social-media-icons/soundcloud-64.png';
import spotify from './social-media-icons/spotify-64.png';
import telegram from './social-media-icons/telegram-app-48.png';
import tiktok from './social-media-icons/tiktok.png';
import tinder from './social-media-icons/tinder-48.png';
import tumblr from './social-media-icons/tumblr-48.png';
import twitch from './social-media-icons/twitch-48 (8).png';
import twitter from './social-media-icons/twitter-48.png';
import venmo from './social-media-icons/venmo-50.png';
import vine from './social-media-icons/vine-48 (9).png';
import vk from './social-media-icons/vk.com-48.png';
import wechat from './social-media-icons/wechat-64.png';
import weibo from './social-media-icons/weibo-48 (10).png';
import wikipedia from './social-media-icons/wikipedia.png';
import xing from './social-media-icons/xing-48 (11).png';
import yelp from './social-media-icons/yelp-48 (12).png';
import youtube from './social-media-icons/youtube.png';
import zoom from './social-media-icons/zoom-48.png';
import pyoneer from './social-media-icons/pyoneer.png';

const socialMediaIcons = {
  amazon,
  amazonPrimeVideo,
  apple_music,
  blogger,
  comments,
  delicious,
  deviantart,
  emailOpen,
  facebook,
  github,
  gmail,
  googleMeet,
  googlePlus,
  html5,
  instagram,
  linkedin,
  microsoftTeams,
  myspace,
  netflix,
  outlook,
  paypal,
  pinterest,
  imqq,
  quora,
  reddit,
  remove,
  share,
  sharechat,
  skype,
  slack,
  soundcloud,
  spotify,
  telegram,
  tiktok,
  tinder,
  tumblr,
  twitch,
  twitter,
  venmo,
  vine,
  vk,
  wechat,
  weibo,
  wikipedia,
  xing,
  yelp,
  youtube,
  zoom,
  pyoneer,
};

export default socialMediaIcons;
