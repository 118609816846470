import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useHandleResponse from '../useHandleResponse';
import { REMOVE_LINK_MUTATION } from '../../graphql/Mutations';
import * as appActions from '../../store/actions/app';
import * as alertActions from '../../store/actions/alert';
import * as authActions from '../../store/actions/auth';
import generateRandomId from '../../utils/genrateRandomId';

const removeLink = () => {
  const dispatch = useDispatch();
  const [removeLinkMutation, { loading, data }] = useMutation(REMOVE_LINK_MUTATION);
  const { successMessage, errorMessage } = useHandleResponse();

  useEffect(() => {
    dispatch(appActions.isLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (data?.removeLink?.errors === null) {
      dispatch(authActions.removeLink(data?.removeLink.link));
      dispatch(
        alertActions.addAlert({
          id: generateRandomId(),
          message: 'Updated Successfully.',
          type: 'success',
        }),
      );
    }
    if (data?.removeLink?.errors?.length) {
      errorMessage(data?.removeLink?.errors);
    }
  }, [data]);

  return [
    removeLinkMutation,
    {
      data,
    },
  ];
};

export default removeLink;
