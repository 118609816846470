import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

// const errorLink = onError(({ graphqlErrors, networkError, errorMessage }) => {
//   if (graphqlErrors) {
//     graphqlErrors.map(({ message }) => console.log(`Graphql error: ${message}`));
//   }
//   if (networkError) {
//     console.log(`networkError`, networkError);
//   }
//   if (errorMessage) {
//     console.log(`errorMessage`, errorMessage);
//   }
// });

// const link = from([
//   errorLink,
//   new HttpLink({
//     uri: `${process.env.REACT_APP_API_URL}`,
//     credentials: 'include',
//   }),
// ]);

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link,
// });

const apiURL = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}`,
  credentials: 'include',
});
const client = new ApolloClient({
  link: apiURL,
  cache: new InMemoryCache({
    typePolicies: {
      BiolinkSettings: {
        keyFields: ['email'],
      },
      PlanSettings: {
        keyFields: ['totalBiolinksLimit'],
      },
    },
  }),
});
export default client;
