import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SEND_FORGOT_PASSWORD_EMAIL_MUTATION } from '../../graphql/Mutations';
import generateRandomId from '../../utils/genrateRandomId';
import validate from '../../utils/validations';
import ErrorSpan from '../../components/ui/errorSpan/ErrorSpan';
import * as alertActions from '../../store/actions/alert';
import images from '../../assets/images';

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const initialFormState = {
    email: '',
    emailError: [],
  };
  const [form, setForm] = useState(initialFormState);
  const [sendForgotPasswordEmail, { loading, data }] = useMutation(
    SEND_FORGOT_PASSWORD_EMAIL_MUTATION,
  );

  useEffect(() => {
    if (data?.sendForgotPasswordEmail.executed) {
      dispatch(
        alertActions.addAlert({
          id: generateRandomId(),
          message: 'Mail Sent.',
          type: 'success',
        }),
      );
    }
  }, [data]);

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const sendForgotEmailHandler = () => {
    const emailError = [];

    if (!validate.emailFormat(form.email)) emailError.push('Enter valid email.');

    setForm({
      ...form,
      emailError,
    });
    if (emailError.length) return;

    // submit form
    sendForgotPasswordEmail({
      variables: {
        email: form.email,
      },
    });
    setForm(initialFormState); // for blank form
  };

  return (
    <>
      <div className="container pt-5">
        <div className="row mt--2">
          <div className="col-md-6">
            <div className="signup-text signup-left-box">
              <h1>
                Single bio link for <span>everything</span>
              </h1>
              <p className>
                Share and cross promote your important links with your followers and our directory
                members.
              </p>
              <div className="login-img">
                <img src={images.signInBg} alt="" className="sign-img " />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card full-height ">
              <div className="card-body">
                <div className="signup-text text-center mb-5">
                  <h1>Forgot Password</h1>
                </div>
                <p className="text-center">
                  Enter your email below and we'll send you a <br /> password reset link.
                </p>
                <div className="form-signup">
                  {data && data.sendForgotPasswordEmail.errors ? (
                    data.sendForgotPasswordEmail.errors.map((err, i) => (
                      <ErrorSpan key={i} error={err.message} />
                    ))
                  ) : data && !data.sendForgotPasswordEmail.errors ? (
                    <div
                      style={{
                        fontSize: '24px',
                        backgroundColor: 'lightgreen',
                        padding: '10px',
                        textAlign: 'center',
                        borderRadius: '10px',
                        boxShadow: 'rgb(0 137 255 / 58%) 0px 0px 3px, rgb(0 43 255) 0px 0px 5px',
                        marginBottom: '20px',
                      }}
                    >
                      <span style={{ color: 'green' }}>
                        Verification email sent to the email. Follow the instruction in the email to
                        reset password.
                      </span>
                    </div>
                  ) : null}
                  <div className="form-group form-floating-label">
                    <input
                      id="inputFloatingLabel1"
                      type="text"
                      className="form-control input-solid"
                      required
                      placeholder="Your Email"
                      autoComplete="off"
                      name="email"
                      value={form.email}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {/* <label htmlFor="inputFloatingLabel1" className="placeholder">
                      Your Email
                    </label> */}
                  </div>
                  {form.emailError.length
                    ? form.emailError.map((err, i) => <ErrorSpan key={i} error={err} />)
                    : null}
                  <div className="card-action text-center">
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      onClick={(evt) => sendForgotEmailHandler(evt)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
