import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import ToggleSwitch from '../../../components/ui/ToggleSwitch';
import { UPDATE_INTEGRATION_SETTINGS_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';

const IntegrationForm = ({ showUpgrade }) => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const [updateIntegrationSettings, { data }] = useNetwork(UPDATE_INTEGRATION_SETTINGS_MUTATION);
  const currentPlan = useSelector((state) => state.auth.user.plan);
  const [form, setForm] = useState({
    facebookPixelIdError: [],
    googleAnalyticsCodeError: [],
    emailCaptureIdError: [],
    enableEmailCapture: currentBiolink.enableEmailCapture,
    emailCaptureId: currentBiolink.emailCaptureId,
    enableFacebookPixel: currentBiolink.enableFacebookPixel,
    facebookPixelId: currentBiolink.facebookPixelId,
  });

  const onToggle = (button) => {
    setForm({
      ...form,
      [button]: !form[button],
    });
  };

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    let isValidated = true;
    const facebookPixelIdError = [];
    const emailCaptureIdError = [];

    // // add validations here
    if (form.enableFacebookPixel && !form.facebookPixelId) {
      isValidated = false;
      facebookPixelIdError.push('Facebook Pixel Id cannot be empty.');
    }

    if (form.enableEmailCapture && !form.emailCaptureId) {
      isValidated = false;
      emailCaptureIdError.push('Google Analytics Code cannot be empty.');
    }

    setForm({
      ...form,
      facebookPixelIdError,
      emailCaptureIdError,
    });
    if (!isValidated) return;

    updateIntegrationSettings({
      variables: {
        id: currentBiolink.id,
        enableFacebookPixel: form.enableFacebookPixel,
        facebookPixelId: form.facebookPixelId,
        enableEmailCapture: form.enableEmailCapture,
        emailCaptureId: form.emailCaptureId,
      },
    });
  };

  return (
    <div className="modal fade" id="modal-4">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Integrations</h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body branding-model">
            <div className="form-list">
              <div className="form-group inte-form form-floating-label clearfix">
                <label htmlFor="inputFloatingLabel11" className="label-design">
                  Facebook Pixel  
                </label>
                <ToggleSwitch
                  currentState={form.enableFacebookPixel}
                  onToggle={() => {
                    if (currentPlan?.settings.facebookPixelEnabled) {
                      onToggle('enableFacebookPixel');
                    } else {
                      showUpgrade();
                    }
                  }}
                  isActive="na"
                />
                {form.enableFacebookPixel && (
                  <>
                    <input
                      type="text"
                      className="form-control input-solid"
                      placeholder="Enter facebook pixel integration id"
                      name="facebookPixelId"
                      value={form.facebookPixelId}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {form.facebookPixelIdError.length
                      ? form.facebookPixelIdError.map((err, i) => <ErrorSpan key={i} error={err} />)
                      : null}
                  </>
                )}
              </div>

              <div className="form-group inte-form form-floating-label clearfix" >
                <label htmlFor="inputFloatingLabel11" className="label-design">
                  Email Capture
                </label>
                <ToggleSwitch
                  currentState={form.enableEmailCapture}
                  onToggle={() => onToggle('enableEmailCapture')}
                  isActive="na"
                />
                {/* <input
                  id="inputFloatingLabel13"
                  type="text"
                  className="form-control input-solid"
                  required
                />
                <label htmlFor="inputFloatingLabel13" className="placeholder">
                  Email Capture
                </label> */}
                {form.enableEmailCapture && (
                  <>
                    <input
                      type="text"
                      className="form-control input-solid"
                      placeholder="Enter email capture id"
                      name="emailCaptureId"
                      value={form.emailCaptureId}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {form.emailCaptureIdError.length
                      ? form.emailCaptureIdError.map((err, i) => <ErrorSpan key={i} error={err} />)
                      : null}
                  </>
                )}
              </div>

              <button type="button" className="btn btn-primary" onClick={onSubmitHandler}>
                Save changes
              </button>
            </div>
          </div>

          <div className="modal-footer">
            <a href="#modal-1" data-toggle="modal" data-dismiss="modal" className="btn btn-default">
              Previous
            </a>
            <button data-dismiss="modal" type="button" className="btn btn-danger">
              Close
            </button>
          </div>
        </div>
        {/* <!-- /.modal-content --> */}
      </div>
      {/* <!-- /.modal-dialog --> */}
    </div>
  );
};

export default IntegrationForm;
