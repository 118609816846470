import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ChangeEmailForm from './ChangeEmailForm';
import ChangePasswordForm from './ChangePasswordForm';
import CreateBiolinkForm from './CreateBiolinkForm';
import CurrentBioLinks from './CurrentBioLinks';
import DeleteAccount from './DeleteAccount';

const Account = () => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const userEmail = useSelector((state) => state.auth.user.email);
  const allBiolinks = useSelector((state) => state.auth.user.biolinks);

  useEffect(() => {
    if (!currentBiolink) {
      alert('Biolink not found, Create one first');
    }
  }, []);

  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner account-section ">
            <div className="row mt--2">
              <div className="col-md-6">
                <ChangeEmailForm
                  userEmail={userEmail}
                  userName={currentBiolink?.username?.username}
                />
              </div>
              <div className="col-md-6">
                <ChangePasswordForm userEmail={userEmail} />
              </div>
            </div>
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="card-header acnt-head">
                    <div className="card-title blue-text big-title">Create Biolink</div>
                  </div>
                  <div className="card-body">
                    <CurrentBioLinks currentBiolink={currentBiolink} bioLinks={allBiolinks} />
                    <CreateBiolinkForm
                      userName={currentBiolink?.username?.username}
                      currentBiolink={currentBiolink}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DeleteAccount />
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
