import socialMediaIcons from '../assets/socialMediaIcons';

const socialMediaList = {
  facebook: {
    title: 'Facebook',
    link: '',
    status: '',
    icon: socialMediaIcons.facebook,
  },
  youtube: {
    title: 'Youtube',
    link: '',
    status: '',
    icon: socialMediaIcons.youtube,
  },
  twitter: {
    title: 'Twitter',
    link: '',
    status: '',
    icon: socialMediaIcons.twitter,
  },
  instagram: {
    title: 'Instagram',
    link: '',
    status: '',
    icon: socialMediaIcons.instagram,
  },
  linkedin: {
    title: 'LinkedIn',
    link: '',
    status: '',
    icon: socialMediaIcons.linkedin,
  },
  wechat: {
    title: 'We Chat',
    link: '',
    status: '',
    icon: socialMediaIcons.wechat,
  },
  tumblr: {
    title: 'Tumblr',
    link: '',
    status: '',
    icon: socialMediaIcons.tumblr,
  },
  gmail: {
    title: 'Gmail',
    link: '',
    status: '',
    icon: socialMediaIcons.gmail,
  },
  skype: {
    title: 'Skype',
    link: '',
    status: '',
    icon: socialMediaIcons.skype,
  },
  tiktok: {
    title: 'Tiktok',
    link: '',
    status: '',
    icon: socialMediaIcons.tiktok,
  },
  zoom: {
    title: 'Zoom',
    link: '',
    status: '',
    icon: socialMediaIcons.zoom,
  },
  reddit: {
    title: 'Reddit',
    link: '',
    status: '',
    icon: socialMediaIcons.reddit,
  },
  amazon: {
    title: 'Amazon',
    link: '',
    status: '',
    icon: socialMediaIcons.amazon,
  },
  amazonPrimeVideo: {
    title: 'Amazon Video',
    link: '',
    status: '',
    icon: socialMediaIcons.amazonPrimeVideo,
  },
  apple_music: {
    title: 'Apple Music',
    link: '',
    status: '',
    icon: socialMediaIcons.apple_music,
  },
  pinterest: {
    title: 'Pinterest',
    link: '',
    status: '',
    icon: socialMediaIcons.pinterest,
  },
  blogger: {
    title: 'Blogger',
    link: '',
    status: '',
    icon: socialMediaIcons.blogger,
  },
  delicious: {
    title: 'Delicious',
    link: '',
    status: '',
    icon: socialMediaIcons.delicious,
  },
  deviantart: {
    title: 'Deviant Art',
    link: '',
    status: '',
    icon: socialMediaIcons.deviantart,
  },
  emailOpen: {
    title: 'Email',
    link: '',
    status: '',
    icon: socialMediaIcons.emailOpen,
  },
  github: {
    title: 'Github',
    link: '',
    status: '',
    icon: socialMediaIcons.github,
  },
  googleMeet: {
    title: 'Google Meet',
    link: '',
    status: '',
    icon: socialMediaIcons.googleMeet,
  },
  googlePlus: {
    title: 'Google Plus',
    link: '',
    status: '',
    icon: socialMediaIcons.googlePlus,
  },
  microsoftTeams: {
    title: 'Microsoft Teams',
    link: '',
    status: '',
    icon: socialMediaIcons.microsoftTeams,
  },
  myspace: {
    title: 'My Space',
    link: '',
    status: '',
    icon: socialMediaIcons.myspace,
  },
  netflix: {
    title: 'Netflix',
    link: '',
    status: '',
    icon: socialMediaIcons.netflix,
  },
  outlook: {
    title: 'Outlook',
    link: '',
    status: '',
    icon: socialMediaIcons.outlook,
  },
  paypal: {
    title: 'Paypal',
    link: '',
    status: '',
    icon: socialMediaIcons.paypal,
  },
  telegram: {
    title: 'Telegram',
    link: '',
    status: '',
    icon: socialMediaIcons.telegram,
  },
  imqq: {
    title: 'IMQQ',
    link: '',
    status: '',
    icon: socialMediaIcons.imqq,
  },
  quora: {
    title: 'Quora',
    link: '',
    status: '',
    icon: socialMediaIcons.quora,
  },
  sharechat: {
    title: 'Share Chat',
    link: '',
    status: '',
    icon: socialMediaIcons.sharechat,
  },
  slack: {
    title: 'Slack',
    link: '',
    status: '',
    icon: socialMediaIcons.slack,
  },
  soundcloud: {
    title: 'Sound Cloud',
    link: '',
    status: '',
    icon: socialMediaIcons.soundcloud,
  },
  spotify: {
    title: 'Spotify',
    link: '',
    status: '',
    icon: socialMediaIcons.spotify,
  },
  tinder: {
    title: 'Tinder',
    link: '',
    status: '',
    icon: socialMediaIcons.tinder,
  },
  twitch: {
    title: 'Twitch',
    link: '',
    status: '',
    icon: socialMediaIcons.twitch,
  },
  venmo: {
    title: 'Venmo',
    link: '',
    status: '',
    icon: socialMediaIcons.venmo,
  },
  vine: {
    title: 'Vine',
    link: '',
    status: '',
    icon: socialMediaIcons.vine,
  },
  vk: {
    title: 'vk.com',
    link: '',
    status: '',
    icon: socialMediaIcons.vk,
  },
  weibo: {
    title: 'Weibo',
    link: '',
    status: '',
    icon: socialMediaIcons.weibo,
  },
  wikipedia: {
    title: 'Wikipedia',
    link: '',
    status: '',
    icon: socialMediaIcons.wikipedia,
  },
  xing: {
    title: 'Xing',
    link: '',
    status: '',
    icon: socialMediaIcons.xing,
  },
  yelp: {
    title: 'Yelp',
    link: '',
    status: '',
    icon: socialMediaIcons.yelp,
  },
  pyoneer: {
    title: 'Pyoneer',
    link: '',
    status: '',
    icon: socialMediaIcons.pyoneer,
  },
};

export default socialMediaList;
