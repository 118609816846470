import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useHandleResponse from '../useHandleResponse';
import { CREATE_REFERRAL_MUTATION } from '../../graphql/Mutations';
import * as appActions from '../../store/actions/app';

const createReferrals = () => {
  const dispatch = useDispatch();
  const [createReferralsMutation, { loading, data }] = useMutation(CREATE_REFERRAL_MUTATION);
  const { successMessage, errorMessage } = useHandleResponse();

  useEffect(() => {
    dispatch(appActions.isLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (data?.createReferrals?.errors === null) {
      successMessage();
    }
    if (data?.createReferrals?.errors?.length) {
      errorMessage(data?.createReferrals?.errors);
    }
  }, [data]);

  return [
    createReferralsMutation,
    {
      data,
    },
  ];
};

export default createReferrals;
