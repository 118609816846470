import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  UPDATE_SOCIAL_ACCOUNTS_SETTINGS_MUTATION,
  CREATE_NEW_LINK,
  REMOVE_LINK_MUTATION,
} from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';
import socialMediaList from '../../../constants/socialMediaList';
import socialMediaIcons from '../../../assets/socialMediaIcons';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import socialIcons from '../../../assets/socialIcons';
import socialIconList from '../../../constants/socialIconList';

const SocialMediaForm = ({ showUpgrade }) => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const [currentSocialMedia, setCurrentSocialMedia] = useState(null);
  const currentPlan = useSelector((state) => state.auth.user.plan);
  const closeModal = useRef(null);
  const [userPlatform, setUserPlatform] = useState([]);
  const [iconLinks, seticonLinks] = useState([]);
  const [removeLinkMutation, { data: deleteData }] = useNetwork(REMOVE_LINK_MUTATION);

  const [updateSocialAccountsSettings, { data }] = useNetwork(
    UPDATE_SOCIAL_ACCOUNTS_SETTINGS_MUTATION,
  );
  // const [socialAccountStyleType, setSocialAccountStyleType] = useState(
  //   currentBiolink?.settings?.socialAccountStyleType,
  // );

  // console.log('currentSocialMedia=======>', currentSocialMedia);
  // console.log('currentPlan=======>', currentPlan);

  // useEffect(() => {
  //   console.log('socialAccountStyleType=======>', socialAccountStyleType);
  // }, [socialAccountStyleType]);

  // const{id,platform}=currentBiolink.links

  useEffect(() => {
    if (data?.updateSocialAccountsSettings.errors === null) {
      closeModal.current.click();
      setCurrentSocialMedia(null);
    }
  }, [data]);

  useEffect(() => {
    console.log('currentSocialMedia===>', currentSocialMedia);
  }, [currentSocialMedia]);

  const [form, setForm] = useState({
    validationError: [],
    linkType: '',
    platform: '',
    platformError: [],
    featured: true,
    url: '',
    urlError: [],
    socialAccountStyleType: currentBiolink?.settings?.socialAccountStyleType,
    enableColoredSocialMediaIcons: true,
  });

  const [socialMedia, setSocialMedia] = useState(socialMediaList);
  const [socialMedias, setSocialMedias] = useState(socialIconList);

  const [createNewLink, { data: createSocialMedia }] = useNetwork(CREATE_NEW_LINK);
  // console.log('Fetching New Social Account', createSocialMedia);

  useEffect(() => {
    if (createSocialMedia?.createNewLink.errors === null) {
      closeModal.current.click();
      setCurrentSocialMedia(null);
      // console.log('SomeThing went Wrong!!');
    }
  }, [createSocialMedia]);
  // const newtamplink=[]
  // currentBiolink.links?.forEach((item, index) => {
  //   newtamplink.push(item.id);
  // })
  // console.log("newtamplink",newtamplink)

  // console.log('Fetching New Social Account', createSocialMedia);

  useEffect(() => {
    console.log('userPlatform---->', userPlatform);
  }, [userPlatform]);

  useEffect(() => {
    const tempAccounts = {};
    const newtempdata = [];
    const temp = [];
    if (currentPlan?.settings.coloredLinksEnabled && form.enableColoredSocialMediaIcons == true) {
      currentBiolink.links?.forEach((item, index) => {
        temp.push(item.platform);
        newtempdata.push(item);

        tempAccounts[item.platform] = {
          ...socialMediaList[item.platform],
          link: item.url,
          status: item.url ? true : false,
          iconColorful: item.iconColorful,
        };
      });

      setSocialMedia({
        ...socialMediaList,
        ...tempAccounts,
      });
    }

    if (form.enableColoredSocialMediaIcons == false) {
      currentBiolink.links?.forEach((item, index) => {
        temp.push(item.platform);

        tempAccounts[item.platform] = {
          ...socialIconList[item.platform],
          link: item.url,
          status: item.url ? true : false,
          iconMinimal: item.iconMinimal,
        };
      });
      setSocialMedia({
        ...socialIconList,
        ...tempAccounts,
      });
    }

    setUserPlatform(temp);
    // if ()
    // console.log('check data ==',tempAccounts);
    console.log('newTempdata', newtempdata);
  }, [currentBiolink.links]);

  const [settings, setSettings] = useState({
    showSocialMediaCustomizeOptions: true,
  });

  const inputChangeHandler = (name, value) => {
    setCurrentSocialMedia({
      ...currentSocialMedia,
      [currentSocialMedia.key]: {
        ...currentSocialMedia[currentSocialMedia.key],
        link: value,
        status: value ? true : false,
      },
    });
  };
  console.log('Check Status Here====', form.enableColoredSocialMediaIcons);
  const onDeleteHandler = (key) => {
    console.log('crLink=+++++>', key);
    const newlinktemp = [];
    currentBiolink.links?.forEach((item) => {
      if (item.platform === key) {
        newlinktemp.push(item.id);
      }
    });
    console.log('item********>', newlinktemp);

    removeLinkMutation({
      variables: {
        id: newlinktemp.toString(),
      },
    });
  };
  // console.log('socialAccountsArray====',socialAccountsArray);
  // const onDeleteSocialMedia = (key) => {
  //   const socialAccountsArray = [];
  //   userPlatform.forEach((item, index) => {
  //     if (item.platform !== key) {
  //       socialAccountsArray.push({
  //         platform: item.platform,
  //         url: item.url,
  //       });
  //     }
  //   });
  // }

  const renderSocialMediaIcons = () => {
    //  console.log("socialMedia",socialMedia)

    return Object.keys(socialMedia).map((key, index) => {
      return (
        <div key={index} data-toggle="tooltip" title={socialMedia[key].title}>
          {/* {form.socialAccountStyleType == 'Round'|| form.socialAccountStyleType == 'Square'} */}

          {/* Code for color icon */}
          {form.enableColoredSocialMediaIcons == true ? (
            <>
              {userPlatform.includes(key) == true ? (
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={() => onDeleteHandler(key)}
                />
              ) : null}

              <a
                className="btn-admin"
                // data-toggle="modal"
                // data-dismiss="modal"
                onClick={(evt) => {
                  evt.preventDefault();
                  console.log("ondddddd.....",evt)
                  if (currentPlan?.settings.socialEnabled && !userPlatform.includes(key)) {
                    setCurrentSocialMedia({
                      [key]: socialMedia[key],
                      key
                    });
                  } else {
                    // showUpgrade();
                    // alert("icon is alredy select")
                    null
                  }
                }}
                // href="#modal-13"
              >
                <img src={socialMedia[key].icon} alt="" />
                {/* <span className="lab-text">{socialMedia[key].title}</span> */}
                <span className="close ">
                  {/* <i
                className={socialMedia[key].status ? 'fa fa-close' : 'fa fa-plus'}
                aria-hidden="true"
              /> */}
                  <i className={socialMedia[key].status ? 'active' : ''} aria-hidden="true" />
                </span>
              </a>
            </>
          ) : (
            <>
              {/* Code for minimal icon */}
          {userPlatform.includes(key) == true ? (
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={() => onDeleteHandler(key)}
                 /> 
              ) : null}
                 
                      
                <a
                className="btn-admin"
                // data-toggle="modal"
                // data-dismiss="modal"
                
                onClick={(evt) => {
                  evt.preventDefault();
                  if (currentPlan?.settings.socialEnabled && !userPlatform.includes(key)) {
                    setCurrentSocialMedia({ [key]: socialMedias[key], key });
                  } else {
                    // showUpgrade();
                    null
                  }
                }}
                // href="#modal-13"
              >
                <img src={socialMedias[key].icon} alt="" />
                {/* <span className="lab-text">{socialMedia[key].title}</span> */}
                <span className="close">
                  {/* <i
               className={socialMedia[key].status ? 'fa fa-close' : 'fa fa-plus'}
               aria-hidden="true"
             /> */}
                  <i className={socialMedias[key].status ? 'active' : ''} aria-hidden="true" />
                </span>
              </a>
            </>
          )}
        </div>
      );
    });
  };

  const renderSocialMediaForm = () => {
    return Object.keys(socialMedia).map((key, index) => {
      if (socialMedia[key].status) {
        return (
          <div key={index} style={{ display: 'flex', marginBottom: '20px' }}>
            {/* <label>{capitalizeFirstLetter(key)}</label> */}
            <img
              // src={socialMediaIcons[key]}
              src={socialIcons[key]}
              alt=""
              style={{ maxHeight: '50px', marginRight: '10px' }}
            />
            <input
              type="text"
              className="input-design"
              name="facebook"
              value={socialMedia[key].link}
              onChange={(e) =>
                setSocialMedia({
                  ...socialMedia,
                  [key]: {
                    ...socialMedia[key],
                    status: true,
                    // staus:!socialMedia[key].status ,
                    link: e.target.value,
                  },
                })
              }
            />
            {/* {form.facebookError.length
            ? form.facebookError.map((err, i) => <ErrorSpan key={i} error={err} />)
            : null} */}
          </div>
        );
      }
      return null;
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    let isValidated = true;
    const validationError = [];
    const urlError = [];

    // // add validations here
    if (!currentSocialMedia[currentSocialMedia.key].link) {
      isValidated = false;
      validationError.push('link cannot be empty.');
    }
    if (!form.url) urlError.push('Url cannot be empty');
    setForm({
      ...form,
      validationError,
      urlError,
    });
    if (!isValidated) return;

    const socialMediaArray = [];
    Object.keys(socialMedia).forEach((key) => {
      if (socialMedia[key].url) {
        socialMediaArray.push({
          platform: key,
          link: socialMedia[key].url,
        });
      }
    });
    socialMediaArray.push({
      platform: currentSocialMedia.key,
      link: currentSocialMedia[currentSocialMedia.key].url,
    });
    // submit form
    updateSocialAccountsSettings({
      variables: {
        id: currentBiolink.id,
        enableColoredSocialMediaIcons: form.enableColoredSocialMediaIcons,
        socialAccountStyleType: form.socialAccountStyleType,
        // socialAccounts: socialMediaArray,
      },
    });
    createNewLink({
      variables: {
        biolinkId: currentBiolink.id,
        // url: form.url,
        url: currentSocialMedia[currentSocialMedia.key].link,
        linkType: 'Social',
        platform: currentSocialMedia.key,
        // icon: socialMedia[key].icon,
        // featured: true,
      },
    });
  };

  return (
    <>
      <div className="modal fade" id="modal-12">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Social Media</h4>
              <button type="button" className="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
                <span className="sr-only">Close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="customize-row social-media-form-section">
                <a
                  className="social-customize social-customize-body"
                  // onClick={() =>
                  //   setSettings({
                  //     ...settings,
                  //     showSocialMediaCustomizeOptions: !settings.showSocialMediaCustomizeOptions,
                  //   })
                  // }
                >
                  Customize
                  <div className="swap-btn-bar-admin social-form-toggle">
                    <a
                      className={
                        settings?.showSocialMediaCustomizeOptions ? 'pull-left' : 'pull-left active'
                      }
                      // onClick={() => onToggleDarkMode()}
                      onClick={() =>
                        setSettings({
                          ...settings,
                          showSocialMediaCustomizeOptions:
                            !settings.showSocialMediaCustomizeOptions,
                        })
                      }
                    />

                    <a
                      className={
                        settings?.showSocialMediaCustomizeOptions
                          ? 'pull-right active'
                          : 'pull-right'
                      }
                      // onClick={() => onToggleDarkMode()}
                      onClick={() =>
                        setSettings({
                          ...settings,
                          showSocialMediaCustomizeOptions:
                            !settings.showSocialMediaCustomizeOptions,
                        })
                      }
                    />
                  </div>
                </a>
                {/* <div className="style-section">
                  <div className="style-section-title">
                    <h6>Style</h6>
                    <div className="minimal-style">
                      <a className="links" href="#">
                        Minimal
                      </a>
                      <a className="links" href="#">
                        Colorful
                      </a>
                    </div>
                  </div>
                  <div className="shape-area">
                    <h6>Shape</h6>
                    <div className="shape-area-body">
                      <a className="round-shape">
                        <img src="" alt="" />
                        <i className="fa fa-facebook" />
                        <p>Round</p>
                      </a>
                      <a className="square-shape">
                        <img src="" alt="" />
                        <i className="fa fa-facebook" />
                        <p>Square</p>
                      </a>
                      <a className="follow-shape">
                        <div className="follow-img">
                          <img src="" alt="" />
                          <span className="follow-social">
                            <i className="fa fa-twitter-square" />
                            Follow
                          </span>
                          <div>oneclick</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="customize-box">
                  {settings.showSocialMediaCustomizeOptions ? (
                    <div className="style-section">
                      <div className="style-section-title">
                        <h6>Style</h6>
                        <div className="minimal-style">
                          <a
                            // className="links"
                            className={
                              form.enableColoredSocialMediaIcons ? 'links ' : 'links  active'
                            }
                            onClick={() =>
                              setForm({
                                ...form,
                                // ...socialIconList,
                                enableColoredSocialMediaIcons: false,
                              })
                            }
                            onKeyPress={() =>
                              setForm({
                                ...socialIconList,
                                enableColoredSocialMediaIcons: false,
                              })
                            }
                          >
                            Minimal
                          </a>
                          <a
                            // className="links"
                            className={
                              form.enableColoredSocialMediaIcons ? 'links  active' : 'links '
                            }
                            onClick={() => {
                              if (currentPlan?.settings.coloredLinksEnabled) {
                                setForm({
                                  ...form,
                                  // ...socialMediaList,
                                  enableColoredSocialMediaIcons: true,
                                });
                              } else {
                                showUpgrade();
                              }
                            }}
                            onKeyPress={() => {
                              if (currentPlan?.settings.coloredLinksEnabled) {
                                setForm({
                                  // ...form,
                                  ...socialMediaList,
                                  enableColoredSocialMediaIcons: true,
                                });
                              } else {
                                showUpgrade();
                              }
                            }}
                          >
                            Colorful
                          </a>
                        </div>
                      </div>

                      <div
                        className={`shape-area ${
                          form.enableColoredSocialMediaIcons == true ? 'color' : 'minimal'
                        }
                 
                  `}
                      >
                        <h6>Shape</h6>

                        <div className="shape-area-body">
                          <a
                            //  className="round-shape"
                            className={
                              form.socialAccountStyleType === 'Round'
                                ? 'round-shape round active'
                                : 'round-shape'
                            }
                            onClick={() => {
                              // if (currentPlan?.settings.coloredLinksEnabled) {
                              setForm({
                                ...form,
                                socialAccountStyleType: 'Round',
                              });
                              // } else {
                              //   showUpgrade();
                              // }
                            }}
                          >
                            <img src="" alt="" /> <i className="fa fa-facebook" />
                            <p>Round</p>
                          </a>
                          <a
                            // className="square-shape"
                            className={
                              form.socialAccountStyleType === 'Square'
                                ? 'square-shape squr active'
                                : 'square-shape'
                            }
                            onClick={() => {
                              // if (currentPlan?.settings.coloredLinksEnabled) {
                              setForm({
                                ...form,
                                socialAccountStyleType: 'Square',
                              });
                              // } else {
                              //   showUpgrade();
                              // }
                            }}
                          >
                            <img src="" alt="" />
                            <i className="fa fa-facebook" />
                            <p>Square</p>
                          </a>
                          {/* <a
                           
                            className={
                              form.socialAccountStyleType === 'OneClick'
                                ? 'follow-shape one active'
                                : 'follow-shape'
                            }
                            onClick={() => {
                             
                              setForm({
                                ...form,
                                socialAccountStyleType: 'OneClick',
                              });
                        
                            }}
                          >
                            <div className="follow-img">
                              <img src="" alt="" /> <i className="fa fa-facebook" />
                              <p>Follow</p>
                            </div>
                            <p>One Click</p>
                          </a> */}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <div className="customize-box">
                  {settings.showSocialMediaCustomizeOptions ? (
                    <div className="customize-body mt-0">
                      <div className="customize-options-container row addBtnhead socialbackground">
                        <div
                          className={
                            form.enableColoredSocialMediaIcons
                              ? 'customize-options col-md-6'
                              : 'customize-options col-md-6 active'
                          }
                          onClick={() =>
                            setForm({
                              ...form,
                              enableColoredSocialMediaIcons: false,
                            })
                          }
                        >
                          Minimal
                        </div>
                        <div
                          className={
                            form.enableColoredSocialMediaIcons
                              ? 'customize-options col-md-6 active'
                              : 'customize-options col-md-6'
                          }
                          onClick={() => {
                            if (currentPlan?.settings.coloredLinksEnabled) {
                              setForm({
                                ...form,
                                enableColoredSocialMediaIcons: true,
                              });
                            } else {
                              showUpgrade();
                            }
                          }}
                        >
                          Colorful
                        </div>
                      </div>
                      <div className="socialstyle mt-3">
                        <ul>
                          <li
                            style={{
                              cursor: 'pointer',
                            }}
                            className={
                              form.socialAccountStyleType === 'Round'
                                ? 'customize-options active'
                                : 'customize-options'
                            }
                            onClick={() => {
                              // if (currentPlan?.settings.coloredLinksEnabled) {
                              setForm({
                                ...form,
                                socialAccountStyleType: 'Round',
                              });
                              // } else {
                              //   showUpgrade();
                              // }
                            }}
                          >
                            <span>
                              <img
                                src="https://avyatated.com/static/media/round.fa09cd68.svg"
                                alt=""
                              />
                            </span>
                            <span>Round </span>
                          </li>
                          <li
                            style={{
                              cursor: 'pointer',
                            }}
                            className={
                              form.socialAccountStyleType === 'Square'
                                ? 'customize-options active'
                                : 'customize-options'
                            }
                            onClick={() => {
                              // if (currentPlan?.settings.coloredLinksEnabled) {
                              setForm({
                                ...form,
                                socialAccountStyleType: 'Square',
                              });
                              // } else {
                              //   showUpgrade();
                              // }
                            }}
                          >
                            <span>
                              <img
                                src="https://avyatated.com/static/media/squar.cb1b71dc.svg"
                                alt=""
                              />
                            </span>
                            <span>Square </span>
                          </li>
                          <li
                            style={{
                              cursor: 'pointer',
                            }}
                            className={
                              form.socialAccountStyleType === 'OneClick'
                                ? 'customize-options active'
                                : 'customize-options'
                            }
                            onClick={() => {
                              // if (currentPlan?.settings.coloredLinksEnabled) {
                              setForm({
                                ...form,
                                socialAccountStyleType: 'OneClick',
                              });
                              // } else {
                              //   showUpgrade();
                              // }
                            }}
                          >
                            <span>
                              <img
                                src="https://avyatated.com/static/media/oneclick.0af319d7.svg"
                                alt=""
                              />
                            </span>
                            <span>One Click</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div> */}
              {currentSocialMedia && currentSocialMedia[currentSocialMedia?.key] ? (
                <div className="modal-body">
                  <div className="form-list add-social">
                    {/* console.log("myconsole======",currentSocialMedia) */}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div className="form-list">
                      <div
                        className={`social-links ${

                          form.enableColoredSocialMediaIcons == true ? 'color' : 'minimal'
                          // currentSocialMedia[currentSocialMedia.key].icon==true? 'color' :'minimal'
                        }
                  ${
                    form.socialAccountStyleType == 'Round'
                      ? 'Round'
                      : form.socialAccountStyleType == 'Square'
                      ? 'Square'
                      : 'OneClick'
                  }
                  `}
                      >
                        <div className="btn-admin">
                          <img
                            src={
                              currentSocialMedia
                                ? currentSocialMedia[currentSocialMedia.key].icon
                                : ''
                            }
                            // src= {socialMedias[key].icon}
                         
                            alt=""
                            style={{
                              height: 25,
                              width: 25,

                            }}
                          />
                      
                            
                        
                        </div>  
                          
                      </div>
                      </div>
                      <div
                        className="form-group form-floating-label"
                        style={{
                          width: '90%',
                        }}
                      >
                        <input
                          id="inputFloatingLabel342"
                          type="text"
                          className="form-control input-solid"
                          required
                          name="url"
                          value={
                            currentSocialMedia
                              ? currentSocialMedia[currentSocialMedia.key].link
                              : ''
                          }
                          // value={form.url}
                          onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                          placeholder={
                            currentSocialMedia
                              ? currentSocialMedia[currentSocialMedia.key].title
                              : ''
                          }
                        />
                        {/* <label htmlFor="inputFloatingLabel342" className="placeholder">
                      {currentSocialMedia ? currentSocialMedia[currentSocialMedia.key].title : ''}
                    </label> */}
                        {form.validationError.length
                          ? form.validationError.map((err, i) => <ErrorSpan key={i} error={err} />)
                          : null}
                      </div>
                      <div className="add-media-btn">
                        <a
                          onClick={(evt) => {
                            onSubmitHandler(evt);
                          }}
                        >
                          Add
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className="form-list ">
                <div
                  className={`social-links ${
                    form.enableColoredSocialMediaIcons == true ? 'color' : 'minimal'
                  }
                  ${
                    form.socialAccountStyleType == 'Round'
                      ? 'Round'
                      : form.socialAccountStyleType == 'Square'
                      ? 'Square'
                      : 'OneClick'
                  }
                  `}
                >
                  {renderSocialMediaIcons()}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <a
                href="#modal-1"
                data-toggle="modal"
                data-dismiss="modal"
                className="btn btn-default"
              >
                Previous
              </a>
              <button type="button" data-dismiss="modal" className="btn btn-danger">
                Close
              </button>
            </div>
          </div>
          {/* <!-- /.modal-content --> */}
        </div>
        {/* <!-- /.modal-dialog --> */}
      </div>

      <div className="modal fade" id="modal-13">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Social Media</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                  setCurrentSocialMedia(null);
                }}
              >
                <span aria-hidden="true">&times;</span>
                <span className="sr-only">Close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-list add-social">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    className={`social-links ${
                      form.enableColoredSocialMediaIcons == true ? 'color' : 'minimal'
                    }
                  ${
                    form.socialAccountStyleType == 'Round'
                      ? 'Round'
                      : form.socialAccountStyleType == 'Square'
                      ? 'Square'
                      : 'OneClick'
                  }
                  `}
                  >
                    <div className="btn-admin">
                      <img
                        src={
                          currentSocialMedia ? currentSocialMedia[currentSocialMedia.key].icon : ''
                        }
                        alt=""
                        style={{
                          height: 25,
                          width: 25,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="form-group form-floating-label"
                    style={{
                      width: '90%',
                    }}
                  >
                    <input
                      id="inputFloatingLabel342"
                      type="text"
                      className="form-control input-solid"
                      required
                      name="url"
                      value={
                        currentSocialMedia ? currentSocialMedia[currentSocialMedia.key].link : ''
                      }
                      // value={form.url}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      placeholder={
                        currentSocialMedia ? currentSocialMedia[currentSocialMedia.key].title : ''
                      }
                    />
                    {/* <label htmlFor="inputFloatingLabel342" className="placeholder">
                      {currentSocialMedia ? currentSocialMedia[currentSocialMedia.key].title : ''}
                    </label> */}
                    {form.validationError.length
                      ? form.validationError.map((err, i) => <ErrorSpan key={i} error={err} />)
                      : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <a
                href="#modal-12"
                data-toggle="modal"
                data-dismiss="modal"
                className="btn btn-default"
                ref={closeModal}
                onClick={() => {
                  setCurrentSocialMedia(null);
                }}
              >
                Previous
              </a>
              <a
                // href="#modal-12"
                // data-toggle="modal"
                // data-dismiss="modal"
                className="btn btn-success"
                onClick={(evt) => {
                  onSubmitHandler(evt);
                }}
              >
                Save
              </a>
            </div>
          </div>
          {/* <!-- /.modal-content --> */}
        </div>
        {/* <!-- /.modal-dialog --> */}
      </div>
    </>
  );
};

export default SocialMediaForm;
