import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { GET_PAYMENT, LOAD_USER_DETAILS } from '../../../graphql/Queries';
import * as authActions from '../../../store/actions/auth';
import Loader from '../../../components/ui/loader/Loader';
import * as alertActions from '../../../store/actions/alert';
import * as appActions from '../../../store/actions/app';
import generateRandomId from '../../../utils/genrateRandomId';

const Success = () => {
  const dispatch = useDispatch();

  const { data: userData, loading: userLoading } = useQuery(LOAD_USER_DETAILS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (userData && userData.me) {
      dispatch(authActions.authenticate(userData.me, true));
    }
  }, [userData]);

  const payments = useSelector((state) => state.auth.user.payments);

  const currentBiolink = useSelector((state) => state.auth.currentBiolink);

  // console.log('dhdhshghdhsgs///////', payments);

  // console.log('Payments Length ======', payments[payments.length - 1]);

  const length = payments[payments.length - 1];

  // console.log('Array length Check======', length);
  const { data: paymentData, loading } = useQuery(GET_PAYMENT, {
    variables: { paymentId: length?.id },
  });

  const [paymentValues, setPaymentValues] = useState([]);

  useEffect(() => {
    dispatch(appActions.isLoading(loading));
  }, [loading]);

  // console.log('Data-Status-Check', paymentData);

  useEffect(() => {
    if (paymentData && paymentData?.getPayment?.payment) {
      setPaymentValues(paymentData?.getPayment?.payment);
    }
    if (paymentData && paymentData?.getPayment.errors === null) {
      // setForm();
      // setForm(payments);
      dispatch(
        alertActions.addAlert({
          id: generateRandomId(),
          message: ' Successfully Done Payment .',
          type: 'success',
        }),
      );
      //  setPaymentValues(paymentData?.getPayment?.payment);
    }
  }, [paymentData, paymentValues]);

  // console.log('Final Data Test====', paymentValues.id);
  // console.log('Final Data Test====', paymentValues.stripeChargeId);
  // console.log('Final Data Test====', paymentValues.stripeInvoiceNumber);

  return (
    <>
      <div>
        <div className="container">
          <div className="page-inner ">
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="payment-box">
                    <div className="sucess-bar">
                      <i className="fa  fa-check-circle" />
                      <h1> Payment Successfull </h1>
                    </div>
                    <ul className="payment-detail pb-0">
                      <div>
                        <li>Transction ID</li> <li>{paymentValues.stripeChargeId}</li>
                        <li>Invocie ID</li> <li>{paymentValues.stripeInvoiceNumber}</li>
                        <li>Date of Issue</li>{' '}
                        <li>
                          {moment(new Date(+paymentValues.stripeInvoiceCreated * 1000)).format(
                            'MMM-DD-YYYY',
                          )}
                        </li>
                        {/* <li>Expire Date</li>
                        <li>
                          {moment(new Date(paymentValues.stripePeriodEnd * 1000)).format(
                            'MMM-DD-YYYY',
                          )}
                        </li> */}
                      </div>
                    </ul>

                    <ul className="payment-detail pt-0">
                      <li>Payament type</li> <li>{paymentValues.paymentType}</li>
                      <li>Mobile</li> <li>{currentBiolink?.settings?.phone}</li>
                      <li>Email</li> <li>{paymentValues.stripeCustomerEmail}</li>
                    </ul>

                    <ul className="payment-detail">
                      <h3 className="model-h">
                        <li>Amount</li> <li>${paymentValues.stripeAmountPaid / 100}</li>
                      </h3>
                    </ul>

                    {/* <li>{item.stripeAmountPaid}</li> */}

                    <div className="btn-bar-bottom text-center sucusses-bar-div">
                      <a
                        href={paymentValues.stripeInvoicePdfUrl}
                        className="btn btn-primary pmt-cls"
                      >
                        Print
                      </a>

                      <button data-dismiss="modal" type="button" className="btn btn-danger pmt-cls">
                        Back{' '}
                      </button>
                    </div>
                  </div>

                  {userLoading ? <Loader /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
