import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useHandleResponse from '../useHandleResponse';
import { UPDATE_DONATION_SETTINGS_MUTATION } from '../../graphql/Mutations';
import * as appActions from '../../store/actions/app';
import * as alertActions from '../../store/actions/alert';
import * as authActions from '../../store/actions/auth';
import generateRandomId from '../../utils/genrateRandomId';

const updateDonationSettings = () => {
  const dispatch = useDispatch();
  const [updateDonationSettingsMutation, { loading, data }] = useMutation(
    UPDATE_DONATION_SETTINGS_MUTATION,
  );
  const { successMessage, errorMessage } = useHandleResponse();

  useEffect(() => {
    dispatch(appActions.isLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (data?.updateDonationSettings?.errors === null) {
      dispatch(authActions.updateDonationSettings(data?.updateDonationSettings.biolink));
      dispatch(
        alertActions.addAlert({
          id: generateRandomId(),
          message: 'Updated Successfully.',
          type: 'success',
        }),
      );
    }
    if (data?.updateDonationSettings?.errors?.length) {
      errorMessage(data?.updateDonationSettings?.errors);
    }
  }, [data]);

  return [
    updateDonationSettingsMutation,
    {
      data,
    },
  ];
};

export default updateDonationSettings;
