import moment from 'moment';
import {
  AUTHENTICATE,
  DELETE_BIOLINK,
  LOGIN,
  SET_CURRENT_BIOLINK,
  UPDATE_USER_DATA,
  UPDATE_STRIPE_SESSION_ID,
  UPDATE_PLANS,
  LOGOUT,
  UPDATE_BIOLINK,
  UPDATE_DARKMODE_SETTING,
  UPLOAD_BIOLINK_COVER_PHOTO,
  IMPORT_BIOLINK_DETAILS_FROM_LINKTREE_PROFILE,
  UPDATE_CONTACT_BUTTON_SETTINGS,
  CREATE_NEW_LINK,
  SORT_BIOLINK_LINKS,
  REMOVE_LINK,
  UPDATE_SOCIAL_ACCOUNTS_SETTINGS,
  UPDATE_INTEGRATION_SETTINGS,
  UPDATE_DIRECTORY_SETTINGS,
  UPDATE_UTM_PARAMETER_SETTINGS,
  UPDATE_SEO_SETTINGS,
  UPDATE_BRANDING_SETTINGS,
  UPDATE_PRIVACY_SETTINGS,
} from '../actions/auth';

const initialState = {
  user: {},
  isAuthenticated: false,
  currentBiolink: {},
  loginTime: null,
  stripeSessionId: null,
  plans: [],
};

const authReducer = (state = initialState, action) => {
  let updatedBiolink = {};
  const updatedUser = state.user;
  // console.log('check data ------', state.user);
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.user,
        currentBiolink: action.currentBiolink,
        isAuthenticated: true,
        loginTime: moment().unix(),
      };

    case AUTHENTICATE:
      if (state.currentBiolink?.id) {
        return {
          ...state,
          user: action.user,
          currentBiolink: action.currentBiolink,
          isAuthenticated: action.isAuthenticated,
        };
      }

      return {
        ...state,
        user: action.user,
        currentBiolink: action.currentBiolink,
        isAuthenticated: action.isAuthenticated,
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        user: action.user,
      };
    case SET_CURRENT_BIOLINK:
      return {
        ...state,
        currentBiolink: action.currentBiolink,
      };
    case DELETE_BIOLINK:
      const user = state.user;
      const biolinks = user.biolinks.slice();
      const index = biolinks.findIndex((item) => item.id === action.biolinkId);
      biolinks.splice(index, 1);
      let currentBiolink = null;
      const sortedBiolinks = user.biolinks?.slice().sort((a, b) => {
        if (parseInt(a.createdAt, 10) > parseInt(b.createdAt, 10)) return 1;
        if (parseInt(a.createdAt, 10) < parseInt(b.createdAt, 10)) return -1;
        return 0;
      });
      currentBiolink = {
        id: sortedBiolinks[0].id,
        username: sortedBiolinks[0].username,
      };
      return {
        ...state,
        user,
        currentBiolink,
      };
    case UPDATE_STRIPE_SESSION_ID:
      return {
        ...state,
        stripeSessionId: action.id,
      };

    case UPDATE_PLANS:
      return {
        ...state,
        plans: action.plans,
      };

    case LOGOUT:
      return initialState;

    case UPDATE_BIOLINK:
      updatedBiolink = {
        ...state.currentBiolink,
        bio: action.biolink.bio,
        category: action.biolink.category,
        city: action.biolink.city,
        country: action.biolink.country,
        displayName: action.biolink.displayName,
        state: action.biolink.state,
      };

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });

      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case UPDATE_DARKMODE_SETTING:
      updatedBiolink = {
        ...state.currentBiolink,
        settings: {
          ...state.currentBiolink.settings,
          enableDarkMode: action.biolink.settings.enableDarkMode,
        },
      };

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });
      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case UPLOAD_BIOLINK_COVER_PHOTO:
      updatedBiolink = {
        ...state.currentBiolink,
        coverPhotoUrl: action.biolink.coverPhotoUrl,
      };

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });

      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case IMPORT_BIOLINK_DETAILS_FROM_LINKTREE_PROFILE:
      updatedBiolink = action.biolink;

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });

      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case UPDATE_CONTACT_BUTTON_SETTINGS:
      updatedBiolink = {
        ...state.currentBiolink,
        settings: {
          ...state.currentBiolink.settings,
          email: action.biolink.settings.email,
          enableColoredContactButtons: action.biolink.settings.enableColoredContactButtons,
          phone: action.biolink.settings.phone,
          showEmail: action.biolink.settings.showEmail,
          showPhone: action.biolink.settings.showPhone,
        },
      };

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });
      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case CREATE_NEW_LINK:
      updatedBiolink = {
        ...state.currentBiolink,
        links: [...state.currentBiolink.links, action.link],
      };

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.link.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });

      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case SORT_BIOLINK_LINKS:
      updatedBiolink = {
        ...state.currentBiolink,
        links: action.biolink.links,
      };

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });

      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case REMOVE_LINK:
      const updatedLinks = state.currentBiolink.links.filter((item) => item.id !== action.link.id);

      updatedBiolink = {
        ...state.currentBiolink,
        links: updatedLinks,
      };

      // updatedUser.biolinks.forEach((biolink, i) => {
      //   if (biolink.id === state.currentBiolink.id) {
      //     updatedUser.biolinks[i] = updatedBiolink;
      //   }
      // });

      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case UPDATE_SOCIAL_ACCOUNTS_SETTINGS:
      updatedBiolink = {
        ...state.currentBiolink,
        settings: {
          ...state.currentBiolink.settings,
          enableColoredSocialMediaIcons: action.biolink.settings.enableColoredSocialMediaIcons,
          socialAccountStyleType: action.biolink.settings.socialAccountStyleType,
          // socialAccounts: action.biolink.settings.socialAccounts,
        },
      };

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });

      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case UPDATE_INTEGRATION_SETTINGS:
      updatedBiolink = {
        ...state.currentBiolink,
        settings: {
          ...state.currentBiolink.settings,
          emailCaptureId: action.biolink.settings.emailCaptureId,
          enableEmailCapture: action.biolink.settings.enableEmailCapture,
          enableFacebookPixel: action.biolink.settings.enableFacebookPixel,
          facebookPixelId: action.biolink.settings.facebookPixelId,
        },
      };

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });

      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case UPDATE_DIRECTORY_SETTINGS:
      updatedBiolink = {
        ...state.currentBiolink,
        settings: {
          ...state.currentBiolink.settings,
          addedToDirectory: action.biolink.settings.addedToDirectory,
          directoryBio: action.biolink.settings.directoryBio,
        },
      };

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });

      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case UPDATE_UTM_PARAMETER_SETTINGS:
      updatedBiolink = {
        ...state.currentBiolink,
        settings: {
          ...state.currentBiolink.settings,
          enableUtmParameters: action.biolink.settings.enableUtmParameters,
          utmCampaign: action.biolink.settings.utmCampaign,
          utmMedium: action.biolink.settings.utmMedium,
          utmSource: action.biolink.settings.utmSource,
        },
      };

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });

      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case UPDATE_SEO_SETTINGS:
      updatedBiolink = {
        ...state.currentBiolink,
        settings: {
          ...state.currentBiolink.settings,
          blockSearchEngineIndexing: action.biolink.settings.blockSearchEngineIndexing,
          pageTitle: action.biolink.settings.pageTitle,
          metaDescription: action.biolink.settings.metaDescription,
          opengraphImageUrl: action.biolink.settings.opengraphImageUrl,
        },
      };

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });

      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case UPDATE_BRANDING_SETTINGS:
      updatedBiolink = {
        ...state.currentBiolink,
        settings: {
          ...state.currentBiolink.settings,
          removeDefaultBranding: action.biolink.settings.removeDefaultBranding,
          enableCustomBranding: action.biolink.settings.enableCustomBranding,
          customBrandingName: action.biolink.settings.customBrandingName,
          customBrandingUrl: action.biolink.settings.customBrandingUrl,
        },
      };

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });

      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    case UPDATE_PRIVACY_SETTINGS:
      updatedBiolink = {
        ...state.currentBiolink,
        settings: {
          ...state.currentBiolink.settings,
          enablePasswordProtection: action.biolink.settings.enablePasswordProtection,
          enableSensitiveContentWarning: action.biolink.settings.enableSensitiveContentWarning,
        },
      };

      updatedUser.biolinks.forEach((biolink, i) => {
        if (biolink.id === action.biolink.id) {
          updatedUser.biolinks[i] = updatedBiolink;
        }
      });

      return {
        ...state,
        user: updatedUser,
        currentBiolink: updatedBiolink,
      };
    default:
      return state;
  }
};

export default authReducer;
