import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  UPDATE_SOCIAL_ACCOUNTS_SETTINGS_MUTATION,
  REMOVE_LINK_MUTATION,
  UPDATE_LINK_MUTATION,
  SORT_BIOLINK_LINKS_MUTATION,
} from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';

const SocialMediaList = () => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);


  const socialAccounts = currentBiolink?.links;
  const [isEditing, setisEditing] = useState(false);

  const [form, setForm] = useState({
    id: '',
    url: '',
    platform: '',
    iconMinimal: '',
    iconColorful: '',
    linkType: '',
    shortenedUrl: '',

    featured: false,
  });

  const [updateSocialAccountsSettings, { data }] = useNetwork(
    UPDATE_SOCIAL_ACCOUNTS_SETTINGS_MUTATION,
  );

  const [removeLinkMutation, { data: deleteData }] = useNetwork(REMOVE_LINK_MUTATION);
  const [updateLink, { data: updateData }] = useNetwork(UPDATE_LINK_MUTATION);
  console.log(" currentBiolin ********", currentBiolink)

  const inputChangeHandler = (name, value) => {
    console.log('show name==>', name);
    console.log('show name==>', value);

    setForm({
      ...form,
      [name]: value,
    });
  };
  const [socialAccountStyleType,setSocialAccountStyleType] = useState('');
  const [enableColoredSocialMediaIcons,setEnableColoredSocialMediaIcons] = useState('');

  useEffect(()=>{ setEnableColoredSocialMediaIcons(currentBiolink?.settings?.enableColoredSocialMediaIcons);
   setSocialAccountStyleType(currentBiolink?.settings?.socialAccountStyleType);},[currentBiolink]
   )

  const onDeleteSocialMedia = (platform) => {
    const socialAccountsArray = [];
    socialAccounts.forEach((item, index) => {
      if (item.platform !== platform) {
        socialAccountsArray.push({
          platform: item.platform,
          url: item.url,
        });
      }
    });

    // submit form
    updateSocialAccountsSettings({
      variables: {
        id: currentBiolink.id,
        socialAccounts: socialAccountsArray,
      },
    });
  };

  const onDeleteHandler = (id) => {
    removeLinkMutation({
      variables: {
        id,
      },
    });
  };

  const onEditHandler = (item) => {
    setisEditing(true);
    setForm({
      ...form,
      id: item.id,
      url: item.url,
      platform: item.platform,
      featured: true,
      shortenedUrl: item.shortenedUrl,
    });
  };

  const onCancelHandler = () => {
    setisEditing(false);
    setForm({
      ...form,
      id: '',
      url: '',
      platform: '',
      shortenedUrl: '',
    });
  };

  const onSaveHandlers = () => {
    const urlError = [];

    if (!form.url) urlError.push('URL cannot be empty');
    setForm({
      ...form,
      urlError,
    });

    if (urlError.length) return;

    updateLink({
      variables: {
        biolinkId: currentBiolink.id,
        id: form.id,
        url: form.url,
        linkType: 'Social',
        platform: form.platform,
        featured: true,
      },
    });
    // setisEditing(false);
    // setForm({
    //   ...form,
    //   id: '',
    //   url: '',
    //   platform: '',
    // });
    onCancelHandler();
  };

  return (
    <ul className="social-list-view social-link-section">
      {socialAccounts ? (
        socialAccounts.map((item, index) => {
           console.log('item=======>', item);
          return (
            <li key={index}>
              {/* ***** Start Editing from******** */}
              {isEditing && form.shortenedUrl === item.shortenedUrl ? (
                <div className="form-list dashboard-list">
                  {/* <input type="hidden" name="currentBiolink" value={item.shortenedUrl} /> */}
                  <div className="icon-view ">
                
                    
                 <img
                   src={ item.iconColorful||item.iconMinimal }
                   alt=""
                   style={{height:25,
                      width:25,

                  }}
                  />
                  </div> 
                  <div className="form-group form-floating-label">
                    <input
                      type="text"
                      placeholder="Enter the link here"
                      className="form-control input-solid"
                      name="url"
                      value={form.url}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                  </div>
                </div>
                
              ) : (
                <>
                                            
                              <div className="icon-view">                    
                              {/* <img src={item.iconMinimal || item.iconColorful} alt="" /> */}
                
                              <div
                  className={`social-links ${
                    enableColoredSocialMediaIcons == true ? 'color' : 'minimal'
                  }
                  ${
                    socialAccountStyleType == 'Round'
                      ? 'Round'
                      : socialAccountStyleType == 'Square'
                      ? 'Square'
                      : 'OneClick'
                  }
                  `}

                >   
                          { currentBiolink?.links ?enableColoredSocialMediaIcons == true ? (<>
                            <a   target="_blank" className="btn-admin social-media-link "
                            > <img src={item.iconColorful} alt="" style={{height:25,
                              width:25
                              
                          }}/> </a></>
                            ) : (<a  target="_blank" className="btn-admin social-media-link"
                            > <img src={item.iconMinimal} alt=""  style={{height:25,
                              width:25
        
                          }}/> </a>)
                             :null   
                        }
                               </div>
                               </div>
                               
                  <div className="link-view">{item.url}</div>
               
                </>
              )}
              {/* ***** End Editing from******** */}

              <div className="d-flex">
                {/* <button type="button" className="delbtn edit-sect">
                  <i className="fa fa-pencil" />
                </button>
                <button
                  className="delbtn"
                  type="button"
                  // onClick={() => onDeleteSocialMedia(item.platform)}
                  onClick={() => onDeleteHandler(item.id)}
                  style={{
                    paddingLeft: '0px',
                    paddingRight: '0px',
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true" />
                </button> */}
                {/* ***** Start Editing Button******** */}

                {isEditing && form.shortenedUrl === item.shortenedUrl ? (
                  <button
                    className="none links-action-btn ml-2 "
                    type="button"
                    onClick={() => onSaveHandlers()}
                  >
                    <i className="fa fa-save" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    className="none links-action-btn ml-2  edit-sect"
                    type="button"
                    onClick={() => onEditHandler(item)}
                  >
                    <i className="fa fa-pencil" aria-hidden="true" />
                  </button>
                )}
                {/* ***** End Editing from******** */}
                {/* ***** Start Editing from******** */}

                {isEditing && form.shortenedUrl === item.shortenedUrl ? (
                  <button
                    className="none links-action-btn delbtn"
                    type="button"
                    onClick={() => onCancelHandler(item.url)}
                  >
                    <i className="fa fa-times" aria-hidden="true"/>
                  </button>
                ) : (
                  <button
                    className="delbtn links-action-btn"
                    type="button"
                    onClick={() => onDeleteHandler(item.id)}
                  >
                    <i className="fa fa-trash" aria-hidden="true" />
                  </button>
                )}
                {/* ***** End Editing from******** */}
              </div>
            </li>
          );
        })
      ) : (
        <span>Social Media not found. Try adding some...</span>
      )}
    </ul>
  );
};

export default SocialMediaList;
