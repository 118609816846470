import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import generateRandomId from '../../../utils/genrateRandomId';
import * as alertActions from '../../../store/actions/alert';
import { ADD_REPORT } from '../../../graphql/Mutations';
import validate from '../../../utils/validations';
import images from '../../../assets/images';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';

const Report = () => {
  const dispatch = useDispatch();
  const [addReportQuery, { loading, data }] = useMutation(ADD_REPORT);
  const initialFormState = {
    firstname: '',
    firstnameError: [],
    lastname: '',
    lastnameError: [],
    email: '',
    emailError: [],
    url: '',
    urlError: [],
    reason: '',
    reasonError: [],
  };
  const [form, setForm] = useState(initialFormState);
  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    const firstnameError = [];
    const lastnameError = [];
    const emailError = [];
    const urlError = [];
    const reasonError = [];

    if (!form.firstname) firstnameError.push('First name cannot be empty');
    if (!form.lastname) lastnameError.push('Last name cannot be empty');
    //  if (!form.email) emailError.push('Email cannot be empty');
    if (!validate.emailFormat(form.email)) emailError.push('Enter valid email.');
    if (!form.url) urlError.push('Url cannot be empty');
    if (!form.reason) reasonError.push('Description cannot be empty');
    setForm({
      ...form,
      firstnameError,
      lastnameError,
      emailError,
      urlError,
      reasonError,
    });
    if (
      firstnameError.length ||
      lastnameError.length ||
      emailError.length ||
      urlError.length ||
      reasonError.length
    )
      return;
    addReportQuery({
      variables: {
        firstName: form.firstname,
        lastName: form.lastname,
        email: form.email,
        reportedUrl: form.url,
        description: form.reason,
      },
    });
  };

  useEffect(() => {
    if (data?.addReport?.errors === null) {
      dispatch(
        alertActions.addAlert({
          id: generateRandomId(),
          message: 'Updated Successfully.',
          type: 'success',
        }),
      );
      setForm({
        ...form,
        firstname: '',
        lastname: '',
        email: '',
        url: '',
        reason: '',
      });
    }
    if (data?.addReport?.errors) {
      dispatch(
        alertActions.addAlert({
          id: generateRandomId(),
          message: `${data?.addReport?.errors[0].field} ${data?.addReport?.errors[0].message}`,

          type: 'error',
        }),
      );
    }
  }, [data]);
  return (
    <>
      <div className="report-area ">
        <div className="container pt-4">
          <div className="row mt--2">
            <div className="col-md-12">
              <div className=" full-height mb-0">
                <div className="center-heading black-text heading-area">
                  <h1>Report A Violation</h1>
                  <h2>
                    <span className="blue-text">Stash.ee</span> Trust Center
                  </h2>
                </div>
                <div className="card-body">
                  <section className="feature-wrapper section-space pt-0 report-section">
                    <div className="report-detail w-75 mx-auto">
                      <p>
                        <b>
                          Please use the form below to report a violation or an account you think we
                          should review.
                        </b>
                      </p>
                      <p>
                        <b>
                          Please use the form below to report a violation or an account you think we
                          should review. You can view our Terms of Service here.
                        </b>
                      </p>
                      <p>
                        We take account violations very seriously, review all requests and take
                        action if required. Please note this form is not for general support
                        requests. If you need help with your own Stash account, please contact
                        support on support@Stash.ee or visit our support portal.
                      </p>
                      <div className="form-list w-75 report-form ">
                        <div className="form-group w-50 form-floating-label">
                          <input
                            id="inputFloatingLabelRe11"
                            type="text"
                            name="firstname"
                            className="form-control input-solid"
                            required
                            placeholder="First Name"
                            value={form.firstName}
                            onChange={(evt) =>
                              inputChangeHandler(evt.target.name, evt.target.value)
                            }
                          />
                          {form.firstnameError.length
                            ? form.firstnameError.map((error, i) => (
                                <ErrorSpan key={i} error={error} />
                              ))
                            : null}
                          {/* <label htmlFor="inputFloatingLabel2" className="placeholder">
                            First Name
                          </label> */}
                        </div>
                        <div className="form-group w-50 form-floating-label">
                          <input
                            id="inputFloatingLabelRe12"
                            type="text"
                            name="lastname"
                            className="form-control input-solid"
                            placeholder="Last Name"
                            required
                            value={form.lastname}
                            onChange={(evt) =>
                              inputChangeHandler(evt.target.name, evt.target.value)
                            }
                          />
                          {form.lastnameError.length
                            ? form.lastnameError.map((error, i) => (
                                <ErrorSpan key={i} error={error} />
                              ))
                            : null}
                          {/* <label htmlFor="inputFloatingLabel8" className="placeholder">
                            Last Name
                          </label> */}
                        </div>
                        <div className="form-group w-50 form-floating-label">
                          <input
                            id="inputFloatingLabelRe14"
                            type="text"
                            name="email"
                            placeholder="Email Address"
                            className="form-control input-solid"
                            required
                            value={form.email}
                            onChange={(evt) =>
                              inputChangeHandler(evt.target.name, evt.target.value)
                            }
                          />
                          {/* <label htmlFor="inputFloatingLabel3" className="placeholder">
                            Email Address
                          </label> */}
                          {form.emailError.length
                            ? form.emailError.map((error, i) => <ErrorSpan key={i} error={error} />)
                            : null}
                        </div>
                        <div className="form-group w-50 form-floating-label">
                          <input
                            id="inputFloatingLabelRe15"
                            type="text"
                            name="url"
                            className="form-control input-solid"
                            required
                            placeholder="Type URL of Linktree You Are Reporting"
                            value={form.url}
                            onChange={(evt) =>
                              inputChangeHandler(evt.target.name, evt.target.value)
                            }
                          />
                          {/* <label htmlFor="inputFloatingLabel4" className="placeholder">
                            Type URL of Linktree You Are Reporting
                          </label> */}
                          {form.urlError.length
                            ? form.urlError.map((error, i) => <ErrorSpan key={i} error={error} />)
                            : null}
                        </div>
                        <div className="form-group w-100 form-floating-label">
                          <textarea
                            id="inputFloatingLabelRe16"
                            className="form-control input-solid"
                            required
                            rows={4}
                            name="reason"
                            placeholder="Write Reason For Reporting This Profile"
                            value={form.reason}
                            onChange={(evt) =>
                              inputChangeHandler(evt.target.name, evt.target.value)
                            }
                          />
                          {/* <label htmlFor="inputFloatingLabel7" className="placeholder">
                            Write Reason For Reporting This Profile
                          </label> */}
                          {form.reasonError.length
                            ? form.reasonError.map((error, i) => (
                                <ErrorSpan key={i} error={error} />
                              ))
                            : null}
                        </div>
                        <div className="form-group w-100 text-center">
                          <button
                            type="button"
                            onClick={(evt) => onSubmitHandler(evt)}
                            className="btn btn-primary "
                          >
                            Submit Form
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="img-section center-img-section img-auto">
                      <img src={images.footerReport} alt="" />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
