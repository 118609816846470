import './style.css';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import appConfig from '../../../constants/appConfig';
import * as alertActions from '../../../store/actions/alert';

const Alert = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (appConfig.alertTimeout) {
      setTimeout(() => {
        dispatch(alertActions.removeAlert(props.alertId));
      }, appConfig.alertTimeout);
    }
  }, []);

  let alertClass =
    props.type === 'success'
      ? 'alert-success'
      : props.type === 'warning'
      ? 'alert-warning'
      : props.type === 'danger'
      ? 'alert-danger'
      : props.type === 'error'
      ? 'alert-error'
      : 'alert-default';
  alertClass = `alert-body ${alertClass}`;

  const removeAlertHandler = (id) => {
    dispatch(alertActions.removeAlert(id));
  };

  return (
    <div className="alert-container">
      <div className={alertClass}>
        <div className="alert-icon">
          <i className="fa fa-exclamation-circle fa-2x" />
        </div>

        <div className="alert-message">
          <span>{props.message}</span>
        </div>

        <div className="alert-actions">
          <i
            className="fa fa-close fa-2x"
            tabIndex="-10"
            role="button"
            onClick={() => removeAlertHandler(props.alertId)}
            onKeyUp={removeAlertHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default Alert;
