import React from 'react';
import { Link } from 'react-router-dom';

const Learnmore = () => {
  return (
    <>
      <div className="content">
        <div className="page-inner ">
          <div className="row mt--2">
            <div className="col-md-12">
              <div className="card full-height">
                <div className="card-body">
                  <div className="container">
                    <div className="monetize-box">
                      <Link to="/app" className="btn btn-primary rounded">
                        Monetize
                      </Link>
                      <p className="w-50 pt-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae quis
                        dignissim aliquam in tellus egestas. Rhoncus Lorem ipsum dolor sit amet
                      </p>
                      <ul className="monetize-list">
                        <li>
                          <span>
                            <i className="fa fa-check" />
                          </span>
                          Premium DM's
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" />
                          </span>
                          Audio &amp; Video Messages
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" />
                          </span>
                          Social Action
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" />
                          </span>
                          Digital Downloads
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" />
                          </span>
                          Physical Products
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-check" />
                          </span>
                          Much More
                        </li>
                      </ul>
                      <a href="#" className="btn btn-primary btn-border ">
                        Add Direct Access
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Learnmore;
