import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { UPLOAD_BIOLINK_COVER_PHOTO_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';

const BannerImage = () => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const [uploadBiolinkCoverPhoto, { data }] = useNetwork(UPLOAD_BIOLINK_COVER_PHOTO_MUTATION);

  const hiddenFileInput = useRef(null);

  const uploadDocumentHandler = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    e.persist();
    const [file] = e.target.files;
    if (file) {
      uploadBiolinkCoverPhoto({
        variables: {
          coverPhoto: file,
          id: currentBiolink.id,
        },
      });
    }
  };

  return (
    <>
      <a className="btn btn-white btn-border btn-round" onClick={() => uploadDocumentHandler()}>
        <i className="fa fa-edit px-1" aria-hidden="true" /> Edit Cover
      </a>

      <input
        type="file"
        name="profileImage"
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleChange}
      />
    </>
  );
};

export default BannerImage;
