import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';

import icons from '../../../assets/icons';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import { VERIFY_BIO_LINK } from '../../../graphql/Mutations';
import { FETCH_ALL_CATEGORIES, LOAD_USER_DETAILS } from '../../../graphql/Queries';
import validate from '../../../utils/validations';
import * as alertActions from '../../../store/actions/alert';
import * as appActions from '../../../store/actions/app';
import * as authActions from '../../../store/actions/auth';
import generateRandomId from '../../../utils/genrateRandomId';
import UpgradeModal from '../../../components/app/UpgradeModal';
import images from '../../../assets/images';
import Loader from '../../../components/ui/loader/Loader';

const VerificationBadge = ({ history }) => {
  // new code start

  const dispatch = useDispatch();
  const currentPlan = useSelector((state) => state.auth.user.plan);
  const [showPlanUpgradeDialog, setShowPlanUpgradeDialog] = useState(false);
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const [biolink, setBiolink] = useState({});
  const hiddenFileInput = React.useRef(null);
  const [currentUserName, setCurrentUserName] = useState('');
  const [currentBioId, setCurrentBioId] = useState('');
  const [currentFileSection, setCurrentFileSection] = useState('');
  const [currentFilePath, setCurrentFilePath] = useState('');
  const [currentFileBuisnessPath, setCurrentFileBuisnessPath] = useState('');
  const [currentFileOtherPath, setCurrentFileOtherPath] = useState('');
  const [currentFileName, setCurrentFileName] = useState({});
  const [verificationStatus, setVerificationStatus] = useState('');
  // select 2 code start here
  const [value1, setValue1] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [formTest, setFormTest] = useState({});

  const [refresh, setRefresh] = useState(false);

  const [items, setItems] = useState([]);

  // select 2 code end here

  /*  Open Code For Fetch Category */
  const {
    error: fetchcategoryError,
    loading: fetchcategoryLoading,
    data: fetchcategoryData,
  } = useQuery(FETCH_ALL_CATEGORIES, {
    variables: {
      first: 0,
    },
  });
  /*  Close Code For Fetch Category */

  /*  Open Code For Fetch UserName */
  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch: userRefetch,
  } = useQuery(LOAD_USER_DETAILS);
  useEffect(() => {
    userRefetch();
  }, []);
  /*  Close Code For Fetch UserName */

  useEffect(() => {
    dispatch(appActions.isLoading(userLoading));
    // console.log('userData=========>', userData);
  }, [userLoading]);

  /*  Open Code For Insert Details */
  const [verifyBiolink, { loading, data }] = useMutation(VERIFY_BIO_LINK);
  // console.log('Final Testing Process====>', data);
  /*  Close Code For Insert Details */
  const initialFormState = {
    biolinkUsername: '',
    username: currentUserName,
    usernameError: [],
    firstName: '',
    firstNameError: [],
    lastName: '',
    lastNameError: [],
    mobileNumber: '',
    mobileNumberError: [],
    workNumber: '',
    workNumberError: [],
    email: '',
    emailError: [],
    websiteLink: '',
    websiteLinkError: [],
    instagramAccount: '',
    instagramAccountError: [],
    twitterAccount: '',
    twitterAccountError: [],
    linkedinAccount: '',
    linkedinAccountError: [],
    categoryId: '',
    categoryIdError: [],
    photoId: '',
    photoIdError: [],
    businessDocument: '',
    businessDocumentError: [],
    otherDocuments: '',
    otherDocumentsError: [],
  };
  const [form, setForm] = useState(initialFormState);

  // console.log('Form Testing =====>', form);
  /*  Open Code For set biolinkUsername */
  useEffect(() => {
    // userRefetch();
    if (userData) {
      if (userData?.me?.biolinks.length) {
        const defaultBiolink = userData?.me?.biolinks.filter(
          (item) => item.id === currentBiolink.id,
        );
        setBiolink(defaultBiolink[0]);
        setCurrentUserName(defaultBiolink[0]?.username?.username);
        setVerificationStatus(defaultBiolink[0]?.verificationStatus);
        // setVerificationStatus('Pending');
        
        // console.log('defaultBiolink====>', defaultBiolink[0]?.verificationStatus);
        setCurrentBioId(currentBiolink.id);

        setForm({
          ...form,
          username: defaultBiolink[0]?.username,
        });
      }
    }
    // console.log('userData=========>', userData);
  }, [userData, verificationStatus, currentUserName]);
  /*  Close Code For set biolinkUsername */

  /*  Open Code For fetch category */
  // useEffect(() => {
  // }, [fetchcategoryData]);
  /*  Close Code For fetch category */

  useEffect(() => {
    dispatch(appActions.isLoading(loading));
  }, [loading]);

  const inputChangeHandler = (name, value) => {
    // console.log('name=======', name);
    if (!currentPlan.settings.verifiedCheckmarkEnabled) {
      setShowPlanUpgradeDialog(true);
      return;
    }
    // add validations here
    // update values
    setForm({
      ...form,
      [name]: value,
      username: currentUserName,
    });
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const temp = [];
    fetchcategoryData?.fetchAllCategories?.edges?.forEach((value) => {
      temp.push({
        label: value.node.categoryName,
        value: value.node.id,
      });
    });
    setOptions(temp);
  }, [fetchcategoryData]);

  const handlerChange = (value2) => {
    // console.log('Test Check ', value2);
    // const value3 = '554,20'.split(',');
    // const v1 = parseFloat(value3[0]);
    // // const v2 = parseFloat(values[1])
    // console.log('value -----', v1);
    setFormTest(value2);
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    if (!currentPlan.settings.verifiedCheckmarkEnabled) {
      setShowPlanUpgradeDialog(true);
      return;
    }
    const usernameError = [];
    const firstNameError = [];
    const lastNameError = [];
    const mobileNumberError = [];
    const workNumberError = [];
    const emailError = [];
    const websiteLinkError = [];
    const instagramAccountError = [];
    const twitterAccountError = [];
    const linkedinAccountError = [];
    const categoryIdError = [];
    const photoIdError = [];
    const businessDocumentError = [];
    const otherDocumentsError = [];
    // add validations here

    if (!currentUserName) usernameError.push('User name cannot be empty');
    if (!form.firstName) firstNameError.push('First name cannot be empty');
    if (!form.lastName) lastNameError.push('Last name cannot be empty');
    if (!form.mobileNumber) mobileNumberError.push('Mobile number cannot be empty');
    if (!form.workNumber) workNumberError.push('Work Number cannot be empty');
    if (!validate.emailFormat(form.email)) emailError.push('Enter valid email.');
    if (!form.websiteLink) websiteLinkError.push('Website Link cannot be empty');
    if (!form.instagramAccount) instagramAccountError.push('Instagram Account cannot be empty');
    if (!form.twitterAccount) twitterAccountError.push('Twitter Account cannot be empty');
    if (!form.linkedinAccount) linkedinAccountError.push('Linkedin Account cannot be empty');
    // if (!form.categoryId) categoryIdError.push('Category cannot be empty');
    // if (!formTest.categoryId) categoryIdError.push('Category cannot be empty');
    if (!currentFilePath) photoIdError.push('Photo Id cannot be empty');
    if (!currentFileBuisnessPath) businessDocumentError.push('Business Document cannot be empty');
    if (!currentFileOtherPath) otherDocumentsError.push('Other Document cannot be empty');

    setForm({
      ...form,
      usernameError,
      firstNameError,
      lastNameError,
      mobileNumberError,
      workNumberError,
      emailError,
      websiteLinkError,
      instagramAccountError,
      twitterAccountError,
      linkedinAccountError,
      categoryIdError,
      photoIdError,
      businessDocumentError,
      otherDocumentsError,
    });

    if (
      usernameError.length ||
      firstNameError.length ||
      lastNameError.length ||
      mobileNumberError.length ||
      workNumberError.length ||
      emailError.length ||
      websiteLinkError.length ||
      instagramAccountError.length ||
      twitterAccountError.length ||
      linkedinAccountError.length ||
      categoryIdError.length ||
      photoIdError.length ||
      businessDocumentError.length ||
      otherDocumentsError.length
    )
      return;
    // submit form
    verifyBiolink({
      variables: {
        biolinkId: currentBioId,
        username: currentUserName,
        firstName: form.firstName,
        lastName: form.lastName,
        mobileNumber: form.mobileNumber,
        workNumber: form.workNumber,
        email: form.email,
        websiteLink: form.websiteLink,
        instagramAccount: form.instagramAccount,
        twitterAccount: form.twitterAccount,
        linkedinAccount: form.linkedinAccount,
        // categoryId: form.categoryId,
        // categoryId: parseFloat(formTest.value),
        categoryId: formTest.value.toString(),
        photoId: currentFilePath,
        businessDocument: currentFileBuisnessPath,
        otherDocuments: currentFileOtherPath,
        //  photoId: form.photoId,
      },
    });
    setCurrentFileName({});
    dispatch(appActions.isLoading(loading));
    window.location.reload(true);
  };
  // window.location.reload(true);
  // useEffect(() => {
  //   dispatch(appActions.isLoading(loading));
  // }, [loading]);

  const uploadDocumentHandler = (event) => {
    hiddenFileInput.current.click();
    setCurrentFileSection(event);
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (currentFileSection == 'photo-id') {
      setCurrentFilePath(event.target.files[0]);
      // setCurrentFileName(event.target.files[0].name);
      setCurrentFileName({ ...currentFileName, FilePhoto: event.target.files[0].name });
    }
    if (currentFileSection == 'buisness-document') {
      setCurrentFileBuisnessPath(event.target.files[0]);
      //  setCurrentFileName(event.target.files[0].name);
      setCurrentFileName({ ...currentFileName, FileBuisness: event.target.files[0].name });
    }
    if (currentFileSection == 'other-document') {
      setCurrentFileOtherPath(event.target.files[0]);
      setCurrentFileName({ ...currentFileName, FileOther: event.target.files[0].name });
    }
  };

  useEffect(() => {
    const photoIdError = [];
    if (currentFilePath) {
      if (currentFileSection.trim() === 'photo-id') {
        if (currentFilePath.type != 'image/jpeg') photoIdError.push('Please select valid image.');
      }
      setForm({
        ...form,
        photoIdError,
      });
    }
  }, [currentFilePath]);

  useEffect(() => {
    const businessDocumentError = [];
    if (currentFileBuisnessPath) {
      if (currentFileSection.trim() === 'buisness-document') {
        if (currentFileBuisnessPath?.type === 'image/jpeg')
          businessDocumentError.push('Please select valid document type.');
      }
    }
    setForm({
      ...form,
      businessDocumentError,
    });
  }, [currentFileBuisnessPath]);

  useEffect(() => {
    const otherDocumentsError = [];
    if (currentFileOtherPath) {
      if (currentFileSection.trim() === 'other-document') {
        if (currentFileOtherPath?.type === 'image/jpeg')
          otherDocumentsError.push('Please select valid document type.');
      }
    }
    setForm({
      ...form,
      otherDocumentsError,
    });
  }, [currentFileOtherPath]);

  /*  Open Code For after Successfully */
  useEffect(() => {
    if (data) {
      if (data?.verifyBiolink?.errors === null) {
        dispatch(
          alertActions.addAlert({
            id: generateRandomId(),
            message: 'Sent Successfully.',
            type: 'success',
          }),
        );
        setForm(initialFormState);
      } else {
        // field

        dispatch(
          alertActions.addAlert({
            id: generateRandomId(),
            message: `${data?.verifyBiolink?.errors[0].field} ${data?.verifyBiolink?.errors[0].message}`,
            type: 'error',
          }),
        );
      }
    }
  }, [data]);
  /* Close Code For after Successfully */

  useEffect(() => {
    setTimeout(() => {
      setRefresh(!refresh);
    }, 3000);
  }, [items]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" style={{ backgroundColor: 'red' }} {...props}>
      username cannot be changed
    </Tooltip>
  );
  // console.log('Last Testing ======', formTest);

  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner verification-section ">
            <div className="row mt--2">
              <div className="col-md-12">
              <div className={`card full-height ${verificationStatus == 'Pending' ? 'pending' : verificationStatus == 'Verified' ? 'verified' : verificationStatus == 'Rejected' ? 'rejected' : ''} `}>
                  <div className="heading-box">
                    <h1>Verification badge</h1>
                    <h2>Get A Verification CheckMark on Your Page</h2>
                  </div>
                  <div className="card-body">
                    <div className="badge-box">
                      <p>
                        A verification badge is a checkmark that appears next to your Stash.ee
                        account’s name to indicate that you:
                      </p>
                      <ul>
                        <li>
                          Are the authentic presence of a notable public figure, brand, or entity it
                          represents.
                        </li>
                        <li>Have been featured in news articles and similar publications.</li>
                        <li>
                          Are a published author, licensed professional, or have been verified on
                          one or more social media websites and platforms.
                        </li>
                      </ul>
                      <p className="small note">
                        **Submitting a request for verification does not guarantee that your account
                        will be verified.**
                      </p>
                      <p>
                        Verifications apply to one account and username. You cannot change your
                        username once verification is honored or you will lose the verification
                        badge.
                      </p>
                      <p>Verifications cannot be transferred to another account.</p>
                    </div>
                    {verificationStatus == 'Not Applied' ? (
                      <form>
                        <ul className="badge-form">
                          <li>
                            <div className="form-group form-floating-label">
                              <OverlayTrigger
                                placement="left"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                              >
                                <input
                                  id="inputFloatingLabel2"
                                  name="username"
                                  type="text"
                                  className="form-control input-solid"
                                  required
                                  disabled="disabled"
                                  value={currentUserName}
                                  style={{ color: 'red' }}
                                />
                              </OverlayTrigger>
                            </div>
                          </li>
                          <li>
                            <div className="form-group form-floating-label">
                              <input
                                id="inputFloatingLabel3"
                                name="firstName"
                                placeholder="First Name"
                                type="text"
                                className="form-control input-solid"
                                value={form.firstName}
                                onChange={(evt) =>
                                  inputChangeHandler(evt.target.name, evt.target.value)
                                }
                                required
                              />
                              {/* <label htmlFor="inputFloatingLabel3" className="placeholder">
                                First Name
                              </label> */}
                              {form.firstNameError.length
                                ? form.firstNameError.map((error, i) => (
                                    <ErrorSpan key={i} error={error} />
                                  ))
                                : null}
                            </div>
                          </li>
                          <li>
                            <div className="form-group form-floating-label">
                              <input
                                id="inputFloatingLabel4"
                                name="lastName"
                                placeholder="Last Name"
                                type="text"
                                className="form-control input-solid"
                                value={form.lastName}
                                onChange={(evt) =>
                                  inputChangeHandler(evt.target.name, evt.target.value)
                                }
                                required
                              />
                              {/* <label htmlFor="inputFloatingLabel4" className="placeholder">
                                Last Name
                              </label> */}
                              {form.lastNameError.length
                                ? form.lastNameError.map((error, i) => (
                                    <ErrorSpan key={i} error={error} />
                                  ))
                                : null}
                            </div>
                          </li>
                          <li>
                            <div className="form-group form-floating-label">
                              <input
                                id="inputFloatingLabel5"
                                name="mobileNumber"
                                type="text"
                                placeholder="Mobile Number"
                                className="form-control input-solid"
                                value={form.mobileNumber}
                                onChange={(evt) =>
                                  inputChangeHandler(evt.target.name, evt.target.value)
                                }
                                required
                              />
                              {/* <label htmlFor="inputFloatingLabel5" className="placeholder">
                                Mobile Number
                              </label> */}
                              {form.mobileNumberError.length
                                ? form.mobileNumberError.map((error, i) => (
                                    <ErrorSpan key={i} error={error} />
                                  ))
                                : null}
                            </div>
                          </li>
                          <li>
                            <div className="form-group form-floating-label">
                              <input
                                id="inputFloatingLabel6"
                                name="workNumber"
                                type="text"
                                placeholder="Work Number"
                                className="form-control input-solid"
                                value={form.workNumber}
                                onChange={(evt) =>
                                  inputChangeHandler(evt.target.name, evt.target.value)
                                }
                                required
                              />
                              {/* <label htmlFor="inputFloatingLabel6" className="placeholder">
                                Work Number
                              </label> */}
                              {form.workNumberError.length
                                ? form.workNumberError.map((error, i) => (
                                    <ErrorSpan key={i} error={error} />
                                  ))
                                : null}
                            </div>
                          </li>
                          <li>
                            <div className="form-group form-floating-label">
                              <input
                                id="inputFloatingLabel7"
                                name="email"
                                type="text"
                                placeholder="Email Address"
                                className="form-control input-solid"
                                value={form.email}
                                onChange={(evt) =>
                                  inputChangeHandler(evt.target.name, evt.target.value)
                                }
                                required
                              />
                              {/* <label htmlFor="inputFloatingLabel7" className="placeholder">
                                Email Address
                              </label> */}
                              {form.emailError.length
                                ? form.emailError.map((error, i) => (
                                    <ErrorSpan key={i} error={error} />
                                  ))
                                : null}
                            </div>
                          </li>
                          <li>
                            <div className="form-group form-floating-label">
                              <input
                                id="inputFloatingLabel8"
                                name="websiteLink"
                                placeholder="Website Link"
                                type="text"
                                className="form-control input-solid"
                                value={form.websiteLink}
                                onChange={(evt) =>
                                  inputChangeHandler(evt.target.name, evt.target.value)
                                }
                                required
                              />
                              {/* <label htmlFor="inputFloatingLabel8" className="placeholder">
                                Website Link
                              </label> */}
                              {form.websiteLinkError.length
                                ? form.websiteLinkError.map((error, i) => (
                                    <ErrorSpan key={i} error={error} />
                                  ))
                                : null}
                            </div>
                          </li>
                          <li className="full-width">
                            <h3>SOCIAL MEDIA LINKS</h3>
                          </li>
                          <li>
                            <div className="form-group form-floating-label">
                              <input
                                id="inputFloatingLabel9"
                                name="instagramAccount"
                                placeholder="Instagram Account"
                                type="text"
                                className="form-control input-solid"
                                value={form.instagramAccount}
                                onChange={(evt) =>
                                  inputChangeHandler(evt.target.name, evt.target.value)
                                }
                                required
                              />
                              {/* <label htmlFor="inputFloatingLabel9" className="placeholder">
                                Instagram Account
                              </label> */}
                              {form.instagramAccountError.length
                                ? form.instagramAccountError.map((error, i) => (
                                    <ErrorSpan key={i} error={error} />
                                  ))
                                : null}
                            </div>
                          </li>
                          <li>
                            <div className="form-group form-floating-label">
                              <input
                                id="inputFloatingLabe20"
                                name="twitterAccount"
                                placeholder="Twitter Account"
                                type="text"
                                className="form-control input-solid"
                                value={form.twitterAccount}
                                onChange={(evt) =>
                                  inputChangeHandler(evt.target.name, evt.target.value)
                                }
                                required
                              />
                              {/* <label htmlFor="inputFloatingLabe20" className="placeholder">
                                Twitter Account
                              </label> */}
                              {form.twitterAccountError.length
                                ? form.twitterAccountError.map((error, i) => (
                                    <ErrorSpan key={i} error={error} />
                                  ))
                                : null}
                            </div>
                          </li>
                          <li>
                            <div className="form-group form-floating-label">
                              <input
                                id="inputFloatingLabe21"
                                name="linkedinAccount"
                                type="text"
                                placeholder="LinkedIn Account"
                                className="form-control input-solid"
                                value={form.linkedinAccount}
                                onChange={(evt) =>
                                  inputChangeHandler(evt.target.name, evt.target.value)
                                }
                                required
                              />
                              {/* <label htmlFor="inputFloatingLabe21" className="placeholder">
                                LinkedIn Account
                              </label> */}
                              {form.linkedinAccountError.length
                                ? form.linkedinAccountError.map((error, i) => (
                                    <ErrorSpan key={i} error={error} />
                                  ))
                                : null}
                            </div>
                          </li>
                          <li>
                            <div className="form-group form-floating-label">
                              {/* <select
                                className="form-control input-solid"
                                id="selectFloatingLabe22"
                                name="categoryId"
                                onChange={(evt) =>
                                  inputChangeHandler(evt.target.name, evt.target.value)
                                }
                                required
                              >
                                <option value="">SELECT CATEGORY</option>
                                {fetchcategoryData?.fetchAllCategories?.edges?.map(
                                  (value, index) => {
                                    return (
                                      <option value={value.node.id}>
                                        {value.node.categoryName}
                                      </option>
                                    );
                                  },
                                )}
                              </select> */}

                              <Select
                                id="selectFloatingLabel8"
                                name="value2"
                                placeholder="Select Category"
                                inputValue={inputValue?.node}
                                // defaultValue={options[0]}
                                onChange={handlerChange}
                                options={options}
                                value={value1?.value}
                              />

                              {form.categoryIdError.length
                                ? form.categoryIdError.map((error, i) => (
                                    <ErrorSpan key={i} error={error} />
                                  ))
                                : null}
                            </div>
                          </li>
                          <li className="mt-2 ml-2">
                            <label className="block-box">PHOTO ID</label>
                            <div
                              className="file btn  btn-success"
                              onClick={() => uploadDocumentHandler('photo-id')}
                              onKeyUp={() => uploadDocumentHandler('photo-id')}
                            >
                              <i className="fa fa-cloud-upload"> </i> Upload
                              <input
                                ref={hiddenFileInput}
                                type="file"
                                name="photoId"
                                className="input-design nonebg"
                                style={{ display: 'none' }}
                                onChange={(event) => handleChange(event)}
                              />
                            </div>
                            {currentFileName?.FilePhoto ? (
                              <div style={{ color: '#2a54ef' }}>{currentFileName?.FilePhoto}</div>
                            ) : null}
                            {form.photoIdError.length
                              ? form.photoIdError.map((error, i) => (
                                  <ErrorSpan key={i} error={error} />
                                ))
                              : null}
                          </li>
                          <li className="mt-2 ml-2">
                            <label className="block-box">BUISNESS DOCUMENT</label>
                            <div
                              className="file btn  btn-success"
                              onClick={() => uploadDocumentHandler('buisness-document')}
                              onKeyUp={() => uploadDocumentHandler('buisness-document')}
                            >
                              <i className="fa fa-cloud-upload"> </i> Upload
                              <input
                                type="file"
                                name="businessDocument"
                                className="input-design nonebg"
                                style={{ display: 'none' }}
                                onChange={(event) => handleChange(event)}
                              />
                            </div>
                            {currentFileName?.FileBuisness ? (
                              <div style={{ color: '#2a54ef' }}>
                                {currentFileName?.FileBuisness}
                              </div>
                            ) : null}
                            {form.businessDocumentError.length
                              ? form.businessDocumentError.map((error, i) => (
                                  <ErrorSpan key={i} error={error} />
                                ))
                              : null}
                          </li>
                          <li className="mt-2 ml-2 ">
                            <label className="block-box">OTHER DOCUMENTS</label>
                            <div
                              className="file btn  btn-success "
                              onClick={() => uploadDocumentHandler('other-document')}
                              onKeyUp={() => uploadDocumentHandler('other-document')}
                            >
                              <i className="fa fa-cloud-upload"> </i> Upload
                              <input
                                type="file"
                                name="otherDocuments"
                                className="input-design"
                                style={{ display: 'none' }}
                                onChange={(event) => handleChange(event)}
                              />
                            </div>
                            {currentFileName?.FileOther ? (
                              <div style={{ color: '#2a54ef' }}>{currentFileName?.FileOther}</div>
                            ) : null}
                            {form.otherDocumentsError.length
                              ? form.otherDocumentsError.map((error, i) => (
                                  <ErrorSpan key={i} error={error} />
                                ))
                              : null}
                          </li>
                          <li className="full-width">
                            <div className="inline-box">
                              <p>VERIFICATION FEES</p>
                              <p className="block note">Money Back Guarantee)</p>
                            </div>
                            <div className="inline-box">
                              <span className="fees">$47.00</span>
                            </div>
                          </li>
                          <li className="full-width">
                            <a
                              href="#"
                              className="btn btn-primary btn-lg"
                              onClick={(evt) => onSubmitHandler(evt)}
                            >
                              Submit
                            </a>
                          </li>
                        </ul>
                      </form>
                    )  : null}
                    {/* {loading ? <Loader /> : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpgradeModal
        currentState={showPlanUpgradeDialog}
        onUpgrade={() => history.push('/app/upgrade')}
        onClose={() => setShowPlanUpgradeDialog(false)}
      />
    </>
  );
};

export default VerificationBadge;
