import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import copy from 'clipboard-copy';
import UpgradeModal from '../../../components/app/UpgradeModal';
import FormHeader from '../../../components/ui/buttons/FormHeader';
import ToggleSwitch from '../../../components/ui/ToggleSwitch';
import { UPDATE_DARK_MODE_OPTIONS_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';
import socialMediaIcons from '../../../assets/socialMediaIcons';
import * as alertActions from '../../../store/actions/alert';
import generateRandomId from '../../../utils/genrateRandomId';
import BannerImage from './BannerImage';
import BrandingSettings from './BrandingSettings';
import ContactButtons from './ContactButtons';
import DirectoryForm from './DirectoryForm';
import ImportForm from './ImportForm';
import IntegrationForm from './IntegrationForm';
import LinksForm from './LinksForm';
import LinksList from './LinksList';
import PaymentsForm from './PaymentsForm';
import PrivacyForm from './PrivacyForm';
import ProfileForm from './ProfileForm';
import ProfileImage from './ProfileImage';
import SEOSettingsForm from './SEOSettingsForm';
import SocialMediaForm from './SocialMediaForm';
import SocialMediaList from './SocialMediaList';
import UTMParameters from './UTMParameters';
import images from '../../../assets/images';

const Dashboard = () => {
  const dispatch = useDispatch();

  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const currentPlan = useSelector((state) => state.auth.user.plan);

  const [enableColoredSocialMediaIcons,setEnableColoredSocialMediaIcons] = useState('');
  const [socialAccountStyleType,setSocialAccountStyleType] = useState('');

  useEffect(() => {
    setEnableColoredSocialMediaIcons(currentBiolink?.settings?.enableColoredSocialMediaIcons);
    setSocialAccountStyleType(currentBiolink?.settings?.socialAccountStyleType);
   // console.log('enableColoredSocialMediaIcons====>', currentBiolink?.settings?.enableColoredSocialMediaIcons);
    // console.log('socialAccountStyleType====>', currentBiolink?.settings?.socialAccountStyleType);
  }, [currentBiolink]);


  const [showPlanUpgradeDialog, setShowPlanUpgradeDialog] = useState(false);

  const [form, setForm] = useState({
    enableDarkMode: false,
  });

  const [updateDarkModeOptions] = useNetwork(UPDATE_DARK_MODE_OPTIONS_MUTATION);

  useEffect(() => {
    setForm({
      enableDarkMode: currentBiolink?.settings?.enableDarkMode,
    });
  }, [currentBiolink]);

  const onToggleDarkMode = () => {
    if (!currentPlan?.settings?.darkModeEnabled) {
      setShowPlanUpgradeDialog(true);
      return;
    }
    setForm({
      enableDarkMode: !form.enableDarkMode,
    });
    updateDarkModeOptions({
      variables: {
        enableDarkMode: !form.enableDarkMode,
        id: currentBiolink?.id,
      },
    });
  };

  const onCopyHandler = (url) => {
    copy(url);

    dispatch(
      alertActions.addAlert({
        id: generateRandomId(),
        message: 'Link copied.',
        type: 'success',
      }),
    );
  };
  return (
    <div className="main-panel">
      <div className="content">
        <div className="page-inner col-md-10 offset-1">
          <div className="panel-header profile-header">
            <div className=" header-bg-01">
              <div
                className="
                  d-flex
                  align-items-left align-items-md-center
                  flex-column flex-md-row
                "
              >
                <div className="profile-edit-wrapper bg-edit-section">
                  <BannerImage />
                </div>
              </div>

              <div className=" scroll-fix-header ">
                <div className=" scroll-toggle">
                  <div className=" theam-label ">
                    <label>Dark Mode</label>
                  </div>
                  {/* <ToggleSwitch
                    currentState={currentBiolink?.settings?.enableDarkMode}
                    onToggle={() => onToggleDarkMode()}
                    isActive={currentPlan?.settings?.darkModeEnabled}
                  /> */}

                  <div className="swap-box">
                    <div className="clearfix">
                      <div className="swap-btn-bar-admin">
                        <a
                          className={
                            currentBiolink?.settings?.enableDarkMode
                              ? 'pull-left'
                              : 'pull-left active'
                          }
                          onClick={() => onToggleDarkMode()}
                        />

                        <a
                          className={
                            currentBiolink?.settings?.enableDarkMode
                              ? 'pull-right active'
                              : 'pull-right'
                          }
                          onClick={() => onToggleDarkMode()}
                        />
                      </div>
                      <div className="lockimages">
                        {currentPlan?.settings?.darkModeEnabled !== 'na' ? (
                          currentPlan?.settings?.darkModeEnabled ? (
                            <img src={images.dashboardLockIcon} alt="" />
                          ) : (
                            <img src={images.dashboardLockIcon} alt="" />
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" scroll-fix-btn">
                  <button type="button" className="" data-toggle="modal" data-target="#modal-1">
                    <span>
                      <i className="fa fa-plus-circle" />
                    </span>
                    Add new item
                  </button>
                  <div className="usd-icon">
                    <Link to="/app/access">
                      <i className="fa fa-usd" aria-hidden="true" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="dt-changeProfile  ">
                <div className="theams-toggle ">
                  <div className=" theam-label ">
                    <label>Dark Mode</label>
                  </div>
                  {/* <ToggleSwitch
                    currentState={currentBiolink?.settings?.enableDarkMode}
                    onToggle={() => onToggleDarkMode()}
                    isActive={currentPlan?.settings?.darkModeEnabled}
                  /> */}

                  <div className="swap-box">
                    <div className="clearfix">
                      <div className="swap-btn-bar-admin">
                        <a
                          className={
                            currentBiolink?.settings?.enableDarkMode
                              ? 'pull-left'
                              : 'pull-left active'
                          }
                          onClick={() => onToggleDarkMode()}
                        />

                        <a
                          className={
                            currentBiolink?.settings?.enableDarkMode
                              ? 'pull-right active'
                              : 'pull-right'
                          }
                          onClick={() => onToggleDarkMode()}
                        />
                      </div>
                      <div className="lockimages">
                        {currentPlan?.settings?.darkModeEnabled !== 'na' ? (
                          currentPlan?.settings?.darkModeEnabled ? (
                            <img src={images.dashboardLockIcon} alt="" />
                          ) : (
                            <img src={images.dashboardLockIcon} alt="" />
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="aded-btn ">
                  <button type="button" className="" data-toggle="modal" data-target="#modal-1">
                    <span>
                      <i className="fa fa-plus-circle" />
                    </span>
                    Add new item
                  </button>
                  <div className="usd-icon">
                    <Link to="/app/access">
                      <i className="fa fa-usd" aria-hidden="true" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swap-btn-bar-admin rightdv topmargin edit-preview">
            <Link to="/app" className={location.pathname !== '/app/preview' ? 'active' : ''}>
              Editor
            </Link>
            <Link to="/preview" className={location.pathname === '/app/preview' ? 'active' : ''}>
              Preview
            </Link>
          </div>
          <div className="white-box dashboard mb-5">
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="preview-wrapper">
                  <div className="profile-left userProfile">
                    <div className="profile-box">
                      <div className="user-profile">
                        <img src={images.dashboardProfile} alt="" />
                        <ProfileImage />
                      </div>
                      {/* <div className="file-btn">
                        <img src="./assets/img/pluse.svg" alt="" />
                      </div> */}
                    </div>
                    <div className="text-left text-heading">
                      <h3 className="mb-1">{currentBiolink?.displayName}</h3>
                      <p className="mb-1 hed-title">
                        <span className="label-box">
                          <i className="fa fa-link" />
                        </span>
                        {/* <span className="label-detail">
                          stash.ee/{currentBiolink?.username.username} */}
                        {/* <span
                            className="copy-button"
                            onClick={() =>
                              onCopyHandler(`stash.ee/${currentBiolink?.username?.username}`)
                            }
                          > */}
                        {/* <span
                            className="copy-button label-detail"
                            onClick={() =>
                              onCopyHandler(`stash.ee/${currentBiolink?.username?.username}`)
                            }
                          >
                            <i className="fa fa-clone" aria-hidden="true" />
                          </span>
                        </span> */}

                        {/* <li> */}
                        <span
                          className="label-detail"
                          onClick={() =>
                            // onCopyHandler(`stash.ee/${currentBiolink?.username?.username}`)
                            onCopyHandler(
                              `https://avyatated.com/${currentBiolink?.username?.username}`,
                            )
                          }
                        >
                          stash.ee/{currentBiolink?.username?.username}
                          <i className="fa fa-clone" aria-hidden="true" />
                        </span>
                        {/* </li> */}
                      </p>
                    </div>
                  </div>

                  <div className="user-detail user-dt-head row align-items-center">
                    <ul className=" mt-2 mb-2 col-xl-6 col-md-12 col-sm-12">
                      <li>
                        <span className="label-detail user-dt-head">
                          <h5>{currentBiolink?.bio}</h5>
                        </span>
                      </li>
                      <li className="user-detail-list">
                        <span className="label-box">
                          <i className="fa fa-map-marker" />
                          Address:
                        </span>
                        <span className="label-detail">
                          {currentBiolink?.city}
                          {currentBiolink?.city && currentBiolink?.state && <span>, </span>}
                          {currentBiolink?.state}
                          {currentBiolink?.state && currentBiolink?.country && <span>, </span>}
                          {currentBiolink?.country}
                        </span>
                      </li>
                      <li className="user-detail-list">
                        <span className="label-box">
                          <i className="fa fa-envelope" />
                          Email:
                        </span>
                        <span className="label-detail">
                          {currentBiolink?.settings?.email || ''}
                        </span>
                      </li>

                      <li className="user-detail-list">
                        <span className="label-box">
                          <i className="fa fa-phone" />
                          Call:
                        </span>
                        <span className="label-detail">{currentBiolink?.settings?.phone}</span>
                      </li>

                      {/* <!--<li><span className="label-box">Donations:</span> <span className="label-detail">  <div className="donation-box"><a href="#"><img src="./assets/img/paypal.png" alt=""/></a>
                      <a href="#"><img src="./assets/img/vi.png" alt=""/></a>
                      <a href="#"><img src="./assets/img/pay.png" alt=""/></a></div>
                      </span>
                      </li>--> */}
                    </ul>

                    {/* <div className="socialicon">
                      <span>
                        <i className="fa fa-facebook-square" /> Facebook
                      </span>
                      <span>
                        <i className="fa fa-twitter" /> Twitter
                      </span>
                      <span>
                        <i className="fa fa-instagram" /> Instagram
                      </span>
                      <span>
                        <i className="fa fa-linkedin" /> Linkdin
                      </span>
                    </div> */}
                    <div className="col-xl-6 col-md-12 col-sm-12">
                      <div className="footer-social-links author-social-admin social-media-container">
                      
                     
                      <div
                  className={`social-links ${
                    enableColoredSocialMediaIcons == true ? 'color' : 'minimal'
                  }
                  ${
                    socialAccountStyleType == 'Round'
                      ? 'Round'
                      : socialAccountStyleType == 'Square'
                      ? 'Square'
                      : 'OneClick'
                  }
                  `}
                >
                          {currentBiolink?.links
                            ? currentBiolink?.links.map((sdata, index) => (
                           
                            enableColoredSocialMediaIcons == true ? (<>
                            <a key={index} href={`${sdata.url}`} target="_blank" className="btn-admin social-media-link"
                            > <img src={sdata.iconColorful} alt=""/> </a></>
                            ) : <a key={index} href={`${sdata.url}`} target="_blank" className="btn-admin social-media-link"
                            > <img src={sdata.iconMinimal} alt="" /> </a>

                          
                            )
                            
                            )
                            : null}

</div>
                        </div>
                        

                        <div className="donation-wrapper">
                          <div className="donation-box">
                            <a href="#">
                              <img src="../assets/img/paypal.png" alt="" />
                            </a>
                            {/* <a href={currentBiolink?.settings?.paypalLink} target="_blank">
                          <img src={socialMediaList.paypal.icon} alt="" />
                        </a> */}
                            <a href="#">
                              <img src="../assets/img/vi.png" alt="" />
                            </a>
                            <a href="#">
                              <img src="../assets/img/pay.png" alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="all-form-wrapper text-left">
                      <ImportForm />
                      {/* <!-- /.modal --> */}

                      {/* <!-- #modal 2 --> */}
                      <ProfileForm />
                      {/* <!-- /.modal --> */}

                      {/* <!-- #modal 3 --> */}
                      <ContactButtons />
                      {/* <!-- /.modal --> */}

                      {/* <!-- #modal 11 --> */}
                      <LinksForm />
                      {/* <!-- /.modal --> */}

                      {/* <!-- #modal 12 --> */}
                      <SocialMediaForm showUpgrade={() => setShowPlanUpgradeDialog(true)} />
                      {/* <!-- /.modal --> */}

                      {/* <!-- #modal 4--> */}
                      <IntegrationForm showUpgrade={() => setShowPlanUpgradeDialog(true)} />
                      {/* <!-- /.modal --> */}

                      {/* <!-- #modal 5--> */}
                      <DirectoryForm showUpgrade={() => setShowPlanUpgradeDialog(true)} />
                      {/* <!-- /.modal --> */}

                      {/* <!-- #modal 6--> */}
                      <UTMParameters showUpgrade={() => setShowPlanUpgradeDialog(true)} />
                      {/* <!-- /.modal --> */}

                      {/* <!-- #modal 7--> */}
                      <SEOSettingsForm showUpgrade={() => setShowPlanUpgradeDialog(true)} />
                      {/* <!-- /.modal --> */}

                      {/* <!-- #modal 8--> */}
                      <BrandingSettings showUpgrade={() => setShowPlanUpgradeDialog(true)} />
                      {/* <!-- /.modal --> */}

                      {/* <!-- #modal 9--> */}
                      <PrivacyForm showUpgrade={() => setShowPlanUpgradeDialog(true)} />
                      {/* <!-- /.modal --> */}

                      {/* <!-- #modal 10--> */}
                      <PaymentsForm showUpgrade={() => setShowPlanUpgradeDialog(true)} />
                      {/* <!-- /.modal --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt--2">
              <div className="col-md-12 full-dash">
                {/**   Social Media Icons*** */}

                <div className="card ">
                  <div className="card-header db-tble">
                    <div className="card-head-row">
                      <div className="card-title blue-text col-7">Social Media Customize</div>
                      <div className="card-tools  text-right col-5">
                        <a
                          href="#modal-12"
                          data-toggle="modal"
                          data-dismiss="modal"
                          className="btn btn-info btn-border btn-round btn-sm mr-2"
                        >
                          <span className="btn-label">
                            <i className="fa fa-plus mr-2" />
                          </span>
                          Add
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="social-links">
                      <SocialMediaList />
                    </div>
                  </div>
                </div>

                <div className="card ">
                  <div className="card-header db-tble">
                    <div className="card-head-row">
                      <div className="card-title blue-text col-7">
                        <h6> Profile </h6>
                      </div>
                      <div className="card-tools  text-right col-5">
                        <a
                          href="#modal-2"
                          data-toggle="modal"
                          data-dismiss="modal"
                          className="btn btn-info btn-border btn-round btn-sm mr-2"
                        >
                          <span className="btn-label">
                            <i className="fa fa-pencil mr-2" />
                          </span>
                          Edit
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <ul className="listing-bottom">
                      <li>
                        <label>User Name :</label>
                        <p>{currentBiolink?.displayName}</p>
                      </li>
                      <li>
                        <label>Bio :</label>
                        <p>{currentBiolink?.bio}</p>
                      </li>
                      <li>
                        <label>Category :</label>
                        <p>{currentBiolink?.category?.categoryName}</p>
                      </li>
                      <li>
                        <div className="row ct-details">
                          <div className="col-xl-12 col-lg-12">
                            <div className="ct-details-col">
                              <label>City :</label>
                              <p>{currentBiolink?.city}</p>
                            </div>
                          </div>
                          <div className="col-xl-12 col-lg-12">
                            <div className="ct-details-col">
                              <label>State :</label>
                              <p>{currentBiolink?.state}</p>
                            </div>
                          </div>
                          <div className="col-xl-12 col-lg-12">
                            <div className="ct-details-col">
                              <label>Country :</label>
                              <p>{currentBiolink?.country}</p>
                            </div>
                          </div>
                        </div>
                      </li>

                      {/* <li>
                      <label>State :</label>
                      <p>{currentBiolink?.state}</p>
                    </li>
                    <li>
                      <label>Country :</label>
                      <p>{currentBiolink?.country}</p>
                    </li> */}
                    </ul>
                  </div>
                </div>

                {/* <div className="card "> */}
                {/* <div className="card-header">
                  <div className="card-head-row">
                    <div className="card-title blue-text">Social Media Customize</div>
                    <div className="card-tools">
                      <a
                        href="#modal-12"
                        data-toggle="modal"
                        data-dismiss="modal"
                        className="btn btn-info btn-border btn-round btn-sm mr-2"
                      >
                        <span className="btn-label">
                          <i className="fa fa-plus mr-2" />
                        </span>
                        Add
                      </a>
                    </div>
                  </div>
                </div> */}

                {/* <div className="card-body">
                  <div className="social-links">
                    <SocialMediaList />
                  </div>
                </div> */}
                {/* </div> */}
              </div>

              <div className="col-md-12 full-dash">
                <div className="card ">
                  <div className="card-header db-tble">
                    <div className="card-head-row">
                      <div className="card-title blue-text col-7">Contact Buttons</div>
                      <div className="card-tools col-5 text-right">
                        <a
                          href="#modal-3"
                          data-toggle="modal"
                          data-dismiss="modal"
                          className="btn btn-info btn-border btn-round btn-sm mr-2"
                        >
                          {currentBiolink?.settings?.email == null &&
                            currentBiolink?.settings?.phone == null ? (
                            <>
                              <span className="btn-label">
                                <i className="fa fa-plus mr-2" />
                              </span>
                              Add
                            </>
                          ) : (
                            <>
                              <span className="btn-label">
                                <i className="fa fa-pencil mr-2" />
                              </span>
                              Edit
                            </>
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <ul className="listing-bottom">
                      <li>
                        <label>Email :</label>
                        <p>{currentBiolink?.settings?.email}</p>
                      </li>
                      <li>
                        <label>Phone :</label>
                        <p>{currentBiolink?.settings?.phone}</p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="card ">
                  <div className="card-header db-tble">
                    <div className="card-head-row">
                      <div className="card-title blue-text col-7">Links & Embeds</div>
                      <div className="card-tools col-5 text-right">
                        <a
                          href="#modal-11"
                          data-toggle="modal"
                          data-dismiss="modal"
                          className="btn btn-info btn-border btn-round btn-sm mr-2"
                        >
                          <span className="btn-label">
                            <i className="fa fa-plus mr-2" />
                          </span>
                          Add
                        </a>
                      </div>
                    </div>
                  </div>
                  <LinksList />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <UpgradeModal
        currentState={showPlanUpgradeDialog}
        onUpgrade={() => {
          // history.push('/app/upgrade')
          // console.log(`show upgrade dialog`);
        }}
        onClose={() => setShowPlanUpgradeDialog(false)}
      /> */}

        <UpgradeModal
          currentState={showPlanUpgradeDialog}
          onUpgrade={() => history.push('/app/upgrade')}
          onClose={() => setShowPlanUpgradeDialog(false)}
        />
      </div>
      );
};

      export default Dashboard;
