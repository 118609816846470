import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import ToggleSwitch from '../../../components/ui/ToggleSwitch';
import { UPDATE_CONTACT_BUTTON_SETTINGS_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';
import validate from '../../../utils/validations';

const ContactButtons = () => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  console.log('currentBiolink===>', currentBiolink);
  const [form, setForm] = useState({
    emailError: [],
    phoneError: [],
    showEmail: currentBiolink?.settings?.showEmail,
    email: currentBiolink?.settings?.email,
    showPhone: currentBiolink?.settings?.showPhone,
    phone: currentBiolink?.settings?.phone,
    enableColoredContactButtons: currentBiolink?.settings?.enableColoredContactButtons,
  });
  // console.log('Show Email', currentBiolink.showEmail);
  const [updateContactButtonSettings, { data }] = useNetwork(
    UPDATE_CONTACT_BUTTON_SETTINGS_MUTATION,
  );

  const [settings, setSettings] = useState({
    showEmailCustomizeOptions: false,
  });

  const onToggle = (button) => {
    setForm({
      ...form,
      [button]: !form[button],
    });
  };

  const inputChangeHandler = (name, value) => {
    console.log('Show the name here ', name);
    console.log('Show the value here ', value);

    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    let isValidated = true;
    const emailError = [];
    const phoneError = [];

    // // add validations here
    if (form.showEmail && !validate.emailFormat(form.email)) {
      isValidated = false;
      emailError.push('Enter valid email.');
    }
    if (form.showPhone && !form.phone) {
      isValidated = false;
      phoneError.push('Phone cannot be empty.');
    }

    setForm({
      ...form,
      emailError,
      phoneError,
    });
    if (!isValidated) return;

    // submit form
    updateContactButtonSettings({
      variables: {
        showEmail: form.showEmail,
        email: form.email,
        showPhone: form.showPhone,
        phone: form.phone,
        enableColoredContactButtons: form.enableColoredContactButtons,
        id: currentBiolink.id,
      },
    });
    // setForm({
    //   ...form,
    //   email: '',
    //   phone: '',
    // });
  };
  console.log('Showing the email Value', form.email);
  console.log('Showing the Phone Value', form);

  return (
    <div className="modal fade" id="modal-3">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Contact Buttons</h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body branding-model contact-customize">
            <div className="customize-row ">
              {/* <a
                className="btn-admin icon-design-admin customize-btn pull-right"
                onClick={() =>
                  setSettings({
                    ...settings,
                    showEmailCustomizeOptions: !settings.showEmailCustomizeOptions,
                  })
                }
              > */}
                  <a
                  className="social-customize social-customize-body"
                >
                Customization
                <div className="swap-btn-bar-admin social-form-toggle">
                    <a
                      className={
                        settings?.showEmailCustomizeOptions ? 'pull-left' : 'pull-left active'
                      }
                      // onClick={() => onToggleDarkMode()}
                      onClick={() =>
                        setSettings({
                          ...settings,
                    showEmailCustomizeOptions: !settings.showEmailCustomizeOptions,
                        })
                      }
                    />

                    <a
                      className={
                        settings?.showEmailCustomizeOptions
                          ? 'pull-right active'
                          : 'pull-right'
                      }
                      // onClick={() => onToggleDarkMode()}
                      onClick={() =>
                        setSettings({
                          ...settings,
                    showEmailCustomizeOptions: !settings.showEmailCustomizeOptions,
                        })
                      }
                    />
                  </div>
              </a>
              <div className="customize-box">
                {/* <a href="#" className="active">
                  Minimal
                </a>
                <a href="#">Colorful</a> */}

                {settings.showEmailCustomizeOptions ? (
                  <div className="customize-body">
                    <div className="customize-options-container addBtnhead row new-customize">
                      <div
                        className={
                          form.enableColoredContactButtons
                            ? 'customize-options col-md-6'
                            : 'customize-options col-md-6 active'
                        }
                        onClick={() =>
                          setForm({
                            ...form,
                            enableColoredContactButtons: false,
                          })
                        }
                      >
                        Minimal
                      </div>
                      <div
                        className={
                          form.enableColoredContactButtons
                            ? 'customize-options col-md-6 active'
                            : 'customize-options col-md-6'
                        }
                        onClick={() =>
                          setForm({
                            ...form,
                            enableColoredContactButtons: true,
                          })
                        }
                      >
                        Colorful
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-list mt-5">
              <div className="form-group inte-form clearfix">
                <label htmlFor="inputFloatingLabel10" className="label-design">
                  Email
                </label>
                <ToggleSwitch
                  currentState={form.showEmail}
                  onToggle={() => onToggle('showEmail')}
                  isActive="na"
                />
                {form.showEmail && (
                  <>
                    <input
                      type="text"
                      id="inputFloatingLabel10"
                      className="form-control input-solid"
                      placeholder="Email"
                      name="email"
                      value={form.email}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                    />
                    {form.emailError.length
                      ? form.emailError.map((err, i) => <ErrorSpan key={i} err or={err} />)
                      : null}
                  </>
                )}
              </div>

              <div className="form-group inte-form clearfix">
                <label htmlFor="inputFloatingLabel11" className="label-design">
                  Phone
                </label>
                <div className="theam-toggle">
                  <ToggleSwitch
                    id="inputFloatingLabel1123"
                    currentState={form.showPhone}
                    onToggle={() => onToggle('showPhone')}
                    isActive="na"
                  />
                  {form.showPhone && (
                    <>
                      <input
                        type="text"
                        id="inputFloatingLabel11"
                        className="form-control input-solid"
                        placeholder="Phone"
                        name="phone"
                        value={form.phone}
                        onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      />
                      {form.phoneError.length
                        ? form.phoneError.map((err, i) => <ErrorSpan key={i} error={err} />)
                        : null}
                    </>
                  )}
                </div>
              </div>

              <button
                type="button"
                className="btn btn-primary"
                onClick={onSubmitHandler}
                data-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>

          <div className="modal-footer">
            <a href="#modal-1" data-toggle="modal" data-dismiss="modal" className="btn btn-default">
              Previous
            </a>
            <button type="button" data-dismiss="modal" className="btn btn-danger">
              Close
            </button>
          </div>
        </div>
        {/* <!-- /.modal-content --> */}
      </div>
      {/* <!-- /.modal-dialog --> */}
    </div>
  );
};

export default ContactButtons;
