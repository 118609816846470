import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import ToggleSwitch from '../../../components/ui/ToggleSwitch';
import { GET_ACCESS_TOKEN } from '../../../graphql/Queries';

import * as authActions from '../../../store/actions/auth';
import BuyButton from '../stripe/BuyButton';

const stripePromise = loadStripe(
  'pk_test_51J27JpBtF9AcyMltHwDwkiuO8G2RWmHbXRH4CoGrr4xhFmD8uKXivczVzntU2yFzz3ziuByv6FlZ24nJRpiDGzyO009ttZKzmZ',
);
const PricingBody = () => {
  const dispatch = useDispatch();
  const [accessToken, setAccessToken] = useState(null);
  const { data: tokenData } = useQuery(GET_ACCESS_TOKEN, {
    fetchPolicy: 'network-only',
  });
  const plans = useSelector((state) => state.auth.plans);
  const currentPlan = useSelector((state) => state.auth.user.plan);
  // console.log('plannnnnnnnn======>', plans);
  // console.log('plannnnnnnnn======>', JSON.stringify(plans));
  const [switchButton, setswitchButton] = useState(false);

  useEffect(() => {
    if (tokenData?.getAccessToken?.errors === null) {
      setAccessToken(tokenData.getAccessToken.access_token);
    }
    if (tokenData?.getAccessToken?.errors?.length) {
      if (tokenData?.getAccessToken?.errors[0].errorCode === 2) {
        // dispatch(authActions.authenticate({}, false));
      }
    }
  }, [tokenData]);

  const monthHandler = (evt) => {
    // evt.target.id
    if (evt.target.id === 'Monthly') {
      document.getElementById('monthly').className = 'active';
      document.getElementById('yearly').className = '';
    } else {
      document.getElementById('monthly').className = '';
      document.getElementById('yearly').className = 'active';
    }
  };

  return (
    <>
      <div className="card-body billing-section ">
        <div className="billing-detail">
          <div className="inline">
            <p>Bill Monthly</p>
          </div>
          <div className="inline">
            {/* <div className="swap-btn-bar-admin">
              <a className="float-left active" />
              <a className="float-right" />
            </div> */}
            <ToggleSwitch
              currentState={switchButton}
              onToggle={() => setswitchButton(!switchButton)}
              isActive="na"
            />
          </div>
          <div className="inline">
            <p>Bill Yearly</p>
          </div>
        </div>
        <div id="generic_price_table">
          <section className="pricing-container">
            <div className="container">
              {/* BLOCK ROW START */}
              <div className="row pricing-row">
                {plans &&
                  plans?.map &&
                  plans.map((item, index) => (
                    <div className="col-md-4 bil-space" key={index}>
                      {/* PRICE CONTENT START */}
                      <div
                        className={`generic_content clearfix ${index == 1 ? 'Highlighted' : index == 2 ? 'ht-cls' : ''}`}
                      >
                        {/* HEAD PRICE DETAIL START */}
                        <div className="generic_head_price clearfix">
                          {/* HEAD CONTENT START */}
                          <div className="generic_head_content clearfix">
                            {/* HEAD START */}
                            <div className="head_bg" />
                            <div className="head">
                              <span>{item.name}</span>
                            </div>
                            {/* //HEAD END */}
                          </div>
                          {/* //HEAD CONTENT END */}
                          {/* PRICE START */}
                          <div className="generic_price_tag clearfix ">
                            <span className="price">
                              <span className="sign">$</span>{' '}
                              {!switchButton ? (
                                <span className="currency">
                                  {item?.monthlyPrice}
                                  <span className="cent mr-1"> .0 </span>
                                  <span
                                    className="month "
                                    onClick={() => monthHandler()}
                                    id="monthly"
                                  >
                                    /Month
                                  </span>
                                </span>
                              ) : (
                                <span className="currency">
                                  {item?.annualPrice}
                                  <span className="cent"> .0 </span>
                                  <span
                                    className="month"
                                    onClick={() => monthHandler()}
                                    id="Yearly"
                                  >
                                    / Annual
                                  </span>
                                </span>
                              )}
                              {/* <span className="cent">.0</span> */}
                              {/* <span className="month " onClick={() => monthHandler()} id="monthly">
                                /Month
                              </span>
                              <span className="month" onClick={() => monthHandler()} id="Yearly">
                                /Yearly
                              </span> */}
                            </span>
                          </div>
                          {/* //PRICE END */}
                        </div>
                        {/* //HEAD PRICE DETAIL END */}
                        {/* FEATURE LIST START */}
                        <div className="generic_feature_list">
                          <ul>
                            <li>
                              <span>
                                {item.settings.totalLinksLimit ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Total Links Limit
                            </li>
                            <li>
                              <span>
                                {item.settings.totalBiolinksLimit ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Total Biolinks Limit
                            </li>
                            <li>
                              <span>
                                {item.settings.totalCustomDomainLimit ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Custom Domain Limit
                            </li>
                            <li>
                              <span>
                                {item.settings.totalBiolinksLimit ? (
                                  <i className="fa fa-check " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Unique Stash.ee URL
                            </li>
                            <li>
                              <span>
                                {item.settings.addedToDirectoryEnabled ? (
                                  <i className="fa fa-check " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Added To Directory
                            </li>
                            <li>
                              <span>
                                {item.settings.customBackHalfEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Custom Back Half
                            </li>
                            <li>
                              <span>
                                {item.settings.coloredLinksEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Colored Links
                            </li>
                            <li>
                              <span>
                                {item.settings.googleAnalyticsEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Google Analytics
                            </li>
                            <li>
                              <span>
                                {item.settings.facebookPixelEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Facebook Pixel
                            </li>
                            <li>
                              <span>
                                {item.settings.verifiedCheckmarkEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" aria-checked />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Verified Check Mark
                            </li>
                            <li>
                              <span>
                                {item.settings.linksSchedulingEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Links Scheduling
                            </li>
                            <li>
                              <span>
                                {item.settings.darkModeEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Dark Mode
                            </li>
                            <li>
                              <span>
                                {item.settings.removableBrandingEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Removable Branding
                            </li>
                            <li>
                              <span>
                                {item.settings.customFooterBrandingEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Custom Footer Branding
                            </li>
                            <li>
                              <span>
                                {item.settings.seoEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Seo
                            </li>
                            <li>
                              <span>
                                {item.settings.socialEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Social Media
                            </li>
                            <li>
                              <span>
                                {item.settings.utmParametersEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Utm Parameters
                            </li>
                            <li>
                              <span>
                                {item.settings.passwordProtectionEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Password Protection
                            </li>
                            <li>
                              <span>
                                {item.settings.sensitiveContentWarningEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Sensitive Content
                            </li>
                            <li>
                              <span>
                                {item.settings.leapLinkEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              Leap Link
                            </li>
                            <li>
                              <span>
                                {item.settings.noAdsEnabled ? (
                                  <i className="fa fa-check  " aria-hidden="true" />
                                ) : (
                                  <i className="fa fa-times " aria-hidden="true" />
                                )}
                              </span>
                              No Ads
                            </li>
                          </ul>
                        </div>
                        {/* //FEATURE LIST END */}
                        {/* BUTTON START */}
                        <div className="generic_price_btn clearfix">
                          {item.name == 'Free' ? ('') : item.name == currentPlan?.name ? (
                            <div className="generic_price_btn clearfix">
                              <a href="#as" disabled>
                                Activated
                              </a>
                            </div>
                          ) : (
                            <Elements stripe={stripePromise}>
                                                            
                              <BuyButton
                                accessToken={accessToken}
                                annualStripId={item.annualPriceStripeId}
                                monthlyPriceStripeId={item.monthlyPriceStripeId}
                              />
                                                          
                            </Elements>
                          )}
                        </div>
                        {/* //BUTTON END */}
                      </div>
                      {/* //PRICE CONTENT END */}
                    </div>
                  ))}
              </div>
              {/* //BLOCK ROW END */}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default PricingBody;
