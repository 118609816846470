import './App.css';
import './assets/css/font-awesome.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/atlantis.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
// import './assets/css/owl.carousel.css';

import React,{createContext} from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';

import RootNavigator from './navigation/RootNavigator';
import { persistor, store } from './store/store';
import client from './graphql/apolloClient';
import AlertProvider from './components/ui/alert/Provider';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ApolloProvider client={client}>
        <AlertProvider>
          <RootNavigator />
        </AlertProvider>
      </ApolloProvider>
    </PersistGate>
  </Provider>
);

export default App;
