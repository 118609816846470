import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ErrorSpan from '../../components/ui/errorSpan/ErrorSpan';
import { VERIFY_FORGOT_PASSWORD_MUTATION } from '../../graphql/Mutations';
import images from '../../assets/images';
import Loader from '../../components/ui/loader/Loader';
import validate from '../../utils/validations';
import * as alertActions from '../../store/actions/alert';
import * as appActions from '../../store/actions/app';
import generateRandomId from '../../utils/genrateRandomId';

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: '',
    password: '',
    passwordError: [],
    confirmPassword: '',
    confirmPasswordError: [],
    token: '',
  });
  const [verifyForgotPassword, { loading, data }] = useMutation(VERIFY_FORGOT_PASSWORD_MUTATION);

  useEffect(() => {
    dispatch(appActions.isLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (data?.verifyForgotPassword?.errors === null) {
      dispatch(
        alertActions.addAlert({
          id: generateRandomId(),
          message: 'Password Updated Successfully.',
          type: 'success',
        }),
      );
      props.history.push('/auth/login');
      // return null;
    }
  }, [data]);

  const search = props.location.search.replace('?', '').split('&');

  const uriParameters = [];

  search.forEach((value) => {
    const parameter = value.split('=');
    uriParameters[parameter[0]] = parameter[1];
  });

  useEffect(() => {
    setForm({ ...form, email: uriParameters.email, token: uriParameters.token });
  }, []);

  const inputChangeHandler = (name, value) => {
    // add validations here
    // update values
    setForm({
      ...form,
      [name]: value,
    });
  };

  const verifyPasswordHandler = () => {
    const passwordError = [];
    const confirmPasswordError = [];

    if (validate.minLength(form.password, 8))
      passwordError.push('Password must be atleast 8 characters long');
    if (form.password !== form.confirmPassword)
      confirmPasswordError.push('Password did not matched.');

    setForm({
      ...form,
      passwordError,
      confirmPasswordError,
    });

    if (passwordError.length || confirmPasswordError.length) return;
    // submit form
    verifyForgotPassword({
      variables: {
        forgotPasswordCode: form.token,
        email: form.email,
        password: form.password,
      },
    });
  };

  return (
    <div>
      <div className="container-fluid signup-container">
        <div className="row">
          <div className="col-md-6 px-0">
            <div className="w-75 m-auto signup-left-box">
              <div className="signup-text">
                <h1>A Single Landing Page Customized By You </h1>
                <p className="mt-4" style={{ width: 100, maxWidth: 'inherit' }}>
                  Stash.ee puts you in control of how your profile looks so you can be on brand and
                  on purpose.
                </p>
              </div>
              <div className="login-img">
                <img src={images.signUpBg} alt="" className="sign-img small-img" />
              </div>
            </div>
          </div>

          <div className="col-md-6 px-0 white-bg">
            <div className="card full-height ">
              <div className="card-body">
                <div className="signup-text text-center mb-5">
                  <div className="signup-area">
                    <h2 className="mt-5">Reset Password</h2>
                    {data && data.verifyForgotPassword.errors
                      ? data.verifyForgotPassword.errors.map((err, i) => (
                          <ErrorSpan key={i} error={err.message} />
                        ))
                      : data && !data.verifyForgotPassword.errors
                      ? 'Password changed successfully.'
                      : null}
                    <form>
                      <div className="form-design">
                        <div className="form-group form-floating-label">
                          <input
                            id="inputFloatingLabel1"
                            type="email"
                            className="form-control input-solid"
                            name="email"
                            value={form.email}
                            readOnly
                          />
                          {/* <label htmlFor="inputFloatingLabel1" className="placeholder">
                            Email
                          </label> */}
                        </div>

                        <div className="form-group form-floating-label">
                          <input
                            id="inputFloatingLabel2"
                            type="password"
                            className="form-control input-solid"
                            name="password"
                            value={form.password}
                            onChange={(evt) =>
                              inputChangeHandler(evt.target.name, evt.target.value)
                            }
                            placeholder="Password"
                          />
                          {/* <label htmlFor="inputFloatingLabel2" className="placeholder">
                            Password
                          </label> */}
                        </div>
                        {form.passwordError.length
                          ? form.passwordError.map((err, i) => <ErrorSpan key={i} error={err} />)
                          : null}

                        <div className="form-group form-floating-label">
                          <input
                            id="inputFloatingLabel3"
                            type="password"
                            className="form-control input-solid"
                            name="confirmPassword"
                            value={form.confirmPassword}
                            onChange={(evt) =>
                              inputChangeHandler(evt.target.name, evt.target.value)
                            }
                            placeholder="Confirm Password"
                          />
                          {/* <label htmlFor="inputFloatingLabel3" className="placeholder">
                            Confirm Password
                          </label> */}
                          {form.confirmPasswordError.length
                            ? form.confirmPasswordError.map((err, i) => (
                                <ErrorSpan key={i} error={err} />
                              ))
                            : null}
                        </div>

                        <div className="card-action text-center">
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={() => verifyPasswordHandler()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
