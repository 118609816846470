import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import authReducer from './reducers/auth';
import alertReducer from './reducers/alert';
import appReducer from './reducers/app';

const rootReducer = combineReducers({
  auth: authReducer,
  alerts: alertReducer,
  app: appReducer,
});

const persistConfig = {
  key: 'rootReducer',
  storage,
  whitelist: ['rootReducer', 'auth'], // or blacklist to exclude specific reducers
};

const presistedReducer = persistReducer(persistConfig, rootReducer);

const enhancers = [applyMiddleware(reduxThunk)];
if (`${process.env.REACT_APP_ENVIORNMENT}` === 'development') {
  enhancers.push(composeWithDevTools());
}

const store = createStore(presistedReducer, compose(...enhancers));
const persistor = persistStore(store);

export { persistor, store };
