import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import ToggleSwitch from '../../../components/ui/ToggleSwitch';
import { UPDATE_DIRECTORY_SETTINGS_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';
import validate from '../../../utils/validations';

const DirectoryForm = ({ showUpgrade }) => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const currentPlan = useSelector((state) => state.auth.user.plan);
  const [form, setForm] = useState({
    addedToDirectory: currentBiolink.addedToDirectory,
    directoryBio: currentBiolink.directoryBio,
    directoryBioError: [],
  });

  const [updateDirectorySettings, { data }] = useNetwork(UPDATE_DIRECTORY_SETTINGS_MUTATION);

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onToggle = (button) => {
    if (!currentPlan?.settings.addedToDirectoryEnabled) {
      showUpgrade();
      return;
    }
    setForm({
      ...form,
      [button]: !form[button],
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    if (!currentPlan?.settings.addedToDirectoryEnabled) {
      showUpgrade();
      return;
    }
    let isValidated = true;
    const directoryBioError = [];

    // add validations here
    if (form.addedToDirectory && validate.minLength(form.directoryBio, 1)) {
      isValidated = false;
      directoryBioError.push('Bio cannot be empty.');
    }
    if (form.addedToDirectory && !form.directoryBio) {
      isValidated = false;
      directoryBioError.push('Bio cannot empty.');
    }
    if (form.addedToDirectory && validate.maxLength(form.directoryBio, 200)) {
      isValidated = false;
      directoryBioError.push('Bio cannot be more than 200 characters.');
    }

    setForm({
      ...form,
      directoryBioError,
    });
    if (!isValidated) return;

    // submit form
    updateDirectorySettings({
      variables: {
        id: currentBiolink.id,
        addedToDirectory: form.addedToDirectory,
        directoryBio: form.directoryBio,
      },
    });
    setForm({
      ...form,
      addedToDirectory: '',
      directoryBio: '',
    });
  };

  return (
    <div className="modal fade" id="modal-5">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Directory</h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body branding-model">
            <div className="form-list">
              <div className="form-group form-floating-label clearfix">
                <ToggleSwitch
                  currentState={form.addedToDirectory}
                  onToggle={() => onToggle('addedToDirectory')}
                  id="inputFloatingLabel14"
                  isActive="na"
                />
                <label htmlFor="inputFloatingLabel14" className="placeholder">
                  Add me to Directory
                </label>
              </div>
              {form.addedToDirectory && (
                <div className="form-group form-floating-label">
                  <textarea
                    id="inputFloatingLabel15"
                    className="form-control input-solid"
                    required
                    name="directoryBio"
                    value={form.directoryBio}
                    placeholder="Bio"
                    onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                  />
                  {/* <label htmlFor="inputFloatingLabel15" className="placeholder">
                    Bio
                  </label> */}
                  {form.directoryBioError.length
                    ? form.directoryBioError.map((err, i) => <ErrorSpan key={i} error={err} />)
                    : null}
                </div>
              )}

              <button
                type="button"
                className="btn btn-primary"
                onClick={(evt) => onSubmitHandler(evt)}
              >
                Save changes
              </button>
            </div>
          </div>

          <div className="modal-footer">
            <a href="#modal-1" data-toggle="modal" data-dismiss="modal" className="btn btn-default">
              Previous
            </a>
            <button data-dismiss="modal" type="button" className="btn btn-danger">
              Close
            </button>
          </div>
        </div>
        {/* <!-- /.modal-content --> */}
      </div>
      {/* <!-- /.modal-dialog --> */}
    </div>
  );
};

export default DirectoryForm;
