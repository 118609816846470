import { ADD_ALERT, REMOVE_ALERT } from '../actions/alert';

const initialState = [
  // {
  //   id: 1234,
  //   message: 'Something Went Wrong!',
  //   type: 'default',
  // },
  // {
  //   id: 1235,
  //   message: 'Something Went Wrong!',
  //   type: 'success',
  // },
  // {
  //   id: 1236,
  //   message: 'Something Went Wrong!',
  //   type: 'warning',
  // },
  // {
  //   id: 1237,
  //   message: 'Something Went Wrong!',
  //   type: 'danger',
  // },
  // {
  //   id: 1238,
  //   message: 'Something Went Wrong!',
  //   type: 'error',
  // },
];

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALERT:
      return [...state, action.alert];
    case REMOVE_ALERT:
      const newErrors = state.filter((err) => err.id !== action.id);
      return newErrors;
    default:
      return state;
  }
};

export default alertReducer;
