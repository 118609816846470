import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import  Table  from 'react-bootstrap/Table';
import moment from 'moment';
// import { Table, Thead, Tbody, Tr, th, Td } from 'react-super-responsive-table';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import { GET_ALL_PLANS } from '../../../graphql/Queries';
import PricingBody from '../../main/pricing/PricingBody'; 

const Billing = (props) => {
  //  const { created, amount } = props.location.state;
  const dispatch = useDispatch();

  const { loading, error, data } = useQuery(GET_ALL_PLANS);
  const payments = useSelector((state) => state.auth.user.payments);

  const [form, setForm] = useState('');

  const [switchButton, setswitchButton] = useState(false);
  useEffect(() => {}, [switchButton]);
  const [itemData, setItemData] = useState([]);

  useEffect(() => {
    if (data) {
      setItemData(data);
    }
  }, [data]);

  const planHandler = () => {
    props.history.push('/app/payment/checkout');
  };

  useEffect(() => {
    // setPlanDays(planDays);
    setItemData(itemData);
  }, []);
  let serialNumber = 0;
  const getSerialNumber = () => {
    serialNumber += 1;
    return serialNumber;
  };

  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner billing-section ">
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="center-heading black-text">
                    <h1>
                      Choose Your <span className="blue-text">Flexible Plan</span>
                    </h1>
                    <h4>No contracts, no surprise fees.</h4>
                  </div>
                  <PricingBody />
                </div>
              </div>
            </div>
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="card-body">
                    <h1>Invoices</h1>
                    <div >
                      <Table responsive>
                        <thead>
                          <tr >
                            <th>#</th>
                            <th>Number</th>
                            <th>Created</th>
                            <th>Amount</th>
                            <th colSpan="1 ">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payments.map((item, index) => (
                            <tr key={index}>
                               <td>{getSerialNumber()}</td>
                              <td>
                                <a> Invoice {index + 1}</a>
                              </td>
                              <td>
                                {moment(new Date(+item.stripeInvoiceCreated * 1000)).format(
                                  'MMM-DD-YYYY',
                                )}
                              </td>
                              <td>${item.stripeAmountPaid / 100}</td>
                              <td>
                                <a href={item.stripeInvoicePdfUrl}>Download</a>/
                                <a href={item.stripeInvoiceUrl} target="_blank">
                                  View
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billing;