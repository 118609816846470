import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { GET_BIOLINK_CHART_DATA, GET_LINK_CLICK_DATA } from '../../../graphql/Queries';
import * as authActions from '../../../store/actions/auth';

const Analytics = (props) => {
  const dispatch = useDispatch();
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const [todayVisited, setTodayVisited] = useState(0);
  const [allTimeVisited, setAllTimeVisited] = useState(0);
  const [xAxis, setXAxis] = useState([]);
  const [yAxis, setYAxis] = useState([]);

  const chartOptions = {
    series: [
      {
        name: 'views',
        data: yAxis,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'area',
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: 'Download',
        align: 'right',
        offsetX: -25,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: xAxis,
      },
    },
  };

  const { data: chartData, refetch: chartRefetch } = useQuery(GET_BIOLINK_CHART_DATA, {
    variables: {
      id: currentBiolink?.id,
    },
  });

  useEffect(() => {
    chartRefetch();
  }, []);

  useEffect(() => {
    if (chartData?.getBiolinkChartData?.result && chartData.getBiolinkChartData.result.length) {
      const xAxisData = [];
      const yAxisData = [];
      chartData.getBiolinkChartData.result.forEach((item) => {
        xAxisData.push(moment(item.date, 'x').format('DD MMM'));
        yAxisData.push(item.views);
      });
      setXAxis(xAxisData);
      setYAxis(yAxisData);
    }
  }, [chartData]);

  const { data } = useQuery(GET_LINK_CLICK_DATA);
  // console.log('REACT_APP_URL=====>', process.env.REACT_APP_URL);
  useEffect(() => {
    if (data?.getLinkClicksData?.errors === null && data.getLinkClicksData.result.length) {
      let totalVisitedData = 0;
      let allTimeVisitedData = 0;

      data.getLinkClicksData.result.forEach((item) => {
        totalVisitedData += item.totalVisited ?? 0;
        allTimeVisitedData += item.allTimeVisited ?? 0;
      });

      setTodayVisited(totalVisitedData);
      setAllTimeVisited(allTimeVisitedData);
    }
    if (data?.getLinkClicksData?.errors?.length) {
      // console.log("test in===========",data?.getLinkClicksData?.errors[0].errorCode);
      if (data?.getLinkClicksData?.errors[0].errorCode === 2) {
        // console.log("test===========",data?.getLinkClicksData?.errors[0].errorCode);
        //  dispatch(authActions.authenticate({}, false));
        //  history.push('/auth/login');
      }
    }
  }, [data]);
  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner ">
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="col-md-12 px-0">
                    <div className="card-header">
                      <h4 className="card-title blue-text"> Analytics</h4>
                    </div>
                    <div className="card-body">
                      <div className="col-md-12 px-0">
                        <div className="analytic-box">
                          <div className="value">
                            <div className="inlinecount"> Today</div>
                            <h6> {todayVisited}</h6>
                          </div>
                        </div>
                        <div className="analytic-box">
                          <div className="value">
                            <div className="inlinecount rcount"> All Times</div>
                            <h6>{allTimeVisited}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="graph">
                        <div className="col-md-10 ">
                          <div className="analytic-box1">
                            {xAxis.length && yAxis.length ? (
                              <Chart
                                // data={this.state.chartData}
                                options={chartOptions.options}
                                series={chartOptions.series}
                                type="area"
                                height={350}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="card-header">
                    <div className="card-title blue-text">Links</div>
                  </div>
                  <div className="card-body">
                    <ul className="listing-bottom linksort minus-margin">
                      {data?.getLinkClicksData?.result?.length &&
                        data?.getLinkClicksData?.result.map((items) => {
                          if (!items?.link?.url) return null;
                          return (
                            <li>
                              <div className="listurl clearfix mt-3">
                                {items?.link?.linkImageUrl && (
                                  <img src={items?.link?.linkImageUrl} alt="" height="40" />
                                )}
                                <div className="personal-social-detail">
                                  <h3>{items.link.linkTitle}</h3>
                                  <p>
                                    <a
                                      href={`${items?.link?.url}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {`${process.env.REACT_APP_URL}/${items?.link?.shortenedUrl}`}
                                    </a>
                                  </p>
                                </div>
                                <div className="prsonal-list-icon">
                                  <span>
                                    {items.todayVisited}/ {items.allTimeVisited}
                                  </span>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;
