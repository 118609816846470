import { gql } from '@apollo/client';

// export const LOAD_USER_DETAILS = gql`
//   query {
//     me {
//       id
//       email
//       planExpirationDate
//       planTrialDone
//       language
//       country
//       currentBiolinkId
//       biolinks {
//         id
//         user {
//           id
//         }
//         profilePhotoUrl
//         coverPhotoUrl
//         displayName
//         city
//         state
//         country
//         latitude
//         longitude
//         bio
//         settings {
//           enableDarkMode
//           showEmail
//           email
//           showPhone
//           phone
//           enableColoredContactButtons
//           addedToDirectory
//           directoryBio
//           enableColoredSocialMediaIcons
//           socialAccounts {
//             platform
//             icon
//             link
//           }
//           enableFacebookPixel
//           facebookPixelId
//           enableGoogleAnalytics
//           googleAnalyticsCode
//           enableUtmParameters
//           utmSource
//           utmMedium
//           utmCampaign
//           blockSearchEngineIndexing
//           pageTitle
//           metaDescription
//           opengraphImageUrl
//           removeDefaultBranding
//           enableCustomBranding
//           customBrandingName
//           customBrandingUrl
//           enablePasswordProtection
//           enableSensitiveContentWarning
//           paypalLink
//           venmoLink
//           payoneerLink
//           emailCaptureId
//           enableEmailCapture
//         }
//         verificationStatus
//         verifiedGovernmentId
//         verifiedEmail
//         verifiedPhoneNumber
//         verifiedWorkEmail
//         featured
//         changedUsername
//         username {
//           id
//           username
//           premiumType
//           expireDate
//         }
//         links {
//           id
//           linkType
//           linkTitle
//           linkImageUrl
//           linkColor
//           url
//           shortenedUrl
//           order
//           startDate
//           endDate
//           enablePasswordProtection
//           note
//           biolink {
//             id
//           }
//         }
//         category {
//           id
//           categoryName
//           featured
//         }
//         createdAt
//       }

//       links {
//         id
//         linkType
//         linkTitle
//         linkImageUrl
//         linkColor
//         url
//         shortenedUrl
//         order
//         startDate
//         endDate
//         enablePasswordProtection
//         note
//         biolink {
//           id
//         }
//       }
//       usernames {
//         id
//         username
//         premiumType
//         expireDate
//       }
//       payments {
//         id
//         paymentType
//         stripeAmountDue
//         stripeAmountPaid
//         stripeAmountRemaining
//         stripeChargeId
//         stripeInvoiceCreated
//         stripePaymentCurrency
//         stripeCustomerId
//         stripeCustomerAddress
//         stripeCustomerEmail
//         stripeCustomerName
//         stripeCustomerPhone
//         stripeCustomerShipping
//         stripeDiscount
//         stripeInvoicePdfUrl
//         stripeInvoiceUrl
//         stripePriceId
//         stripeSubscriptionId
//         stripeInvoiceNumber
//         stripePeriodStart
//         stripePeriodEnd
//         stripeStatus
//         createdAt
//       }
//       codes {
//         id
//         type
//         code
//         discount
//         quantity
//         expireDate
//         createdAt
//       }
//       referrals {
//         id
//         referredByEmail
//         referredByName
//         referredToEmail
//         referredToName
//         createdAt
//         referredBy {
//           id
//           email
//         }
//       }

//       plan {
//         id
//         name
//         monthlyPrice
//         monthlyPriceStripeId
//         annualPrice
//         annualPriceStripeId
//         settings {
//           totalBiolinksLimit
//           totalLinksLimit
//           totalCustomDomainLimit
//           darkModeEnabled
//           addedToDirectoryEnabled
//           customBackHalfEnabled
//           noAdsEnabled
//           removableBrandingEnabled
//           customFooterBrandingEnabled
//           coloredLinksEnabled
//           googleAnalyticsEnabled
//           facebookPixelEnabled
//           verifiedCheckmarkEnabled
//           linksSchedulingEnabled
//           seoEnabled
//           socialEnabled
//           utmParametersEnabled
//           passwordProtectionEnabled
//           sensitiveContentWarningEnabled
//           leapLinkEnabled
//           donationLinkEnabled
//           emailCaptureEnabled
//         }
//         enabledStatus
//         visibilityStatus
//       }
//     }
//   }
// `;

export const LOAD_USER_DETAILS = gql`
  query {
    me {
      id
      email
      planExpirationDate
      planTrialDone
      language
      country
      currentBiolinkId
      biolinks {
        id
        user {
          id
        }
        profilePhotoUrl
        coverPhotoUrl
        displayName
        city
        state
        country
        latitude
        longitude
        bio
        settings {
          enableDarkMode
          showEmail
          email
          showPhone
          phone
          enableColoredContactButtons
          addedToDirectory
          directoryBio
          enableColoredSocialMediaIcons
          socialAccountStyleType

          enableFacebookPixel
          facebookPixelId
          enableGoogleAnalytics
          googleAnalyticsCode
          enableUtmParameters
          utmSource
          utmMedium
          utmCampaign
          blockSearchEngineIndexing
          pageTitle
          metaDescription
          opengraphImageUrl
          removeDefaultBranding
          enableCustomBranding
          customBrandingName
          customBrandingUrl
          enablePasswordProtection
          enableSensitiveContentWarning
          paypalLink
          venmoLink
          payoneerLink
          emailCaptureId
          enableEmailCapture
        }
        verificationStatus
        verifiedGovernmentId
        verifiedEmail
        verifiedPhoneNumber
        verifiedWorkEmail
        featured
        changedUsername
        username {
          id
          username
          premiumType
          expireDate
        }
        links {
          id
          linkType
          linkTitle
          linkImageUrl
          linkColor
          url
          platform
          iconMinimal
          iconColorful
          featured
          shortenedUrl
          order
          startDate
          endDate
          enablePasswordProtection
          note
        }
        category {
          id
          categoryName
          featured
        }
        createdAt
      }
      links {
        id
        linkType
        linkTitle
        linkImageUrl
        linkColor
        url
        platform
        iconMinimal
        iconColorful
        featured
        shortenedUrl
        order
        startDate
        endDate
        enablePasswordProtection
        note
      }
      usernames {
        id
        username
        premiumType
        expireDate
      }
      payments {
        id
        paymentType
        stripeAmountDue
        stripeAmountPaid
        stripeAmountRemaining
        stripeChargeId
        stripeInvoiceCreated
        stripePaymentCurrency
        stripeCustomerId
        stripeCustomerAddress
        stripeCustomerEmail
        stripeCustomerName
        stripeCustomerPhone
        stripeCustomerShipping
        stripeDiscount
        stripeInvoicePdfUrl
        stripeInvoiceUrl
        stripePriceId
        stripeSubscriptionId
        stripeInvoiceNumber
        stripePeriodStart
        stripePeriodEnd
        stripeStatus
        createdAt
      }
      codes {
        id
        type
        code
        discount
        quantity
        expireDate
        createdAt
      }
      referrals {
        id
        referredByEmail
        referredByName
        referredToEmail
        referredToName
        createdAt
        referredBy {
          id
          email
        }
      }
      plan {
        id
        name
        monthlyPrice
        monthlyPriceStripeId
        annualPrice
        annualPriceStripeId
        settings {
          totalBiolinksLimit
          totalLinksLimit
          totalCustomDomainLimit
          darkModeEnabled
          addedToDirectoryEnabled
          customBackHalfEnabled
          noAdsEnabled
          removableBrandingEnabled
          customFooterBrandingEnabled
          coloredLinksEnabled
          googleAnalyticsEnabled
          facebookPixelEnabled
          verifiedCheckmarkEnabled
          linksSchedulingEnabled
          seoEnabled
          socialEnabled
          utmParametersEnabled
          passwordProtectionEnabled
          sensitiveContentWarningEnabled
          leapLinkEnabled
          donationLinkEnabled
          emailCaptureEnabled
        }
        enabledStatus
        visibilityStatus
      }
    }
  }
`;
// dashboard

export const GET_ALL_LINKS_OF_BIOLINK = gql`
  query GetAllLinksOfBiolink($biolinkId: String!, $showOnPage: Boolean!) {
    getAllLinksOfBiolink(biolinkId: $biolinkId, showOnPage: $showOnPage) {
      errors {
        errorCode
        field
        message
      }
      links {
        id
        linkTitle
        linkType
        url
        platform
        iconMinimal
        iconColorful
        featured
        shortenedUrl
        order
        biolink {
          id
        }
      }
    }
  }
`;

// Referrals
export const GET_ALL_REFERRALS_LIST = gql`
  query GetReferralsList {
    getReferralsList {
      errors {
        field
        message
      }
      referrals {
        id
        referredByEmail
        referredByName
        referredToEmail
        referredToName
        createdAt
      }
    }
  }
`;
// Biolinks Query
export const GET_ALL_USER_BIOLINKS_QUERY = gql`
  query GetAllUserBiolinks {
    getAllUserBiolinks {
      errors {
        errorCode
        field
        message
      }
      biolinks {
        id
        user {
          id
        }
        profilePhotoUrl
        coverPhotoUrl
        displayName
        city
        state
        country
        latitude
        longitude
        bio
        settings {
          enableDarkMode
          showEmail
          email
          showPhone
          phone
          enableColoredContactButtons
          addedToDirectory
          directoryBio
          enableColoredSocialMediaIcons
          socialAccountStyleType

          enableFacebookPixel
          facebookPixelId
          enableGoogleAnalytics
          googleAnalyticsCode
          enableUtmParameters
          utmSource
          utmMedium
          utmCampaign
          blockSearchEngineIndexing
          pageTitle
          metaDescription
          opengraphImageUrl
          removeDefaultBranding
          enableCustomBranding
          customBrandingName
          customBrandingUrl
          enablePasswordProtection
          enableSensitiveContentWarning
          paypalLink
          venmoLink
          payoneerLink
        }
        verificationStatus
        verifiedGovernmentId
        verifiedEmail
        verifiedPhoneNumber
        verifiedWorkEmail
        featured
        changedUsername
        username {
          id
          username
          premiumType
          expireDate
        }
        links {
          id
          linkType
          linkTitle
          linkImageUrl
          url
          shortenedUrl
          order
          platform
          iconMinimal
          iconColorful
          featured
          startDate
          endDate
          enablePasswordProtection
          note
          biolink {
            id
          }
        }
        category {
          id
          categoryName
          featured
        }
      }
    }
  }
`;

export const GET_BIOLINK_FROM_USERNAME = gql`
  query GetBiolinkFromUsername($username: String!) {
    getBiolinkFromUsername(username: $username) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        user {
          id
        }
        profilePhotoUrl
        coverPhotoUrl
        displayName
        city
        state
        country
        latitude
        longitude
        bio
        settings {
          enableDarkMode
          showEmail
          email
          showPhone
          phone
          enableColoredContactButtons
          addedToDirectory
          directoryBio
          enableColoredSocialMediaIcons
          socialAccountStyleType

          enableFacebookPixel
          facebookPixelId
          enableGoogleAnalytics
          googleAnalyticsCode
          enableUtmParameters
          utmSource
          utmMedium
          utmCampaign
          blockSearchEngineIndexing
          pageTitle
          metaDescription
          opengraphImageUrl
          removeDefaultBranding
          enableCustomBranding
          customBrandingName
          customBrandingUrl
          enablePasswordProtection
          enableSensitiveContentWarning
          paypalLink
          venmoLink
          payoneerLink
        }
        verificationStatus
        verifiedGovernmentId
        verifiedEmail
        verifiedPhoneNumber
        verifiedWorkEmail
        featured
        changedUsername
        username {
          id
          username
          premiumType
          expireDate
        }
        links {
          id
          linkType
          linkTitle
          linkImageUrl
          linkColor
          url
          shortenedUrl
          platform
          iconMinimal
          iconColorful
          featured
          order
          startDate
          endDate
          enablePasswordProtection
          note
          biolink {
            id
          }
        }
        category {
          id
          categoryName
          featured
        }
      }
    }
  }
`;

// Categories Query
export const FETCH_ALL_CATEGORIES = gql`
  query FetchAllCategories($query: String, $first: Float) {
    fetchAllCategories(options: { before: "", after: "", query: $query, first: $first }) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          categoryName
        }
        cursor
      }
    }
  }
`;

// Directory Query
export const GET_ALL_DIRECTORIES = gql`
  query GetAllDirectories(
    $categoryId: [Int!]
    $query: String
    $before: String
    $after: String
    $first: Float
  ) {
    getAllDirectories(
      categoryIds: $categoryId
      options: { query: $query, before: $before, after: $after, first: $first }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          username {
            id
            username
            premiumType
            expireDate
            createdAt
            updatedAt
          }
          profilePhotoUrl
          coverPhotoUrl
          displayName
          city
          state
          country
          latitude
          longitude
          bio
          settings {
            enableDarkMode
            showEmail
            email
            showPhone
            phone
            enableColoredContactButtons
            addedToDirectory
            directoryBio
            enableColoredSocialMediaIcons
            socialAccountStyleType

            enableFacebookPixel
            facebookPixelId
            enableGoogleAnalytics
            googleAnalyticsCode
            enableUtmParameters
            utmSource
            utmMedium
            utmCampaign
            blockSearchEngineIndexing
            pageTitle
            metaDescription
            opengraphImageUrl
            removeDefaultBranding
            enableCustomBranding
            customBrandingName
            customBrandingUrl
            enablePasswordProtection
            enableSensitiveContentWarning
            paypalLink
            venmoLink
            payoneerLink
          }
          verificationStatus
          verifiedGovernmentId
          verifiedEmail
          verifiedPhoneNumber
          verifiedWorkEmail
          category {
            id
            categoryName
          }
          links {
            id
            linkType
            linkTitle
            url
            platform
            iconMinimal
            iconColorful
            featured
            biolink {
              id
            }
          }
        }
      }
    }
  }
`;

// Links Query
export const GET_ALL_USER_LINKS = gql`
  query GetAllUserLinks {
    getAllUserLinks {
      errors {
        errorCode
        field
        message
      }
      links {
        id
        linkType
        linkTitle
        url
        shortenedUrl
        platform
        iconMinimal
        iconColorful
        featured
        enablePasswordProtection
        note
        updatedAt
        biolink {
          id
        }
      }
    }
  }
`;

// Plan Query
export const GET_ACCESS_TOKEN = gql`
  query GetAccessToken {
    getAccessToken {
      errors {
        errorCode
        field
        message
      }
      access_token
    }
  }
`;

export const GET_ALL_PLANS = gql`
  query GetAllPlan {
    getAllPlans {
      errors {
        errorCode
        message
      }
      plans {
        id
        name
        monthlyPrice
        monthlyPriceStripeId
        annualPrice
        annualPriceStripeId
        settings {
          totalBiolinksLimit
          totalLinksLimit
          totalCustomDomainLimit
          darkModeEnabled
          addedToDirectoryEnabled
          customBackHalfEnabled
          noAdsEnabled
          removableBrandingEnabled
          customFooterBrandingEnabled
          coloredLinksEnabled
          googleAnalyticsEnabled
          facebookPixelEnabled
          verifiedCheckmarkEnabled
          linksSchedulingEnabled
          seoEnabled
          socialEnabled
          utmParametersEnabled
          passwordProtectionEnabled
          sensitiveContentWarningEnabled
          leapLinkEnabled
        }
        enabledStatus
        visibilityStatus
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

// Analytics Query

export const GET_LINK_CLICK_DATA = gql`
  query GetLinkClicksData {
    getLinkClicksData {
      errors {
        errorCode
        message
      }
      result {
        todayVisited
        allTimeVisited
        link {
          id
          linkType
          linkTitle
          linkColor
          linkImageUrl
          url
          shortenedUrl
          platform
          iconMinimal
          iconColorful
          featured
          order
          startDate
          endDate
          enablePasswordProtection
          note
        }
      }
    }
  }
`;

export const GET_BIOLINK_CHART_DATA = gql`
  query GetBiolinkChartData($id: String) {
    getBiolinkChartData(id: $id) {
      errors {
        errorCode
        field
        message
      }
      result {
        views
        date
      }
    }
  }
`;

export const GET_SEARCH_QUERIES = gql`
  query GetSearchQueries($query: String) {
    getSearchQueries(query: $query) {
      results
      errors {
        errorCode
        field
        message
      }
    }
  }
`;

export const GET_ALL_MESSAGES = gql`
  query GetAllMessages(
    $otherUserId: String!
    $before: String
    $after: String
    $query: String
    $first: Float
    $last: Float
  ) {
    getAllMessages(
      otherUserId: $otherUserId
      options: { before: $before, after: $after, query: $query, first: $first, last: $last }
    ) {
      errors {
        errorCode
        field
        message
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          message
          attachmentUrl
          createdAt
          sender {
            id
            email
          }
          receiver {
            id
            email
          }
        }
      }
    }
  }
`;

export const GET_LAST_MESSAGES = gql`
  query GetLastMessages(
    $before: String
    $after: String
    $query: String
    $first: Float
    $last: Float
  ) {
    getLastMessages(
      options: { before: $before, after: $after, query: $query, first: $first, last: $last }
    ) {
      errors {
        errorCode
        field
        message
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          message
          attachmentUrl
          sender {
            id
            email
            usernames {
              id
              username
            }
          }
          receiver {
            id
            email
            usernames {
              id
              username
            }
          }
        }
        cursor
      }
    }
  }
`;

export const GET_USER_ACTIVITY = gql`
  query GetUserActivity(
    $before: String
    $after: String
    $query: String
    $first: Float
    $last: Float
  ) {
    getUserActivity(
      options: { before: $before, after: $after, query: $query, first: $first, last: $last }
    ) {
      errors {
        errorCode
        field
        message
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          description
          showInActivity
          createdAt
        }
      }
    }
  }
`;

// Get All Follow User

export const GET_IF_FOLLOWING = gql`
  query GetIfFollowing($biolinkId: String!) {
    getIfFollowing(biolinkId: $biolinkId) {
      errors {
        errorCode
        field
        message
      }
      following
    }
  }
`;

export const GET_ALL_FOLLOWERS = gql`
  query GetAllFollowings($before: String, $after: String, $query: String) {
    getAllFollowings(
      options: { before: $before, after: $after, query: $query, first: Float, last: Float }
    ) {
      errors {
        errorCode
        field
        message
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
        }
        cursor
      }
    }
  }
`;
// Link By Shorternd Url
export const GET_LINK_BY_SHORTENED_URL = gql`
  query GetLinkByShortenedUrl($password: String, $shortenedUrl: String!) {
    getLinkByShortenedUrl(password: $password, shortenedUrl: $shortenedUrl) {
      errors {
        errorCode
        field
        message
      }
      link {
        id
        linkType
        linkTitle
        linkColor
        linkImageUrl
        url
        platform
        icon
        featured
        shortenedUrl
        enablePasswordProtection
      }
    }
  }
`;

// GET Payment Details
export const GET_PAYMENT = gql`
  query GetPayment($paymentId: String!) {
    getPayment(paymentId: $paymentId) {
      errors {
        errorCode
        field
        message
      }
      payment {
        id
        paymentType
        stripeAmountDue
        stripeAmountPaid
        stripeAmountRemaining
        stripeChargeId
        stripeInvoiceCreated
        stripePaymentCurrency
        stripeCustomerId
        stripeCustomerAddress
        stripeCustomerEmail
        stripeCustomerName
        stripeCustomerPhone
        stripeCustomerShipping
        stripeDiscount
        stripeInvoicePdfUrl
        stripeInvoiceUrl
        stripePriceId
        stripeSubscriptionId
        stripeInvoiceNumber
        stripePeriodStart
        stripePeriodEnd
        stripeStatus
        createdAt
        user {
          id
        }
      }
    }
  }
`;

export const GET_ALL_USER_PAYMENT = gql`
  query GetAllUserPayment(
    $before: String
    $after: String
    $query: String
    $first: Float
    $last: Float
  ) {
    getAllUserPayments(
      options: { before: $before, after: $after, query: $query, first: $first, last: $last }
    ) {
      errors {
        errorCode
        field
        message
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          paymentType
          stripeAmountDue
          stripeAmountPaid
          stripeAmountRemaining
          stripeChargeId
          stripeInvoiceCreated
          stripePaymentCurrency
          stripeCustomerId
          stripeCustomerAddress
          stripeCustomerEmail
          stripeCustomerName
          stripeCustomerPhone
          stripeCustomerShipping
          stripeDiscount
          stripeInvoicePdfUrl
          stripeInvoiceUrl
          stripePriceId
          stripeSubscriptionId
          stripeInvoiceNumber
          stripePeriodStart
          stripePeriodEnd
          stripeStatus
          createdAt
          user {
            id
          }
        }
      }
    }
  }
`;

export const GET_SENT_EMAIL_REFERRALS = gql`
  query GetSentEmailReferrals($before: String, $after: String, $query: String) {
    getSentEmailReferrals(
      options: { before: $before, after: $after, query: $query, first: Float, last: Float }
    ) {
      errors {
        errorCode
        field
        message
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          referredByEmail
          referredByName
          referredToEmail
          referredToName
          createdAt
          referredBy {
            id
            email
            lastActiveTill
            planExpirationDate
            planTrialDone
            usedReferralsToPurchasePlan
            language
          }
        }
        cursor
      }
    }
  }
`;
