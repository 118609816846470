import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Elements, useStripe } from '@stripe/react-stripe-js';
// import {  CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { useQuery } from '@apollo/client';

import { loadStripe } from '@stripe/stripe-js';
import { GET_ACCESS_TOKEN } from '../../../graphql/Queries';

import ToggleSwitch from '../../../components/ui/ToggleSwitch';
import BuyButton from '../../main/stripe/BuyButton';

import PricingBody from '../../main/pricing/PricingBody';

import * as appActions from '../../../store/actions/app';
import * as alertActions from '../../../store/actions/alert';
import generateRandomId from '../../../utils/genrateRandomId';

const stripePromise = loadStripe(
  'pk_test_51J27JpBtF9AcyMltHwDwkiuO8G2RWmHbXRH4CoGrr4xhFmD8uKXivczVzntU2yFzz3ziuByv6FlZ24nJRpiDGzyO009ttZKzmZ',
);
const Upgrade = (props, { history, annualStripId, monthlyPriceStripeId }) => {
  const currentPlan = useSelector((state) => state.auth.user);
  /// console.log('========currentPlan==========>', currentPlan);
  // console.log('accessToken=====>', currentPlan);
  const [switchButton, setswitchButton] = useState(false);
  const [planName, setPlanName] = useState('');
  const [isFreeSelected, setIsFreeSelected] = useState('block');
  const [isSavingsPlanSelected, setIsSavingsPlanSelected] = useState('none');
  const [isPremiumPlanSelected, setIsPremiumPlanSelected] = useState('none');
  const [planType, setPlanType] = useState('');

  const [accessToken, setAccessToken] = useState(null);
  const { data: tokenData } = useQuery(GET_ACCESS_TOKEN, {
    fetchPolicy: 'network-only',
  });

  // const [accessToken, setAccessToken] = useState(null);

  // const monthHandler = (evt) => {
  //   // evt.target.id
  //   // console.log('========planType changes ==========>', evt.target.id);
  //   if (evt.target.id === 'Monthly' || evt.target.id === 'monthly') {
  //     document.getElementById('monthly').className = 'active';
  //     document.getElementById('yearly').className = '';
  //   } else {
  //     document.getElementById('monthly').className = '';
  //     document.getElementById('yearly').className = 'active';
  //   }
  // };

  useEffect(() => {
    // console.log('========switchButton changes==========>', switchButton);
    if (switchButton === true) {
      setPlanType('Annual');
    } else {
      setPlanType('Monthly');
    }
  }, [switchButton]);

  useEffect(() => {
    // console.log('========planType changes==========>', planType);
    if (switchButton === 'Monthly') {
      setswitchButton(false);
    } else if (switchButton === 'Annual') {
      setswitchButton(true);
    }
  }, [planType]);

  useEffect(() => {
    console.log('========planType==========>', currentPlan?.planType);
    if (currentPlan?.plan?.name == 'Free') {
      // var valll = "block";
      setIsFreeSelected('block');
      setIsSavingsPlanSelected('none');
      setIsPremiumPlanSelected('none');
    } else if (currentPlan?.plan?.name == 'Savings Plan') {
      setIsSavingsPlanSelected('block');
      setIsFreeSelected('none');
      setIsPremiumPlanSelected('none');
    } else if (currentPlan?.plan?.name == 'Premium Plan') {
      setIsPremiumPlanSelected('block');
      setIsSavingsPlanSelected('none');
      setIsFreeSelected('none');
    }
  }, []);

  const plans = useSelector((state) => state.auth.plans);
  // console.log('accessToken=====>', plans);
  useEffect(() => {
    if (tokenData?.getAccessToken?.errors === null) {
      setAccessToken(tokenData.getAccessToken.access_token);
    }
    if (tokenData?.getAccessToken?.errors?.length) {
      if (tokenData?.getAccessToken?.errors[0].errorCode === 2) {
        // dispatch(authActions.authenticate({}, false));
      }
    }
  }, [tokenData]);
  // console.log('Check--Status', plans);
  // console.log('Check-Data-Status', plans[0]);
  // const planHandler = () => {
  //   props.history.push('/app/payment/checkout');
  // };

  const planNameHandler = (evt) => {
    // console.log('console 234===---->>>>>> ', evt.target.id);
    // console.log('console 234===---->>>>>> ', evt.target.id.trim());

    setPlanName(evt.target.id);

    //  $('.all-planss').removeClass('active-plan');
    // $('.all-planss > #', evt.target.id).addClass('active-plan');
    // $(this).parent().addClass('active-plan');
    if (evt.target.id == 'Free') {
      setIsFreeSelected('block');
      setIsSavingsPlanSelected('none');
      setIsPremiumPlanSelected('none');
      // document.getElementById('Free');
      // console.log('Staus===---->>>>>> ', evt.target.id);
      // console.log('Staus===----///////>>>>>> ', evt.target.id[0]);
    }

    if (evt.target.id == 'Savings Plan') {
      // document.getElementById('savings plan');
      setIsFreeSelected('none');
      setIsSavingsPlanSelected('block');
      setIsPremiumPlanSelected('none');
      //  console.log('Staus===---->>>>>> ', evt.target.id);
    }

    if (evt.target.id == 'Premium Plan') {
      // document.getElementById('Premium Plan');
      setIsFreeSelected('none');
      setIsSavingsPlanSelected('none');
      setIsPremiumPlanSelected('block');
      // console.log('Staus===---->>>>>> ', evt.target.id);
    }
  };

  useEffect(() => {
    //   console.log('planName==>', isPremiumPlanSelected);
  }, [planName, isFreeSelected, isSavingsPlanSelected, isPremiumPlanSelected]);

  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner billing-section upgrade-area ">
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="center-heading black-text">
                    <h1>
                      <span className="blue-text">Upgrade</span>
                    </h1>
                  </div>
                  <div className="card-body">
                    <div id="generic_price_table">
                      <section>
                        <div className="container">
                          {/* BLOCK ROW START */}
                          <div className="upgrade-box">
                            <div className="row">
                              {plans &&
                                plans.map((item, index) => (
                                  //  planName == item.name ? val2 = 'block' : val2 = 'none'

                                  <div
                                    //   key={index == 0}

                                    className="col-md-6"
                                    id={item.name}
                                    // style={{
                                    //   display: { ...(planName == item.name ? 'block' : 'none') },
                                    // }}
                                    style={{
                                      display:
                                        item.name == 'Free'
                                          ? isFreeSelected
                                          : item.name == 'Savings Plan'
                                          ? isSavingsPlanSelected
                                          : item.name == 'Premium Plan'
                                          ? isPremiumPlanSelected
                                          : 'block',
                                    }}
                                  >
                                    {/* PRICE CONTENT START */}
                                    <div
                                      className={`generic_content clearfix ${
                                        index == 1 ? 'Highlighted' : ''
                                      }`}
                                    >
                                      {/* HEAD PRICE DETAIL START */}
                                      <div className="generic_head_price clearfix ">
                                        {/* HEAD CONTENT START */}
                                        <div className="generic_head_content clearfix">
                                          {/* HEAD START */}
                                          <div className="head_bg" />
                                          <div className="head  ">
                                            <span onClick={() => planNameHandler()} id="Free">
                                              {item.name}
                                            </span>
                                          </div>
                                          {/* //HEAD END */}
                                        </div>
                                        {/* //HEAD CONTENT END */}
                                        {/* PRICE START */}
                                        <div className="generic_price_tag clearfix">
                                          <span className="price">
                                            <span className="sign">$</span>{' '}
                                            {!switchButton ? (
                                              <span className="currency">
                                                {item?.monthlyPrice}
                                                <span className="cent"> .0 </span>
                                                <span
                                                  className="month "
                                                  onClick={() => monthHandler()}
                                                  id="monthly"
                                                >
                                                  /Month
                                                </span>
                                              </span>
                                            ) : (
                                              <span className="currency">
                                                {item?.annualPrice}
                                                <span className="cent"> .0 </span>
                                                <span
                                                  className="month"
                                                  onClick={() => monthHandler()}
                                                  id="Yearly"
                                                >
                                                  /Annual
                                                </span>
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                        {/* //PRICE END */}
                                      </div>
                                      {/* //HEAD PRICE DETAIL END */}
                                      {/* FEATURE LIST START */}
                                      <div className="generic_feature_list">
                                        <ul>
                                          <li>
                                            <span>
                                              {item.settings.totalLinksLimit ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Total Links Limit
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.totalBiolinksLimit ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Total Biolinks Limit
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.totalCustomDomainLimit ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Custom Domain Limit
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.totalBiolinksLimit ? (
                                                <i className="fa fa-check " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Unique Stash.ee URL
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.addedToDirectoryEnabled ? (
                                                <i className="fa fa-check " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Added To Directory
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.customBackHalfEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Custom Back Half
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.coloredLinksEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Colored Links
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.googleAnalyticsEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Google Analytics
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.facebookPixelEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Facebook Pixel
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.verifiedCheckmarkEnabled ? (
                                                <i
                                                  className="fa fa-check  "
                                                  aria-hidden="true"
                                                  aria-checked
                                                />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Verified Check Mark
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.linksSchedulingEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Links Scheduling
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.darkModeEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Dark Mode
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.removableBrandingEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Removable Branding
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.customFooterBrandingEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Custom Footer Branding
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.seoEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Seo
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.socialEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Social Media
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.utmParametersEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Utm Parameters
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.passwordProtectionEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Password Protection
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.sensitiveContentWarningEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Sensitive Content
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.leapLinkEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            Leap Link
                                          </li>
                                          <li>
                                            <span>
                                              {item.settings.noAdsEnabled ? (
                                                <i className="fa fa-check  " aria-hidden="true" />
                                              ) : (
                                                <i className="fa fa-times " aria-hidden="true" />
                                              )}
                                            </span>
                                            No Ads
                                          </li>
                                        </ul>
                                      </div>
                                      {/* //FEATURE LIST END */}
                                      {/* BUTTON START */}
                                      <div className="generic_price_btn clearfix">
                                        {/* <a className href>
                            Buy package
                          </a> */}
                                        {currentPlan?.plan?.name == item.name ||
                                        item.name == 'Free' ? (
                                          ''
                                        ) : (
                                          <Elements stripe={stripePromise}>
                                            <BuyButton
                                              accessToken={accessToken}
                                              annualStripId={item.annualPriceStripeId}
                                              monthlyPriceStripeId={item.monthlyPriceStripeId}
                                              annualMonthlyStripId={
                                                planType == 'Monthly'
                                                  ? item.monthlyPriceStripeId
                                                  : planType == 'Annual'
                                                  ? item.annualPriceStripeId
                                                  : ''
                                              }
                                            />
                                          </Elements>
                                        )}
                                      </div>
                                      {/* //BUTTON END */}
                                    </div>
                                    {/* //PRICE CONTENT END */}
                                  </div>
                                ))}
                              <div className="col-md-6">
                                {/* PRICE CONTENT START */}
                                {/* {plans &&
                                  plans.map((item, index) => ( */}
                                <div className="generic_content active clearfix">
                                  {/* HEAD PRICE DETAIL START */}
                                  <div className="generic_head_price clearfix">
                                    {/* HEAD CONTENT START */}
                                    <div className="generic_head_content clearfix">
                                      {/* HEAD START */}
                                      <div className="head_bg" />
                                      <div className="head">
                                        <span>select plan</span>
                                      </div>
                                      {/* //HEAD END */}
                                    </div>
                                    {/* //HEAD CONTENT END */}
                                  </div>
                                  {/* //HEAD PRICE DETAIL END */}
                                  {/* FEATURE LIST START */}
                                  <div className="generic_feature_list">
                                    <div className="bill-box row">
                                      <div className="col-md-4 col-sm-4">Bill Monthly</div>
                                      <div className="col-md-4 col-sm-4">
                                        {/* {planType == 'Monthly' && planType == 'Annual '
                                          ? isSavingsPlanSelected
                                          : ''} */}
                                        <ToggleSwitch
                                          currentState={switchButton}
                                          onToggle={() => setswitchButton(!switchButton)}
                                          // style={{
                                          //   display:
                                          //     isFreeSelected &&
                                          //     isSavingsPlanSelected &&
                                          //     isPremiumPlanSelected &&
                                          //     planType !== 'Monthly' &&
                                          //     planType !== 'Annual'
                                          //       ? 'active-plan'
                                          //       : 'block',
                                          // }}
                                        />
                                      </div>
                                      <div className="col-md-4 col-sm-4 text-right">Bill Yearly</div>
                                    </div>

                                    {plans &&
                                      plans.map((item, index) => (
                                        <>
                                          <ul key={index} className="selected-plan">
                                            <li
                                              // onClick={(evt) => planNameHandler(evt)}
                                              // id={item.name}
                                              // className={...(currentPlan?.plan?.name == 'Free') ?("active-plan"):("") }
                                              // active-plan
                                              className={`row ${
                                                planName == item.name
                                                  ? 'active-plan'
                                                  : currentPlan?.plan?.name == item.name &&
                                                    planName == ''
                                                  ? 'active-plan'
                                                  : ''
                                              }`}
                                            >
                                              <h2
                                                className={`col-md-6  `}
                                                onClick={(evt) => planNameHandler(evt)}
                                                id={item.name}
                                              >
                                                {item.name ? item.name : null}
                                                {/* {item.annualPriceStripeId} */}
                                              </h2>
                                              {!switchButton ? (
                                                <div className="plan-price col-md-6 text-right">
                                                  ${item.monthlyPrice} /
                                                  <small
                                                    onClick={() => monthHandler()}
                                                    id="monthly"
                                                  >
                                                    Month
                                                  </small>
                                                </div>
                                              ) : (
                                                <div className="plan-price inline-box ">
                                                  ${item.annualPrice} /
                                                  <small onClick={() => monthHandler()} id="Yearly">
                                                    Year
                                                  </small>
                                                </div>
                                              )}
                                            </li>
                                          </ul>
                                        </>
                                      ))}
                                  </div>
                                  {/* //FEATURE LIST END */}
                                  {/* BUTTON START */}
                                  <div className="generic_price_btn clearfix">
                                    {plans &&
                                      plans.map((item) =>
                                        // console.log("isFreeSelected====>", isFreeSelected);

                                        item.name == 'Free' && isFreeSelected == 'block' ? (
                                          currentPlan?.plan?.name == item.name ? (
                                            <div className="generic_price_btn clearfix">
                                              <a href="#as">Activated</a>
                                            </div>
                                          ) : (
                                            ''
                                          )
                                        ) : item.name == 'Savings Plan' &&
                                          isSavingsPlanSelected == 'block' ? (
                                          currentPlan?.plan?.name == item.name ? (
                                            <div className="generic_price_btn clearfix">
                                              <a href="#as" disabled>
                                                Activated
                                              </a>
                                            </div>
                                          ) : (
                                            <Elements stripe={stripePromise}>
                                              <BuyButton
                                                disabled
                                                accessToken={accessToken}
                                                annualStripId={item.annualPriceStripeId}
                                                monthlyPriceStripeId={item.monthlyPriceStripeId}
                                                annualMonthlyStripId={
                                                  planType == 'Monthly'
                                                    ? item.monthlyPriceStripeId
                                                    : planType == 'Annual'
                                                    ? item.annualPriceStripeId
                                                    : ''
                                                }
                                              />
                                            </Elements>
                                          )
                                        ) : item.name == 'Premium Plan' &&
                                          isPremiumPlanSelected == 'block' ? (
                                          currentPlan?.plan?.name == item.name ? (
                                            <div className="generic_price_btn clearfix">
                                              <a href="#as" disabled>
                                                Activated
                                              </a>
                                            </div>
                                          ) : (
                                            <Elements stripe={stripePromise}>
                                              <BuyButton
                                                disabled
                                                accessToken={accessToken}
                                                annualStripId={item.annualPriceStripeId}
                                                monthlyPriceStripeId={item.monthlyPriceStripeId}
                                                annualMonthlyStripId={
                                                  planType == 'Monthly'
                                                    ? item.monthlyPriceStripeId
                                                    : planType == 'Annual'
                                                    ? item.annualPriceStripeId
                                                    : ''
                                                }
                                              />
                                            </Elements>
                                          )
                                        ) : (
                                          ''
                                        ),
                                      )}
                                  </div>

                                  {/* //BUTTON END */}
                                </div>
                                {/* ))} */}
                                {/* //PRICE CONTENT END */}
                              </div>
                            </div>
                          </div>
                          {/* //BLOCK ROW END */}
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Upgrade;
