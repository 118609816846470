import React, { useEffect, useState } from 'react';
// import { Table } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useQuery } from '@apollo/client';
import { GET_ALL_REFERRALS_LIST } from '../../graphql/Queries';

const ReferralsList = ({ referrals, onListUpdated }) => {
  // console.log('testing data here====', referrals);
  // window.location.reload(true);
  // const { error, loading, data, refetch } = useQuery(GET_ALL_REFERRALS_LIST);
  const [list, setList] = useState([]);

  // useEffect(() => {
  //   if (data?.getReferralsList.referrals?.length) {
  //     setList(data?.getReferralsList?.referrals);
  //   }
  // }, [data, error, loading]);

  // useEffect(() => {
  //   onListUpdated();
  // }, [list]);

  // update list
  // useEffect(() => {
  //   refetch();
  // }, [updateList]);

  const renderReferralsList = () => {
    return referrals.map((item, index) => {
      return (
        <Tr key={index} className="bgsection">
          <Td>{index + 1}</Td>
          <Td>{item.referredToName}</Td>
          <Td>{item.referredToEmail}</Td>
        </Tr>
      );
    });
  };

  return (
    <>
      <div className="col-md-6">
        <div className="card full-height">
          <div className="card-header">
            <div className="card-title blue-text">Direct Email Referral</div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <Table className="table table-head-bg-primary">
                <Thead>
                  <Tr className="tablebg">
                    <Th>#</Th>
                    <Th>Name</Th>
                    <Th>Email</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {referrals.length ? (
                    renderReferralsList()
                  ) : (
                    <Tr>
                      <Td colSpan="3">No referrals, try adding some</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="card full-height">
          <div className="card-header">
            <div className="card-title blue-text">From Social Media</div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <Table className="table table-head-bg-primary">
                <Thead>
                  <Tr className="tablebg">
                    <Th>#</Th>
                    <Th>Name</Th>
                    <Th>Email</Th>
                  </Tr>
                </Thead>
                <Tbody className="bgsection">
                  {referrals.map((item, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{item.referredByName}</Td>
                      <Td>{item.referredByEmail}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralsList;
