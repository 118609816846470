import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import generateRandomId from '../utils/genrateRandomId';
import * as alertActions from '../store/actions/alert';
import * as authActions from '../store/actions/auth';
import { LOAD_USER_DETAILS } from '../graphql/Queries';

const useHandleResponse = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const loginTime = useSelector((state) => state.auth.loginTime);

  const [me, { data: userDetails }] = useLazyQuery(LOAD_USER_DETAILS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (userDetails && userDetails.me?.biolinks.length) {
      dispatch(authActions.authenticate(userDetails.me, true));
    }
  }, [userDetails]);

  // useEffect(() => {
  //   const diff = moment().unix() - loginTime;
  //   if (diff > 10 && data?.me === null) {
  //     // dispatch(authActions.authenticate({}, false));
  //     history.push('/auth/login');
  //   }
  // }, [data]);

  const successMessage = () => {
    me();
    dispatch(
      alertActions.addAlert({
        id: generateRandomId(),
        message: 'Updated Successfully.',
        type: 'success',
      }),
    );
  };

  const errorMessage = (errors) => {
    errors.forEach((err) => {
      console.log('hooks errors======>', err);
      if (err.errorCode === 3 || err.errorCode === 2) {
        dispatch(authActions.authenticate({}, false));
        history.push('/auth/login');
      }
      dispatch(
        alertActions.addAlert({
          id: generateRandomId(),
          message: `${err.field} ${err.message}`,
          type: 'error',
        }),
      );
    });
  };

  return {
    successMessage,
    errorMessage,
  };
};

export default useHandleResponse;
