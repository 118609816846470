import React from 'react';

const EmailVerify = (props) => {
  return (
    <>
      <div>
        <div className="container">
          <div className="page-inner ">
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="payment-box-ev">
                    <div className="sucess-bar">
                      <h1>
                        We have send verification link to your registered mail id please verify your
                        account.
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerify;
