import {
  REGISTER_MUTATION,
  ADD_SUPPORT,
  REMOVE_LINK_MUTATION,
  CREATE_NEW_LINK,
  SORT_BIOLINK_LINKS_MUTATION,
  UPDATE_INTEGRATION_SETTINGS_MUTATION,
  IMPORT_BIOLINK_DETAILS_FROM_LINKTREE_PROFILE_MUTATION,
  UPDATE_BIOLINK_MUTATION,
  UPDATE_CONTACT_BUTTON_SETTINGS_MUTATION,
  UPDATE_SOCIAL_ACCOUNTS_SETTINGS_MUTATION,
  UPDATE_DIRECTORY_SETTINGS_MUTATION,
  UPDATE_UTM_PARAMETER_SETTINGS_MUTATION,
  UPDATE_SEO_SETTINGS_MUTATION,
  UPDATE_BRANDING_SETTINGS_MUTATION,
  UPDATE_PRIVACY_SETTINGS_MUTATION,
  UPDATE_DONATION_SETTINGS_MUTATION,
  UPDATE_DARK_MODE_OPTIONS_MUTATION,
  UPLOAD_BIOLINK_COVER_PHOTO_MUTATION,
  UPLOAD_BIOLINK_PROFILE_PHOTO_MUTATION,
  REMOVE_BIOLINK_MUTATION,
  UPDATE_LINK_MUTATION,
  CREATE_REFERRAL_MUTATION,
  CHANGE_USER_ACCOUNT_INFO_MUTATION,
  CREATE_NEW_BIOLINK_MUTATION,
  CHANGE_USER_PASSWORD_MUTATION,
  CHANGE_CURRENT_BIOLINKID_MUTATION,
  SEND_MESSAGE_MUTATION,
  FOLLOW_USER_MUTATION,
  UNFOLLOW_USER_MUTATION,
} from '../graphql/Mutations';

import registerUser from './mutations/registerUser';
import addSupport from './mutations/addSupport';
import removeLink from './mutations/removeLink';
import createNewLink from './mutations/createNewLink';
import sortBiolinkLinks from './mutations/sortBiolinkLinks';
import updateIntegrationSettings from './mutations/updateIntegrationSettings';
import importBiolinkDetailsFromLinktreeProfile from './mutations/importBiolinkDetailsFromLinktreeProfile';
import updateBiolink from './mutations/updateBiolink';
import updateContactButtonSettings from './mutations/updateContactButtonSettings';
import updateSocialAccountsSettings from './mutations/updateSocialAccountsSettings';
import updateDirectorySettings from './mutations/updateDirectorySettings';
import updateUTMParameterSettings from './mutations/updateUTMParameterSettings';
import updateSEOSettings from './mutations/updateSEOSettings';
import updateBrandingSettings from './mutations/updateBrandingSettings';
import updatePrivacySettings from './mutations/updatePrivacySettings';
import updateDonationSettings from './mutations/updateDonationSettings';
import updateDarkModeOptions from './mutations/updateDarkModeOptions';
import uploadBiolinkCoverPhoto from './mutations/uploadBiolinkCoverPhoto';
import uploadBiolinkProfilePhoto from './mutations/uploadBiolinkProfilePhoto';
import removeBiolink from './mutations/removeBiolink';
import updateLink from './mutations/updateLink';
import createReferrals from './mutations/createReferrals';
import changeUserAccountInfo from './mutations/changeUserAccountInfo';
import createNewBiolink from './mutations/createNewBiolink';
import changeUserPassword from './mutations/changeUserPassword';
import changeCurrentBiolinkId from './mutations/changeCurrentBiolinkId';
import sendMessage from './mutations/sendMessage';
import followUser from './mutations/followUser';
import unfollowUser from './mutations/unfollow';

const useNetwork = (request) => {
  switch (request) {
    case REGISTER_MUTATION:
      return registerUser();
    case ADD_SUPPORT:
      return addSupport();
    case REMOVE_LINK_MUTATION:
      return removeLink();
    case CREATE_NEW_LINK:
      return createNewLink();
    case SORT_BIOLINK_LINKS_MUTATION:
      return sortBiolinkLinks();
    case UPDATE_INTEGRATION_SETTINGS_MUTATION:
      return updateIntegrationSettings();
    case IMPORT_BIOLINK_DETAILS_FROM_LINKTREE_PROFILE_MUTATION:
      return importBiolinkDetailsFromLinktreeProfile();
    case UPDATE_BIOLINK_MUTATION:
      return updateBiolink();
    case UPDATE_CONTACT_BUTTON_SETTINGS_MUTATION:
      return updateContactButtonSettings();
    case UPDATE_SOCIAL_ACCOUNTS_SETTINGS_MUTATION:
      return updateSocialAccountsSettings();
    case UPDATE_DIRECTORY_SETTINGS_MUTATION:
      return updateDirectorySettings();
    case UPDATE_UTM_PARAMETER_SETTINGS_MUTATION:
      return updateUTMParameterSettings();
    case UPDATE_SEO_SETTINGS_MUTATION:
      return updateSEOSettings();
    case UPDATE_BRANDING_SETTINGS_MUTATION:
      return updateBrandingSettings();
    case UPDATE_PRIVACY_SETTINGS_MUTATION:
      return updatePrivacySettings();
    case UPDATE_DONATION_SETTINGS_MUTATION:
      return updateDonationSettings();
    case UPDATE_DARK_MODE_OPTIONS_MUTATION:
      return updateDarkModeOptions();
    case UPLOAD_BIOLINK_COVER_PHOTO_MUTATION:
      return uploadBiolinkCoverPhoto();
    case UPLOAD_BIOLINK_PROFILE_PHOTO_MUTATION:
      return uploadBiolinkProfilePhoto();
    case REMOVE_BIOLINK_MUTATION:
      return removeBiolink();
    case UPDATE_LINK_MUTATION:
      return updateLink();
    case CREATE_REFERRAL_MUTATION:
      return createReferrals();
    case CHANGE_USER_ACCOUNT_INFO_MUTATION:
      return changeUserAccountInfo();
    case CREATE_NEW_BIOLINK_MUTATION:
      return createNewBiolink();
    case CHANGE_USER_PASSWORD_MUTATION:
      return changeUserPassword();
    case CHANGE_CURRENT_BIOLINKID_MUTATION:
      return changeCurrentBiolinkId();
    case SEND_MESSAGE_MUTATION:
      return sendMessage();
    case FOLLOW_USER_MUTATION:
      return followUser();
    case UNFOLLOW_USER_MUTATION:
      return unfollowUser();
    default:
      return null;
  }
};

export default useNetwork;
