import React from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as appActions from '../../../store/actions/app';
import * as alertActions from '../../../store/actions/alert';
import generateRandomId from '../../../utils/genrateRandomId';

const BuyButton = ({
  history,
  accessToken,
  annualStripId,
  monthlyPriceStripeId,
  annualMonthlyStripId,
}) => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.auth.isAuthenticated);
  const stripe = useStripe();

  const createCheckoutSession = (id) => {
    return fetch('https://linkby-server.herokuapp.com/api/stripe/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({
        priceId: id,
      }),
    })
      .then((result) => {
        return result.json();
      })
      .catch((err) => {
        dispatch(appActions.isLoading(false));
        dispatch(
          alertActions.addAlert({
            id: generateRandomId(),
            message: 'Somethink went wrong.',
            type: 'success',
          }),
        );
      });
  };

  const handleSubmit = async (event) => {
    dispatch(appActions.isLoading(true));
    if (!isLogin) {
      dispatch(appActions.isLoading(false));
      history.push('/auth/login');
      return;
    }
    event.preventDefault();
    const response = await createCheckoutSession(annualMonthlyStripId);
    // const res = await createCheckoutSession(monthlyPriceStripeId);
    // // if (!stripe || !elements) {
    if (!stripe) {
      dispatch(appActions.isLoading(false));
      return;
    }
    stripe
      .redirectToCheckout({
        sessionId: response.sessionId,
      })
      .then((result) => {
        dispatch(appActions.isLoading(false));
      })
      .catch((err) => {
        dispatch(appActions.isLoading(false));
        dispatch(
          alertActions.addAlert({
            id: generateRandomId(),
            message: 'Something went wrong.',
            type: 'success',
          }),
        );
      });

    // for Monthly Period
    // const res = await createCheckoutSession(monthlyPriceStripeId);
    // // // if (!stripe || !elements) {
    // if (!stripe) {
    //   dispatch(appActions.isLoading(false));
    //   return;
    // }
    // stripe
    //   .redirectToCheckout({
    //     sessionId: response.sessionId,
    //   })
    //   .then((result) => {
    //     dispatch(appActions.isLoading(false));
    //   })
    //   .catch((err) => {
    //     dispatch(appActions.isLoading(false));
    //     dispatch(
    //       alertActions.addAlert({
    //         id: generateRandomId(),
    //         message: 'Somethink went wrong.',
    //         type: 'success',
    //       }),
    //     );
    //   });
  };

  return (
    // <button className=" btn-cart " type="button" onClick={handleSubmit}>
    <a href="#as" onClick={handleSubmit}>
      Buy Package
    </a>
    // </button>
  );
};

export default withRouter(BuyButton);
