import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import { UPDATE_DONATION_SETTINGS_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';
import socialMediaList from '../../../assets/socialMediaIcons';

const PaymentsForm = ({ showUpgrade }) => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const currentPlan = useSelector((state) => state.auth.user.plan);

  const [form, setForm] = useState({
    paypalLink: currentBiolink.settings?.paypalLink,
    paypalLinkError: [],
    venmoLink: currentBiolink.settings?.venmoLink,
    venmoLinkError: [],
    payoneerLink: currentBiolink.settings?.payoneerLink,
    payoneerLinkError: [],
  });

  const [updateDonationSettings, { data }] = useNetwork(UPDATE_DONATION_SETTINGS_MUTATION);

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    // submit form
    updateDonationSettings({
      variables: {
        id: currentBiolink.id,
        paypalLink: form.paypalLink,
        venmoLink: form.venmoLink,
        payoneerLink: form.payoneerLink,
      },
    });
    setForm({
      ...form,
      payoneerLink: '',
      venmoLink: '',
      paypalLink: '',
    });
  };

  return (
    <div className="modal fade" id="modal-10">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Add Payments</h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-list">
              <div className="form-group form-floating-label">
                {/* <input
                  id="inputFloatingLabel22"
                  type="text"
                  className="form-control input-solid"
                  required
                /> */}

                <img
                  src={socialMediaList.paypal.icon}
                  alt=""
                  height="25"
                  style={{ display: 'inline-block' }}
                />
                <input
                  type="text"
                  id="inputFloatingLabel2222"
                  className="form-control input-solid"
                  name="paypalLink"
                  value={form.paypalLink || ''}
                  onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                  placeholder="Paypal Link"
                />
                {/* <label htmlFor="inputFloatingLabel2222" className="placeholder">
                  Paypal Link
                </label> */}
                {form.paypalLinkError.length
                  ? form.paypalLinkError.map((err, i) => <ErrorSpan key={i} error={err} />)
                  : null}
              </div>

              <div className="form-group form-floating-label">
                {/* <input
                  id="inputFloatingLabel22"
                  type="text"
                  className="form-control input-solid"
                  required
                /> */}

                <img
                  src={socialMediaList.venmo.icon}
                  alt=""
                  height="25"
                  style={{ display: 'inline-block' }}
                />
                <input
                  type="text"
                  id="inputFloatingLabel2223"
                  className="form-control input-solid"
                  name="venmoLink"
                  value={form.venmoLink || ''}
                  onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                  placeholder="Venmo Link"
                />
                {/* <label htmlFor="inputFloatingLabel2223" className="placeholder">
                  Venmo Link
                </label> */}
                {form.venmoLinkError.length
                  ? form.venmoLinkError.map((err, i) => <ErrorSpan key={i} error={err} />)
                  : null}
              </div>

              <div className="form-group form-floating-label">
                <img
                  src={socialMediaList.venmo.icon}
                  alt=""
                  height="25"
                  style={{ display: 'inline-block' }}
                />
                <input
                  type="text"
                  id="inputFloatingLabel2225"
                  className="form-control input-solid"
                  name="payoneerLink"
                  value={form.payoneerLink || ''}
                  onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                  placeholder="Pyoneer Link"
                />
                {/* <label htmlFor="inputFloatingLabel2225" className="placeholder">
                  Pyoneer Link
                </label> */}
                {form.payoneerLinkError.length
                  ? form.payoneerLinkError.map((err, i) => <ErrorSpan key={i} error={err} />)
                  : null}
              </div>

              <button
                type="button"
                className="btn btn-primary"
                onClick={(evt) => onSubmitHandler(evt)}
              >
                Save changes
              </button>
            </div>
          </div>

          <div className="modal-footer">
            <a href="#modal-1" data-toggle="modal" data-dismiss="modal" className="btn btn-default">
              Previous
            </a>
            <button data-dismiss="modal" type="button" className="btn btn-danger">
              Close
            </button>
          </div>
        </div>
        {/* <!-- /.modal-content --> */}
      </div>
      {/* <!-- /.modal-dialog --> */}
    </div>
  );
};

export default PaymentsForm;
