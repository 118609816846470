import React,{ useState,useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'clipboard-copy';
import images from '../../../assets/images';
import * as alertActions from '../../../store/actions/alert';
import generateRandomId from '../../../utils/genrateRandomId';
import validate from '../../../utils/validations';
import socialMediaIcons from '../../../assets/socialMediaIcons';

const MyDirectory = () => {
  const dispatch = useDispatch();
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const [enableColoredSocialMediaIcons,setEnableColoredSocialMediaIcons] = useState('');
  const [socialAccountStyleType,setSocialAccountStyleType] = useState('');
  // console.log('currentBiolink======>', currentBiolink);
  const onCopyHandler = (url) => {
    copy(url);
    dispatch(
      alertActions.addAlert({
        id: generateRandomId(),
        message: 'Link copied.',
        type: 'success',
      }),
    );
  };
  
  useEffect(()=>{ setEnableColoredSocialMediaIcons(currentBiolink?.settings?.enableColoredSocialMediaIcons);
    setSocialAccountStyleType(currentBiolink?.settings?.socialAccountStyleType);},[currentBiolink]);
  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="page-inner my-directory ">
            <div className="white-box mb-5">
              <div className="row mt--2">
                <div className="col-md-12">
                  <div className="preview-wrapper md-header-area personal-directory row">
                    <div className="col-md-5 my-dir-border">
                      <div className="my-dir-body">
                      <div className="profile-box">
                        <div className="user-profile">
                          <img
                            src={
                              validate.imageValidate(images.dieractoryprofile)
                                ? images.dieractoryprofile
                                : images.dummy_img
                            }
                            alt=""
                          />
                          <div className="file-btn">
                            <img src="img/pluse.svg" alt="" />
                          </div>
                        </div>
                        <div className="file-btn">
                          <img src="img/pluse.svg" alt="" />
                        </div>
                     
                      <h3 className="md-usernm mt-2"> {currentBiolink.username?.username}</h3>
                      </div>
                      <ul className="my-directory-list">
                      <li>
                          <h2>{currentBiolink?.category?.categoryName}</h2>
                        </li>
                        <li>
                          <span
                            className="label-detail"
                            onClick={() =>
                              onCopyHandler(`stash.ee/${currentBiolink?.username?.username}`)
                            }
                          >
                            stash.ee/{currentBiolink?.username?.username}
                            <i className="fa fa-clone" aria-hidden="true" />
                          </span>
                        </li>
                        <li>
                          <span>
                            {' '}
                            <div className="verify">
                              {currentBiolink.settings?.verificationStatus == 'Verified' ? (
                                <i className="fa fa-check-circle" aria-hidden="true">
                                  Verified Author
                                </i>
                              ) : null}
                              {/* {currentBiolink.settings?.verifiedGovernmentId === true ? (
                        <a className="verified">
                          Government ID <i className="fa fa-check-circle" aria-hidden="true" />
                        </a>
                      ) : null} */}
                              {/* {currentBiolink.settings?.verifiedEmail === true ? (
                                <a className="verified">
                                  Email <i className="fa fa-check-circle" aria-hidden="true" />
                                </a>
                              ) : null}

                              {currentBiolink.settings?.verifiedPhoneNumber === true ? (
                                <a className="verified">
                                  Phone Number
                                  <i className="fa fa-check-circle" aria-hidden="true" />
                                </a>
                              ) : null}
                              {currentBiolink.settings?.verifiedWorkEmail === true ? (
                                <a>
                                  Work Email <i className="fa fa-check-circle" aria-hidden="true" />
                                </a>
                              ) : null} */}
                            </div>
                          </span>

                          <div>
                            <p>{currentBiolink.bio}</p>
                          </div>
                        </li>
                      </ul>
                      </div>
                    </div>
                    <div className="user-detail md-user-info col-md-7">
                      <ul className="preview-list">
                        <li>
                          <span className="label-box">
                            <i className="fa fa-map-marker" />
                            Address:
                          </span>

                          <span className="label-detail">
                            {currentBiolink.city}
                            {currentBiolink.city && currentBiolink.state && <span>, </span>}
                            {currentBiolink.state}
                            {currentBiolink.state && currentBiolink.country && <span>, </span>}
                            {currentBiolink.country}{' '}
                          </span>
                        </li>
                        <li>
                          <span className="label-box">
                            <i className="fa fa-envelope" />
                            Email:
                          </span>
                          <span className="label-detail"> {currentBiolink?.settings?.email}</span>
                        </li>
                        <li>
                          <span className="label-box">
                            <i className="fa fa-phone" />
                            Call:
                          </span>
                          <span className="label-detail"> {currentBiolink?.settings?.phone}</span>
                        </li>
                      </ul>
                      {/* <div className="socialicon">
                        <span>
                          <i className="fa fa-facebook-square" /> Facebook
                        </span>
                        <span>
                          <i className="fa fa-twitter" /> Twitter
                        </span>
                        <span>
                          <i className="fa fa-instagram" /> Instagram
                        </span>
                        <span>
                          <i className="fa fa-linkedin" /> Linkdin
                        </span>
                      </div> */}

                      <div className="footer-social-links author-social-admin social-media-container">
                      <div
                  className={`social-links ${
                    enableColoredSocialMediaIcons == true ? 'color' : 'minimal'
                  }
                  ${
                    socialAccountStyleType == 'Round'
                      ? 'Round'
                      : socialAccountStyleType == 'Square'
                      ? 'Square'
                      : 'OneClick'
                  }
                  `}
                >
                          {currentBiolink?.links
                            ? currentBiolink?.links.map((sdata, index) => (
                           
                            enableColoredSocialMediaIcons == true ? (<>
                            <a key={index} href={`${sdata.url}`} className="btn-admin social-media-link"
                            > <img src={sdata.iconColorful} alt="" /> </a></>
                            ) : <a key={index} href={`${sdata.url}`} className="btn-admin social-media-link"
                            > <img src={sdata.iconMinimal} alt="" /> </a>

                          
                            )
                            
                            )
                            : null}
                            
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card full-height">
                  <div className="card-body">
                    <div className="row listing-bottom minus-margin">
                      {currentBiolink.links?.map((ldata, index) => {
                        return (
                          <div className="col-md-12 mt-3" key={index}>
                            <div className="listurl clearfix">
                              <div className="personal-social-detail">
                                <h3>{ldata.linkTitle}</h3>
                                <p>{ldata.url}</p>
                              </div>
                              <div className="prsonal-list-icon">
                                <img
                                  src="https://avyatated.com/static/media/urlicon.321fe65c.svg"
                                  alt=""
                                />
                                {/* <button className="delbtn" type="button">
                                  <i className="fa fa-trash" aria-hidden="true" />
                                </button> */}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyDirectory;
