import { gql } from '@apollo/client';

export const REGISTER_MUTATION = gql`
  mutation RegisterUser(
    $referralToken: String!
    $name: String!
    $email: String!
    $password: String!
  ) {
    registerUser(
      referralToken: $referralToken
      options: { username: $name, email: $email, password: $password }
    ) {
      errors {
        field
        message
      }
      user {
        id
        email
        planExpirationDate
        planTrialDone
        language
        country
        currentBiolinkId
        biolinks {
          id
          user {
            id
          }
          profilePhotoUrl
          coverPhotoUrl
          displayName
          city
          state
          country
          latitude
          longitude
          bio
          settings {
            enableDarkMode
            showEmail
            email
            showPhone
            phone
            enableColoredContactButtons
            addedToDirectory
            directoryBio
            enableColoredSocialMediaIcons
            socialAccountStyleType

            enableFacebookPixel
            facebookPixelId
            enableGoogleAnalytics
            googleAnalyticsCode
            enableUtmParameters
            utmSource
            utmMedium
            utmCampaign
            blockSearchEngineIndexing
            pageTitle
            metaDescription
            opengraphImageUrl
            removeDefaultBranding
            enableCustomBranding
            customBrandingName
            customBrandingUrl
            enablePasswordProtection
            enableSensitiveContentWarning
            paypalLink
            venmoLink
            payoneerLink
            emailCaptureId
            enableEmailCapture
          }
          verificationStatus
          verifiedGovernmentId
          verifiedEmail
          verifiedPhoneNumber
          verifiedWorkEmail
          featured
          changedUsername
          username {
            id
            username
            premiumType
            expireDate
          }
          links {
            id
            linkType
            linkTitle
            linkImageUrl
            linkColor
            url
            platform
            iconMinimal
            iconColorful
            featured
            shortenedUrl
            order
            startDate
            endDate
            enablePasswordProtection
            note
            biolink {
              id
            }
          }
          category {
            id
            categoryName
            featured
          }
          createdAt
        }
        links {
          id
          linkType
          linkTitle
          linkImageUrl
          linkColor
          url
          shortenedUrl
          order
          platform
          iconMinimal
          iconColorful
          featured
          startDate
          endDate
          enablePasswordProtection
          note
          biolink {
            id
          }
        }
        usernames {
          id
          username
          premiumType
          expireDate
        }
        payments {
          id
          paymentType
          stripeAmountDue
          stripeAmountPaid
          stripeAmountRemaining
          stripeChargeId
          stripeInvoiceCreated
          stripePaymentCurrency
          stripeCustomerId
          stripeCustomerAddress
          stripeCustomerEmail
          stripeCustomerName
          stripeCustomerPhone
          stripeCustomerShipping
          stripeDiscount
          stripeInvoicePdfUrl
          stripeInvoiceUrl
          stripePriceId
          stripeSubscriptionId
          stripeInvoiceNumber
          stripePeriodStart
          stripePeriodEnd
          stripeStatus
          createdAt
        }
        codes {
          id
          type
          code
          discount
          quantity
          expireDate
          createdAt
        }
        referrals {
          id
          referredByEmail
          referredByName
          referredToEmail
          referredToName
          createdAt
          referredBy {
            id
            email
          }
        }
        plan {
          id
          name
          monthlyPrice
          monthlyPriceStripeId
          annualPrice
          annualPriceStripeId
          settings {
            totalBiolinksLimit
            totalLinksLimit
            totalCustomDomainLimit
            darkModeEnabled
            addedToDirectoryEnabled
            customBackHalfEnabled
            noAdsEnabled
            removableBrandingEnabled
            customFooterBrandingEnabled
            coloredLinksEnabled
            googleAnalyticsEnabled
            facebookPixelEnabled
            verifiedCheckmarkEnabled
            linksSchedulingEnabled
            seoEnabled
            socialEnabled
            utmParametersEnabled
            passwordProtectionEnabled
            sensitiveContentWarningEnabled
            leapLinkEnabled
            donationLinkEnabled
            emailCaptureEnabled
          }
          enabledStatus
          visibilityStatus
        }
      }
    }
  }
`;

// export const LOGIN_MUTATION = gql`
//   mutation Login($email: String!, $password: String!) {
//     login(options: { email: $email, password: $password }) {
//       errors {
//         field
//         message
//       }
//       user {
//         id
//         email
//         planExpirationDate
//         planTrialDone
//         language
//         country
//         currentBiolinkId
//         biolinks {
//           id
//           user {
//             id
//           }
//           profilePhotoUrl
//           coverPhotoUrl
//           displayName
//           city
//           state
//           country
//           latitude
//           longitude
//           bio
//           settings {
//             enableDarkMode
//             showEmail
//             email
//             showPhone
//             phone
//             enableColoredContactButtons
//             addedToDirectory
//             directoryBio
//             enableColoredSocialMediaIcons
//             socialAccounts {
//               platform
//               icon
//               link
//             }
//             enableFacebookPixel
//             facebookPixelId
//             enableGoogleAnalytics
//             googleAnalyticsCode
//             enableUtmParameters
//             utmSource
//             utmMedium
//             utmCampaign
//             blockSearchEngineIndexing
//             pageTitle
//             metaDescription
//             opengraphImageUrl
//             removeDefaultBranding
//             enableCustomBranding
//             customBrandingName
//             customBrandingUrl
//             enablePasswordProtection
//             enableSensitiveContentWarning
//             paypalLink
//             venmoLink
//             payoneerLink
//             emailCaptureId
//             enableEmailCapture
//           }
//           verificationStatus
//           verifiedGovernmentId
//           verifiedEmail
//           verifiedPhoneNumber
//           verifiedWorkEmail
//           featured
//           changedUsername
//           username {
//             id
//             username
//             premiumType
//             expireDate
//           }
//           links {
//             id
//             linkType
//             linkTitle
//             linkImageUrl
//             linkColor
//             url
//             shortenedUrl
//             order
//             startDate
//             endDate
//             enablePasswordProtection
//             note
//             biolink {
//               id
//             }
//           }
//           category {
//             id
//             categoryName
//             featured
//           }
//           createdAt
//         }

//         links {
//           id
//           linkType
//           linkTitle
//           linkImageUrl
//           linkColor
//           url
//           shortenedUrl
//           order
//           startDate
//           endDate
//           enablePasswordProtection
//           note
//           biolink {
//             id
//           }
//         }
//         usernames {
//           id
//           username
//           premiumType
//           expireDate
//         }
//         payments {
//           id
//           paymentType
//           stripeAmountDue
//           stripeAmountPaid
//           stripeAmountRemaining
//           stripeChargeId
//           stripeInvoiceCreated
//           stripePaymentCurrency
//           stripeCustomerId
//           stripeCustomerAddress
//           stripeCustomerEmail
//           stripeCustomerName
//           stripeCustomerPhone
//           stripeCustomerShipping
//           stripeDiscount
//           stripeInvoicePdfUrl
//           stripeInvoiceUrl
//           stripePriceId
//           stripeSubscriptionId
//           stripeInvoiceNumber
//           stripePeriodStart
//           stripePeriodEnd
//           stripeStatus
//           createdAt
//         }
//         codes {
//           id
//           type
//           code
//           discount
//           quantity
//           expireDate
//           createdAt
//         }
//         referrals {
//           id
//           referredByEmail
//           referredByName
//           referredToEmail
//           referredToName
//           createdAt
//           referredBy {
//             id
//             email
//           }
//         }
//         plan {
//           id
//           name
//           monthlyPrice
//           monthlyPriceStripeId
//           annualPrice
//           annualPriceStripeId
//           settings {
//             totalBiolinksLimit
//             totalLinksLimit
//             totalCustomDomainLimit
//             darkModeEnabled
//             addedToDirectoryEnabled
//             customBackHalfEnabled
//             noAdsEnabled
//             removableBrandingEnabled
//             customFooterBrandingEnabled
//             coloredLinksEnabled
//             googleAnalyticsEnabled
//             facebookPixelEnabled
//             verifiedCheckmarkEnabled
//             linksSchedulingEnabled
//             seoEnabled
//             socialEnabled
//             utmParametersEnabled
//             passwordProtectionEnabled
//             sensitiveContentWarningEnabled
//             leapLinkEnabled
//             donationLinkEnabled
//             emailCaptureEnabled
//           }
//           enabledStatus
//           visibilityStatus
//         }
//       }
//     }
//   }
// `;

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(options: { email: $email, password: $password }) {
      errors {
        field
        message
      }
      user {
        id
        email
        emailVerifiedAt
        planExpirationDate
        planTrialDone
        planType
        language
        country
        currentBiolinkId
        biolinks {
          id
          user {
            id
          }
          profilePhotoUrl
          coverPhotoUrl
          displayName
          city
          state
          country
          latitude
          longitude
          bio
          settings {
            enableDarkMode
            showEmail
            email
            showPhone
            phone
            enableColoredContactButtons
            addedToDirectory
            directoryBio
            enableColoredSocialMediaIcons
            socialAccountStyleType

            enableFacebookPixel
            facebookPixelId
            enableGoogleAnalytics
            googleAnalyticsCode
            enableUtmParameters
            utmSource
            utmMedium
            utmCampaign
            blockSearchEngineIndexing
            pageTitle
            metaDescription
            opengraphImageUrl
            removeDefaultBranding
            enableCustomBranding
            customBrandingName
            customBrandingUrl
            enablePasswordProtection
            enableSensitiveContentWarning
            paypalLink
            venmoLink
            payoneerLink
            emailCaptureId
            enableEmailCapture
          }
          verificationStatus
          verifiedGovernmentId
          verifiedEmail
          verifiedPhoneNumber
          verifiedWorkEmail
          featured
          changedUsername
          username {
            id
            username
            premiumType
            expireDate
          }
          links {
            id
            linkType
            linkTitle
            linkImageUrl
            linkColor
            url
            shortenedUrl
            platform
            iconMinimal
            iconColorful
            featured
            order
            startDate
            endDate
            enablePasswordProtection
            note
          }
          category {
            id
            categoryName
            featured
          }
          createdAt
        }
        links {
          id
          linkType
          linkTitle
          linkImageUrl
          linkColor
          url
          shortenedUrl
          platform
          iconMinimal
          iconColorful
          featured
          order
          startDate
          endDate
          enablePasswordProtection
          note
        }
        usernames {
          id
          username
          premiumType
          expireDate
        }
        payments {
          id
          paymentType
          stripeAmountDue
          stripeAmountPaid
          stripeAmountRemaining
          stripeChargeId
          stripeInvoiceCreated
          stripePaymentCurrency
          stripeCustomerId
          stripeCustomerAddress
          stripeCustomerEmail
          stripeCustomerName
          stripeCustomerPhone
          stripeCustomerShipping
          stripeDiscount
          stripeInvoicePdfUrl
          stripeInvoiceUrl
          stripePriceId
          stripeSubscriptionId
          stripeInvoiceNumber
          stripePeriodStart
          stripePeriodEnd
          stripeStatus
          createdAt
        }
        codes {
          id
          type
          code
          discount
          quantity
          expireDate
          createdAt
        }
        referrals {
          id
          referredByEmail
          referredByName
          referredToEmail
          referredToName
          createdAt
          referredBy {
            id
            email
          }
        }
        plan {
          id
          name
          monthlyPrice
          monthlyPriceStripeId
          annualPrice
          annualPriceStripeId
          settings {
            totalBiolinksLimit
            totalLinksLimit
            totalCustomDomainLimit
            darkModeEnabled
            addedToDirectoryEnabled
            customBackHalfEnabled
            noAdsEnabled
            removableBrandingEnabled
            customFooterBrandingEnabled
            coloredLinksEnabled
            googleAnalyticsEnabled
            facebookPixelEnabled
            verifiedCheckmarkEnabled
            linksSchedulingEnabled
            seoEnabled
            socialEnabled
            utmParametersEnabled
            passwordProtectionEnabled
            sensitiveContentWarningEnabled
            leapLinkEnabled
            donationLinkEnabled
            emailCaptureEnabled
          }
          enabledStatus
          visibilityStatus
        }
      }
    }
  }
`;
export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      errors {
        errorCode
        field
        message
      }
    }
  }
`;

export const SEND_FORGOT_PASSWORD_EMAIL_MUTATION = gql`
  mutation SendForgotPasswordEmail($email: String!) {
    sendForgotPasswordEmail(options: { email: $email }) {
      errors {
        errorCode
        field
        message
      }
    }
  }
`;

export const VERIFY_FORGOT_PASSWORD_MUTATION = gql`
  mutation VerifyForgotPassword($forgotPasswordCode: String!, $email: String!, $password: String!) {
    verifyForgotPassword(
      forgotPasswordCode: $forgotPasswordCode
      options: { email: $email, password: $password }
    ) {
      errors {
        errorCode
        field
        message
      }
    }
  }
`;
// dashboard
export const UPLOAD_BIOLINK_COVER_PHOTO_MUTATION = gql`
  mutation UploadBiolinkCoverPhoto($coverPhoto: Upload!, $id: String!) {
    uploadBiolinkCoverPhoto(coverPhoto: $coverPhoto, id: $id) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        username {
          id
          username
          premiumType
          expireDate
          createdAt
          updatedAt
        }
        coverPhotoUrl
      }
    }
  }
`;

export const UPLOAD_BIOLINK_PROFILE_PHOTO_MUTATION = gql`
  mutation UploadBiolinkProfilePhoto($profilePhoto: Upload!, $id: String!) {
    uploadBiolinkProfilePhoto(profilePhoto: $profilePhoto, id: $id) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        username {
          id
          username
          premiumType
          expireDate
          createdAt
          updatedAt
        }
        profilePhotoUrl
      }
    }
  }
`;

export const UPDATE_DARK_MODE_OPTIONS_MUTATION = gql`
  mutation UpdateDarkModeOptions($enableDarkMode: Boolean!, $id: String!) {
    updateDarkModeOptions(options: { enableDarkMode: $enableDarkMode }, id: $id) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        username {
          id
          username
        }
        settings {
          email
          enableDarkMode
        }
      }
    }
  }
`;

export const IMPORT_BIOLINK_DETAILS_FROM_LINKTREE_PROFILE_MUTATION = gql`
  mutation ImportBiolinkDetailsFromLinktreeProfile($linktreeUsername: String!, $id: String!) {
    importBiolinkDetailsFromLinktreeProfile(linktreeUsername: $linktreeUsername, id: $id) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        user {
          id
        }
        profilePhotoUrl
        coverPhotoUrl
        displayName
        city
        state
        country
        latitude
        longitude
        bio
        settings {
          enableDarkMode
          showEmail
          email
          showPhone
          phone
          enableColoredContactButtons
          addedToDirectory
          directoryBio
          enableColoredSocialMediaIcons
          socialAccountStyleType

          enableFacebookPixel
          facebookPixelId
          enableGoogleAnalytics
          googleAnalyticsCode
          enableUtmParameters
          utmSource
          utmMedium
          utmCampaign
          blockSearchEngineIndexing
          pageTitle
          metaDescription
          opengraphImageUrl
          removeDefaultBranding
          enableCustomBranding
          customBrandingName
          customBrandingUrl
          enablePasswordProtection
          enableSensitiveContentWarning
          paypalLink
          venmoLink
          payoneerLink
          emailCaptureId
          enableEmailCapture
        }
        verificationStatus
        verifiedGovernmentId
        verifiedEmail
        verifiedPhoneNumber
        verifiedWorkEmail
        featured
        changedUsername
        username {
          id
          username
          premiumType
          expireDate
        }
        links {
          id
          linkType
          linkTitle
          linkImageUrl
          linkColor
          url
          shortenedUrl
          order
          platform
          iconMinimal
          iconColorful
          featured
          startDate
          endDate
          enablePasswordProtection
          note
          biolink {
            id
          }
        }
        category {
          id
          categoryName
          featured
        }
        createdAt
      }
    }
  }
`;

export const UPDATE_BIOLINK_MUTATION = gql`
  mutation UpdateBiolink(
    $displayName: String
    $city: String
    $state: String
    $country: String
    $bio: String
    $categoryId: Float
    $id: String!
  ) {
    updateBiolink(
      options: {
        displayName: $displayName
        city: $city
        state: $state
        country: $country
        bio: $bio
        categoryId: $categoryId
      }
      id: $id
    ) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        username {
          id
          username
          premiumType
          expireDate
          createdAt
          updatedAt
        }
        displayName
        city
        state
        country
        latitude
        longitude
        bio
        category {
          id
          categoryName
        }
      }
    }
  }
`;

export const UPDATE_CONTACT_BUTTON_SETTINGS_MUTATION = gql`
  mutation UpdateContactButtonSettings(
    $showEmail: Boolean
    $email: String
    $showPhone: Boolean
    $phone: String
    $enableColoredContactButtons: Boolean
    $id: String!
  ) {
    updateContactButtonSettings(
      options: {
        showEmail: $showEmail
        email: $email
        showPhone: $showPhone
        phone: $phone
        enableColoredContactButtons: $enableColoredContactButtons
      }
      id: $id
    ) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        username {
          id
          username
          premiumType
          expireDate
          createdAt
          updatedAt
        }
        settings {
          showEmail
          email
          showPhone
          phone
          enableColoredContactButtons
        }
      }
    }
  }
`;

export const UPDATE_SOCIAL_ACCOUNTS_SETTINGS_MUTATION = gql`
  mutation UpdateSocialAccountsSettings(
    $id: String!
    $enableColoredSocialMediaIcons: Boolean
    $socialAccountStyleType: String
  ) {
    updateSocialAccountsSettings(
      options: {
        enableColoredSocialMediaIcons: $enableColoredSocialMediaIcons
        socialAccountStyleType: $socialAccountStyleType
      }
      id: $id
    ) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        username {
          id
          username
          premiumType
          expireDate
          createdAt
          updatedAt
        }
        settings {
          email
          enableColoredSocialMediaIcons
          socialAccountStyleType
        }
        links {
          id
          linkType
          platform
          iconMinimal
          iconColorful
          featured
        }
      }
    }
  }
`;

export const UPDATE_INTEGRATION_SETTINGS_MUTATION = gql`
  mutation UpdateIntegrationSettings(
    $id: String!
    $enableFacebookPixel: Boolean
    $facebookPixelId: String
    $enableGoogleAnalytics: Boolean
    $googleAnalyticsCode: String
    $enableEmailCapture: Boolean
    $emailCaptureId: String
  ) {
    updateIntegrationSettings(
      options: {
        enableFacebookPixel: $enableFacebookPixel
        facebookPixelId: $facebookPixelId
        enableGoogleAnalytics: $enableGoogleAnalytics
        googleAnalyticsCode: $googleAnalyticsCode
        enableEmailCapture: $enableEmailCapture
        emailCaptureId: $emailCaptureId
      }
      id: $id
    ) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        settings {
          email
          enableGoogleAnalytics
          enableFacebookPixel
          googleAnalyticsCode
          facebookPixelId
          enableEmailCapture
          emailCaptureId
        }
      }
    }
  }
`;

export const SORT_BIOLINK_LINKS_MUTATION = gql`
  mutation SortBiolinkLinks($id: String!, $linkIds: [String!]) {
    sortBiolinkLinks(id: $id, options: { linkIds: $linkIds }) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        username {
          id
          username
          premiumType
          expireDate
          createdAt
          updatedAt
        }
        links {
          id
          linkType
          linkTitle
          linkImageUrl
          linkColor
          url
          platform
          iconMinimal
          iconColorful
          featured
          shortenedUrl
          order
          startDate
          endDate
          enablePasswordProtection
          note
          biolink {
            id
          }
        }
      }
    }
  }
`;

export const UPDATE_DIRECTORY_SETTINGS_MUTATION = gql`
  mutation UpdateDirectorySettings(
    $addedToDirectory: Boolean!
    $directoryBio: String!
    $id: String!
  ) {
    updateDirectorySettings(
      options: { addedToDirectory: $addedToDirectory, directoryBio: $directoryBio }
      id: $id
    ) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        username {
          id
          username
          premiumType
          expireDate
          createdAt
          updatedAt
        }
        settings {
          email
          directoryBio
          addedToDirectory
        }
      }
    }
  }
`;

export const UPDATE_UTM_PARAMETER_SETTINGS_MUTATION = gql`
  mutation UpdateUTMParameterSettings(
    $id: String!
    $enableUtmParameters: Boolean
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
  ) {
    updateUTMParameterSettings(
      id: $id
      options: {
        enableUtmParameters: $enableUtmParameters
        utmSource: $utmSource
        utmMedium: $utmMedium
        utmCampaign: $utmCampaign
      }
    ) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        settings {
          email
          enableUtmParameters
          utmSource
          utmMedium
          utmCampaign
        }
      }
    }
  }
`;

export const UPDATE_SEO_SETTINGS_MUTATION = gql`
  mutation UpdateSEOSettings(
    $id: String!
    $blockSearchEngineIndexing: Boolean
    $pageTitle: String
    $metaDescription: String
    $opengraphImageUrl: String
  ) {
    updateSEOSettings(
      id: $id
      options: {
        blockSearchEngineIndexing: $blockSearchEngineIndexing
        pageTitle: $pageTitle
        metaDescription: $metaDescription
        opengraphImageUrl: $opengraphImageUrl
      }
    ) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        username {
          id
          username
        }
        settings {
          blockSearchEngineIndexing
          pageTitle
          metaDescription
          opengraphImageUrl
          email
        }
      }
    }
  }
`;

export const UPDATE_BRANDING_SETTINGS_MUTATION = gql`
  mutation UpdateBrandingSettings(
    $id: String!
    $removeDefaultBranding: Boolean
    $enableCustomBranding: Boolean
    $customBrandingName: String
    $customBrandingUrl: String
  ) {
    updateBrandingSettings(
      id: $id
      options: {
        removeDefaultBranding: $removeDefaultBranding
        enableCustomBranding: $enableCustomBranding
        customBrandingName: $customBrandingName
        customBrandingUrl: $customBrandingUrl
      }
    ) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        username {
          id
          username
        }
        settings {
          email
          removeDefaultBranding
          enableCustomBranding
          customBrandingName
          customBrandingUrl
        }
      }
    }
  }
`;

export const UPDATE_PRIVACY_SETTINGS_MUTATION = gql`
  mutation UpdatePrivacySettings(
    $id: String!
    $enablePasswordProtection: Boolean
    $password: String
    $enableSensitiveContentWarning: Boolean
  ) {
    updatePrivacySettings(
      id: $id
      options: {
        enablePasswordProtection: $enablePasswordProtection
        password: $password
        enableSensitiveContentWarning: $enableSensitiveContentWarning
      }
    ) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        username {
          id
          username
        }
        settings {
          email
          enablePasswordProtection
          enableSensitiveContentWarning
        }
      }
    }
  }
`;

export const UPDATE_DONATION_SETTINGS_MUTATION = gql`
  mutation UpdateDonationSettings(
    $id: String!
    $paypalLink: String
    $venmoLink: String
    $payoneerLink: String
  ) {
    updateDonationSettings(
      id: $id
      options: { paypalLink: $paypalLink, venmoLink: $venmoLink, payoneerLink: $payoneerLink }
    ) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        username {
          id
          username
        }
        settings {
          paypalLink
          venmoLink
          payoneerLink
          email
        }
      }
    }
  }
`;

export const REMOVE_BIOLINK_MUTATION = gql`
  mutation removeBiolink($id: String!) {
    removeBiolink(id: $id) {
      errors {
        errorCode
        field
        message
      }
    }
  }
`;

// referrals
export const CREATE_REFERRAL_MUTATION = gql`
  mutation CreateReferrals(
    $userInfo: [ReferredUserInfo!]!
    $referredByEmail: String!
    $referredByName: String!
  ) {
    createReferrals(
      referralOptions: {
        userInfo: $userInfo
        referredByEmail: $referredByEmail
        referredByName: $referredByName
      }
    ) {
      errors {
        message
      }
    }
  }
`;
// accounts
export const CHANGE_USER_ACCOUNT_INFO_MUTATION = gql`
  mutation ChangeUserAccountInfo($biolinkId: String!, $email: String, $username: String) {
    changeUserAccountInfo(biolinkId: $biolinkId, options: { email: $email, username: $username }) {
      errors {
        errorCode
        field
        message
      }
    }
  }
`;

export const CREATE_NEW_BIOLINK_MUTATION = gql`
  mutation CreateNewBioLink($username: String!) {
    createNewBiolink(options: { username: $username }) {
      errors {
        errorCode
        field
        message
      }
      biolink {
        id
        username {
          id
          username
          premiumType
          expireDate
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const CHANGE_USER_PASSWORD_MUTATION = gql`
  mutation ChangeUserPassword($oldPassword: String!, $newPassword: String!) {
    changeUserPassword(options: { oldPassword: $oldPassword, newPassword: $newPassword }) {
      errors {
        errorCode
        field
        message
      }
    }
  }
`;

export const CHANGE_CURRENT_BIOLINKID_MUTATION = gql`
  mutation ChangeCurrentBiolinkId($biolinkId: String!) {
    changeCurrentBiolinkId(biolinkId: $biolinkId) {
      errors {
        errorCode
        field
        message
      }
      user {
        id
        currentBiolinkId
      }
    }
  }
`;

export const DELETE_USER_ACCOUNT = gql`
  mutation DeleteUserAccount($password: String!) {
    deleteUserAccount(options: { password: $password }) {
      errors {
        errorCode
        field
        message
      }
    }
  }
`;
// link shortner
export const CREATE_NEW_LINK = gql`
  mutation CreateNewLink(
    $biolinkId: String
    $url: String!
    $note: String
    $linkTitle: String
    $linkType: String
    $linkColor: String
    $platform: String
  ) {
    createNewLink(
      biolinkId: $biolinkId
      options: {
        url: $url
        note: $note
        linkTitle: $linkTitle
        linkType: $linkType
        linkColor: $linkColor
        platform: $platform
      }
    ) {
      errors {
        errorCode
        field
        message
      }
      link {
        id
        linkType
        linkTitle
        linkImageUrl
        linkColor
        url
        shortenedUrl
        order
        platform
        iconMinimal
        iconColorful
        featured
        startDate
        endDate
        enablePasswordProtection
        note
        biolink {
          id
        }
      }
    }
  }
`;

export const UPDATE_LINK_MUTATION = gql`
  mutation updateLink(
    $biolinkId: String!
    $id: String!
    $url: String!
    $note: String
    $enablePasswordProtection: Boolean
    $password: String
  ) {
    updateLink(
      biolinkId: $biolinkId
      id: $id
      options: {
        url: $url
        note: $note
        enablePasswordProtection: $enablePasswordProtection
        password: $password
      }
    ) {
      errors {
        errorCode
        field
        message
      }
      link {
        id
      }
    }
  }
`;

export const REMOVE_LINK_MUTATION = gql`
  mutation removeLinkByShortenedUrl($id: String!) {
    removeLink(id: $id) {
      errors {
        errorCode
        field
        message
      }
      link {
        id
        url
        shortenedUrl
        enablePasswordProtection
        deletedAt
        biolink {
          id
        }
      }
    }
  }
`;
// verification badge
export const VERIFY_BIO_LINK = gql`
  mutation verifyBiolink(
    $biolinkId: String!
    $username: String!
    $firstName: String!
    $lastName: String!
    $mobileNumber: String!
    $workNumber: String!
    $email: String!
    $websiteLink: String!
    $instagramAccount: String!
    $twitterAccount: String!
    $linkedinAccount: String!
    $photoId: Upload!
    $businessDocument: Upload!
    $otherDocuments: Upload!
    $categoryId: String!
  ) {
    verifyBiolink(
      biolinkId: $biolinkId
      photoId: $photoId
      businessDocument: $businessDocument
      otherDocuments: $otherDocuments
      options: {
        username: $username
        firstName: $firstName
        lastName: $lastName
        mobileNumber: $mobileNumber
        workNumber: $workNumber
        email: $email
        websiteLink: $websiteLink
        instagramAccount: $instagramAccount
        twitterAccount: $twitterAccount
        linkedinAccount: $linkedinAccount
        categoryId: $categoryId
      }
    ) {
      errors {
        errorCode
        field
        message
      }
    }
  }
`;
export const ADD_REPORT = gql`
  mutation addReport(
    $firstName: String!
    $lastName: String!
    $email: String!
    $reportedUrl: String!
    $description: String!
  ) {
    addReport(
      options: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        reportedUrl: $reportedUrl
        description: $description
      }
    ) {
      errors {
        errorCode
        field
        message
      }
    }
  }
`;

export const ADD_SUPPORT = gql`
  mutation AddSupport(
    $fullName: String!
    $email: String!
    $phoneNumber: String!
    $company: String!
    $subject: String!
    $message: String!
  ) {
    addSupport(
      options: {
        fullName: $fullName
        email: $email
        phoneNumber: $phoneNumber
        company: $company
        subject: $subject
        message: $message
      }
    ) {
      errors {
        errorCode
        field
        message
      }
    }
  }
`;

export const SEND_MESSAGE_MUTATION = gql`
  mutation SendMessage($receiverId: String!, $message: String, $attachment: Upload) {
    sendMessage(receiverId: $receiverId, attachment: $attachment, options: { message: $message }) {
      errors {
        errorCode
        field
        message
      }
      message {
        id
        message
        attachmentUrl
        createdAt
        updatedAt
        deletedAt
        sender {
          id
          email
        }
        receiver {
          id
          email
        }
      }
    }
  }
`;

// Follow user

export const FOLLOW_USER_MUTATION = gql`
  mutation FollowBiolink($followingBiolinkId: String!) {
    followBiolink(followingBiolinkId: $followingBiolinkId) {
      errors {
        field
        message
      }
    }
  }
`;

// UnFollow User

export const UNFOLLOW_USER_MUTATION = gql`
  mutation unfollowBiolink($followingBiolinkId: String!) {
    unfollowBiolink(followingBiolinkId: $followingBiolinkId) {
      errors {
        field
        message
      }
    }
  }
`;
export const VERIFY_USER_EMAIL_BY_ACTIVATION_CODE = gql`
  mutation VerifyUserEmailByActivationCode($emailActivationCode: String!) {
    verifyUserEmailByActivationCode(emailActivationCode: $emailActivationCode) {
      errors {
        errorCode
        field
        message
      }
    }
  }
`;
