import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_PLANS } from '../../../graphql/Queries';
import ToggleSwitch from '../../../components/ui/ToggleSwitch';
import images from '../../../assets/images';

const Checkout = () => {
  const [switchButton, setswitchButton] = useState(false);
  useEffect(() => {}, [switchButton]);
  const [itemData, setItemData] = useState([]);
  const { data } = useQuery(GET_ALL_PLANS);

  useEffect(() => {
    if (data) {
      setItemData(data);
    }
  }, [data]);

  useEffect(() => {
    setItemData(itemData);
  }, []);

  return (
    <div className="main-panel">
      <div className="content">
        <div className="page-inner ">
          <div className="row mt--2">
            <div className="col-md-12">
              <div className="card full-height">
                <div className="card-header">
                  <div className="card-title blue-text big-title">Subscription Details</div>
                </div>
                <div className="card-body pt-5 px-5">
                  <p>Add Personal -------------------------------------------$12/month</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="payment-form">
                        <h1>Payment</h1>
                        <div className="card-icons">
                          <img src="https://img.icons8.com/color/48/000000/visa.png" alt="" />
                          <img
                            src="https://img.icons8.com/color/48/000000/mastercard-logo.png"
                            alt=""
                          />
                          <img src="https://img.icons8.com/color/48/000000/maestro.png" alt="" />
                        </div>
                        <div className="form-list ">
                          <div className="form-group form-floating-label">
                            <input
                              id="inputFloatingLabel2"
                              type="text"
                              className="form-control input-solid"
                              required
                              placeholder="Cardholder's name:"
                            />
                            {/* <label htmlFor="inputFloatingLabel2" className="placeholder">
                              Cardholder's name:
                            </label> */}
                          </div>
                          <div className="form-group form-floating-label">
                            <input
                              id="inputFloatingLabel3"
                              type="text"
                              className="form-control input-solid"
                              required
                              placeholder="Card Number:"
                            />
                            {/* <label htmlFor="inputFloatingLabel3" className="placeholder">
                              Card Number:
                            </label> */}
                          </div>
                          <div className="form-group form-floating-label">
                            <input
                              id="inputFloatingLabel4"
                              type="text"
                              className="form-control input-solid"
                              required
                              placeholder="Expiry date:"
                            />
                            {/* <label htmlFor="inputFloatingLabel4" className="placeholder">
                              Expiry date:
                            </label> */}
                          </div>
                          <div className="form-group form-floating-label">
                            <input
                              id="inputFloatingLabel5"
                              type="text"
                              className="form-control input-solid"
                              required
                              placeholder="CVV"
                            />
                            {/* <label htmlFor="inputFloatingLabel5" className="placeholder">
                              CVV
                            </label> */}
                          </div>
                          <div className="form-group ">
                            <button type="button" className="btn btn-primary">
                              Pay $5
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="payment-img">
                        <img src={images.paymentimg} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
