import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import images from '../../../assets/images';
import socialMediaIcons from '../../../assets/socialMediaIcons';
import validate from '../../../utils/validations';

const PersonDirectoryDetail = (props) => {
  if (!props?.location?.state) {
    props.history.push('/directory');
    return null;
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isLogin = useSelector((state) => state.auth.isAuthenticated);
  const { profileDatas, nodeId } = props?.location?.state;
  const [currentUrls, setCurrentUrls] = useState({
    profileDatas,
    nodeId,
  });
  const [currentProfile, setCurrentProfile] = useState(0);
  const [currentData, setCurrentData] = useState('');
  const [profilePagination, setProfilePagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
    endCursor: '',
  });
  const [enableColoredSocialMediaIcons,setEnableColoredSocialMediaIcons] = useState('');
  const [socialAccountStyleType,setSocialAccountStyleType] = useState('');
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  useEffect(()=>{    
    setEnableColoredSocialMediaIcons(currentBiolink?.settings?.enableColoredSocialMediaIcons);
    setSocialAccountStyleType(currentBiolink?.settings?.socialAccountStyleType);},[currentBiolink]);

  useEffect(() => {
    if (currentUrls?.profileDatas?.pageInfo) {
      setProfilePagination({
        hasNextPage: currentUrls?.profileDatas?.pageInfo.hasNextPage,
        hasPreviousPage: currentUrls?.profileDatas?.pageInfo.hasPreviousPage,
        startCursor: currentUrls?.profileDatas?.pageInfo.startCursor,
        endCursor: currentUrls?.profileDatas?.pageInfo.endCursor,
      });
    }

    setCurrentData(currentUrls?.profileDatas?.edges);
    setCurrentProfile(currentUrls.nodeId);
  }, [currentUrls]);

  const onClickProfile = (val) => {
    if (val || val === 0) {
      setCurrentProfile(val);
    }
  };

  const createAccountHandler = () => {
    alert('Create an account...');
  };

  return (
    <>
      <div className="container">
        <div className="page-inner biolink-inner  ">
          {currentData[currentProfile ? currentProfile : 0]?.node ? (
            <>
              <div key={currentData[currentProfile ? currentProfile : 0]?.node.id}>
                <div className="white-box mb-5">
                  <div className="row mt--2">
                    <div className="col-md-12">
                      <div className="preview-wrapper md-header-area mt-5 pt-5 personal-directory row biolink-directry">
                        <div className="col-md-5 biolink-first">
                          <div className="profile-box ">
                            <div className="user-profile">
                              <img
                                src={
                                  validate.imageValidate(
                                    currentData[currentProfile ? currentProfile : 0]?.node
                                      ?.profilePhotoUrl,
                                  )
                                    ? currentData[currentProfile ? currentProfile : 0]?.node
                                        ?.profilePhotoUrl
                                    : images.dummy_img
                                }
                                alt=""
                              />
                              <div className="file-btn">
                                <img src="img/pluse.svg" alt="" />
                              </div>
                            </div>
                            <div className="file-btn">
                              <img src="img/pluse.svg" alt="" />
                            </div>
                          </div>

                          <h3 className="md-usernm mt-2">
                            {currentData[currentProfile]?.node.username?.username}
                          </h3>
                          <h2>
                                {
                                  currentData[currentProfile ? currentProfile : 0]?.node?.category
                                    ?.categoryName
                                }
                              </h2>
                        </div>
                        <div className="user-detail user-info col-md-7 ">
                          <ul className="preview-list">
                             <li>
                              <span className="label-box">
                                <i className="fa fa-map-marker" />
                                Address:
                              </span>
                              <span className="label-detail">
                                {currentData[currentProfile]?.node?.city},
                                {currentData[currentProfile]?.node?.state}
                              </span>
                            </li>
                            <li>
                              <p>{currentData[currentProfile]?.node.bio}</p>
                            </li>
                            <li>
                              <span className="label-box">
                                <i className="fa fa-envelope" />
                                Email:
                              </span>
                              <span
                                className={
                                  !isLogin ? 'label-detail blur-on-lose-focus' : 'label-detail'
                                }
                              >
                                {isLogin
                                  ? currentData[currentProfile]?.node?.settings?.email
                                  : '##################'}
                              </span>
                            </li>
                            <li>
                              <span className="label-box">
                                <i className="fa fa-phone" />
                                Call:
                              </span>
                              <span
                                className={
                                  !isLogin ? 'label-detail blur-on-lose-focus' : 'label-detail'
                                }
                              >
                                {isLogin
                                  ? currentData[currentProfile]?.node.settings.phone
                                  : '##########'}
                              </span>
                            </li>
                            {/* <li><span class="label-box">Donations:</span> <span class="label-detail">  <div class="donation-box"><a href="#"><img src="img/paypal.png" alt=""/></a>
                        <a href="#"><img src="img/vi.png" alt=""/></a>
                        <a href="#"><img src="img/pay.png" alt=""/></a></div>
</span>
</li> */}
                          </ul>
                          <div className="socialicon">
                          <div
                  className={`social-links ${
                    enableColoredSocialMediaIcons == true ? 'color' : 'minimal'
                  }
                  ${
                    socialAccountStyleType == 'Round'
                      ? 'Round'
                      : socialAccountStyleType == 'Square'
                      ? 'Square'
                      : 'OneClick'
                  }
                  `}
                >
                          {currentBiolink?.links
                            ? currentBiolink?.links.map((sdata, index) => (
                           
                            enableColoredSocialMediaIcons == true ? (<>
                            <a key={index} href={`${sdata.url}`} target="_blank" className="btn-admin social-media-link"
                            > <img src={sdata.iconColorful} alt="" /> </a></>
                            ) : <a key={index} href={`${sdata.url}`} target="_blank" className="btn-admin social-media-link"
                            > <img src={sdata.iconMinimal} alt="" /> </a>

                          
                            )
                            
                            )
                            : null}

</div>
                            {/* <span>
                              <i className="fa fa-facebook-square" /> Facebook
                            </span> */}
                          </div>
                        </div>
                        <div className="pagination-box">
                          {currentProfile != 0 ? (
                            <a
                              onClick={() =>
                                onClickProfile(currentProfile ? currentProfile - 1 : 0)
                              }
                              className="nav-pagi prev float-left"
                            >
                              <i className="fa fa-angle-left" />
                            </a>
                          ) : null}
                          {currentData?.length - 1 != currentProfile ? (
                            <a
                              onClick={() =>
                                onClickProfile(currentProfile ? currentProfile + 1 : 1)
                              }
                              className="nav-pagi next float-right"
                            >
                              <i className="fa fa-angle-right" />
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt--2">
                  <div className="col-md-12">
                    <div className="card full-height">
                      <div className="card-body biolink-directry-col">
                        <div className="row listing-bottom minus-margin">
                          {currentData[currentProfile]?.node?.links
                            ? currentData[currentProfile]?.node?.links.map((ldata, index) => {
                                return (
                                  <div className="col-md-12 mt-3">
                                    <div className="listurl clearfix">
                                      <a href={`${ldata.url}`}>
                                        <div className="personal-social-detail">
                                          <h3>{ldata.linkTitle}</h3>
                                          <p>{ldata.url}</p>
                                        </div>
                                      </a>
                                      <div className="prsonal-list-icon">
                                        <img
                                          src="https://avyatated.com/static/media/urlicon.321fe65c.svg"
                                          alt=""
                                        />
                                        {/* <button className="delbtn" type="button">
                                          <i className="fa fa-trash" aria-hidden="true" />
                                        </button> */}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PersonDirectoryDetail;
