import React from 'react';
import images from '../../assets/images';
import Claim from './Claim';

const Feature = (props) => {
  return (
    <>
      <div className="container pt-5">
        <div className="row mt--2">
          <div className="col-md-12">
            <div className="card full-height">
              <div className="center-heading black-text heading-area">
                <div className="sub-heading">Features</div>
                <h1>Our Features that you can use</h1>
              </div>
              <div className="card-body">
                <section className="feature-wrapper section-space">
                  <div className="row feature-row">
                    <div className="col-md-6 order-md-1">
                      <div className="feature-image">
                        <img src={images.featureOne} alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 order-md-2">
                      <div className="feature-detail">
                        <h1>
                          Easy Link <span>Shortening</span> and <span>Tracking </span>
                        </h1>
                        <p>
                          Use branded short links to promote you and your brands. Get real-time
                          traffic statistics for your links.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row feature-row">
                    <div className="col-md-6 order-md-2">
                      <div className="feature-image">
                        <img src={images.featureTwo} alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 order-md-1">
                      <div className="feature-detail">
                        <h1>
                          No <span>More</span> Restrictions. Embed All Social Media Links, Videos,
                          and Schedulers.
                        </h1>
                        <p>
                          Share all your Social Media profiles, important links, and curated content
                          with just one URL.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row feature-row">
                    <div className="col-md-6 order-md-1">
                      <div className="feature-image">
                        <img src={images.featureThree} alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 order-md-2">
                      <div className="feature-detail">
                        <h1>
                          Get Featured In Our Bio Directory, So people can search and find you.
                        </h1>
                        <p>
                          You’ve got everything you need to be discovered. Grow your audience by
                          building your profile in our searchable directory.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Claim />
      </div>
    </>
  );
};

export default Feature;
