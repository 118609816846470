import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import List from 'react-smooth-draggable-list';
import { Button, Modal, Table } from 'react-bootstrap';

import images from '../../../assets/images';

import {
  REMOVE_LINK_MUTATION,
  UPDATE_LINK_MUTATION,
  SORT_BIOLINK_LINKS_MUTATION,
} from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';
import EditLinksForm from './EditLinksForm';

const LinksList = () => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const [linksList, setLinksList] = useState([]);
  const LinksLength = currentBiolink.links.length;
  const [isEditing, setisEditing] = useState(false);

  const [currentOrder, setCurrentOrder] = useState([]);
  // const defaultOrder = [0, 1, 2];
  const [initialOrder, setInitialOrder] = useState(() => {
    const temp = [];
    currentBiolink.links.forEach((val, i) => {
      temp.push(i);
    });
    return temp;
  });
  const [editSwitch, setEditSwitch] = useState('');

  const [form, setForm] = useState({
    id: '',
    url: '',
    urlError: [],
    shortenedUrl: '',
    note: '',
    enablePasswordProtection: false,
    password: '',
    passwordError: [],
  });
  const [defaultOrder, setDefaultOrder] = useState(initialOrder);

  const [sortBiolinkLinksMutation, { data: orderData }] = useNetwork(SORT_BIOLINK_LINKS_MUTATION);
  const [removeLinkMutation, { data: deleteData }] = useNetwork(REMOVE_LINK_MUTATION);
  const [updateLink, { data: updateData }] = useNetwork(UPDATE_LINK_MUTATION);

  useEffect(() => {
    setLinksList(() => {
      return (
        currentBiolink.links.slice().sort((a, b) => {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        }) || []
      );
    });

    setInitialOrder(() => {
      const temp = [];
      currentBiolink.links.forEach((val, i) => {
        temp.push(i);
      });
      return temp;
    });
  }, [currentBiolink.links]);

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onReOrderHandler = (newOrder) => {
    setCurrentOrder(newOrder);
    setDefaultOrder(newOrder);
  };

  useEffect(() => {
    if (orderData?.sortBiolinkLinks?.biolink) {
      setDefaultOrder(initialOrder);
    }
  }, [orderData]);

  const onSaveHandler = (evt) => {
    evt.preventDefault();
    // setisEditing(false);
    if (!currentOrder.length) return;
    const updatedOrder = [];
    currentOrder.forEach((item) => {
      updatedOrder.push(linksList[item].id);
    });

    sortBiolinkLinksMutation({
      variables: {
        id: currentBiolink.id,
        linkIds: updatedOrder,
      },
    });
    // setisEditing(false);
    setForm({
      ...form,
      id: '',
      url: '',
      shortenedUrl: '',
      note: '',
      enablePasswordProtection: false,
      password: '',
    });
  };

  const onDeleteHandler = (id) => {
    removeLinkMutation({
      variables: {
        id,
      },
    });
  };
  // window.location.reload(true);

  const handleModalShow = (item) => {
    if (item.enablePasswordProtection) {
      updateLink({
        variables: {
          biolinkId: currentBiolinkId,
          id: item.id,
          url: item.url,
          enablePasswordProtection: false,
          password: '',
        },
      });
      return;
    }
    if (isEditing) {
      setForm({
        ...form,
        enablePasswordProtection: true,
      });
      setModalShow(true);
      return;
    }
    setModalShow(true);
    setForm({
      ...form,
      biolinkId: currentBiolinkId,
      id: item.id,
      url: item.url,
      note: item.note,
      enablePasswordProtection: true,
    });
  };

  const onEditHandler = (item) => {
    setisEditing(true);
    setForm({
      ...form,
      id: item.id,
      url: item.url,
      shortenedUrl: item.shortenedUrl,
      note: item.note,
      enablePasswordProtection: item.enablePasswordProtection,
    });
  };

  const onCancelHandler = () => {
    setisEditing(false);
    setForm({
      ...form,
      id: '',
      url: '',
      shortenedUrl: '',
      note: '',
      enablePasswordProtection: false,
      password: '',
    });
  };

  const onSaveHandlers = () => {
    const urlError = [];

    if (!form.url) urlError.push('URL cannot be empty');
    setForm({
      ...form,
      urlError,
    });

    if (urlError.length) return;

    updateLink({
      variables: {
        biolinkId: currentBiolink.id,
        id: form.id,
        url: form.url,
        note: form.note,
      },
    });
    onCancelHandler();
  };

  const renderList = () => {
    return (
      <>
        <List
          rowHeight={80}
          className="bg-new"
          onReOrder={(newOrder) => onReOrderHandler(newOrder)}
        >
          {linksList.map((item, index) => (
            <List.Item
              className="listurl"
              key={index}
              // order={order}
            >
              {item.linkType == 'Embed' ? (
                <>
                  {/* <div className="newFrame" style={{ display: 'flex' }}> */}
                  {/* <iframe
                  width="250"
                  // height="80"
                  src={item.url}
                  title={item.linkTitle}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                /> */}
                  {/* <iframe src={item.url} allow="autoplay; encrypted-media" title="video" /> */}
                  {/* <div className="personal-social-detail" style={{ cursor: 'grab' }}>
                    <h3>{item.linkTitle}</h3>
                    <p>{item.url}</p>
                  </div> */}

                  {/* *****Edit Code Here ****** */}
                  {/* </div> */}
                </>
              ) : item.linkType == 'Embed' ? (
                <div
                  key={index}
                  className="persional-row link-section"
                  style={{
                    padding: 0,
                    borderRadius: 0,
                    marginBottom: 0,
                    color: item.linkColor,
                  }}
                >
                  <hr
                    style={{
                      height: '10px',
                      borderRadius: '5px',
                    }}
                  />
                </div>
              ) : (
                <>
                  {/* <h3>{item.linkTitle}</h3>
                <p>{item.url}</p> */}

                  {/* s</a> */}
                </>
              )}
              {/* <div className="iconyout">
              <img src={images.youtube_icon} alt="" />
            </div>
            <div className="namelist">
              <h3>{item.linkTitle}</h3>
              <p>{item.url}</p>
            </div> */}

              {/* ******Edit code Here****** */}

              <div className="personal-social-detail" style={{ cursor: 'grab' }}>
                {isEditing && form.shortenedUrl === item.shortenedUrl ? (
                  <div className="shorturl-edit-form">
                    <div className="shorturl-edit-form-input-group">
                      <label>Long URL:</label>
                      <input
                        type="text"
                        placeholder="Enter the link here"
                        className="input-design short-link-input"
                        name="url"
                        value={form.url}
                        onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      />
                    </div>
                    <div className="shorturl-edit-form-input-group">
                      <label>Note:</label>
                      <input
                        type="text"
                        placeholder="Enter a note (optional)"
                        className="input-design short-link-input"
                        name="note"
                        value={form.note}
                        onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="urllist">
                      <a
                        href={`${item.url}`}
                        target="blank_"
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          cursor: 'grab',
                        }}
                      >
                        {item.url}
                      </a>
                    </div>
                    {item.note ? (
                      <div style={{ marginTop: '5px' }}>
                        <span>Note: {item.note}</span>
                      </div>
                    ) : null}
                  </>
                )}
              </div>

              <div className="prsonal-list-icon">
                <img
                  className="mr-2"
                  src="https://avyatated.com/static/media/urlicon.321fe65c.svg"
                  alt=""
                />
                {isEditing && form.shortenedUrl === item.shortenedUrl ? (
                  <button
                    className="none links-action-btn ml-2 "
                    type="button"
                    onClick={() => onSaveHandlers()}
                  >
                    <i className="fa fa-save" aria-hidden="true" />
                  </button>
                ) : (
                  // <button
                  //   className="none links-action-btn ml-2  edit-sect"
                  //   type="button"
                  //   onClick={() => onEditHandler(item)}
                  // >
                  //   <i className="fa fa-pencil links-action-btn" aria-hidden="true" />
                  // </button>
                  <a
                    onClick={() => setEditSwitch(item)}
                    href="#modal-133"
                    data-toggle="modal"
                    data-dismiss="modal"
                    className="btn-sm"
                  >
                    <i className="fa fa-pencil links-action-btn" aria-hidden="true" />
                  </a>
                )}

                {isEditing && form.shortenedUrl === item.shortenedUrl ? (
                  <button
                    className="none links-action-btn delbtn"
                    type="button"
                    onClick={() => onCancelHandler(item.url)}
                  >
                    <i className="fa fa-times" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    className="delbtn links-action-btn"
                    type="button"
                    onClick={() => onDeleteHandler(item.id)}
                  >
                    <i className="fa fa-trash" aria-hidden="true" />
                  </button>
                )}
              </div>
              {/* </> */}

              {/* <div className="prsonal-list-icon">
              <img src={images.urlicon} alt="" />
              <div>
              <button className="delbtn" type="button" onClick={() => onDeleteHandler(item.id)}>
                <i className="fa fa-trash" aria-hidden="true" />
              </button> */}
              {/* </div> */}
              {/* </div> */}
            </List.Item>
          ))}
        </List>
        <EditLinksForm item={editSwitch} />
      </>
    );
  };

  return (
    <div className="card-body">
      <div className="listing-bottom">
        {linksList.length ? (
          renderList()
        ) : (
          <div style={{ margin: '40px' }}>No Links found. Try adding some...</div>
        )}
        <button type="button" className="btn btn-primary" onClick={(evt) => onSaveHandler(evt)}>
          Save changes
        </button>
      </div>
    </div>
  );
};

export default LinksList;
