import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ErrorSpan from '../../../components/ui/errorSpan/ErrorSpan';
import ToggleSwitch from '../../../components/ui/ToggleSwitch';
import { UPDATE_SEO_SETTINGS_MUTATION } from '../../../graphql/Mutations';
import useNetwork from '../../../hooks/useNetwork';

const SEOSettingsForm = ({ showUpgrade }) => {
  const currentBiolink = useSelector((state) => state.auth.currentBiolink);
  const currentPlan = useSelector((state) => state.auth.user.plan);
  const initialFormState = {
    blockSearchEngineIndexing: currentBiolink.settings?.blockSearchEngineIndexing || false,
    pageTitle: currentBiolink.settings?.pageTitle || '',
    metaDescription: currentBiolink.settings?.metaDescription || '',
    opengraphImageUrl: currentBiolink.settings?.opengraphImageUrl || '',
    pageTitleError: [],
    metaDescriptionError: [],
    opengraphImageUrlError: [],
  };
  const [form, setForm] = useState(initialFormState);

  const [updateSEOSettings, { data }] = useNetwork(UPDATE_SEO_SETTINGS_MUTATION);

  const onToggle = (button) => {
    if (!currentPlan?.settings.addedToDirectoryEnabled) {
      showUpgrade();
      return;
    }
    if (button === 'blockSearchEngineIndexing' && form[button] === true) {
      setForm({
        ...form,
        blockSearchEngineIndexing: false,
        pageTitle: '',
        metaDescription: '',
        opengraphImageUrl: '',
      });
    } else {
      setForm({
        ...form,
        [button]: !form[button],
      });
    }
  };

  const inputChangeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    if (!currentPlan?.settings.seoEnabled) {
      showUpgrade();
      return;
    }
    let isValidated = true;
    const pageTitleError = [];
    const metaDescriptionError = [];
    const opengraphImageUrlError = [];

    // add validations here
    if (form.blockSearchEngineIndexing && !form.pageTitle) {
      isValidated = false;
      pageTitleError.push('Page Title cannot be empty.');
    }
    if (form.blockSearchEngineIndexing && !form.metaDescription) {
      isValidated = false;
      metaDescriptionError.push('Meta Description cannot be empty.');
    }

    if (form.blockSearchEngineIndexing && !form.opengraphImageUrl) {
      isValidated = false;
      opengraphImageUrlError.push('Opengraph Image Url cannot be empty.');
    }

    setForm({
      ...form,
      pageTitleError,
      metaDescriptionError,
      opengraphImageUrlError,
    });
    if (!isValidated) return;

    // submit form
    updateSEOSettings({
      variables: {
        blockSearchEngineIndexing: form.blockSearchEngineIndexing,
        pageTitle: form.pageTitle,
        metaDescription: form.metaDescription,
        opengraphImageUrl: form.opengraphImageUrl,
        id: currentBiolink.id,
      },
    });
    setForm({
      ...form,
      pageTitle: '',
      metaDescription: '',
      opengraphImageUrl: '',
    });
  };

  return (
    <div className="modal fade" id="modal-7">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">SEO Settings</h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body branding-model">
            <div className="form-list">
              <div className="form-group form-floating-label clearfix">
                <ToggleSwitch
                  currentState={form.blockSearchEngineIndexing}
                  onToggle={() => onToggle('blockSearchEngineIndexing')}
                  id="inputFloatingLabel117"
                  isActive="na"
                />
                <label htmlFor="inputFloatingLabel117" className="placeholder">
                  Enable SEO
                </label>
              </div>
              {form.blockSearchEngineIndexing && (
                <>
                  <div className="form-group form-floating-label clearfix">
                    {/* <input
                      id="inputFloatingLabel19"
                      type="text"
                      className="form-control input-solid"
                      required
                    /> */}
                    <input
                      type="text"
                      id="inputFloatingLabel19"
                      className="form-control input-solid"
                      name="pageTitle"
                      value={form.pageTitle}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      placeholder="Page Title"
                    />
                    {/* <label htmlFor="inputFloatingLabel19" className="placeholder">
                      Page Title
                    </label> */}
                    {form.pageTitleError.length
                      ? form.pageTitleError.map((err, i) => <ErrorSpan key={i} error={err} />)
                      : null}
                  </div>

                  <div className="form-group form-floating-label">
                    {/* <input
                      id="inputFloatingLabel20"
                      type="text"
                      className="form-control input-solid"
                      required
                    /> */}
                    <input
                      type="text"
                      id="inputFloatingLabel20"
                      className="form-control input-solid"
                      name="metaDescription"
                      value={form.metaDescription}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      placeholder="Meta Description"
                    />
                    {/* <label htmlFor="inputFloatingLabel20" className="placeholder">
                      Meta Description
                    </label> */}
                    {form.metaDescriptionError.length
                      ? form.metaDescriptionError.map((err, i) => <ErrorSpan key={i} error={err} />)
                      : null}
                  </div>

                  <div className="form-group form-floating-label">
                    {/* <input
                      id="inputFloatingLabel21"
                      type="text"
                      className="form-control input-solid"
                      required
                    /> */}
                    <input
                      type="text"
                      id="inputFloatingLabel21"
                      className="form-control input-solid"
                      rows="5"
                      name="opengraphImageUrl"
                      value={form.opengraphImageUrl}
                      onChange={(evt) => inputChangeHandler(evt.target.name, evt.target.value)}
                      placeholder="Opengraph Image Url"
                    />
                    {/* <label htmlFor="inputFloatingLabel21" className="placeholder">
                      Opengraph Image Url
                    </label> */}
                    {form.opengraphImageUrlError.length
                      ? form.opengraphImageUrlError.map((err, i) => (
                          <ErrorSpan key={i} error={err} />
                        ))
                      : null}
                  </div>
                </>
              )}

              <button
                type="button"
                className="btn btn-primary"
                onClick={(evt) => onSubmitHandler(evt)}
              >
                Save changes
              </button>
            </div>
          </div>

          <div className="modal-footer">
            <a href="#modal-1" data-toggle="modal" data-dismiss="modal" className="btn btn-default">
              Previous
            </a>
            <button type="button" data-dismiss="modal" className="btn btn-danger">
              Close
            </button>
          </div>
        </div>
        {/* <!-- /.modal-content --> */}
      </div>
      {/* <!-- /.modal-dialog --> */}
    </div>
  );
};

export default SEOSettingsForm;
