import { useLazyQuery, useQuery } from '@apollo/client';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import images from '../../../assets/images';
import validate from '../../../utils/validations';
import {
  FETCH_ALL_CATEGORIES,
  GET_ALL_DIRECTORIES,
  GET_SEARCH_QUERIES,
} from '../../../graphql/Queries';
import * as appActions from '../../../store/actions/app';

const Directory = (props) => {
  const dispatch = useDispatch();

  const limit = 30;
  const [categoryArray, setCategoryArray] = useState([]);
  const [categoryUpdated, setCategoryUpdated] = useState(false);
  const [searchUpdated, setSearchUpdated] = useState(false);
  const [isSectionName, setIsSectionName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [validationCate, setValidationCate] = useState('');
  const [autoSearchValue, setAutoSearchValue] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [items, setItems] = useState([]);
  const [value1, setValue1] = useState('');
  const [inputValue, setInputValue] = useState('');

  let dynamic = '';
  let queryData = '';
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
    endCursor: '',
  });

  const { data } = useQuery(FETCH_ALL_CATEGORIES, {
    variables: {
      first: 0,
    },
  });

  const {
    data: directoryData,
    loading: directoryLoading,
    refetch: directoryRefetch,
    fetchMore: directoryFetchMore,
  } = useQuery(GET_ALL_DIRECTORIES, {
    variables: {
      first: limit,
    },
  });

  const [
    getSearchQueries,
    {
      data: directorySeachData,
      // loading: directorySeachLoading,
      // refetch: directorySeachRefetch,
      // fetchMore: directorySeachFetchMore,
    },
  ] = useLazyQuery(
    GET_SEARCH_QUERIES,
    {
      variables: {
        query: autoSearchValue,
      },
    },
    // {
    //   fetchPolicy: 'cache-and-network',
    // },
  );

  useEffect(() => {
    if (directorySeachData?.getSearchQueries?.results.length) {
      const tempitem = [];
      directorySeachData?.getSearchQueries?.results?.forEach((value, index) => {
        tempitem.push(value);
      });
      setItems(tempitem);
    }
  }, [directorySeachData]);

  useEffect(() => {
    setTimeout(() => {
      setRefresh(!refresh);
    }, 3000);
  }, [items]);

  useEffect(() => {
    directoryRefetch();
  }, []);

  useEffect(() => {
    if (!directoryData?.errors) {
      if (directoryData?.getAllDirectories?.pageInfo) {
        setPagination({
          hasNextPage: directoryData.getAllDirectories.pageInfo.hasNextPage,
          hasPreviousPage: directoryData.getAllDirectories.pageInfo.hasPreviousPage,
          startCursor: directoryData.getAllDirectories.pageInfo.startCursor,
          endCursor: directoryData.getAllDirectories.pageInfo.endCursor,
        });
      }
    }

    dispatch(appActions.isLoading(directoryLoading));
  }, [directoryData, directoryLoading]);

  const loadMoreSearch = () => {
    getSearchQueries({
      variables: {
        query: autoSearchValue,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult;
      },
    });
  };

  const loadMore = () => {
    if (isSectionName == '') {
      // In case empty category & empty seach
      dynamic = pagination.endCursor;
      queryData = '';
    }
    if (isSectionName == 'Search') {
      dynamic = pagination.endCursor;
      queryData = searchQuery;
    }
    if (isSectionName == 'Category') {
      dynamic = pagination.endCursor;
      queryData = searchQuery;
    }

    directoryFetchMore({
      variables: {
        categoryId: categoryArray.length ? categoryArray : null,
        query: queryData, // Directory name
        after: isLoadMore == true ? dynamic : '',
        first: limit,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if ((isSectionName == 'Search' || isSectionName == 'Category') && isLoadMore == false) {
          fetchMoreResult.getAllDirectories.edges = [...fetchMoreResult.getAllDirectories.edges];
        } else {
          fetchMoreResult.getAllDirectories.edges = [
            ...previousResult.getAllDirectories.edges,
            ...fetchMoreResult.getAllDirectories.edges,
          ];
        }
        return fetchMoreResult;
      },
    });
  };

  useEffect(() => {
    const SearchData = loadMoreSearch();
  }, [autoSearchValue]);

  useEffect(() => {
    if (isLoadMore == true) {
      loadMore();
    }
  }, [isLoadMore]);

  const onClickCategory = (id, catetype = null) => {
    // check if alredy exist
    const updatedCategoryArray = [...categoryArray];
    const index = updatedCategoryArray.indexOf(id);
    if (index !== -1) {
      // remove
      updatedCategoryArray.splice(index, 1);
    } else {
      // add

      if (updatedCategoryArray.length === 3) {
        setValidationCate('You can not selete more than 3 category');
        return;
      }
      updatedCategoryArray.push(id);
    }
    setCategoryUpdated(true);
    setCategoryArray(updatedCategoryArray);
    setValidationCate('');
    setIsLoadMore(false);
  };

  useEffect(() => {}, [validationCate]);

  useEffect(() => {
    setCategoryUpdated(false);
    setIsSectionName('Category');
    if (categoryUpdated) {
      loadMore();
    }
  }, [categoryArray]);

  const onSearchDirectory = (e) => {
    setIsLoadMore(false);
    setIsSectionName('Search');
    if (e.target.value) {
      setSearchQuery(e.target.value);
    } else {
      setIsSectionName('Search');
      setSearchQuery('');
    }
    // loadMore();
    setSearchUpdated(true);
  };

  useEffect(() => {
    setSearchUpdated(false);
    if (searchUpdated) {
      loadMore();
    }
  }, [searchQuery]);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const temp = [];
    data?.fetchAllCategories?.edges?.forEach((value) => {
      temp.push({
        label: value.node.categoryName,
        value: value.node.id,
      });
    });
    setOptions(temp);
  }, [data]);

  const handleOnSearch = (string) => {
    if (string == null) {
      string = '';
    }
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setInputValue(string);
    setSearchQuery(string);
    setAutoSearchValue(string);
  };

  const handleOnHover = (result) => {
    // the item hovered
  };

  const handleOnSelect = (item) => {
    // the item selected

    if (item == null) {
      item = '';
    }
    setValue1(item);
    setIsLoadMore(false);
    setIsSectionName('Search');
    setSearchUpdated(true);
    setSearchQuery(item);
  };

  // console.log('testing the data===', \\);
  const handleOnFocus = (item) => {
    // handle focus event
  };

  return (
    <>
      <div className="white-box fron-end-wrapper bg-main-directory">
        <div className="container">
          <div className="row mt--2">
            <div className="col-md-12">
              <div className="card  full-height">
                <div className="center-heading black-text pb-0">
                  <h1 className="mt-0">Search People By Directory</h1>
                </div>
                <div className="card-body">
                  <div className="form-list w-50 mobile-full-width mx-auto">
                    <Autocomplete
                      placeholder="Search Here"
                      value={value1}
                      onChange={(event, newValue) => handleOnSelect(newValue)}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => handleOnSearch(newInputValue)}
                      id="controllable-states-demo"
                      options={items}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                     <i className="fa fa-search search-icon" />
                    {/* <form className="navbar-left navbar-form nav-search mr-md-3">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <button type="submit" className="btn btn-search pr-1">
                            <i className="fa fa-search search-icon" />
                          </button>
                        </div>
                      </div>
                    </form> */}
                  </div>
                  {validationCate ? <div className="categories_class">{validationCate}</div> : null}
                  <div className="all-tab-area">
                    {data?.fetchAllCategories?.edges?.map((value, index) => {
                      return (
                        <a
                          key={index}
                          className={categoryArray.includes(value.node.id) ? 'selected' : ''}
                          style={{ cursor: 'pointer' }}
                          onClick={() => onClickCategory(value.node.id)}
                        >
                          {value.node.categoryName}
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card main-directory full-height">
                <div className="card-body">
                  <div className="main-directory-wrapper directory-width-mob">
                    <div className="row">
                      {directoryData?.getAllDirectories?.edges.length
                        ? directoryData.getAllDirectories.edges.map((value, index) => {
                            return (
                              <div className="col-md-3" key={index}>
                                {directoryData ? (
                                  <div className="main-directory-box green-bg">
                                    <Link
                                      to={{
                                        pathname: '/person_directory_detail',
                                        state: {
                                          profileDatas: directoryData.getAllDirectories,
                                          nodeId: index,
                                        },
                                      }}
                                      id={value.node.id}
                                    >
                                      <div className="directory-profile">
                                        <div className="directory-img">
                                        <img
                                          src={
                                            validate.imageValidate(value.node.profilePhotoUrl)
                                              ? value.node.profilePhotoUrl
                                              : images.dummy_img
                                          }
                                          alt=""
                                        />
                                        </div>
                                        <div className="directory-author">
                                         <h3>{value.node.username?.username}</h3>
                                      <h5>{value.node?.category?.categoryName}</h5>
                                      </div>
                                      </div>
                                      <p>{value.node?.bio?.substring(0, 40)}...</p>
                                    </Link>
                                    <span>
                                      <Link
                                        to={{
                                          pathname: `${value?.node?.username?.username}`,
                                        }}
                                        className="go_to_biolink btn btn-primary"
                                      >
                                        Go to Biolink
                                      </Link>
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            );
                          })
                        : 'No record found'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Directory;
